import React from "react";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

type Props = {
  latitude: number;
  longitude?: number;
  height?: string;
  width?: string;
};

const MapView = ({
  latitude = 6.465422,
  longitude = 3.406448,
  width = "100%",
  height = "250px",
}: Props) => {
  return (
    <APIProvider apiKey={process.env.REACT_APP_APIKEY || ""}>
      <Map
        style={{ width, height }}
        defaultCenter={{ lat: 22.54992, lng: 0 }}
        defaultZoom={17}
        center={{
          lat: latitude,
          lng: longitude,
        }}
        // zoom={17}
      >
        <Marker
          position={{
            lat: latitude,
            lng: longitude,
          }}
        />
      </Map>
    </APIProvider>
  );
};

export default MapView;
