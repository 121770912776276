import { useNavigate } from "react-router-dom";
import StarPrimary from "../../../../components/icons/star-primary";
import { currencyFormat } from "../../../../utils/helper-functions";
import { useDashboardMetric } from "../../../../hooks/use-stats";
import { useState } from "react";
import { paths } from "../../../../routes/paths";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../../redux/reducers/user-slice";

interface ActivityCardProps {}
interface ActivityCardItemProps {
  title: string;
  stats: string | number | undefined;
  percent: string | number;
  action: () => void;
  actionTitle: string;
  isOverallRating?: boolean;
  bgColor?: string;
  borderColor?: string;
  showCurrency?: boolean;
  onChangeCurrency?: (pa: any) => void;
  currencies?: string[];
}

const ActivityCardItem = ({
  title,
  stats,
  percent,
  action,
  actionTitle,
  isOverallRating,
  bgColor,
  borderColor,
  showCurrency,
  onChangeCurrency,
  currencies,
}: ActivityCardItemProps) => (
  <div
    className={`border cursor-pointer flex flex-col gap-3 rounded-xl min-h-24 dark:border-gray800  ${bgColor} ${borderColor}`}
  >
    <div className="px-4 py-4 flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <span className="text-base font-semibold text-gray1000 dark:text-gray200">
          {title}
        </span>
        {showCurrency && (
          <select
            onChange={(e) =>
              onChangeCurrency
                ? onChangeCurrency(e.target.value)
                : console.log(e.target.value)
            }
            className="text-xs font-semibold dark:bg-gray800 dark:text-gray200"
            name="currency"
            id="currency"
          >
            {currencies?.map((item, i) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className="flex items-center gap-2">
        <h1 className="text-4xl break-words font-bold">{stats}</h1>
        {isOverallRating && <StarPrimary />}
      </div>
      <div className="border-b-0 border-bg-gray600">
        <span className="text-gray800 dark:text-gray100 font-medium flex items-center gap-1">
          <span className="flex items-center gap-1">
            {/* <span className="text-gray600 text-xs">- {percent}%</span>
            <span className="text-xs font-semibold"> since joining</span> */}
          </span>
        </span>
      </div>
    </div>
    <hr
      className={`bg-gray600 my-0 py-0 dark:border-gray800  ${borderColor}`}
    />
    <div onClick={action} className="px-4 pb-4 flex gap-2 items-center">
      <span className="text-xs ">{actionTitle}</span>
      <svg
        width="13"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.16663 6.66927H12.8333M12.8333 6.66927L6.99996 0.835938M12.8333 6.66927L6.99996 12.5026"
          stroke="#525252"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
);

const ActivityCard: React.FC<ActivityCardProps> = () => {
  const navigate = useNavigate();
  const profile = useSelector(getCurrentUser);
  const [selectCurrency, setSelectedCurrency] = useState("NGN");
  const { metricData, metrics } = useDashboardMetric(selectCurrency);

  return (
    <div className="grid md:grid-cols-3 gap-5">
      <ActivityCardItem
        title="All Booking"
        stats={metricData?.bookingsCount || 0}
        percent={0}
        action={() => navigate(paths.HOST_DASHBOARD + "/published-listing")}
        actionTitle="My listing"
      />
      <ActivityCardItem
        title="Total earnings"
        stats={currencyFormat(
          metricData?.totalEarnings?.total_earnings || 0,
          selectCurrency
        )}
        percent={0}
        action={() => navigate(`/payment-info`)}
        actionTitle="Payment & Account"
        showCurrency
        onChangeCurrency={(e) => setSelectedCurrency(e)}
        currencies={metrics?.totalEarnings.map((item) => item.currency)}
      />
      <ActivityCardItem
        title="Overall rating"
        stats={metricData?.overallRating || 0}
        percent={0}
        action={() => navigate(`/host-info/${profile?.id}`)}
        actionTitle="View ratings"
        isOverallRating
        bgColor="bg-[#FFF4D1] dark:bg-[#937003]"
        borderColor="border-[#F2C94C] dark:border-[#F2C94C]"
      />
    </div>
  );
};

export default ActivityCard;
