import { useEffect, useState } from "react";
import { CardCheckItem } from "../../../../components/custom/select-input";
import InputField, {
  PhoneInputField,
} from "../../../../components/custom/text-input";
import NavigateButton from "./NavigateButton";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../routes/paths";
import { usePropertyForm } from "../hooks/use-property-form";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../../redux/reducers/user-slice";
import { email_validation } from "../../../../utils/input-validation";
import { FormProvider, useForm } from "react-hook-form";

const contactOptions = [
  { title: "Email", id: "1", icon: "email-icon" },
  { title: "WhatsApp", id: "2", icon: "whatsapp-icon" },
  { title: "Phone", id: "3", icon: "phone-icon" },
];

const ChooseContact = () => {
  const navigate = useNavigate();
  const user = useSelector(getCurrentUser);
  const { propertyFormValue, setPropertyFieldValue } = usePropertyForm();
  const methods = useForm();
  const [selectedContact, setSelectedContact] = useState<string[]>([]);
  const [email, setEmail] = useState<string>(
    propertyFormValue.contact_email.length > 1
      ? propertyFormValue.contact_email
      : user.email || ""
  );
  const [whatsappno, setWhatsappno] = useState<string>(
    propertyFormValue.contact_whatsapp_number || ""
  );
  const [phoneno, setPhoneno] = useState<string>(
    propertyFormValue.contact_phone_number.length > 1
      ? propertyFormValue.contact_phone_number
      : user.phone || ""
  );
  // const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleContactSelection = (id: string) => {
    setSelectedContact((prev) =>
      prev.includes(id) ? prev.filter((x) => x !== id) : [...prev, id]
    );
  };

  const isNextDisabled = () => {
    if (selectedContact.includes("1") && !email) return true;
    if (selectedContact.includes("2") && whatsappno.length <= 1) return true;
    if (selectedContact.includes("3") && phoneno.length <= 1) return true;
    return selectedContact.length === 0;
  };

  const onSubmit = methods.handleSubmit(() => {
    // const newErrors: { [key: string]: string } = {};

    // // WhatsApp and Phone validation
    // // if (selectedContact.includes("2") && whatsappno.length <= 9) {
    // //   newErrors.whatsapp = "WhatsApp number is required";
    // // }

    // // if (selectedContact.includes("3") && phoneno.length <= 9) {
    // //   newErrors.phone = "Phone number is required";
    // // }

    // if (Object.keys(newErrors).length > 0) {
    //   setErrors(newErrors);
    //   return;
    // }

    // All fields are valid
    setPropertyFieldValue({
      ...propertyFormValue,
      contact_email: email,
      contact_phone_number: phoneno,
      contact_whatsapp_number: whatsappno,
    });
    navigate(`${paths.HOST_LANDING}/property-price`);
  });

  useEffect(() => {
    const contactTypes = [];
    if (propertyFormValue.contact_email.length > 1) contactTypes.push("1");
    if (propertyFormValue.contact_phone_number.length > 1)
      contactTypes.push("3");
    if (propertyFormValue.contact_whatsapp_number.length > 1)
      contactTypes.push("2");

    setSelectedContact(contactTypes);
    setWhatsappno(propertyFormValue.contact_whatsapp_number || "");
    setPhoneno(propertyFormValue.contact_phone_number || user.phone || "");
    setEmail(propertyFormValue.contact_email || user.email || "");

    // eslint-disable-next-line
  }, []);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col items-center mx-4 overflow-scroll no-scrollbar relative">
        <p className="font-display text-xl lg:text-3xl font-bold text-gray1000 dark:text-gray200 lg:mx-8 lg:text-center xl:text-center mb-8">
          Choose your contact option
        </p>
        <div className="w-full flex flex-wrap gap-2 justify-center items-center">
          {contactOptions.map((item) => (
            <CardCheckItem
              key={item.id}
              icon={item.icon}
              onSelect={() => handleContactSelection(item.id)}
              title={item.title}
              isSelected={selectedContact.includes(item.id)}
            />
          ))}
        </div>
        <div className="lg:flex overflow-x-scroll items-center w-full gap-1 my-3 no-scrollbar">
          {selectedContact.includes("1") && (
            <InputField
              onChange={(e) => setEmail(e.target.value)}
              placeholder="youremail@eveafterdark.com"
              label="Confirm Email"
              type="email"
              value={email}
              name="email"
              validation={email_validation}
            />
          )}
          {selectedContact.includes("2") && (
            <PhoneInputField
              label="Confirm WhatsApp contact"
              value={whatsappno}
              onChange={(phone: any) => setWhatsappno(phone)}
              inputProps={{
                name: "whatsappno",
                // required: true,
                // autoFocus: true,
              }}
              // isValid={whatsappno.length > 9}
              // errMsg={errors.whatsapp}
            />
          )}
          {selectedContact.includes("3") && (
            <PhoneInputField
              label="Confirm Phone contact"
              value={phoneno}
              onChange={(phone: any) => setPhoneno(phone)}
              inputProps={{
                name: "phoneno",
                // required: true,
                // autoFocus: true,
              }}
              // isValid={phoneno.length > 9}
              // errMsg={errors.phone}
            />
          )}
        </div>
        <NavigateButton
          onBack={() => navigate(-1)}
          disable={isNextDisabled()}
          onNext={onSubmit}
        />
      </div>
    </FormProvider>
  );
};

export default ChooseContact;
