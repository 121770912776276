import React from "react";
import ArrowRight from "../icons/arrow-right";
import ArrowLeft from "../icons/arrow-left";

interface PaginationProps {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1, 2, 3);
      if (currentPage > 4) {
        pageNumbers.push("...");
      }
      if (currentPage > 3 && currentPage < totalPages - 2) {
        pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
      }
      if (currentPage < totalPages - 3) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages - 2, totalPages - 1, totalPages);
    }
    return pageNumbers.filter(
      (value, index, self) =>
        self.indexOf(value) === index &&
        (value !== "..." || self[index - 1] !== "...")
    );
  };

  return (
    <div className="flex justify-between mt-5 pt-5 border-t border-gray400 dark:border-gray800">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`flex md:text-base text-sm items-center gap-2 text-gray800 dark:text-gray100 font-medium ${
          currentPage === 1 ? "cursor-not-allowed" : ""
        }`}
      >
        <ArrowLeft className="dark:stroke-gray100" />
        Previous
      </button>
      <div className="flex gap-2 items-center">
        {getPageNumbers().map((number, index) =>
          typeof number === "string" ? (
            <span key={index} className="px-2">
              ...
            </span>
          ) : (
            <button
              key={index}
              onClick={() => onPageChange(number)}
              className={`md:min-w-10 min-w-5 h-5 md:h-10 text-xs md:text-base flex items-center justify-center rounded-full ${
                currentPage === number
                  ? "bg-primary100 dark:bg-[#FF3333] text-primary400 dark:text-gray100"
                  : "text-gray800"
              }`}
            >
              {number}
            </button>
          )
        )}
      </div>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`flex md:text-base text-sm items-center gap-2 text-gray800 dark:text-gray100 font-medium ${
          currentPage === totalPages ? "cursor-not-allowed" : ""
        }`}
      >
        Next
        <ArrowRight className="dark:stroke-gray100" />
      </button>
    </div>
  );
};

export default Pagination;
