import React from "react";
import Location from "../icons/location";

interface Props {
  title: string;
  desc: string;
  img: string;
  location: string;
}

const PlacesCard = ({ title, desc, img, location }: Props) => {
  return (
    <div className="lg:w-52 lg:max-w-52  w-60 gap-3 flex flex-col lg:my-0 my-4 flex-shrink-0">
      <img src={img} alt="" className="h-50 w-auto rounded-xl" />
      <span className="font-bold text-base font-display text-gray900 dark:text-gray300">
        {title}
      </span>
      <span className="font-display text-sm text-gray800 dark:text-gray300 -mt-1.5">
        {desc}
      </span>
      <div className="flex gap-1 items-center">
        <Location />
        <p className="text-xs text-gray800 font-display dark:text-gray300">
          {location}
        </p>
      </div>
    </div>
  );
};

export default PlacesCard;
