import React from "react";

const Bathroom = ({ ...rest }) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M9.48143 7.4445V8.77783M9.48143 3.4445V4.77783M12.4814 6.89227V8.2256M12.4814 2.89227V4.2256M14.333 0.777832H5.68142C4.56132 0.777832 4.00127 0.777832 3.57344 0.995819C3.19712 1.18757 2.89116 1.49353 2.69941 1.86985C2.48143 2.29767 2.48143 2.85773 2.48143 3.97783V14.144M5.38742 14.144H-0.184688"
      stroke="#A3A3A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Bathroom;
