import React, { useEffect, useState } from "react";
// import Calendar from "../../../components/custom/calendar";
import HostDashboardLayout from "./components/host-dashboard-layout";
import StarPrimary from "../../../components/icons/star-primary";
import { currencyFormat } from "../../../utils/helper-functions";
import { TextInput } from "../../../components/custom/text-input";
import DeleteIcon from "../../../components/icons/delete";
import EditIcon from "../../../components/icons/edit";
import { useGetBalances } from "../../../hooks/use-stats";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUser,
  getHostTransactions,
  getTransactionPagination,
} from "../../../redux/reducers/user-slice";
import Modal from "../../../components/custom/modal";
import { fetchHostTransactions } from "../../../redux/actions/authuser";
import moment from "moment";
import { Link } from "react-router-dom";
import PaginationTwo from "../../../components/custom/pagination-two";

interface PaymentProps {}
interface ActivityCardItemProps {
  title: string;
  stats: string | number;
  percent: string | number;
  action: () => void;
  actionTitle?: string;
  isOverallRating?: boolean;
  bgColor?: string;
  borderColor?: string;
  showCurrency?: boolean;
  onChangeCurrency?: (pa: any) => void;
  currencies?: string[];
}

const ActivityCardItem = ({
  title,
  stats,
  percent,
  action,
  actionTitle,
  isOverallRating,
  bgColor,
  borderColor,
  showCurrency,
  onChangeCurrency,
  currencies,
}: ActivityCardItemProps) => (
  <div
    className={`border cursor-pointer flex flex-col gap-3 rounded-xl min-h-24 w-full dark:border-gray800 ${bgColor} ${borderColor}`}
    onClick={action}
  >
    <div className="px-4 py-4 flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <span className="text-base font-semibold text-gray1000 dark:text-gray200">
          {title}
        </span>
        {showCurrency && (
          <select
            onChange={(e) =>
              onChangeCurrency
                ? onChangeCurrency(e.target.value)
                : console.log(e.target.value)
            }
            className="text-xs font-semibold dark:bg-gray800 dark:text-gray200 rounded-sm"
            name="currency"
            id="currency"
          >
            {currencies?.map((item, i) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className="flex items-center gap-2">
        <h1 className="text-4xl break-words font-bold dark:text-gray200">
          {stats}
        </h1>
        {isOverallRating && <StarPrimary />}
      </div>
      <div className="border-b-0 border-bg-gray600">
        <span className="text-gray800 dark:text-gray100 font-medium flex items-center gap-1">
          <span className="flex items-center gap-1">
            {/* <span className="text-gray600 text-xs">- {percent}%</span>
            <span className="text-xs font-semibold"> since joining</span> */}
          </span>
        </span>
      </div>
    </div>
    <hr className={`bg-gray600 my-0 py-0 dark:border-gray800 ${borderColor}`} />
    <div className="px-4 pb-4 flex gap-2 items-center">
      <span className="text-xs dark:text-gray200">{actionTitle}</span>
      {actionTitle && (
        <svg
          width="13"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.16663 6.66927H12.8333M12.8333 6.66927L6.99996 0.835938M12.8333 6.66927L6.99996 12.5026"
            stroke="#525252"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </div>
  </div>
);

const PaymentAccount: React.FC<PaymentProps> = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState("all");
  const [selectCurrency, setSelectedCurrency] = useState("NGN");
  const { currencyBalance, currencyWallet, balances } =
    useGetBalances(selectCurrency);
  const profile = useSelector(getCurrentUser);
  const [openMsg, setOpenMsg] = useState(false);
  const transactions = useSelector(getHostTransactions);
  const paginations = useSelector(getTransactionPagination);
  const [searchText, setSearchText] = useState("");
  const [filterTransactions, setFilterTransactions] = useState(transactions);

  useEffect(() => {
    //@ts-ignore
    dispatch(fetchHostTransactions(selectCurrency));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (active !== "all") {
      //@ts-ignore
      dispatch(fetchHostTransactions(selectCurrency, active));
    }

    // eslint-disable-next-line
  }, [active]);

  useEffect(() => {
    setFilterTransactions(transactions);

    // eslint-disable-next-line
  }, [transactions]);

  useEffect(() => {
    if (searchText.length > 1) {
      const searchTractions = filterTransactions?.filter((item) =>
        item.payment_reference.includes(searchText)
      );
      setFilterTransactions(searchTractions);
    }

    // eslint-disable-next-line
  }, [searchText]);

  return (
    <HostDashboardLayout
      crumbs={[{ name: "Payment and Account", path: `/payment-info` }]}
    >
      <div className="flex flex-col gap-10 md:px-7">
        <div className="flex md:flex-row flex-col gap-5 pt-2 font-display text-gray1000 mb-5">
          <div className="md:w-[68%] w-full h-fit md:border-r border-gray400 dark:border-gray800 md:pr-5 flex flex-col gap-5 pb-4">
            <div className="flex lg:flex-nowrap flex-wrap w-full items-center gap-5">
              <ActivityCardItem
                title="Book Balance"
                stats={currencyFormat(
                  currencyWallet?.current_balance || 0,
                  selectCurrency
                )}
                percent={0}
                action={() => {}}
                actionTitle=" "
                showCurrency
                onChangeCurrency={(e) => setSelectedCurrency(e)}
                currencies={balances.wallets.map((item) => item.currency_code)}
              />
              <ActivityCardItem
                title="Available Balance"
                stats={currencyFormat(
                  currencyBalance?.sum || 0,
                  selectCurrency
                )}
                percent={0}
                action={() => {}}
                actionTitle="Request Payout"
                showCurrency
                onChangeCurrency={(e) => setSelectedCurrency(e)}
              />
            </div>
            <div className="flex items-center justify-between">
              <p className="text-base font-semibold flex gap-2 dark:text-gray200">
                Transactions{" "}
                <span className="text-xs bg-gray400 dark:bg-gray900 rounded-full p-1">
                  {paginations?.transactionCount}
                </span>
              </p>
              {/* <button className="p-2 text-eadWhite bg-primary500 rounded-full text-xs">
                Request payout
              </button> */}
            </div>
            <div className="flex flex-wrap-reverse items-center justify-between gap-3">
              <div className="flex items-center border border-gray500 dark:border-gray800 rounded-md">
                <p
                  onClick={() => setActive("all")}
                  className={`${
                    active === "all" && "bg-gray300 dark:bg-gray800"
                  } text-sm font-semibold border-r border-r-gray500 dark:border-r-gray900 p-2 cursor-pointer dark:text-gray500`}
                >
                  All transactions
                </p>
                <p
                  onClick={() => setActive("credit")}
                  className={`${
                    active === "credit" && "bg-gray300 dark:bg-gray800"
                  } text-sm font-semibold border-r border-r-gray500 dark:border-r-gray900 p-2 cursor-pointer dark:text-gray500`}
                >
                  In flow
                </p>
                <p
                  onClick={() => setActive("debit")}
                  className={`${
                    active === "debit" && "bg-gray300 dark:bg-gray800"
                  } text-sm font-semibold p-2 cursor-pointer dark:text-gray500`}
                >
                  Out flow
                </p>
              </div>
              <div className="flex items-center">
                <TextInput
                  placeholder="Search"
                  onChange={(e) => setSearchText(e.target.value)}
                  className="lg:w-full"
                />
              </div>
            </div>
            <div className="w-full overflow-x-auto">
              <table className="w-full text-sm dark:text-gray200">
                <thead>
                  <tr className="text-xs font-semibold border-t border-gray400 text-gray700 dark:border-gray900 dark:text-gray200">
                    <th className="py-5">Type</th>
                    <th className="py-5 min-w-32">Reference</th>
                    <th className="py-5 min-w-32">Payment description</th>
                    <th className="py-5 min-w-32">Amount</th>
                    <th className="py-5 min-w-32">Date</th>
                    <th className="py-5">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions?.map((item) => (
                    <tr
                      key={item.id}
                      className="text-sm text-gray800 border-y last:border-b border-gray400 dark:border-gray900 dark:text-gray200 text-center"
                    >
                      <td className="py-5">
                        {item.transaction_type.toLocaleUpperCase()}
                      </td>
                      <td className="py-5">{item.payment_reference}</td>
                      <td className="py-5">{item.description}</td>
                      <td className="py-5">
                        {currencyFormat(item.amount || 0, selectCurrency)}
                      </td>
                      <td className="py-5">
                        {moment(item.available_date).format("DD-MM-YYYY hh mm")}
                      </td>
                      <td className="py-5 ">
                        <span
                          className={`p-1.5 text-gray800 ${
                            item.is_payed_out ? "bg-green-300" : "bg-yellow-300"
                          } rounded-full w-fit text-xs font-semibold`}
                        >
                          {item.is_payed_out ? "Completed" : "Pending"}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <PaginationTwo
                currentPage={paginations?.page || 0}
                totalPages={paginations?.pages || 0}
                onPrevPage={() => {
                  dispatch(
                    //@ts-ignore
                    fetchHostTransactions(
                      selectCurrency,
                      active,
                      paginations?.page
                    )
                  );
                }}
                onNextPage={() => {
                  dispatch(
                    //@ts-ignore
                    fetchHostTransactions(
                      selectCurrency,
                      active,
                      paginations?.page
                    )
                  );
                }}
              />
            </div>
          </div>
          <div className="md:w-[32%] w-full flex flex-col gap-3 bg-gray100 p-3 border border-gray400 rounded-xl dark:border-gray900 dark:bg-gray1000 dark:text-gray200">
            <p className="text-lg font-semibold dark:text-gray200 ">
              Payment method
            </p>
            {profile.account_number ? (
              <div className="border border-gray400 rounded-lg p-2 mb-3 flex flex-col gap-3 dark:border-gray900">
                <p className="text-base font-semibold flex items-center gap-3">
                  Bank
                  <span className="text-xs p-1 bg-gray300 text-green-500 rounded-full dark:bg-gray900">
                    active
                  </span>
                </p>
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm">{profile.account_name}</p>
                    <p className="text-sm">{profile.account_number}</p>
                  </div>
                  <div className="flex items-center gap-3">
                    <button onClick={() => setOpenMsg(true)}>
                      <DeleteIcon />
                    </button>
                    <button onClick={() => setOpenMsg(true)}>
                      <EditIcon />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <Link
                to={"/guest/verification"}
                className="p-2 text-sm rounded-full bg-gray500 font-semibold text-center w-fit my-2 shadow dark:text-gray1000"
              >
                Add payout method
              </Link>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={openMsg}
        onClose={() => {
          setOpenMsg(false);
        }}
        className="lg:w-1/3"
      >
        <div className="p-4 rounded-xl w-full">
          <p className="text-base font-semibold text-gray1000 dark:text-gray200 mb-3">
            Looking to change your bank?
          </p>
          <p className="text-base font-light text-gray1000 dark:text-gray200 mb-3">
            For security reasons, please contact EAD Shortlets support
            08170700011 08170400011 or hello@eadshortlets.com to change your
            bank details.
          </p>
          <div className="w-full flex justify-center">
            <button
              onClick={() => setOpenMsg(false)}
              className={`flex cursor-pointer text-xs font-display font-semibold p-2 px-4 rounded-full shadow bg-primary500 items-center text-eadWhite transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              okay
            </button>
          </div>
        </div>
      </Modal>
    </HostDashboardLayout>
  );
};

export default PaymentAccount;
