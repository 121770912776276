// ConfirmDelete.tsx
import React from "react";
import ToggleButton from "../../../../components/custom/toggle-button";

interface DateInspectionProps {
  dayofweek: string;
  timeInspect?: string;
  showToggle?: boolean;
  onToggleAction?: () => void;
  activeToggle?: boolean;
  className?: string;
}

const DateInspectionItem: React.FC<DateInspectionProps> = ({
  dayofweek,
  timeInspect,
  showToggle,
  onToggleAction,
  activeToggle,
  className,
}) => {
  return (
    <div
      className={`flex items-start md:items-center gap-1 text-xs dark:text-gray100 ${className}`}
    >
      <div className="flex gap-1">
        <svg
          width="15"
          height="15"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="dark:stroke-gray100"
            d="M17.5 8.33464H2.5M13.3333 1.66797V5.0013M6.66667 1.66797V5.0013M6.5 18.3346H13.5C14.9001 18.3346 15.6002 18.3346 16.135 18.0622C16.6054 17.8225 16.9878 17.44 17.2275 16.9696C17.5 16.4348 17.5 15.7348 17.5 14.3346V7.33464C17.5 5.9345 17.5 5.23444 17.2275 4.69966C16.9878 4.22925 16.6054 3.8468 16.135 3.60712C15.6002 3.33464 14.9001 3.33464 13.5 3.33464H6.5C5.09987 3.33464 4.3998 3.33464 3.86502 3.60712C3.39462 3.8468 3.01217 4.22925 2.77248 4.69966C2.5 5.23444 2.5 5.9345 2.5 7.33464V14.3346C2.5 15.7348 2.5 16.4348 2.77248 16.9696C3.01217 17.44 3.39462 17.8225 3.86502 18.0622C4.3998 18.3346 5.09987 18.3346 6.5 18.3346Z"
            stroke={"#525252"}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>{" "}
        <p>{dayofweek}</p>
      </div>
      <div className="ml-1 flex items-center gap-1 md:flex-nowrap flex-wrap">
        {timeInspect && (
          <div className="font-semibold text-blue-500 dark:text-gray100 bg-blue-100 dark:bg-blue-500/50 text flex items-center gap-1 rounded-full px-2 text-xs">
            <span className="text-xs">●</span>
            <p className="text-xs">{timeInspect}</p>
          </div>
        )}
        {showToggle && (
          <ToggleButton isActive={activeToggle} onToggle={onToggleAction} />
        )}
        {/* <div className="ml-1 font-semibold text-green-500 bg-green-100 dark:bg-green-500/50 flex items-center gap-1 rounded-full px-2">
          <span>●</span>
          <span>Booked</span>
          <span className="text-gray1000 dark:text-gray100 font-normal text-xs">
            <span className="text-gray700 dark:text-gray100 text-xs">|</span>{" "}
            {"jan 24"}
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default DateInspectionItem;
