import React from "react";

const SearchIcon = ({ ...rest }) => (
  <svg width="20" height="24" viewBox="0 0 18 17" fill="none" {...rest}>
    <path
      className="dark:stroke-gray500"
      d="M16.5 15.8335L13.5834 12.9168M15.6667 7.91683C15.6667 11.8288 12.4954 15.0002 8.58333 15.0002C4.67132 15.0002 1.5 11.8288 1.5 7.91683C1.5 4.00481 4.67132 0.833496 8.58333 0.833496C12.4954 0.833496 15.6667 4.00481 15.6667 7.91683Z"
      stroke="#525252"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SearchIcon;
