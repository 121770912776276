import { useEffect, useState } from "react";
import VerifedUser from "../../../../components/icons/verified-user";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUser,
  getUserProperties,
} from "../../../../redux/reducers/user-slice";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../routes/paths";
import { fetchUserProperties } from "../../../../redux/actions/authuser";
import {
  dashboardStatsApi,
  fetchHostBookings,
  viewChecklist,
} from "../../../../redux/actions/property";
import { getDashboardStats } from "../../../../redux/reducers/property-slice";

interface HostDashboardHeaderProps {}

const HostDashboardHeader: React.FC<HostDashboardHeaderProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(getCurrentUser);
  const stats = useSelector(getDashboardStats);
  const userProperties = useSelector(getUserProperties);

  const activityTabs = [
    {
      name: "Latest activity",
      route: "latest-activity",
      count: stats.published,
      filter: "awaiting-approval",
    },
    {
      name: "Published listing",
      route: "published-listing",
      count: stats.published,
      filter: "published",
    },
    {
      name: "Pending Review",
      route: "pending-review",
      count: stats.pending_review,
      filter: "pending_review",
    },
    {
      name: "Draft",
      route: "draft",
      count: stats.draft,
      filter: "draft",
    },
  ];
  const [activeFilter, setActiveFilter] = useState({ ...activityTabs[0] });

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchUserProperties(activeFilter?.filter));
    // @ts-ignore
    dispatch(viewChecklist());

    // @ts-ignore
    dispatch(dashboardStatsApi());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchUserProperties(activeFilter?.filter));
    // @ts-ignore
    dispatch(viewChecklist());

    // @ts-ignore
    dispatch(dashboardStatsApi());

    // @ts-ignore
    dispatch(fetchHostBookings());

    // eslint-disable-next-line
  }, [window.location.pathname]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/")[3];
    //@ts-ignore
    setActiveFilter(...activityTabs.filter(item => item.route === pathname ))

    // eslint-disable-next-line
  },[window.location.pathname])

  const fetchFilteredProperty = (filter: string) =>
    // @ts-ignore
    dispatch(fetchUserProperties(filter));

  return (
    <div className="md:px-10 h-auto flex flex-col gap-5 md:gap-8 font-display dark:border-gray800 pb-7">
      {/* welcome head */}
      <div className="flex md:flex-row flex-col justify-between">
        <div className="flex flex-col md:gap-2">
          <div className="flex items-center gap-2">
            <span className="lg:text-2xl text-xl font-bold text-gray1000 dark:text-gray200">
              Welcome back, {userInfo?.first_name}
            </span>
            {userInfo?.is_verified ? <VerifedUser /> : ""}
          </div>
          <span className="text-base dark:text-gray200 text-gray1000">
            You have <span className="text-primary500">{stats.upcoming_bookings} upcoming booking</span>
          </span>
        </div>
        <div className="flex w-fit justify-center md:mt-0 mt-4 gap-2 md:gap-3 items-center rounded-full text-xs lg:text-sm text-gray800 font-medium md:h-8 p-1 pl-2 border border-gray400 bg-gray100 md:pl-3 md:pr-2 dark:text-gray200 dark:bg-gray1000 dark:border-gray800">
          {userProperties.length >= 1 && (
            <div className="bg-primary400 w-2 h-2 md:w-3 md:h-3 rounded-full"></div>
          )}
          All reservations
          <div className="rounded-full w-6 h-6 md:h-5 md:w-5 flex items-center justify-center border border-gray400 dark:border-gray800">
            {stats.active_bookings}
          </div>
        </div>
      </div>
      {/* filter categories */}
      <div className="flex flex-wrap items-center gap-3">
        {activityTabs.map((activity, index) => (
          <div
            key={index}
            className={`transition-all duration-200 ease-in-out flex gap-3 items-center rounded-full dark:text-gray100 text-gray800 font-medium lg:h-8 border border-gray400 dark:border-gray800 px-2 py-1 cursor-pointer ${
              activeFilter.name === activity.name
                ? "bg-primary100 dark:bg-[#660808] border-primary200 dark:border-[#7F0303]"
                : " bg-gray100 dark:bg-transparent"
            }`}
            onClick={() => {
              setActiveFilter(activity);
              fetchFilteredProperty(activity.filter);
              navigate(`${paths.HOST_DASHBOARD}/${activity.route}`);
            }}
          >
            <span className="text-xs lg:text-sm">{activity.name}</span>
            {activity.route !== "latest-activity" && (
              <div
                className={`transition-all duration-200 ease-in-out rounded-full h-5 w-5 m lg:px-1 flex items-center justify-center border border-gray400 dark:border-gray800 ${
                  activeFilter.name === activity.name
                    ? "bg-primary400 dark:bg-[#F10001] border-none text-white"
                    : ""
                }`}
              >
                <span className="text-xs sm:text-sm">{activity.count}</span>
              </div>
            )}
          </div>
        ))}
      </div>
      <hr className="border-[#EAECF0] py-0" />
    </div>
  );
};

export default HostDashboardHeader;
