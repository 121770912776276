import React, { FC } from "react";

interface Props extends React.SVGProps<SVGSVGElement> {}

const HeartEmpty: FC<Props> = ({ ...rest }) => (
  <svg
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g opacity="0.8">
      <rect y="0.666992" width="36" height="36" rx="18" fill="#F5F5F5" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9941 12.9468C16.328 10.999 13.5496 10.475 11.4621 12.2587C9.37454 14.0423 9.08064 17.0245 10.72 19.134C12.083 20.8879 16.208 24.5871 17.5599 25.7844C17.7112 25.9183 17.7868 25.9853 17.875 26.0116C17.952 26.0346 18.0363 26.0346 18.1132 26.0116C18.2015 25.9853 18.2771 25.9183 18.4283 25.7844C19.7803 24.5871 23.9052 20.8879 25.2683 19.134C26.9076 17.0245 26.6496 14.0235 24.5262 12.2587C22.4028 10.4938 19.6603 10.999 17.9941 12.9468Z"
        stroke="#FF5C5C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export default HeartEmpty;
