import React from "react";

const ListingSkeleton = () => {
  return (
    <div className="border border-gray400 rounded-md p-4 w-full">
      <div className="animate-pulse w-full">
        <div className="flex gap-3 w-full">
          <div className="flex flex-col gap-2 w-full">
            <div className="rounded-xl h-40 w-full bg-gray600"></div>
            <div className="flex gap-3">
              <div className="rounded-xl h-20 w-full bg-gray600"></div>
              <div className="rounded-xl h-20 w-full bg-gray600"></div>
            </div>
          </div>
          <div className="w-full flex flex-col justify-between">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-gray600 rounded col-span-2"></div>
              <div className="h-2 bg-gray600 rounded col-span-1"></div>
            </div>
            <div className="h-2 w-1/2 bg-gray600 rounded col-span-1"></div>
            <div className="h-2 w-1/2 bg-gray600 rounded col-span-1"></div>
            <div className="h-2 w-1/2 bg-gray600 rounded col-span-1"></div>
            <div className="h-2 w-1/2 bg-gray600 rounded col-span-1"></div>
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-gray600 rounded col-span-2"></div>
              <div className="h-2 bg-gray600 rounded col-span-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingSkeleton;
