import { useEffect, useState } from "react";

const getFromLocalStorage = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("dark-theme");
  }
  return null;
};

function useDarkmode() {
  const [dark, setDark] = useState(() => {
    const savedTheme = getFromLocalStorage();
    const prefersDarkScheme =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    return savedTheme ? savedTheme === "dark" : prefersDarkScheme;
  });

  const darkModeHandler = () => {
    const isDark = document.body.classList.toggle("dark");
    setDark(isDark);
    localStorage.setItem("dark-theme", isDark ? "dark" : "light");
  };

  useEffect(() => {
    const savedTheme = getFromLocalStorage();
    if (
      savedTheme === "dark" ||
      (!savedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.body.classList.add("dark");
      setDark(true);
    } else {
      document.body.classList.remove("dark");
      setDark(false);
    }
  }, []);

  return { dark, darkModeHandler };
}

export default useDarkmode;
