import React from "react";

const UserPicHolder = ({ ...rest }) => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" {...rest}>
    <rect
      x="0.332031"
      y="0.667969"
      width="40"
      height="40"
      rx="20"
      fill="#E5E5E5"
    />
    <path
      d="M28.332 29.668C28.332 28.2724 28.332 27.5746 28.1598 27.0068C27.772 25.7284 26.7716 24.728 25.4932 24.3402C24.9254 24.168 24.2276 24.168 22.832 24.168H17.832C16.4365 24.168 15.7387 24.168 15.1709 24.3402C13.8925 24.728 12.8921 25.7284 12.5043 27.0068C12.332 27.5746 12.332 28.2724 12.332 29.668M24.832 16.168C24.832 18.6533 22.8173 20.668 20.332 20.668C17.8467 20.668 15.832 18.6533 15.832 16.168C15.832 13.6827 17.8467 11.668 20.332 11.668C22.8173 11.668 24.832 13.6827 24.832 16.168Z"
      stroke="#525252"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default UserPicHolder;
