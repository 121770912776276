import React from "react";
interface UploadProps {
  onClick: () => void;
  loader?: boolean;
}

const UploadFiles: React.FC<UploadProps> = ({ onClick, loader }) => {
  return (
    <div
      className="w-full h-40 rounded-xl border-dashed border border-gray600 flex flex-col items-center justify-center cursor-pointer"
      onClick={loader ? () => {} : onClick}
    >
      {loader ? null : (
        <svg
          width="46"
          height="47"
          viewBox="0 0 46 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="3"
            y="3.66602"
            width="40"
            height="40"
            rx="20"
            fill="#F2F4F7"
          />
          <rect
            x="3"
            y="3.66602"
            width="40"
            height="40"
            rx="20"
            stroke="#F9FAFB"
            stroke-width="6"
          />
          <path
            d="M19.6667 26.9993L23.0001 23.666M23.0001 23.666L26.3334 26.9993M23.0001 23.666V31.166M29.6667 27.6184C30.6847 26.7777 31.3334 25.506 31.3334 24.0827C31.3334 21.5514 29.2814 19.4993 26.7501 19.4993C26.568 19.4993 26.3976 19.4043 26.3052 19.2475C25.2185 17.4034 23.2121 16.166 20.9167 16.166C17.465 16.166 14.6667 18.9642 14.6667 22.416C14.6667 24.1378 15.363 25.6969 16.4892 26.8273"
            stroke="#292929"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}

      {loader ? (
        <div className="border-eadWhite h-10 w-10 animate-spin rounded-full border-4 border-t-primary500 border-r-primary500" />
      ) : (
        <div className="flex flex-col items-center text-center">
          <span className="text-primary500 text-sm font-semibold">
            Click to upload
          </span>
          <span className="text-sm text-gray-800">
            SVG, PNG, JPG or PDF (max. 800x400px)
          </span>
        </div>
      )}
    </div>
  );
};

export default UploadFiles;
