import React from "react";

const ChevronDown = ({ ...rest }) => (
  <svg
    {...rest}
    width="14"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path
      d="M6 9L12 15L18 9"
      stroke="#525252"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ChevronDown;
