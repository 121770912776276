// @ts-nocheck

import { useCallback, useEffect, useRef, useState } from "react";

const initialTimerState = {
  minutes: "00",
  seconds: "00",
};

const fiveMinutes = 60 * 5;

export const useTimer = (duration: number) => {
  const timerInteval = useRef(null);
  const minutes = useRef(null);
  const seconds = useRef(null);
  const timer = useRef(duration || fiveMinutes);

  const [time, setTime] = useState({ minutes: "00", seconds: "00" });

  const startTimer = useCallback(() => {
    timerInteval.current = setInterval(() => {
      minutes.current = parseInt(timer.current / 60, 10);
      seconds.current = parseInt(timer.current % 60, 10);

      const calMinutes =
        minutes.current < 10 ? "0" + minutes.current : minutes.current;
      const calSeconds =
        seconds.current < 10 ? "0" + seconds.current : seconds.current;

      setTime({
        minutes: calMinutes,
        seconds: calSeconds,
      });

      if (--timer.current < 0) {
        timer.current = duration;
      }
    }, 1000);
  }, [duration]);

  const clearTimer = useCallback(() => {
    clearInterval(timerInteval.current);
    setTime(initialTimerState);
    minutes.current = "00";
    seconds.current = "00";
    timer.current = duration;
  }, [duration]);

  const restartTimer = () => {
    clearTimer();
    startTimer();
  };

  useEffect(() => {
    startTimer();

    return () => {
      clearTimer();
    };
  }, [clearTimer, startTimer]);

  return {
    restartTimer,
    clearTimer,
    minutes: time.minutes,
    seconds: time.seconds,
  };
};
