import React, { FC } from "react";

interface ArrowIconProps {
  className?: string;
}

const ArrowIcon: FC<ArrowIconProps> = ({ className }) => (
  <svg
  className={className || ""}
  width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className || ""}
      d="M12.8333 6.66927H1.16666M1.16666 6.66927L6.99999 12.5026M1.16666 6.66927L6.99999 0.835938"
      stroke="#525252"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowIcon;
