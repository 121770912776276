import Auth from "../../middleware/storage";
import {
  setBanks,
  setHostTransactions,
  setReferrals,
  setTransactionPagination,
  setUser,
  setUserProperties,
} from "../reducers/user-slice";
import { setLoading } from "../reducers/loader-slice";
import apiClient from "../../network/axios-client";
import { SigninUserType, SignupUserType, UserType } from "../types";
import { urls } from "../../network";
import { showToast } from "../../utils/helper-functions";

const client = apiClient;

// thunks
export const signUpUserEmail =
  (credentials: SignupUserType) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.post(urls.Signup, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast(res.data.errors.message, "error");
        return false;
      } else {
        Auth.setToken(res.data.data?.token);
        client.defaults.headers.common.Authorization = `Bearer ${res.data.data?.token}`;
        Auth.setUser({
          firstName: res.data.data.user.first_name,
          lastName: res.data.data.user.last_name,
          picture: res.data.data.user.profile_pic_url,
          email: res.data.data.user.email,
          is_email_verified: res.data.data.user.is_email_verified,
          is_phone_verified: res.data.data.user.is_phone_verified,
        });
        Auth.setIsEmailVerified(res.data.data.user.is_email_verified);
        dispatch(setUser(res.data.data.user));
        dispatch(setLoading(false));
        dispatch(fetchUser());
        showToast("Welcome! " + res.data.data.user.first_name, "success");
        return true;
      }
    } catch (error) {
      console.log(error);
      // showToast("An error occurred!", "error");
      dispatch(setLoading(false));
    }
  };

export const signInUserEmail =
  (credentials: SigninUserType) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.post(urls.Signin, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        console.log(res.data.errors.message);
        showToast(res.data.errors.message, "error");
        return false;
      } else {
        if (!res.data.data.user.is_email_verified) {
          dispatch(resendEmailVerify({ email: res.data.data.user.email }));
        }
        Auth.setToken(res.data.data?.token);
        Auth.setUser({
          firstName: res.data.data.user.first_name,
          lastName: res.data.data.user.last_name,
          picture: res.data.data.user.profile_pic_url,
          email: res.data.data.user.email,
          is_email_verified: res.data.data.user.is_email_verified,
          is_phone_verified: res.data.data.user.is_phone_verified,
        });
        Auth.setIsEmailVerified(res.data.data.user.is_email_verified);
        dispatch(setUser(res.data.data.user));
        dispatch(setLoading(false));
        dispatch(fetchUser());
        return true;
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };

export const signInGoogleAuth = (token: string) => async (dispatch: any) => {
  try {
    // dispatch(setLoading(true));
    const res = await client.get(urls.GoogleSigninAuth(token));
    if (res.data.hasError) {
      dispatch(setLoading(false));
      console.log(res.data.errors.message);
      showToast(res.data.errors.message, "error");
      return false;
    } else {
      if (!res.data.data.user.is_email_verified) {
        dispatch(resendEmailVerify({ email: res.data.data.user.email }));
      }
      Auth.setToken(res.data.data?.token);
      Auth.setUser({
        firstName: res.data.data.user.first_name,
        lastName: res.data.data.user.last_name,
        picture: res.data.data.user.profile_pic_url,
        email: res.data.data.user.email,
        is_email_verified: res.data.data.user.is_email_verified,
        is_phone_verified: res.data.data.user.is_phone_verified,
      });
      Auth.setIsEmailVerified(res.data.data.user.is_email_verified);
      dispatch(setUser(res.data.data.user));
      dispatch(setLoading(false));
      dispatch(fetchUser());
      return true;
    }
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
  }
};

// export const signInGoogle = (token) => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     const res = await Api.auth.signGoogle(token);
//     if (res.data.hasError) {
//       dispatch(setLoading(false));
//       showToast(res.data.errors.message, "error");
//       return false;
//     } else {
//       dispatch(setLoading(false));
//       Auth.setToken(res.data.data?.token, null);
//       Auth.setUser({
//         firstName: res.data.data.user.first_name,
//         lastName: res.data.data.user.last_name,
//         picture: res.data.data.user.profile_pic_url,
//       });
//       dispatch(setUser(res.data.data.user));
//       dispatch(setStore(res.data.data.store));
//       dispatch(setIsStoreOwner(res.data.data.is_store_owner));
//       showToast("Welcome!", "success");
//       return true;
//     }
//   } catch (error) {
//     dispatch(setLoading(false));
//     console.log("signin::", error);
//     showToast("An error occurred!", "error");
//   }
// };

export const forgotPassword =
  (credentials: { email: string }) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.patch(urls.ForgotPassword, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast(res.data?.errors?.message, "error");
      } else {
        dispatch(setLoading(false));
        return true;
      }
    } catch (error) {
      console.log(error);
      // showToast("An error occurred!", "error");
      dispatch(setLoading(false));
    }
  };

// export const resendForgotPassword = (credentials) => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     const res = await Api.auth.resendForgotPassword(credentials);
//     if (res.data.hasError) {
//       dispatch(setLoading(false));
//       showToast(res.data.errors.message, "error");
//       return false;
//     } else {
//       dispatch(setLoading(false));
//       showToast("Code has been resent to you email!", "success");
//       return true;
//     }
//   } catch (error) {
//     dispatch(setLoading(false));
//     console.log("forgotresend::", error);
//     showToast("An error occurred!", "error");
//   }
// };

export const resetPassword =
  (credentials: { email: string; code: string; new_password: string }) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.patch(urls.ResetPassword, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast(res?.data?.errors.message, "error");
        // showToast('An error occurred!', 'error');
      } else {
        dispatch(setLoading(false));
        return true;
      }
    } catch (error) {
      console.log(error);
      // showToast("An error occurred!", "error");
      dispatch(setLoading(false));
    }
  };

export const fetchUser = () => async (dispatch: any) => {
  try {
    const res = await client.get(urls.UserProfile);
    if (res.data.hasError) {
      // showToast('An error occurred!', 'error');
    } else {
      dispatch(setUser(res.data.data.user));
      Auth.setUser({
        firstName: res.data.data.user.first_name,
        lastName: res.data.data.user.last_name,
        picture: res.data.data.user.profile_pic_url,
        is_email_verified: res.data.data.user.is_email_verified,
        is_phone_verified: res.data.data.user.is_phone_verified,
        email: res.data.data.user.email,
      });
      Auth.setIsEmailVerified(res.data.data.user.is_email_verified);
    }
  } catch (error) {
    console.log(error);
    // showToast("An error occurred!", "error");
    dispatch(setLoading(false));
  }
};

export const fetchUserProperties = (id?: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await client.get(urls.HostProperties(id));
    if (res.data.hasError) {
      dispatch(setLoading(false));
      // showToast('An error occurred!', 'error');
    } else {
      dispatch(setLoading(false));
      dispatch(setUserProperties(res.data.data.hostProperties));
    }
  } catch (error) {
    // showToast("An error occurred!", "error");
    dispatch(setLoading(false));
  }
};

export const resendEmailVerify =
  (credentials: { email: string }) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.post(urls.ResendEmailVerification, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast(res.data?.errors?.message, "error");
      } else {
        dispatch(setLoading(false));
      }
    } catch (error) {
      console.log(error);
      showToast("An error occurred!", "error");
      dispatch(setLoading(false));
    }
  };

export const verifyEmail =
  (credentials: { email: string; code: string }) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.patch(urls.VerifyEmail, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast(res.data?.errors?.message, "error");
      } else {
        dispatch(setLoading(false));
        Auth.setIsEmailVerified(true);
        dispatch(fetchUser());
        return true;
      }
    } catch (error) {
      console.log(error);
      showToast("An error occurred!", "error");
      dispatch(setLoading(false));
    }
  };

export const changeUserPassword =
  (credentials: { old_password: string; new_password: string }) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.patch(urls.ChangePassword, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast(res.data?.errors?.message, "error");
      } else {
        dispatch(setLoading(false));
        dispatch(fetchUser());
        showToast(res.data.message, "success");
      }
    } catch (error) {
      console.log(error);
      showToast("An error occurred!", "error");
      dispatch(setLoading(false));
    }
  };

export const editUserProfile =
  (credentials: UserType) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.patch(urls.EditProfile, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast(res.data?.errors?.message, "error");
      } else {
        dispatch(setLoading(false));
        dispatch(fetchUser());
        showToast(res.data.message, "success");
        return true;
      }
    } catch (error) {
      console.log(error);
      showToast("An error occurred!", "error");
      dispatch(setLoading(false));
    }
  };

export const uploadImagesSingleNoWaterMark =
  (credentials: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.post(urls.MediaUploadOneClean, credentials);
      if (res.data.hasError) {
        showToast(res.data.message, "error");
        dispatch(setLoading(false));
        return false;
      } else {
        dispatch(setLoading(false));
        return res.data.data.media_url;
      }
    } catch (error) {
      dispatch(setLoading(false));
      showToast("An error occurred!", "error");
      console.log(error);
    }
  };

export const updateBankDetails =
  (credentials: {
    bank_name: string;
    bank_code: string;
    account_name: string;
    account_number: string;
    account_country: string;
  }) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.patch(urls.UpdateBankDetails, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast("An error occurred!", "error");
      } else {
        dispatch(setLoading(false));
        dispatch(fetchUser());
        showToast(res.data.message, "success");
      }
    } catch (error) {
      console.log(error);
      showToast("An error occurred!", "error");
      dispatch(setLoading(false));
    }
  };

export const fetchAllBanks = (country?: string) => async (dispatch: any) => {
  try {
    const res = await client.get(urls.AllBanks(country));
    if (res.data.hasError) {
    } else {
      dispatch(setBanks(res.data.data.paystackBanks));
    }
  } catch (error) {
    console.log(error);
  }
};

export const VerifyBankDetails =
  (credentials: { bank_code: string; account_number: string }) =>
  async (dispatch: any) => {
    try {
      const res = await client.post(urls.ProcessNameEnquiry, credentials);
      if (res.data.hasError) {
        return res.data.message;
      } else {
        return res.data.data.payStackAccountDetails as {
          account_number: string;
          account_name: string;
          bank_id: number;
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

export const updateUserDocument =
  (credentials: {
    document_number: string | number;
    document_type: string;
    document_image_url: string;
    user_selfie_url: string | null;
  }) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.post(urls.UpdateRequestKyc, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast(res.data.errors.message, "error");
        return;
      } else {
        showToast(res.data.title, "success");
        dispatch(setLoading(false));
        return true;
      }
    } catch (error) {
      console.log(error);
      showToast("An error occurred!", "error");
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchHostTransactions =
  (currency?: string, filter?: string, page?: number) =>
  async (dispatch: any) => {
    try {
      const res = await client.get(
        urls.HostTransactions(currency, filter, page)
      );
      if (res.data.hasError) {
      } else {
        dispatch(setHostTransactions(res.data.data.wallet_transactions));
        dispatch(
          setTransactionPagination({
            ...res.data.data.pagination,
            transactionCount: res.data.data.transactionCount,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

export const fetchInvites = () => async (dispatch: any) => {
  try {
    const res = await client.get(urls.ViewReferrals);
    if (res.data.hasError) {
    } else {
      dispatch(setReferrals(res.data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendInvites =
  (body: { email: string }) => async (dispatch: any) => {
    try {
      const res = await client.post(urls.SendInvite, body);
      if (res.data.hasError) {
      } else {
        dispatch(fetchInvites());
      }
    } catch (error) {
      console.log(error);
    }
  };
