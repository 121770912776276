import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { InputField } from "../../../components/custom/text-input";
import Monkeyhide from "../../../assets/images/hidemonkey.png";
import Emojiokay from "../../../assets/images/emojiokay.png";
import { paths } from "../../../routes/paths";
import ChevronLeft from "../../../components/icons/chevron-left";
import { resetPassword } from "../../../redux/actions/authuser";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoading } from "../../../redux/reducers/loader-slice";
import Navbar from "../../../components/custom/navbar";
import { FormProvider, useForm } from "react-hook-form";
import {
  password_validation_signup,
  text_validation,
} from "../../../utils/input-validation";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm();
  const { state } = useLocation();
  const [success, setSuccess] = useState(false);
  const isLoading = useSelector(getIsLoading);
  const [new_password, setNewpassword] = useState("");

  const onSubmit = methods.handleSubmit(async (data) => {
    console.log(data)
  
    const res = await dispatch(
      // @ts-ignore
      resetPassword({ ...data, email: state?.email })
    );
    methods.reset();

    if (res) {
      setSuccess(true);
      return;
    }
  });

  return (
    <div className="dark:bg-gray1000 h-screen">
      <div className="max-w-screen-xl sticky top-0 mx-auto">
        <Navbar />
      </div>
      <div className="flex flex-col items-center mt-8 lg:mt-20 gap-8 relative px-4">
        <div>
          <img
            src={success ? Emojiokay : Monkeyhide}
            alt=""
            className="object-contain"
          />
        </div>
        <p className="text-xl font-display font-bold text-gray1000 text-center dark:text-gray200">
          {success ? "Easy! password reset" : "Set a brand new password"}
        </p>
        <p className="text-base font-display text-gray1000 text-center dark:text-gray200">
          {success
            ? "Your password has been successfully reset. Click below to log in to your account."
            : "Your new password must be different to previously used passwords."}
        </p>
        <div className="flex flex-col items-center dark:bg-gray1000 justify-center mx-3 w-full lg:w-1/2 gap-6">
          {!success && (
            <FormProvider {...methods}>
              <form
                onSubmit={(e) => e.preventDefault()}
                noValidate
                autoComplete="off"
                className="w-full flex flex-col items-center justify-center gap-3"
              >
                <InputField
                  label="Code*"
                  placeholder="Enter 4 digit code"
                  type="text"
                  name="code"
                  validation={text_validation}
                />
                <InputField
                  label="Password*"
                  placeholder="Enter your password"
                  type="password"
                  name="new_password"
                  // subtext="Must be at least 8 characters, 1 uppercase."
                  validation={password_validation_signup}
                  onChange={(e) => setNewpassword(e.target.value)}
                />

                <InputField
                  label="Re-enter password*"
                  placeholder="Re-enter password"
                  type="password"
                  name="password"
                  // subtext="Must be at least 8 characters, 1 uppercase."
                  validation={{
                    required: {
                      value: true,
                      message: "required",
                    },
                    validate: (value: string) =>
                      value === new_password || "Passwords do not match",
                  }}
                />

                <button
                  onClick={onSubmit}
                  disabled={isLoading}
                  className={`${
                    isLoading ? "bg-primary300" : "bg-primary500"
                  } opacity flex justify-center cursor-pointer p-2 text-gray100 rounded-full text-center font-display font-bold text-xs shadow-md mb-4 w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
                >
                  {isLoading ? (
                    <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
                  ) : (
                    "Confirm new password reset"
                  )}
                </button>
              </form>
            </FormProvider>
          )}

          {success && (
            <button
              onClick={() => navigate(paths.SIGNIN)}
              className="p-2 text-gray100 bg-primary500 rounded-full text-center font-display font-bold text-xs shadow-md mb-4 w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            >
              Continue
            </button>
          )}

          <Link to={paths.SIGNIN} className="items-center my-2 flex">
            <ChevronLeft />
            <span className="text-xs text-gray1000 mx-2 font-display font-semibold dark:text-gray200">
              Back to log in
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
