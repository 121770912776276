import React, { FC } from "react";

interface ArrowIconProps {
  className?: string;
}

const ArrowIcon: FC<ArrowIconProps> = ({ className }) => (
  <svg
    className={className || ""}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className || ""}
      d="M1 8.66797H15M15 8.66797L8 1.66797M15 8.66797L8 15.668"
      stroke="#525252"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowIcon;
