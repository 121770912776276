import { configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
// import { routerMiddleware } from "connected-react-router";
import { createRootReducer } from "./root-reducer";

export const history = createBrowserHistory();

export const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      routerMiddleware: history,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
