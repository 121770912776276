import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowTopRight from "../../../../components/icons/arrow-top-right";
import ShareIcon from "../../../../components/icons/share";
import ConfirmDeleteModal from "./confirm-delete";
import { PropertyType, UserPropertyType } from "../../../../redux/types";
import { currencyFormat } from "../../../../utils/helper-functions";
import { paths } from "../../../../routes/paths";
import DateInspectionItem from "./date-inspection-item";
import moment from "moment";

interface PublishedListingCardProps {
  property: UserPropertyType | PropertyType;
}
const PublishedListingCard: React.FC<PublishedListingCardProps> = ({
  property,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const allImages = property?.property_media?.map((item) => item.url);

  const handleViewDetails = () => {
    navigate(paths.HOST_DASHBOARD_MANAGE_PROPERTY + "/" + property.slug);
  };

  const handleDelete = () => {
    // setIsModalOpen(false);
  };

  return (
    <>
      <div className="border border-gray400 dark:border-gray800 flex md:flex-row flex-col gap-5 rounded-xl min-h-24 p-3">
        <div className="md:w-[40%] w-full">
          {property?.property_media.length > 2 ? (
            <div className="flex flex-col gap-3">
              {allImages[0] ? (
                <img
                  className="rounded-2xl w-full h-[12.5rem] object-cover"
                  src={allImages[0]}
                  alt=""
                />
              ) : (
                <div className="w-full h-[12.5rem] bg-gray400 dark:bg-gray800 text-gray500 dark:text-gray100 flex items-center justify-center">
                  No image available
                </div>
              )}
              <div className="flex gap-3 h-28">
                {allImages[1] ? (
                  <img
                    className="w-1/2 h-full object-cover rounded-xl"
                    src={allImages[1]}
                    alt=""
                  />
                ) : (
                  <div className="w-1/2 bg-gray400 dark:bg-gray800 text-gray500 dark:text-gray100 flex items-center justify-center rounded-xl">
                    No image available
                  </div>
                )}
                {allImages[2] ? (
                  <>
                    <div className="w-1/2 rounded-2xl bg-gray400 dark:bg-gray800 text-gray800 dark:text-gray100 flex flex-col items-center justify-center">
                      <span className="text-2xl">+</span>
                      <span className="text-sm">
                        {allImages.length - 2} other{" "}
                        {allImages.length - 2 <= 1 ? "image" : "images"}
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="w-1/2 bg-gray400 dark:bg-gray800 text-gray500 dark:text-gray100 flex items-center justify-center rounded-xl">
                    No image available
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>

        {/* Card content */}
        <div className="flex flex-col md:w-[60%] w-full md:gap-0 gap-5 justify-between">
          <div className="flex flex-col gap-3 md:gap-2">
            <div className="flex md:flex-row flex-col md:gap-0 gap-2 md:items-center justify-between">
              <h2 className="text-sm font-semibold dark:text-gray100">
                {property.name}
              </h2>
              {/* Additional details */}
              <div className="flex items-center gap-2">
                <span
                  className={`${
                    property.is_live
                      ? "text-green-500 bg-gray-100 dark:bg-gray900 dark:text-gray100"
                      : "text-primary500 bg-primary100 dark:bg-primary500/50 dark:text-gray100"
                  } rounded-full px-2 py-1 text-xs`}
                >
                  {property.is_live ? "Published" : "Pending"}
                </span>{" "}
                <span
                  className={`${
                    property.is_verified
                      ? "text-blue-500 bg-blue-100 dark:text-blue-100 dark:bg-blue-500/50"
                      : "text-gray900 bg-gray400 dark:text-gray200 dark:bg-gray1000"
                  } rounded-full px-2 py-1 text-xs`}
                >
                  {property.is_verified ? "Verified" : "Unverified"}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 dark:text-gray100">
              <p className="md:text-xl text-xl font-bold">
                {currencyFormat(property.price)}
              </p>
              <span className="text-xs">per night</span>
            </div>
            <p className="text-xs dark:text-gray100">
              Accepts {property.guests}
            </p>

            {property.monday_availability && (
              <DateInspectionItem
                dayofweek={"Monday"}
                timeInspect={`${moment(
                  property.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  property.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
            {property.tuesday_availability && (
              <DateInspectionItem
                dayofweek={"Tuesday"}
                timeInspect={`${moment(
                  property.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  property.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
            {property.wednesday_availability && (
              <DateInspectionItem
                dayofweek={"Wednesday"}
                timeInspect={`${moment(
                  property.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  property.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
            {property.thursday_availability && (
              <DateInspectionItem
                dayofweek={"Thursday"}
                timeInspect={`${moment(
                  property.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  property.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
            {property.friday_availability && (
              <DateInspectionItem
                dayofweek={"Friday"}
                timeInspect={`${moment(
                  property.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  property.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
            {property.saturday_availability && (
              <DateInspectionItem
                dayofweek={"Saturday"}
                timeInspect={`${moment(
                  property.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  property.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
            {property.sunday_availability && (
              <DateInspectionItem
                dayofweek={"Sunday"}
                timeInspect={`${moment(
                  property.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  property.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
          </div>
          <div className="flex items-center justify-between py-3">
            <button
              onClick={handleViewDetails}
              className="bg-gray400 dark:bg-gray900 dark:hover:bg-gray800 hover:bg-gray500 transition-all duration-300 ease-in-out text-gray1000 dark:text-gray100 text-xs h-9 px-3 rounded-full w-fit flex font-semibold gap-2 items-center justify-center"
            >
              View Details
              <ArrowTopRight color="#525252" className="w-2.5" />
            </button>
            {/* <button
              onClick={() => setIsModalOpen(true)}
              className="bg-primary500 hover:bg-red-600 transition-all duration-300 ease-in-out text-white text-xs rounded-full w-fit flex font-semibold items-center justify-center p-1.5"
            >
              Delete
            </button>
            {false && (
              <button className="bg-gray400 dark:bg-gray900 dark:hover:bg-gray800 hover:bg-gray500 transition-all duration-300 ease-in-out text-gray1000 dark:text-gray100 text-xs md:text-sm rounded-full md:h-12 h-10 mt-3 px-4 w-fit flex font-semibold gap-2 items-center justify-center">
                Share
                <ShareIcon className="dark:stroke-gray600" />
              </button>
            )} */}
            <button className="bg-gray400 dark:bg-gray900 dark:hover:bg-gray800 hover:bg-gray500 transition-all duration-300 ease-in-out text-gray1000 dark:text-gray100 text-xs rounded-full w-fit flex gap-2 font-semibold items-center justify-center h-9 px-3">
              Share
              <ShareIcon className="dark:stroke-gray600" />
            </button>
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default PublishedListingCard;
