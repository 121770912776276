import { useState } from 'react';
import apiClient from '../network/axios-client';
import { urls } from '../network';
import { showToast } from '../utils/helper-functions';

export const usePhoneVerify = () => {
  const [isPhoneVerifyLoading, setLoading] = useState(false)
  const [validateOtp, setValidateOtp] = useState(false)
  const verifyPhone = async (body:{phone: string}) => {
    try {
      setLoading(true)
      const res = await apiClient.post(urls.VerifyPhone, body)
      setLoading(false)
      if (res.data.hasError) {
        showToast(res.data?.errors?.message, "error");
      } else {
        setValidateOtp(true)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const validatePhone = async (body:{phone: string; code: string}) => {
    try {
      setLoading(true)
      const res = await apiClient.patch(urls.ValidatePhone, body)
      setLoading(false)
      if (res.data.hasError) {
        showToast(res.data?.errors?.message, "error");
      } else {
        setValidateOtp(false)
        return true
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return {isPhoneVerifyLoading, validateOtp, validatePhone, verifyPhone, setValidateOtp}
};

