import React from "react";
import NavbarHost from "./navbar-host";

interface Props {
  children: any;
}
const OnboardingHostLayout = ({ children }: Props) => {
  return (
    <div className="flex justify-center w-screen  bg-gray200 relative dark:bg-gray1000">
      <div className="w-full h-screen relative flex flex-col justify-between overflow-scroll no-scrollbar">
        <NavbarHost />
        {children}
      </div>

      <div className="bg-primary300 w-full h-screen hidden lg:block xl:block 2xl:block bg-image-three dark:bg-image-four">
        <img
          src="https://weddingstory.nyc3.digitaloceanspaces.com/eads_dev1716373862496-hostead.1f4a33c8abb40baaa29b.png"
          alt=""
          className="object-fit h-full w-full"
        />
      </div>
    </div>
  );
};

export default OnboardingHostLayout;
