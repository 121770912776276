import React, { useState, useRef, useEffect, ReactNode } from "react";
import ChevronDown from "../icons/chevron-down";
import { TextInput } from "./text-input";

interface DropdownProps {
  options: string[];
  onSelect: (selectedOption: string) => void;
  defaultValue?: string;
  icon?: ReactNode;
  label?: string;
}

const DropdownSearch: React.FC<DropdownProps> = ({
  options,
  onSelect,
  defaultValue = "Select",
  icon,
  label,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(defaultValue);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = useState("");

  const handleSelect = (option: string) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <p className="text-xs pb-1 text-gray1000 dark:text-gray200">
        {label || ""}
      </p>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full text-nowrap flex px-3 text-sm items-center justify-between border border-gray400 dark:border-gray800 rounded-full p-2 focus:outline-none focus:border-primary500 transition duration-300 ease-in-out h-10 ${
          isOpen ? "border-gray700" : ""
        } ${
          selectedOption === "Select"
            ? "text-gray-400 dark:text-gray400"
            : "text-gray1000 dark:text-gray100"
        }`}
      >
        <div className="flex items-center gap-3">
          {icon && <span>{icon}</span>}
          {selectedOption}
        </div>
        <ChevronDown
          className={`transform transition-transform duration-300 ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        />
      </button>
      {isOpen && (
        <div className="absolute text-sm flex flex-col scrollbar-thin dark:text-gray100 w-full bg-white dark:bg-[#141414] border border-gray400 dark:border-gray800 rounded-2xl mt-2 max-h-60 overflow-y-auto overflow-x-hidden shadow-xl shadow-neutral-200/30 dark:shadow-gray1000/30 z-10">
          <TextInput
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={`Search ${label}`}
            className="px-1 w-full lg:w-full my-1"
          />
          <div className="scrollbar-thin mt-2 overflow-y-auto z-10 flex flex-col">
            {searchText.length > 0
              ? options
                  .filter((item) =>
                    item
                      .toLocaleLowerCase()
                      .includes(searchText.toLocaleLowerCase())
                  )
                  .map((option, index) => (
                    <span
                      key={index}
                      className="p-3 hover:bg-gray100 dark:hover:bg-gray800 cursor-pointer transition-all duration-300 ease-in-out"
                      onClick={() => handleSelect(option)}
                    >
                      {option}
                    </span>
                  ))
              : options.map((option, index) => (
                  <span
                    key={index}
                    className="p-3 hover:bg-gray100 dark:hover:bg-gray800 cursor-pointer transition-all duration-300 ease-in-out"
                    onClick={() => handleSelect(option)}
                  >
                    {option}
                  </span>
                ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownSearch;
