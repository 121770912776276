import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoading } from "../../redux/reducers/loader-slice";
import { FormProvider, useForm } from "react-hook-form";
import InputField, { PhoneInputField } from "./text-input";
import { name_validation } from "../../utils/input-validation";
import { editUserProfile } from "../../redux/actions/authuser";
import { getCurrentUser } from "../../redux/reducers/user-slice";
import { Link } from "react-router-dom";
import { paths } from "../../routes/paths";

interface Props {
  onSetModal: () => void;
}

const UpdateProfileForm: React.FC<Props> = ({ onSetModal }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const profile = useSelector(getCurrentUser);
  const methods = useForm();
  const [phone, setPhone] = useState("");
  const [errMsg, setErrorMsg] = useState<string | null>(null);

  const onSubmit = methods.handleSubmit(async (data) => {
    setErrorMsg(null);
    if (phone.length < 9) {
      setErrorMsg("Phone number is required");
      return;
    }
    const res = await dispatch(
      // @ts-ignore
      editUserProfile({
        first_name: data.first_name,
        last_name: data.last_name,
        phone: phone,
      })
    );
    if (res) {
      methods.reset();
      setPhone("");
      onSetModal();
    }
  });

  useEffect(() => {
    methods.setValue("first_name", profile?.first_name);
    methods.setValue("last_name", profile?.last_name);

    // eslint-disable-next-line
  }, [profile]);

  return (
    <div className="flex flex-col items-center justify-center w-full p-4 dark:bg-gray1000 rounded-xl">
      <p className="text-gray900 text-2xl font-semibold my-4 dark:text-gray200">
        Let's get to know you.
      </p>

      <FormProvider {...methods}>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
          className="w-full flex flex-col gap-3"
        >
          <InputField
            label="First name*"
            placeholder="Enter your first name"
            type="text"
            name="first_name"
            validation={name_validation}
            className="lg:w-auto"
            subtext="Your First name should match those on your ID."
          />
          <InputField
            label="Last name*"
            placeholder="Enter your last name"
            type="text"
            name="last_name"
            validation={name_validation}
            className="lg:w-auto"
            subtext="Your Last name should match those on your ID."
          />
          <PhoneInputField
            label="Phone*"
            value={phone}
            onChange={(phone: any) => setPhone(phone)}
            inputProps={{
              name: "phone",
              required: true,
            }}
            errMsg={errMsg}
            className="lg:w-auto"
          />
          <button
            disabled={isLoading}
            onClick={onSubmit}
            className={`${
              isLoading ? "bg-primary300" : "bg-primary500"
            } w-full mt-4 opacity flex justify-center cursor-pointer p-2 text-gray100 rounded-full text-center font-display font-bold text-xs shadow-md mb-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
          >
            {isLoading ? (
              <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
            ) : (
              "Complete profile"
            )}
          </button>
        </form>
      </FormProvider>
      <Link
        to={paths.HOME}
        className="text-xs text-primary500 underline font-semibold text-center"
      >
        Do this later? Go Home.
      </Link>
    </div>
  );
};

export default UpdateProfileForm;
