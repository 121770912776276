import { useDispatch, useSelector } from "react-redux";
import CheckMarkIcon from "../../../../components/icons/check-mark";
import { useState } from "react";
import moment from "moment";
import ArrowIcon from "../../../../components/icons/arrow-right";
import { CategoryProp, PropertyType } from "../../../../redux/types";
import { getIsLoading } from "../../../../redux/reducers/loader-slice";
import DropdownTwo from "../../../../components/custom/drop-down-two";
import {
  fetchSingleProperty,
  updateProperty,
  updatePropertyDocument,
  uploadDocsSingle,
  viewChecklist,
} from "../../../../redux/actions/property";
import CalendarIcon from "../../../../components/icons/calendar";
import Modal from "../../../../components/custom/modal";
import BookInspection from "../../host-onboard/components/BookInspection";
import UploadFiles from "../../../../components/uploadFiles";

interface PropertyVerificationCardViewProps {
  property: PropertyType;
  propertyChecklist: {
    id: number;
    name: string;
    is_inspected: boolean;
    inspection_date: string;
    property_verification_request: {
      created_at: string;
      deleted_at: null;
      document_image_url: string;
      document_number: string;
      document_type: string;
      id: 2;
      is_deleted: boolean;
      status: string;
      updated_at: string;
    }[];
    verification_status:
      | "pending"
      | "awaiting approval"
      | "verified"
      | "rejected";
  };
}

type ValuePiece = Date | null;

const PropertyVerificationCardView: React.FC<
  PropertyVerificationCardViewProps
> = ({ property, propertyChecklist }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tag, setTag] = useState(false);
  const isLoading = useSelector(getIsLoading);
  const [imageurl, setImageUrl] = useState<string | null>(null);
  const [docType, setDoctype] = useState<string>("");
  const [errorMsg, setErroMsg] = useState("");
  const [value, onChange] = useState<ValuePiece>(new Date());

  const propertyCategories = JSON.parse(
    localStorage.getItem("allcategories") || "{}"
  ) as CategoryProp;

  const handleUploadClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/jpeg,image/gif,image/png,application/pdf";
    fileInput.click();
    const fd = new FormData();

    fileInput.onchange = async (event: any) => {
      fd.append("media", event.target.files[0], "media");
      // @ts-ignore
      const res = (await dispatch(uploadDocsSingle(fd))) as string;
      if (res) {
        setImageUrl(res);
        // setDocfile((prevUploadedFiles) => [...prevUploadedFiles, res]);
      }
      return;
    };
  };

  const saveUpload = async () => {
    if (docType.length < 1) {
      setErroMsg("Kindly select a document type");
      return;
    }
    if (!imageurl) {
      setErroMsg("Kindly select a document image");
      return;
    }
    const res = await dispatch(
      // @ts-ignore
      updatePropertyDocument({
        property_id: property?.id,
        document_type: docType,
        document_image_url: imageurl,
      })
    );

    if (res) {
      setOpen(false);
      setImageUrl(null);
      // @ts-ignore
      dispatch(fetchSingleProperty(property?.id));
      // @ts-ignore
      dispatch(viewChecklist());
    }
  };

  const onBookInspection = async () => {
    // @ts-ignore
    const res = await dispatch(
      // @ts-ignore
      updateProperty({
        id: property?.id,
        inspection_date: value?.toISOString(),
      })
    );
    if (res) {
      setOpenModal(false);
      // @ts-ignore
      dispatch(viewChecklist());
      // @ts-ignore
      dispatch(fetchSingleProperty(property?.id));
    }
  };

  const VerifyListCheck = ({
    title,
    active,
    action,
    show,
    tag,
  }: {
    title: string;
    active: boolean;
    action: () => void;
    show?: boolean;
    tag?: boolean;
  }) => (
    <div className="bg-gray100 p-4 rounded-xl border border-gray400 dark:border-gray800 mb-3 dark:bg-gray900">
      <div className="flex justify-between items-start">
        <div className="flex items-center gap-3">
          <span
            className={`${
              active ? "text-black dark:text-gray-100" : "text-primary500"
            }`}
          >
            ●
          </span>
          <span className="text-xs font-normal text-gray1000 dark:text-gray200">
            {title}
          </span>
          {/* {active ? <CheckMarkIcon className="flex-shrink-0 w-4 h-4" /> : null} */}
        </div>
        {active ? (
          <CheckMarkIcon className="flex-shrink-0 w-4 h-4" />
        ) : (
          <button
            onClick={action}
            className="text-xs flex items-center gap-2 font-bold text-gray900 dark:text-gray600 flex-shrink-0"
          >
            See {show ? "Less" : "Details"} <ArrowIcon className="w-3 h-3" />
          </button>
        )}
      </div>
      {tag && (
        <div className="w-full mt-2 transition-all px-5 flex flex-col gap-3">
          <p className="text-xs font-normal text-gray1000 dark:text-gray200">
            This listing is pending inspection, you’ll see comments below.
          </p>
          <div
            onClick={() => setOpenModal(true)}
            className="flex gap-1 items-center cursor-pointer"
          >
            <CalendarIcon color="#FF3333" />
            <p className="text-xs font-bold text-primary500">
              Reschedule inspection
            </p>
          </div>
        </div>
      )}
      {show && (
        <div className="w-full mt-2 transition-all px-5 flex flex-col gap-3">
          <p className="text-xs font-normal text-gray1000 dark:text-gray200">
            To verify your property and increase your chances of getting booked,
            upload a document that proves right to upload;
          </p>
          <div>
            <p className="text-xs bg-primary500">{errorMsg}</p>
            <DropdownTwo
              label="Document type"
              // @ts-ignore
              options={
                propertyCategories.propertyVerificationDocumentTypes.map(
                  (item) => {
                    return { value: item, label: item };
                  }
                ) || []
              }
              onSelect={(e) => {
                setDoctype(e);
                setErroMsg("");
              }}
              value={docType || "Select"}
            />
          </div>
          {imageurl ? (
            <p className="py-2 text-primary500 text-base">
              document file uploaded
            </p>
          ) : (
            <UploadFiles onClick={handleUploadClick} loader={isLoading} />
          )}
          <button
            onClick={saveUpload}
            disabled={!docType || !imageurl}
            className={`text-xs flex items-center gap-2 font-bold text-eadWhite ${
              !docType || !imageurl || isLoading
                ? "bg-primary300"
                : "bg-primary500"
            } rounded-full p-1 w-fit place-self-end flex-shrink-0`}
          >
            {isLoading ? "uplaoding" : "upload"}
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className="bg-gray100 p-4 rounded-xl border border-gray400 dark:border-gray800 dark:bg-gray900">
      <div className="flex gap-2 items-center justify-between mb-4">
        <p className="text-base font-bold text-gray1000 dark:text-gray200">
          Property verification
        </p>
        <div className="flex gap-2 flex-shrink-0">
          <p className="text-xs bg-gray400 text-primary500 rounded-full px-2 py-0.5 dark:bg-gray1000">
            {propertyChecklist?.verification_status}
          </p>
          <p
            className={`text-xs ${
              property?.is_verified
                ? "bg-pr bg-green-500"
                : "bg-gray400 dark:bg-gray1000"
            } text-gray1000 dark:text-gray200 rounded-full px-2 py-0.5`}
          >
            {property?.is_verified ? "verified" : "unverified"}
          </p>
        </div>
      </div>
      <p className="text-xs font-normal text-gray1000 dark:text-gray200 pb-2">
        Earn a <span className="font-bold">Verified</span> badge, increase your
        chances of getting booked.
      </p>
      <VerifyListCheck
        title={"Uploaded pictures"}
        active={property?.property_media?.length > 3}
        action={() => console.log("")}
      />
      <VerifyListCheck
        title={`Property Inspection ${
          propertyChecklist?.inspection_date?.length === 0 ? "" : "on"
        } ${moment(property?.inspection_date).format("D MMMM YYYY")}`}
        active={propertyChecklist?.is_inspected}
        action={() => {
          setTag((prev) => !prev);
        }}
        tag={tag}
      />
      <VerifyListCheck
        title={` Right to host (any document that shows ownership and right to list
          this property)`}
        active={
          !!propertyChecklist?.property_verification_request?.[0]
            ?.document_image_url
        }
        action={() => {
          setOpen((prev) => !prev);
        }}
        show={open}
      />

      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <BookInspection
          onCancel={() => {
            setOpenModal(false);
          }}
          onConfirm={onBookInspection}
          onChange={onChange}
          value={value}
        />
      </Modal>
    </div>
  );
};

export default PropertyVerificationCardView;
