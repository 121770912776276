import React from "react";
import ChevronLeft from "../components/icons/chevron-left";
import Bird from "../assets/images/bird.png";
import BedroomWhite from "../components/icons/bedroom-white";
import EADLogo from "../assets/images/EADlogo.png";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";

function ErrorPage() {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center gap-8 relative px-4">
      <img src={EADLogo} alt="" className="h-14 w-auto absolute top-0 my-4" />
      <div>
        <img src={Bird} alt="" className="object-contain" />
      </div>
      <p className=" text-3xl font-display font-bold text-gray1000 text-left">
        Can you hear the twittering birds?
      </p>
      <p className="text-lg font-display text-gray1000 text-left">
        Looks peaceful out here, we love the serene atmosphere too. Now let’s go
        home
      </p>
      <div className="lg:flex xl:flex lg:items-center lg:gap-3 place-self-start lg:place-self-center xl:place-self-center">
        <button
          onClick={() => navigate(-1)}
          className="p-2 rounded-full flex items-center justify-center gap-2 bg-gray400"
        >
          <ChevronLeft />
          <span className="font-display text-sm text-gray1000 font-semibold">
            Go back
          </span>
        </button>
        <button
          onClick={() => navigate(paths.HOME)}
          className="p-2 rounded-full flex items-center justify-center gap-2 bg-primary500 w-auto xl:mt-0 lg:mt-0 mt-3"
        >
          <BedroomWhite />
          <span className="font-display text-eadWhite text-sm font-semibold">
            Let’s go back home, make some magic
          </span>
        </button>
      </div>
    </div>
  );
}

export default ErrorPage;
