import React from "react";

interface ToggleButtonProps {
  isActive?: boolean;
  onToggle?: () => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ isActive, onToggle }) => {
  return (
    <div
      onClick={onToggle}
      className={`h-6 cursor-pointer w-10 rounded-full p-1 flex items-center transition-all duration-200 ease-in-out ${
        isActive
          ? "bg-primary400"
          : "bg-white dark:bg-transparent border border-gray400 dark:border-gray800"
      }`}
    >
      <div
        className={`w-4 h-4 rounded-full transition-all duration-200 ease-in-out ${
          isActive ? "translate-x-4 bg-white" : "bg-primary400"
        }`}
      ></div>
    </div>
  );
};

export default ToggleButton;
