import React from "react";
import Bedroom from "../icons/bedroom";

type Props = {
  title: string;
  icon?: any;
};

const PillsTwo = ({ title, icon }: Props) => {
  return (
    <div className="rounded-full flex p-0.5 px-1 gap-1 items-center border border-gray400 bg-gray300 dark:bg-gray800 dark:border-gray800">
      {icon || <Bedroom className="w-2" />}
      <span className="text-[10px] font-display font-semibold text-gray800 dark:text-gray300">{title}</span>
    </div>
  );
};

export default PillsTwo;
