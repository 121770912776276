import React from "react";
import { BsChevronRight } from "react-icons/bs";
import { useSelector } from "react-redux";
import { getGuestBookings } from "../../../../redux/reducers/property-slice";
import moment from "moment";
import { currencyFormat } from "../../../../utils/helper-functions";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../routes/paths";
import { getIsLoading } from "../../../../redux/reducers/loader-slice";
import { CiCalendar } from "react-icons/ci";

const PastBookings: React.FC = () => {
  const navigate = useNavigate();
  const upcompingBookings = useSelector(getGuestBookings);
  const isLoading = useSelector(getIsLoading);
  return (
    <div className="flex flex-col gap-2 font-display">
      {upcompingBookings.length === 0 && !isLoading && (
        <div className="w-full bg-primary100 border border-gray400 rounded-2xl h-96 flex flex-col gap-2 p-5 text-center items-center justify-center text-gray800 dark:text-gray800">
          <CiCalendar className="text-5xl" />
          <span className="font-bold text-2xl tracking-tight">
            No past bookings
          </span>
          <span className="w-3/4 text-sm">
            With guaranteed quality, chose from our carefully selected homes
          </span>
          <button
            onClick={() => navigate(`${paths.SEARCH_LIST}`)}
            className="bg-primary500 font-display text-sm font-bold text-eadWhite text-center h-9 flex items-center justify-center px-3 rounded-full mt-3"
          >
            Find a property
          </button>
        </div>
      )}
      {isLoading ? (
        <div className="animate-pulse w-full">
          <div className="flex flex-col gap-2 w-full">
            <div className="rounded-xl h-20 w-full bg-gray500"></div>
            <div className="rounded-xl h-20 w-full bg-gray500"></div>
          </div>
        </div>
      ) : (
        upcompingBookings
          // .filter((item) => moment(new Date()).isAfter(item.check_out_date))
          .map((booking) => (
            <div
              key={booking.id}
              className="flex md:flex-row flex-col md:items-start gap-5 bg-gray200 text-gray1000 dark:text-gray100 dark:bg-transparent border border-gray400 dark:border-gray800 p-3 rounded-2xl"
            >
              <img
                src={
                  booking.property_json?.property_media
                    ? booking.property_json.property_media[0]?.url
                    : ""
                }
                alt={booking.booking_reference}
                className="w-full h-auto rounded-xl bg-gray500 lg:h-32 lg:w-52"
              />
              <div className="flex flex-col gap-1.5 text-gray900 dark:text-gray200 w-full justify-between">
                <span className="font-bold text-lg">
                  {booking.property_json?.name}
                </span>
                <div className="flex items-center gap-1 text-gray800 dark:text-gray200 font-semibold text-sm">
                  <span>
                    {" "}
                    {moment(booking?.check_in_date).format("D MMM")}{" "}
                    {booking?.property_json?.check_in_time}
                  </span>
                  <BsChevronRight />
                  <span>
                    {moment(booking?.check_out_date).format("D MMM")}{" "}
                    {booking?.property_json?.check_out_time}
                  </span>
                </div>
                <span className="font-bold text-sm">
                  {currencyFormat(booking?.total_amount_paid || 0)}
                </span>
                <div className="flex items-center justify-between w-full">
                  <button
                    onClick={() =>
                      navigate(
                        `${paths.VIEW_PROPERTY}/${booking.property_json?.slug}`
                      )
                    }
                    className="bg-gray400 cursor-pointer mt-2 w-fit text-nowrap hover:bg-gray500 transition-all duration-200 ease-in-out text-gray1000 rounded-full flex font-bold items-center justify-center text-sm p-2 px-3 gap-2"
                  >
                    Rebook
                  </button>

                  <button
                    onClick={() =>
                      navigate(`${paths.GUEST}/booking-details/${booking.id}`)
                    }
                    className="bg-gray400 cursor-pointer mt-2 w-fit text-nowrap hover:bg-gray500 transition-all duration-200 ease-in-out text-gray1000 rounded-full flex font-bold items-center justify-center text-sm p-2 px-3 gap-2"
                  >
                    {(booking.reviews || [])?.length === 0
                      ? "Leave a review"
                      : "View booking"}
                  </button>
                </div>
              </div>
            </div>
          ))
      )}
    </div>
  );
};

export default PastBookings;
