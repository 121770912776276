import React from "react";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isToday,
  parse,
  startOfToday,
  startOfWeek,
} from "date-fns";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import { useState } from "react";

interface CalendarProps {
  onChange?: (date: Date) => void;
}

const CalendarDayInspection: React.FC<CalendarProps> = ({ onChange }) => {
  const today = startOfToday();
  const days = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  const colStartClasses = [
    "col-start-7", // Sunday
    "col-start-1", // Monday
    "col-start-2", // Tuesday
    "col-start-3", // Wednesday
    "col-start-4", // Thursday
    "col-start-5", // Friday
    "col-start-6", // Saturday
  ];

  const [currMonth, setCurrMonth] = useState(() => format(today, "MMM-yyyy"));
  const [pickDate, setPickDate] = useState<Date | null>(null);

  let firstDayOfMonth = parse(currMonth, "MMM-yyyy", new Date());

  const daysInMonth = eachDayOfInterval({
    start: startOfWeek(firstDayOfMonth, { weekStartsOn: 1 }),
    end: endOfWeek(endOfMonth(firstDayOfMonth), { weekStartsOn: 1 }),
  });

  const getPrevMonth = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const firstDayOfPrevMonth = add(firstDayOfMonth, { months: -1 });
    setCurrMonth(format(firstDayOfPrevMonth, "MMM-yyyy"));
  };

  const getNextMonth = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const firstDayOfNextMonth = add(firstDayOfMonth, { months: 1 });
    setCurrMonth(format(firstDayOfNextMonth, "MMM-yyyy"));
  };

  const handleDayClick = (day: Date) => {
    if (onChange) onChange(day);
    setPickDate(day);
  };

  return (
    <div className="flex items-center justify-center">
      <div className="w-full flex flex-col gap-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between w-full">
            <button
              className="cursor-pointer text-sm text-gray600  hover:text-gray900 transition-all duration-300 ease-in-out"
              onClick={getPrevMonth}
            >
              <FaChevronLeft />
            </button>
            <p className="font-semibold text-sm md:text-lg text-gray900 dark:text-gray100">
              {format(firstDayOfMonth, "MMMM yyyy")}
            </p>

            <button
              className="cursor-pointer text-sm text-gray600  hover:text-gray900 transition-all duration-300 ease-in-out"
              onClick={getNextMonth}
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-7 gap-7 place-items-center">
          {days.map((day, idx) => (
            <div key={idx} className="font-semibold md:text-base text-xs">
              {day}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-3 place-items-center">
          {daysInMonth.map((day, idx) => (
            <div
              key={idx}
              className={`${colStartClasses[getDay(day)]} relative`}
            >
              <p
                className={`cursor-pointer md:text-base text-xs transition-all duration-200 ease-in-out flex items-center justify-center rounded-full w-7 h-7 md:h-10 md:w-10 ${
                  pickDate?.getTime() === day.getTime()
                    ? "bg-gray400 dark:bg-gray800 dark:text-gray100 text-gray1000"
                    : "text-gray600 "
                }
                ${
                  today.getTime() < day.getTime()
                    ? "text-gray900"
                    : "text-gray600 "
                }
                 ${
                   !isToday(day) &&
                   "hover:bg-primary200 dark:hover:bg-primary500/30"
                 }`}
                onClick={() => {
                  if (today.getTime() < day.getTime()) {
                    handleDayClick(day);
                  }
                }}
              >
                {format(day, "d")}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarDayInspection;
