import Apa from "../assets/images/apa.png";
import Apa1 from "../assets/images/apa1.png";
import {
  AcIcon,
  ApartmentIcon,
  BeachIcon,
  DuplexIcon,
  EmailIcon,
  PhoneCallIcon,
  TvIcon,
  WhatsappIcon,
  WifiIcon,
} from "../assets/svgs";

export const guestfaqs = [
  {
    id: 1,
    header: "What is EAD Shortlets about?",
    text: "EAD shortlets is an all-in-one platform for finding and booking short stay apartments. ",
  },
  {
    id: 2,
    header: "What payment method do I require?",
    text: "You can make payments through your preferred payment method",
  },
  {
    id: 3,
    header: "How do I know if an apartment is available on a certain date?",
    text: "Find the apartment you want to book on our website, click to view more about the apartment and availability.",
  },
  {
    id: 4,
    header: "Where does EAD operate?",
    text: "We cover shortlets in Lagos, London, Abuja, Dubai, Kenya, South Africa and Ghana.",
  },
  {
    id: 5,
    header: "Is there a maximum occupancy for the shortlets?",
    text: "With EAD Shortlets, occupancy is per apartment. Browse through our homes to view unique requirements per home.",
  },
  {
    id: 6,
    header: "Can guests bring pets?",
    text: "This depends on the specific apartment. Rules are different with each EAD apartment.",
  },
  {
    id: 7,
    header: "Can I inspect an apartment before deciding on booking?",
    text: "Apartments are already inspected and certified for guests' use and comfort. However, for more information, guests can visit our website to see images, amenities  and every apartment details before booking.  In case of any issues? contact support.",
  },
  {
    id: 8,
    header: "Can apartments be used for parties?",
    text: "This depends on the specific apartment. Rules are different with each EAD apartment. Ensure to read house rules always.",
  },
  {
    id: 9,
    header: "Do I have to pay a caution fee?",
    text: "No caution fee is required for EAD apartments",
  },
  {
    id: 10,
    header: "Can I make a reservation for a friend?",
    text: "EAD customers can make bookings on behalf of their families and friends. They receive a booking notification and confirmation and the guest can share booking information.",
  },
];

export const hostfaqs = [
  {
    id: 1,
    header: "What is EAD Shortlets about?",
    text: "EAD shortlets is an all-in-one platform for finding and booking shortlet apartments. With EAD shortlets, you can turn your home into profits. Sign up to become an EAD Shortlets host today!",
  },
  {
    id: 2,
    header: "How do I list my property on EAD Shortlets?",
    text: "Click on ‘Become a Host’ on our website, Sign up, and list your first property. Begin the journey to earning income with your space today.",
  },
  {
    id: 3,
    header: "How long will it take for my apartment to get verified?",
    text: "EAD apartments are verified after the host submits required verification documents. Complete documentation attracts a verification badge.",
  },
  {
    id: 4,
    header: "How long does it take to receive remittances for apartments?",
    text: "Hosts will be able to receive their money after guest checks in successfully or as soon as guests vacate their homes. ",
  },
  {
    id: 5,
    header:
      "What amenities should a space have to be accepted by EAD shortlet offer?",
    text: "An EAD apartment is expected to have basic modern amenities and infrastructures. For example, Air conditioning, Smart TV, washing machine, Power supply, kitchen, parking area, WiFi etc.",
  },
  {
    id: 6,
    header: "Is there support for an EAD host?",
    text: "Yes there is. Host can reach out to the EAD team anytime for support.",
  },
  {
    id: 7,
    header: "Can I list all my properties in other locations?",
    text: "Yes. We operate across these regions - Lagos, Abuja, Kenya, Ghana, South Africa, Dubai, and London.",
  },
  {
    id: 8,
    header: "How are property listings reviewed and approved on EAD shortlets?",
    text: "This depends on the specific apartment. Rules are different with each EAD apartment. Ensure to read house rules always.Listed properties are reviewed and approved after the host has submitted important verification documents and apartments have been inspected.",
  },
  {
    id: 9,
    header: "What are the benefits of being an EAD host?",
    text: "A EAD hosts is that hosts get verification badges for approved apartment(s) which increases visibility and bookings.",
  },
];

export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
].map((item) => ({
  id: item.name,
  name: item.name,
  value: item.name,
  label: item.name,
  code: item.code,
}));

export const cities = [
  { label: "Lekki", value: "city:Lekki" },
  { label: "Yaba", value: "city:Yaba" },
  { label: "Bariga", value: "city:Bariga" },
];

export const otherRules = [
  { id: "No Pets", value: "No Pets", name: "No Pets", label: "No Pets" },
  {
    id: "No Smoking",
    value: "No Smoking",
    name: "No Smoking",
    label: "No Smoking",
  },
];

export const shortlets = [
  {
    img: Apa,
    price: 150000,
    duration: "per night",
    title: "Studio apartment in Lekki",
    desc: "Amazing 1 bedroom studio apartment in Lekki area, well furnished and serviced.",
    location: "Lekki, Lagos",
    slug: "studio",
  },
  {
    img: Apa1,
    price: 70000,
    duration: "per night",
    title: "3 bedroom duplex in Ikoyi",
    desc: "Amazing 1 bedroom 3 bedroom duplex in Ikoyi area, well furnished and serviced.",
    location: "Ikoyi, Lagos",
    slug: "duplex",
  },
  {
    img: Apa,
    price: 150000,
    duration: "per night",
    title: "Studio apartment in Lekki",
    desc: "Amazing 1 bedroom studio apartment in Lekki area, well furnished and serviced.",
    location: "Lekki, Lagos",
    slug: "apartment",
  },
  {
    img: Apa1,
    price: 70000,
    duration: "per night",
    title: "3 bedroom duplex in Ikoyi",
    desc: "Amazing 1 bedroom 3 bedroom duplex in Ikoyi area, well furnished and serviced.",
    location: "Ikoyi, Lagos",
    slug: "bed-duplex",
  },
  {
    img: Apa,
    price: 150000,
    duration: "per night",
    title: "Studio apartment in Lekki",
    desc: "Amazing 1 bedroom studio apartment in Lekki area, well furnished and serviced.",
    location: "Lekki, Lagos",
    slug: "studio",
  },
  {
    img: Apa1,
    price: 70000,
    duration: "per night",
    title: "3 bedroom duplex in Ikoyi",
    desc: "Amazing 1 bedroom 3 bedroom duplex in Ikoyi area, well furnished and serviced.",
    location: "Ikoyi, Lagos",
    slug: "studio",
  },
  {
    img: Apa,
    price: 150000,
    duration: "per night",
    title: "Studio apartment in Lekki",
    desc: "Amazing 1 bedroom studio apartment in Lekki area, well furnished and serviced.",
    location: "Lekki, Lagos",
    slug: "studio",
  },
  {
    img: Apa1,
    price: 70000,
    duration: "per night",
    title: "3 bedroom duplex in Ikoyi",
    desc: "Amazing 1 bedroom 3 bedroom duplex in Ikoyi area, well furnished and serviced.",
    location: "Ikoyi, Lagos",
    slug: "studio",
  },
  {
    img: Apa,
    price: 150000,
    duration: "per night",
    title: "Studio apartment in Lekki",
    desc: "Amazing 1 bedroom studio apartment in Lekki area, well furnished and serviced.",
    location: "Lekki, Lagos",
    slug: "studio",
  },
  {
    img: Apa1,
    price: 70000,
    duration: "per night",
    title: "3 bedroom duplex in Ikoyi",
    desc: "Amazing 1 bedroom 3 bedroom duplex in Ikoyi area, well furnished and serviced.",
    location: "Ikoyi, Lagos",
    slug: "studio",
  },
];

export const imaged = [
  {
    src: "https://picsum.photos/id/1018/1000/600/",
    title: "1",
  },
  {
    src: "https://picsum.photos/id/1015/1000/600/",
    title: "2",
  },
  {
    src: "https://picsum.photos/id/1019/1000/600/",
    title: "3",
  },
  {
    src: "https://picsum.photos/id/1015/1000/600/",
    title: "4",
  },
  {
    src: "https://picsum.photos/id/1019/1000/600/",
    title: "5",
  },
];

export const imagesprop = [
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
    thumbnailHeight: 1000,
    thumbnailWidth: 1000,
    originalClass: "rounded-full",
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
    thumbnailHeight: 1000,
    thumbnailWidth: 1000,
    originalClass: "rounded-full",
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
    thumbnailHeight: 1000,
    thumbnailWidth: 1000,
    originalClass: "rounded-full",
  },
];

export const datesList = [
  {
    day: "Sunday",
    isActive: false,
    time: "01:00PM - 04:00PM",
    booked: [],
  },
  {
    day: "Monday",
    isActive: true,
    time: "01:00PM - 04:00PM",
    booked: ["Jan 20", "Jan 27"],
  },
  {
    day: "Tuesday",
    isActive: true,
    time: "01:00PM - 04:00PM",
    booked: ["Jan 20", "Jan 27"],
  },
  {
    day: "Wednesday",
    isActive: true,
    time: "01:00PM - 04:00PM",
    booked: ["Jan 20", "Jan 27"],
  },
  {
    day: "Thursday",
    isActive: true,
    time: "01:00PM - 04:00PM",
    booked: ["Jan 20", "Jan 27"],
  },
  {
    day: "Friday",
    isActive: false,
    time: "01:00PM - 04:00PM",
    booked: [],
  },
  {
    day: "Saturday",
    isActive: false,
    time: "01:00PM - 04:00PM",
    booked: [],
  },
];

export const IconPack = [
  {
    tag: "duplex-icon",
    icon: DuplexIcon,
  },
  {
    tag: "workspace-icon",
    icon: ApartmentIcon,
  },
  {
    tag: "apartment-icon",
    icon: ApartmentIcon,
  },
  {
    tag: "hotel-icon",
    icon: ApartmentIcon,
  },
  {
    tag: "wifi-icon",
    icon: WifiIcon,
  },
  {
    tag: "tv-icon",
    icon: TvIcon,
  },
  {
    tag: "AC-icon",
    icon: AcIcon,
  },
  {
    tag: "pool-icon",
    icon: BeachIcon,
  },
  {
    tag: "email-icon",
    icon: EmailIcon,
  },
  {
    tag: "whatsapp-icon",
    icon: WhatsappIcon,
  },
  {
    tag: "phonecall-icon",
    icon: PhoneCallIcon,
  },
];

export const getIconComponent = (iconTag: string) => {
  switch (iconTag) {
    case "duplex-icon":
      return;
    default:
      break;
  }
};

export const INSTANT_SEARCH_INDEX_NAME = "properties";
export const INSTANT_SEARCH_QUERY_SUGGESTIONS =
  "properties_demo_query_suggestions";
export const INSTANT_SEARCH_HIERARCHICAL_ATTRIBUTES = [
  "hierarchicalCategories.lvl0",
  "hierarchicalCategories.lvl1",
];

export const bedroomOptions = [
  { name: "Bedrooms", value: "bedrooms >= 0" },
  { name: "1 Bedroom", value: "bedrooms = 1" },
  { name: "2 Bedrooms", value: "bedrooms = 2" },
  { name: "3 Bedrooms", value: "bedrooms = 3" },
  { name: "4 Bedrooms", value: "bedrooms = 4" },
  { name: "5 Bedrooms", value: "bedrooms = 5" },
  { name: "Above 5 Bedrooms", value: "bedrooms >= 5" },
];

export const guestOptions = [
  { name: "Guests", value: "guests > 0" },
  { name: "Less than 3 Guests", value: "guests < 3" },
  { name: "3 to 5 Guests", value: "(guests >= 3 AND guests <= 5)" },
  { name: "5 to 10 Guests", value: "(guests >= 5 AND guests <= 10)" },
];

export const priceOptions = [
  { name: "Any price", value: "(price >= 1000)" },
  { name: "10,000 - 50,000", value: "(price >= 10000 AND price <= 50000)" },
  { name: "50,000 - 100,000", value: "(price >= 50000 AND price <= 100000)" },
  { name: "Above 100,000", value: "(price >= 100000)" },
];

export const BookingStatus = {
  pending: "pending",
  paid: "paid",
  awaiting: "awaiting_host_approval",
  reserved: "reserved",
  guestCancel: "guest_cancelled",
  hostCancel: "host_cancelled",
};

export const timezones = [
  {
    timezone: "Pacific Standard Time",
    abbreviation: "PST",
    offset: "-08:00",
  },
  {
    timezone: "Mountain Standard Time",
    abbreviation: "MST",
    offset: "-07:00",
  },
  {
    timezone: "Central Standard Time",
    abbreviation: "CST",
    offset: "-06:00",
  },
  {
    timezone: "Eastern Standard Time",
    abbreviation: "EST",
    offset: "-05:00",
  },
  {
    timezone: "Greenwich Mean Time",
    abbreviation: "GMT",
    offset: "+00:00",
  },
  {
    timezone: "Central European Time",
    abbreviation: "CET",
    offset: "+01:00",
  },
  {
    timezone: "Eastern European Time",
    abbreviation: "EET",
    offset: "+02:00",
  },
  {
    timezone: "Moscow Standard Time",
    abbreviation: "MSK",
    offset: "+03:00",
  },
  {
    timezone: "India Standard Time",
    abbreviation: "IST",
    offset: "+05:30",
  },
  {
    timezone: "China Standard Time",
    abbreviation: "CST",
    offset: "+08:00",
  },
  {
    timezone: "Japan Standard Time",
    abbreviation: "JST",
    offset: "+09:00",
  },
  {
    timezone: "Australian Eastern Standard Time",
    abbreviation: "AEST",
    offset: "+10:00",
  },
];

export const timezone2 = [
  "Africa/Lagos",
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Fort_Nelson",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Colombo",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kathmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
];
