import { FaSwimmingPool, FaWifi, FaTv } from "react-icons/fa";
import {
  TbTemperatureSnow,
  TbMicrowave,
  TbHotelService,
  TbBath,
  TbBuildingEstate,
  TbBuildingCommunity,
  TbBrandWhatsapp,
} from "react-icons/tb";
import { MdOutlinePower, MdDirectionsCar } from "react-icons/md";
import { FiSmartphone } from "react-icons/fi";
import {
  PiBowlFoodLight,
  PiFireExtinguisher,
  PiToilet,
  PiWashingMachine,
} from "react-icons/pi";
import {
  IoAirplaneOutline,
  IoBedOutline,
  IoGameControllerOutline,
} from "react-icons/io5";
import { BiCctv } from "react-icons/bi";
import { LiaDumbbellSolid } from "react-icons/lia";
import { LuHotel } from "react-icons/lu";
import { HiOutlineMail, HiOutlineOfficeBuilding } from "react-icons/hi";

export const iconsData = [
  {
    name: "Room",
    tags: ["room-icon"],
    icon: <IoBedOutline className="dark:text-gray500" />,
  },
  {
    name: "Bathroom",
    tags: ["bathroom-icon"],
    icon: <TbBath className="dark:text-gray500" />,
  },
  {
    name: "Swimming Pool",
    tags: ["pool-icon"],
    icon: <FaSwimmingPool className="dark:text-gray500" />,
  },
  {
    name: "Wi-Fi",
    tags: ["wifi-icon"],
    icon: <FaWifi className="dark:text-gray500" />,
  },
  {
    name: "TV",
    tags: ["tv-icon"],
    icon: <FaTv className="dark:text-gray500" />,
  },
  {
    name: "Air Conditioning",
    tags: ["ac-icon"],
    icon: <TbTemperatureSnow className="dark:text-gray500" />,
  },
  {
    name: "Power Supply",
    tags: ["power-icon"],
    icon: <MdOutlinePower className="dark:text-gray500" />,
  },
  {
    name: "Parking",
    tags: ["parking-icon"],
    icon: <MdDirectionsCar className="dark:text-gray500" />,
  },
  {
    name: "Airplane",
    tags: ["airplane-icon"],
    icon: <IoAirplaneOutline className="dark:text-gray500" />,
  },
  {
    name: "Game Room",
    tags: ["game-icon"],
    icon: <IoGameControllerOutline className="dark:text-gray500" />,
  },
  {
    name: "Washing Machine",
    tags: ["washing-machine-icon"],
    icon: <PiWashingMachine className="dark:text-gray500" />,
  },
  {
    name: "Fire Extinguisher",
    tags: ["fire-extinguisher-icon"],
    icon: <PiFireExtinguisher className="dark:text-gray500" />,
  },
  {
    name: "CCTV",
    tags: ["cctv-icon"],
    icon: <BiCctv className="dark:text-gray500" />,
  },
  {
    name: "Microwave",
    tags: ["microwave-icon"],
    icon: <TbMicrowave className="dark:text-gray500" />,
  },
  {
    name: "Food",
    tags: ["food-icon"],
    icon: <PiBowlFoodLight className="dark:text-gray500" />,
  },
  {
    name: "Gym",
    tags: ["gym-icon"],
    icon: <LiaDumbbellSolid className="dark:text-gray500" />,
  },
  {
    name: "Toilet",
    tags: ["toilet-icon"],
    icon: <PiToilet className="dark:text-gray500" />,
  },
  {
    name: "Hotel Service",
    tags: ["hotel-service-icon"],
    icon: <TbHotelService className="dark:text-gray500" />,
  },
  {
    name: "Hotel",
    tags: ["hotel-icon"],
    icon: <LuHotel className="dark:text-gray500" />,
  },
  {
    name: "Workspace",
    tags: ["workspace-icon"],
    icon: <HiOutlineOfficeBuilding className="dark:text-gray500" />,
  },
  {
    name: "Duplex",
    tags: ["duplex-icon"],
    icon: <TbBuildingEstate className="dark:text-gray500" />,
  },
  {
    name: "Apartment",
    tags: ["apartment-icon"],
    icon: <TbBuildingCommunity className="dark:text-gray500" />,
  },
  {
    name: "Email",
    tags: ["email-icon"],
    icon: <HiOutlineMail className="dark:text-gray500" />,
  },

  {
    name: "Whatsapp",
    tags: ["whatsapp-icon"],
    icon: <TbBrandWhatsapp className="dark:text-gray500" />,
  },

  {
    name: "Phone",
    tags: ["phone-icon"],
    icon: <FiSmartphone className="dark:text-gray500" />,
  },
];

export const getIconComponent = (iconTag: string) => {
  const iconData = iconsData.find((iconData) =>
    iconData.tags.includes(iconTag)
  );
  return iconData ? (
    iconData.icon
  ) : (
    <TbHotelService className="dark:text-gray500" />
  );
};
