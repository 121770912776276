import AppRoutes from "./routes/app-route";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./pages/error-fallback";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SignInLinkContextProvider } from "./context/sign-in-link-context";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_SIGNIN_KEY || ""}
      >
        <Provider store={store}>
          <SignInLinkContextProvider>
            <ToastContainer
              hideProgressBar={true}
              pauseOnFocusLoss={false}
              theme="colored"
            />
            <AppRoutes />
          </SignInLinkContextProvider>
        </Provider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
};

export default App;
