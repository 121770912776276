import React from "react";
import Bedroom from "../icons/bedroom";

type Props = {
  title: string;
  icon?: any;
};

const Pills = ({ title, icon }: Props) => {
  return (
    <div className="rounded-full flex p-1 gap-1 items-center border border-gray400 bg-gray300 dark:bg-gray1000 dark:border-gray800">
      {icon || <Bedroom className="w-3" />}
      <span className="text-xs font-display font-semibold text-gray800 dark:text-gray300">{title}</span>
    </div>
  );
};

export default Pills;
