import { useState } from "react";
import SearchIcon from "../../../../components/icons/search";
import useGooglePlaces from "../../../../hooks/use-google-places";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../routes/paths";
import NavigateButton from "./NavigateButton";
import useGooglePlaceInfo from "../../../../hooks/use-google-place-info";
import MapView from "../../../../components/custom/map-view-location";
import CloseMobileIcon from "../../../../components/icons/close-mobile";

const EnterAddress = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const { places } = useGooglePlaces(address);
  const [placeid, setPlaceid] = useState("");
  const [showlist, setShowlist] = useState(false);
  const { place } = useGooglePlaceInfo(placeid);

  return (
    <div className="flex flex-col items-center gap-8 mx-3">
      <p className="font-display text-xl lg:text-3xl font-bold text-gray1000 lg:mx-8 lg:text-center xl:text-center dark:text-gray200">
        Hosting your property on Eve After Dark is
        <span className="text-primary500"> just magical!</span>
      </p>
      <p className="text-base font-display text-gray900 dark:text-gray200">
        Making guests feel at home while you earn is what we call magic.
      </p>
      {place && (
        <MapView
          latitude={place.geometry.location.lat}
          longitude={place.geometry.location.lng}
        />
      )}
      <div className="lg:w-2/3 lg:mx-auto w-full">
        <label className="text-xs font-display dark:text-gray200">
          Tell us about your place
        </label>
        <div className="flex items-center bg-eadWhite p-1 lg:px-2 border border-gray400 rounded-full justify-between ease-in-out transition-all">
          <div className="flex items-center gap-2 w-full">
            <SearchIcon />
            <input
              type="text"
              className="text-primay500 w-full p-1 placeholder-primary500 text-xs placeholder:text-xs font-display outline-none focus:ring-0 ease-in-out transition-all"
              placeholder="Enter your address"
              onChange={(text) => {
                setAddress(text.target.value);
                setShowlist(true);
              }}
              value={address}
            />
          </div>
          {address && address?.length > 0 && (
            <button onClick={() => setAddress("")} className="ease-in-out">
              <CloseMobileIcon className="w-7" />
            </button>
          )}
        </div>
        <div className="w-full flex flex-col rounded-lg p-2 flex-shrink-0 dark:bg-gray1000">
          {address.length > 0 && places.length === 0 && (
            <div className="h-18 flex items-center justify-center py-5">
              <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500 border-r-primary500" />
            </div>
          )}
          {showlist &&
            places?.map((place) => (
              <p
                key={place.place_id}
                onClick={() => {
                  setPlaceid(place.place_id);
                  setAddress(place.description);
                  setShowlist(false);
                }}
                className="py-1 text-xs font-display dark:text-gray200 text-gray1000 cursor-pointer hover:bg-gray500 dark:hover:bg-gray900"
              >
                {place?.description}
              </p>
            ))}
        </div>
        {/* navigation */}
        <NavigateButton
          onBack={() => navigate(-1)}
          disable={places?.length < 1 || address?.length < 3 || !placeid}
          onNext={() => {
            navigate(`${paths.HOST_LANDING}/confirm-address`, {
              state: { placeId: placeid },
            });
          }}
          nextBtn="Continue"
        />
      </div>
    </div>
  );
};

export default EnterAddress;
