import React, { useEffect, useState } from "react";
import { paths } from "../../../../routes/paths";
import BreadcrumbsTwo from "../../host-dashboard/components/breadcrumb-two";
import CopyIcon from "../../../../components/icons/copy";
import FacebookFilled from "../../../../components/icons/facebook-filled";
import TwitterFilled from "../../../../components/icons/twitter-filled";
import InstagramFilled from "../../../../components/icons/instagram-filled";
import LinkedinFilled from "../../../../components/icons/linkedin-filled";
import TelegramFilled from "../../../../components/icons/telegram-filled";
import { TextInput } from "../../../../components/custom/text-input";
import { validateEmail } from "../../../../utils/helper-functions";
import ArrowTopRightWhite from "../../../../components/icons/arrow-top-right-white";
import UserPicHolder from "../../../../components/icons/user-pic-holder";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUser,
  getReferrals,
} from "../../../../redux/reducers/user-slice";
import { fetchInvites, sendInvites } from "../../../../redux/actions/authuser";

const Promotions = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [activeFilter, setActiveFilter] = useState("pending");
  const profile = useSelector(getCurrentUser);
  const referrals = useSelector(getReferrals);
  const [loading, setLoading] = useState(false);
  const { pending, joined } = referrals;
  const tabs = [
    { name: "Pending", filter: "pending", stats: referrals?.pending?.length },
    { name: "Joined", filter: "joined", stats: referrals?.joined?.length },
  ];

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleCopy = (txt: string) => {
    navigator.clipboard.writeText(txt).then(() => {
      setTooltipVisible(true);
      setTimeout(() => {
        setTooltipVisible(false);
      }, 2000);
    });
  };

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchInvites());

    // eslint-disable-next-line
  }, []);
  console.log("referrals", referrals);
  return (
    <div>
      <div className="mb-10">
        <BreadcrumbsTwo name="Promotions" path={`${paths.GUEST}/promotions`} />
      </div>
      <div className="flex items-center gap-3 relative">
        <p
          className={`font-display text-sm lg:text-base font-bold cursor-pointer text-gray1000 dark:text-gray200 `}
        >
          Referral
        </p>
        {tooltipVisible && (
          <div className="absolute w-full flex items-center justify-center">
            <span className="bg-gray800 text-white text-xs rounded-full px-2 py-1 font-semibold">
              Copied
            </span>
          </div>
        )}
      </div>
      <hr className="border-gray400 dark:border-gray800  my-3" />
      <div className="relative w-full lg:grid xl:grid grid-cols-2 gap-4 flex flex-wrap">
        <div className="w-full bg-gray300 dark:bg-gray1000 dark:border-gray800 border border-gray400 rounded-xl flex flex-col p-3 gap-2">
          <p className="text-sm font-semibold dark:text-gray200">
            Love Eve After Dark? Refer an host or guest and earn after they make
            their first listing or booking respectively
          </p>
          <div className="w-full my-2">
            <p className="text-sm dark:text-gray200 mb-1">
              Share unique invitation link
            </p>
            <div className="flex bg-eadWhite border border-gray400 rounded-full p-3 justify-between w-full dark:bg-gray800">
              <p className="text-sm dark:text-gray200">
                {`${process.env.REACT_APP_WEB_URL}/invite/${
                  profile?.referral_code || ""
                }`}
              </p>
              <button
                onClick={() =>
                  handleCopy(
                    `${process.env.REACT_APP_WEB_URL}/invite/${profile?.referral_code}`
                  )
                }
              >
                <CopyIcon />
              </button>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <FacebookFilled />
            <TwitterFilled />
            <InstagramFilled />
            <LinkedinFilled />
            <TelegramFilled />
          </div>
          <hr className="border-gray400 dark:border-gray800  my-1" />
          <TextInput
            label="Invite by email"
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
            className="lg:w-full"
            value={email}
          />
          <button
            disabled={!validateEmail(email) || loading}
            className={`text-sm text-eadWhite flex gap-2 items-center p-2 font-medium w-fit rounded-full ${
              validateEmail(email) ? "bg-primary500" : "bg-primary200"
            }`}
            onClick={async () => {
              setLoading(true);
              // @ts-ignore
              await dispatch(sendInvites({ email }));
              setLoading(false);
              setEmail("");
            }}
          >
            {loading && (
              <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
            )}
            Send invite
            <ArrowTopRightWhite />
          </button>
          <hr className="border-gray400 dark:border-gray800  my-1" />
          <div className="w-full my-2">
            <p className="text-sm dark:text-gray200 mb-1">
              Send DM using the template
            </p>
            <div className=" dark:text-gray1000 bg-eadWhite border border-gray400 rounded-2xl p-3 justify-between w-full">
              <p className="text-sm">
                Hey, have you heard of Eve After Dark Shortlets? <br /> <br />
                It's a great platform to host and make extra income with your
                properties. It's also a fantastic alternative to Airbnb. <br />
                <br />I think you'd really enjoy it! Here's the link to check it
                out: {process.env.REACT_APP_WEB_URL}/invite/
                {profile?.referral_code || ""}. Let me know if you decide to
                join.
              </p>
            </div>
            <button
              onClick={() =>
                handleCopy(`Hey, have you heard of Eve After Dark Shortlets? It's a great platform to host and make extra income with your properties. It's also a fantastic alternative to Airbnb. I think you'd really enjoy it! Here's the link to check it out: ${process.env.REACT_APP_WEB_URL}/invite/${profile?.referral_code || ""}. Let me know if you decide to join.`)
              }
              className="my-3 text-sm bg-gray400 dark:bg-gray800 dark:text-gray300 rounded-full flex items-center gap-2 p-3 font-semibold"
            >
              <CopyIcon /> Copy
            </button>
          </div>
        </div>
        <div className="w-full bg-gray300 dark:bg-gray1000 dark:border-gray800 border border-gray400 rounded-xl flex flex-col gap-2 p-3">
          <div className="flex items-center gap-3">
            {tabs.map((item) => (
              <p
                onClick={() => setActiveFilter(item.filter)}
                className={`text-sm font-semibold cursor-pointer ${
                  item.filter === activeFilter
                    ? "text-gray1000 dark:text-gray200"
                    : " text-gray600 dark:text-gray600"
                } `}
              >
                {item.name} ({item.stats})
              </p>
            ))}
          </div>
          <hr className="border-gray400 dark:border-gray800  my-1" />
          <div>
            {activeFilter === "pending" &&
              (pending || []).map((item, i) => (
                <div className="flex items-center gap-4 dark:text-gray200 my-2">
                  <div className="flex items-center gap-2">
                    {item?.profile_pic_url ? (
                      <img src={item?.profile_pic_url} alt="user" />
                    ) : (
                      <UserPicHolder />
                    )}
                    <p className="text-sm font-semibold ">
                      {item.first_name} {item.last_name}
                    </p>
                  </div>
                  <p className="text-sm">{item.email}</p>
                </div>
              ))}
            {activeFilter === "joined" &&
              (joined || []).map((item, i) => (
                <div className="flex items-center gap-4 dark:text-gray200 my-2">
                  <div className="flex items-center gap-2">
                    {item?.profile_pic_url ? (
                      <img src={item?.profile_pic_url} alt="user" />
                    ) : (
                      <UserPicHolder />
                    )}
                    <p className="text-sm font-semibold ">
                      {item.first_name} {item.last_name}
                    </p>
                  </div>
                  <p className="text-sm">{item.email}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotions;
