import React from "react";

type Props = {
  color?: string;
};

const CopyIcon = ({ color, ...rest }: Props) => (
  <svg
    className="md:w-5 md:h-5 w-4 h-4 flex-shrink-0"
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    {...rest}
  >
    <path
      className="dark:stroke-gray100"
      d="M4 14.668C3.06812 14.668 2.60218 14.668 2.23463 14.5157C1.74458 14.3127 1.35523 13.9234 1.15224 13.4333C1 13.0658 1 12.5999 1 11.668V4.86797C1 3.74786 1 3.18781 1.21799 2.75999C1.40973 2.38366 1.71569 2.0777 2.09202 1.88596C2.51984 1.66797 3.0799 1.66797 4.2 1.66797H11C11.9319 1.66797 12.3978 1.66797 12.7654 1.82021C13.2554 2.0232 13.6448 2.41255 13.8478 2.9026C14 3.27014 14 3.73609 14 4.66797M11.2 21.668H17.8C18.9201 21.668 19.4802 21.668 19.908 21.45C20.2843 21.2582 20.5903 20.9523 20.782 20.576C21 20.1481 21 19.5881 21 18.468V11.868C21 10.7479 21 10.1878 20.782 9.75999C20.5903 9.38366 20.2843 9.0777 19.908 8.88596C19.4802 8.66797 18.9201 8.66797 17.8 8.66797H11.2C10.0799 8.66797 9.51984 8.66797 9.09202 8.88596C8.71569 9.0777 8.40973 9.38366 8.21799 9.75999C8 10.1878 8 10.7479 8 11.868V18.468C8 19.5881 8 20.1481 8.21799 20.576C8.40973 20.9523 8.71569 21.2582 9.09202 21.45C9.51984 21.668 10.0799 21.668 11.2 21.668Z"
      stroke="#525252"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default CopyIcon;
