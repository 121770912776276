import EADLoader from "../assets/images/eadloader.png";

function Loader() {
  return (
    <div className="h-screen w-screen flex items-center justify-center dark:bg-[#141414] relative">
      <img src={EADLoader} alt="" className="animate-bounce w-40 lg:w-40" />
    </div>
  );
}

export default Loader;
