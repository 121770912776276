import { useEffect, useState } from "react";
import apiClient from "../network/axios-client";
import { urls } from "../network";
import { ReviewsProps } from "../redux/types";
import { showToast } from "../utils/helper-functions";

export const useGuestReviews = () => {
  const [guestReviews, setReviews] = useState<ReviewsProps[]>([]);
  const [isLoadingGuestReview, setIsLoadingGuestReview] = useState(false);

  const fetchGuestReviews = async () => {
    setIsLoadingGuestReview(true);
    try {
      const res = await apiClient.get(urls.ViewGuestReviews);
      setIsLoadingGuestReview(false);
      if (res.data.hasError) {
      } else {
        setReviews(res.data.data);
      }
    } catch (error) {
      showToast("An error occured", "error");
    }
    // eslint-disable-next-line
  };

  const postGuestReview = async (body: {
    booking_id: number;
    comment: string;
    experience: string;
    rating: number;
    guest_id: number;
    property_id: number;
  }) => {
    setIsLoadingGuestReview(true);
    try {
      const res = await apiClient.post(urls.ReviewGuest, body);
      setIsLoadingGuestReview(false);
      if (res.data.hasError) {
        showToast("An error occured", "error");
      } else {
        fetchGuestReviews();
        setReviews(res.data.data.review.reviews);
      }
    } catch (error) {
      showToast("An error occured", "error");
    }
  };

  useEffect(() => {
    fetchGuestReviews();
    // eslint-disable-next-line
  }, []);

  return {
    guestReviews,
    isLoadingGuestReview,
    postGuestReview,
  };
};

export const useHostReviews = () => {
  const [hostReviews, setReviews] = useState<ReviewsProps[]>([]);
  const [isLoadingHostReview, setIsLoadingHostReview] = useState(false);

  const fetchHostReviews = async () => {
    setIsLoadingHostReview(true);
    try {
      const res = await apiClient.get(urls.ViewHostReviews);
      setIsLoadingHostReview(false);
      if (res.data.hasError) {
      } else {
        setReviews(res.data.data);
      }
    } catch (error) {
      showToast("An error occured", "error");
    }
    // eslint-disable-next-line
  };

  useEffect(() => {
    fetchHostReviews();
    // eslint-disable-next-line
  }, []);

  return {
    hostReviews,
    isLoadingHostReview,
  };
};

export const usePropertyReviews = (id: any) => {
  const [propertyReviews, setReviews] = useState<ReviewsProps[]>([]);
  const [isLoadingPropertyReview, setIsLoadingPropertyReview] = useState(false);

  const fetchPropertyReviews = async () => {
    setIsLoadingPropertyReview(true);
    try {
      const res = await apiClient.get(urls.ViewPropertyReviews(id));
      setIsLoadingPropertyReview(false);
      if (res.data.hasError) {
        showToast("An error occured", "error");
      } else {
        setReviews(res.data.data);
      }
    } catch (error) {
      showToast("An error occured", "error");
    }
    // eslint-disable-next-line
  };

  const postPropertyReview = async (body: {
    property_id: number;
    comment: string;
    experience: string;
    rating: number;
    booking_id: number;
  }) => {
    setIsLoadingPropertyReview(true);
    try {
      const res = await apiClient.post(urls.ReviewProperty, body);
      setIsLoadingPropertyReview(false);
      if (res.data.hasError) {
        showToast(res.data?.errors?.message, "error");
      } else {
        fetchPropertyReviews();
        setReviews(res.data.data.review.reviews);
        showToast(res.data.title, "success");
      }
    } catch (error) {
      showToast("An error occured", "error");
    }
  };

  useEffect(() => {
    if (id) {
      fetchPropertyReviews();
    }
    // eslint-disable-next-line
  }, [id]);

  return {
    propertyReviews,
    isLoadingPropertyReview,
    postPropertyReview,
  };
};
