import React from "react";
import Minusicon from "../icons/minus-icon";
import Plusicon from "../icons/plus-icon";

interface PropertyDetailCardProps {
  title: string;
  count: number;
  onDecrement: () => void;
  onIncrement: () => void;
  border?: boolean;
}

const PropertyDetailCard: React.FC<PropertyDetailCardProps> = ({
  title,
  count,
  onDecrement,
  onIncrement,
  border = true,
}) => {
  const borderClass = border ? "border-gray500" : "border-none";

  return (
    <div
      className={`rounded-xl flex flex-col p-4 border ${borderClass} dark:text-gray100`}
    >
      <p className="font-display text-sm font-medium">{title}</p>
      <div className="flex gap-4 items-center mt-3 ">
        <button
          onClick={onDecrement}
          className="p-2 rounded-full border  dark:border-gray800 border-gray400"
        >
          <Minusicon />
        </button>
        <div className="py-2 px-4 rounded-full border  dark:border-gray800 border-gray400">
          <p className="font-display text-sm">{count}</p>
        </div>
        <button
          onClick={onIncrement}
          className="p-2 rounded-full border  dark:border-gray800 border-gray400"
        >
          <Plusicon />
        </button>
      </div>
    </div>
  );
};

export default PropertyDetailCard;
