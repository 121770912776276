import { useEffect, useState } from "react";
import Signupimg from "../../../assets/images/signupimg.png";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../../components/custom/text-input";
import OnboardingLayout from "../../../components/custom/onboarding-layout";
import { useDispatch, useSelector } from "react-redux";
import {
  resendEmailVerify,
  verifyEmail,
} from "../../../redux/actions/authuser";
import { getIsLoading } from "../../../redux/reducers/loader-slice";
import Auth from "../../../middleware/storage";
import { useTimer } from "../../../hooks/use-timer";
import { FormProvider, useForm } from "react-hook-form";
import { text_validation } from "../../../utils/input-validation";
import { paths } from "../../../routes/paths";
import useQuery from "../../../hooks/use-query";

const EmailVerify = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const methods = useForm();
  const isLoading = useSelector(getIsLoading);
  const { minutes, seconds, restartTimer, clearTimer } = useTimer(60);
  const user = Auth.getUser();
  const [userEmail, setUserEmail] = useState(
    query.get("email") || user?.email || ""
  );

  const handleResendCode = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const res = dispatch(
      // @ts-ignore
      resendEmailVerify({ email: userEmail })
    );
    if (res) {
      setTimeout(() => {
        restartTimer();
      }, 1000);
    }
  };

  const onSubmit = methods.handleSubmit(async (data) => {
    try {
      const verifyRes = await dispatch(
        // @ts-ignore
        verifyEmail({
          email: userEmail,
          code: data.code,
        })
      );

      if (verifyRes) {
        navigate(paths.SIGNIN);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      methods.reset();
    }
  });

  useEffect(() => {
    if (minutes === "00" && seconds === "00") {
      clearTimer();
    }
    // eslint-disable-next-line
  }, [seconds, minutes]);

  useEffect(() => {
    restartTimer();

    if (!Auth.isAuthenticated()) {
      navigate(paths.SIGNIN);
    }

    if (query.get("email") || user?.email) {
      setUserEmail(query.get("email") || user?.email);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <OnboardingLayout image={Signupimg}>
      <div className="px-3 lg:px-0">
        <div className="flex flex-col items-center justify-center pt-8 gap-3">
          <p className="text-center text-2xl text-gray1000 dark:text-gray200 font-display font-bold lg:w-1/2 xl:w-1/2 2xl:w-1/2">
            Verify your email
          </p>
          <p className="text-base text-gray1000 dark:text-gray200 font-display mb-6 text-center lg:w-1/2">
            An email has been sent to your email address ({userEmail}), please
            enter the code received to verify your account.
          </p>
        </div>
        <div>
          <FormProvider {...methods}>
            <form
              onSubmit={(e) => e.preventDefault()}
              noValidate
              autoComplete="off"
              className="w-full flex flex-col items-center justify-center gap-2"
            >
              <InputField
                label="Code*"
                placeholder="Enter 4 digit code"
                type="text"
                name="code"
                validation={text_validation}
              />
              <button
                disabled={isLoading}
                onClick={onSubmit}
                className={`${
                  isLoading ? "bg-primary300" : "bg-primary500"
                } opacity flex justify-center cursor-pointer p-2 text-gray100 rounded-full text-center font-display font-bold text-xs shadow-md mb-4 w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
              >
                {isLoading ? (
                  <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
                ) : (
                  "Confirm"
                )}
              </button>
            </form>
          </FormProvider>
          <div className="w-full flex flex-col items-center justify-center">
            <button
              onClick={handleResendCode}
              disabled={!(minutes === "00" && seconds === "00")}
              className={`${
                !(minutes === "00" && seconds === "00")
                  ? "bg-primary300"
                  : "bg-primary500"
              } opacity flex justify-center cursor-pointer p-2 text-gray100 rounded-full text-center font-display font-bold text-xs shadow-md mb-4 w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              Resend verification link ({`${minutes}:${seconds}`})
            </button>
            {/* <Link
              to={paths.SIGNIN}
              className="text-xs text-gray1000 dark:text-gray200 text-center my-2"
            >
              Already have an account{" "}
              <span className="text-xs text-primary500 mx-2">Login</span>
            </Link> */}
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default EmailVerify;
