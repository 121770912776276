type Props = {
  open: boolean;
  onClose: () => void;
  children: any;
  className?: string;
};

export default function ModalSideBar({
  open,
  onClose,
  children,
  className,
}: Props) {
  return (
    // backdrop
    <div
      onClick={onClose}
      className={` z-50
        fixed inset-0 transition-colors
        ${open ? "visible bg-eadBlack/70" : "invisible"}
      `}
    >
      {/* modal */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`
         transition-all ease-in-out duration-300
          ${open ? "scale-100 opacity-100 translate-x-0" : "scale-125 opacity-0 translate-x-full"} ${className}
        `}
      >
        {children}
      </div>
    </div>
  );
}
