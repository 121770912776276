import React from "react";

const ChevronRightRed = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.1665 7.16732H12.8332M12.8332 7.16732L6.99984 1.33398M12.8332 7.16732L6.99984 13.0007"
      stroke="#FF3333"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ChevronRightRed;
