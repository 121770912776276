// ConfirmDelete.tsx
import React from "react";
import Modal from "../../../../components/custom/modal";

interface ConfirmDeleteProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmDelete: React.FC<ConfirmDeleteProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal onClose={onClose} open={isOpen}>
      <div className="bg-white dark:bg-gray800 p-5 rounded-xl shadow-xl">
        <h2 className="text-xl font-bold dark:text-gray100">Confirm Delete</h2>
        <p className="mt-3 dark:text-gray300">
          Are you sure you want to delete this listing?
        </p>
        <div className="flex justify-end mt-5 gap-3">
          <button
            onClick={onClose}
            className="bg-gray400 dark:bg-gray900 dark:hover:bg-gray800 hover:bg-gray500 transition-all duration-300 ease-in-out text-gray1000 dark:text-gray100 text-sm rounded-full h-10 px-4 flex items-center justify-center"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-red-500 hover:bg-red-600 transition-all duration-300 ease-in-out text-white text-sm rounded-full h-10 px-4 flex items-center justify-center"
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDelete;
