import React from "react";
import CalendarIcon from "../../../../components/icons/calendar";
import moment from "moment";

import "../styles.scss";
import { getIsLoading } from "../../../../redux/reducers/loader-slice";
import { useSelector } from "react-redux";
import CalendarDayInspection from "../../../../components/custom/calendar-day-inspection";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  onChange: (pa: any) => void;
  value: Value;
};

const BookInspection = ({ onConfirm, onCancel, onChange, value }: Props) => {
  const isLoading = useSelector(getIsLoading);

  return (
    <div className="max-w-md max-h-md flex flex-col gap-1 dark:bg-gray1000 bg-gray200 p-4 rounded-xl">
      <p className="text-gray1000 dark:text-gray200 font-display font-bold text-xl">
        Book inspection date
      </p>
      <p className="text-gray1000 font-display dark:text-gray200 text-sm">
        You’ve successfully submitted your home for review, choose your
        preferred inspection date.
      </p>
      <div className="flex items-center justify-between rounded-full p-1 border border-gray400">
        <p className="text-xs font-display">
          {moment(value?.toString()).format("LL")}
        </p>
        <CalendarIcon />
      </div>
      <div className="">
        <CalendarDayInspection onChange={onChange} />
      </div>
      <hr className="my-2 border-gray400" />
      <div className="flex items-center justify-center gap-2 mx-4">
        <button
          onClick={onCancel}
          className="text-xs font-display rounded-full p-2 text-gray1000 font-bold bg-gray400"
        >
          Cancel
        </button>
        <button
          onClick={onConfirm}
          disabled={isLoading}
          className={`w-full text-xs font-display rounded-full p-2 text-eadWhite font-bold flex justify-center ${
            isLoading ? "bg-primary300" : "bg-primary500"
          }`}
        >
          {isLoading ? (
            <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
          ) : (
            "Confirm"
          )}
        </button>
      </div>
      <p className="text-gray1000 font-display text-xs text-center my-3 dark:text-gray200">
        Your listing is pending approval,{" "}
        <span className="text-primary500 underline">pass quality checks</span>{" "}
        and <span className="text-primary500 underline">see terms.</span>
      </p>
    </div>
  );
};

export default BookInspection;
