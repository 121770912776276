export const name_validation = {
  required: {
    value: true,
    message: "Name is required",
  },
  maxLength: {
    value: 30,
    message: "30 characters max",
  },
  minLength: {
    value: 2,
    message: "Name should be atleast a minimum of 2 characters",
  },
};

export const email_validation = {
  required: {
    value: true,
    message: "Email is required",
  },
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Email is not valid",
  },
};

export const password_validation = {
  required: {
    value: true,
    message: "required",
  },
  minLength: {
    value: 6,
    message: "min 6 characters",
  },
};

export const password_validation_signup = {
  required: {
    value: true,
    message: "Password is required",
  },
  pattern: {
    value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
    message:
      "Password must have a minimum of 8 characters, including at least one uppercase letter, one number, and one special character",
  },
};
export const text_validation = {
  required: {
    value: true,
    message: "code is required",
  },
  maxLength: {
    value: 4,
    message: "code should have a maximum of 4 characters",
  },
  minLength: {
    value: 4,
    message: "code should have a minimum of 4 characters",
  },
};
