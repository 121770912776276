import { useDispatch, useSelector } from "react-redux";
import { getIsLoading } from "../../../../redux/reducers/loader-slice";
import { getCurrentUser } from "../../../../redux/reducers/user-slice";
import {
  PhoneInputField,
  TextInput,
} from "../../../../components/custom/text-input";
import {
  editUserProfile,
  uploadImagesSingleNoWaterMark,
} from "../../../../redux/actions/authuser";
import { useEffect, useState } from "react";

const PersonalInfo = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const userInfo = useSelector(getCurrentUser);
  const [formfields, setFieldValue] = useState({
    first_name: userInfo.first_name || "",
    last_name: userInfo.last_name || "",
    email: userInfo.email || "",
    phone: userInfo.phone || "",
  });

  const onEditUserProfile = async () => {
    await dispatch(
      // @ts-ignore
      editUserProfile({
        first_name: formfields.first_name,
        last_name: formfields.last_name,
        phone: formfields.phone,
      })
    );
  };

  const onUpdateProfilePic = async (e: any) => {
    const fd = new FormData();
    fd.append("media", e.target.files[0], "media");
    // @ts-ignore
    const res = (await dispatch(uploadImagesSingleNoWaterMark(fd))) as string;
    if (res) {
      await dispatch(
        // @ts-ignore
        editUserProfile({
          profile_pic_url: res,
        })
      );
    }
  };

  useEffect(() => {
    setFieldValue({
      first_name: userInfo.first_name || "",
      last_name: userInfo.last_name || "",
      email: userInfo.email || "",
      phone: userInfo.phone || "",
    });
  }, [userInfo]);

  return (
    <div className="">
      <div className="flex flex-col p-2 rounded-2xl bg-gray200 dark:bg-gray1000 dark:border-gray800 border border-gray400 mb-2">
        <div className="lg:flex items-center gap-4 p-2">
          <label htmlFor="file_input" className="cursor-pointer">
            {userInfo.profile_pic_url ? (
              <div className="flex item-center justify-center">
                {isLoading ? (
                  <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
                ) : (
                  <img
                    src={userInfo.profile_pic_url || ""}
                    className="w-16 h-16 rounded-full"
                    alt=""
                  />
                )}
              </div>
            ) : (
              <div className="w-16 h-16 rounded-full bg-gray500 flex item-center justify-center">
                {isLoading && (
                  <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
                )}
              </div>
            )}
          </label>
          <input
            type="file"
            aria-describedby="file_input_help"
            id="file_input"
            accept="image/png, image/jpeg"
            name="media_urls"
            onChange={onUpdateProfilePic}
            hidden
          />
          <div className="flex flex-col gap-2">
            <p className="text-sm font-display font-bold dark:text-gray200">
              {userInfo.profile_pic_url
                ? "Your profile photo will be displayed so that hosts can recognise you"
                : "Add your profile photo, it will be displayed so that hosts can recognise you"}
            </p>
            <label
              htmlFor="file_input"
              className="text-sm font-display font-bold text-primary500 cursor-pointer"
            >
              Click to upload{" "}
              {/* <span className="font-display font-normal text-gray900">
                or drag and drop
              </span> */}
            </label>
            <p className="text-sm font-display font-normal dark:text-gray200">
              SVG, PNG, JPG or GIF (max. 800x400px)
            </p>
          </div>
        </div>
        <p className="text-sm font-display font-medium sel place-self-end mr-2 dark:text-gray200">
          Reset
        </p>
      </div>
      <div className="pt-2">
        <p className="text-sm font-bold dark:text-gray200">Update your name</p>
        <div className="lg:flex items-center rounded-2xl p-4 bg-gray300 dark:bg-gray1000 border border-gray400 dark:border-gray800 gap-3 my-2">
          <TextInput
            label="First name*"
            placeholder="First name"
            onChange={(e) =>
              setFieldValue({ ...formfields, first_name: e.target.value })
            }
            type="text"
            required
            value={formfields.first_name}
          />
          <TextInput
            label="Last name*"
            placeholder="Last name"
            onChange={(e) =>
              setFieldValue({ ...formfields, last_name: e.target.value })
            }
            type="text"
            required
            value={formfields.last_name}
          />
        </div>
      </div>
      <div className="pt-2">
        <p className="text-sm font-bold dark:text-gray200">
          Your email address
        </p>
        <div className="rounded-2xl p-4 bg-gray300 border border-gray400 dark:bg-gray1000 dark:border-gray800 my-2 w-full">
          <TextInput
            label="Email address*"
            type="email"
            className="lg:w-full"
            readOnly
            value={formfields.email}
          />
        </div>
      </div>
      <div className="pt-2">
        <p className="text-sm font-bold dark:text-gray200">Your phone number</p>
        <div className="rounded-2xl p-4 bg-gray300 border border-gray400 dark:bg-gray1000 dark:border-gray800 my-2">
          <PhoneInputField
            label="Phone*"
            value={formfields.phone}
            inputProps={{
              name: "phone",
              readOnly: true,
            }}
            className="lg:w-full"
          />
        </div>
      </div>
      <div className="w-full flex items-center justify-center gap-2 py-4">
        <button className="flex text-xs font-display font-semibold   p-2 rounded-full shadow bg-gray500 items-center text-gray1000 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100">
          Cancel
        </button>
        <button
          onClick={onEditUserProfile}
          className={`flex text-xs font-display font-semibold p-2 rounded-full shadow bg-primary500 items-center text-eadWhite transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
        >
          {isLoading ? (
            <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
};

export default PersonalInfo;
