import React, { FC } from "react";

interface WhatsappProps extends React.SVGProps<SVGSVGElement> {}

const Whatsapp: FC<WhatsappProps> = ({ ...rest }) => (
  <svg
  className="md:w-5 md:h-5 w-4 h-4"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
    className="dark:fill-gray800"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.9165C17.799 23.9165 22.5 19.2155 22.5 13.4165C22.5 7.61751 17.799 2.9165 12 2.9165C6.20101 2.9165 1.5 7.61751 1.5 13.4165C1.5 15.2997 1.99575 17.067 2.86386 18.5952L1.5 23.9165L6.98615 22.6444C8.47603 23.4556 10.1842 23.9165 12 23.9165ZM12 22.3011C16.9068 22.3011 20.8846 18.3233 20.8846 13.4165C20.8846 8.50967 16.9068 4.53189 12 4.53189C7.09316 4.53189 3.11538 8.50967 3.11538 13.4165C3.11538 15.3111 3.70838 17.0671 4.71888 18.5092L3.92308 21.4934L6.95995 20.7342C8.39202 21.7224 10.1284 22.3011 12 22.3011Z"
      fill="#424242"
    />
    <path
    className="dark:fill-gray600"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.1665C17.799 23.1665 22.5 18.4655 22.5 12.6665C22.5 6.86751 17.799 2.1665 12 2.1665C6.20101 2.1665 1.5 6.86751 1.5 12.6665C1.5 14.5497 1.99575 16.317 2.86386 17.8452L1.5 23.1665L6.98615 21.8944C8.47603 22.7056 10.1842 23.1665 12 23.1665ZM12 21.5511C16.9068 21.5511 20.8846 17.5733 20.8846 12.6665C20.8846 7.75967 16.9068 3.78189 12 3.78189C7.09316 3.78189 3.11538 7.75967 3.11538 12.6665C3.11538 14.5611 3.70838 16.3171 4.71888 17.7592L3.92308 20.7434L6.95995 19.9842C8.39202 20.9724 10.1284 21.5511 12 21.5511Z"
      fill="#525252"
    />
    <path
    className="dark:fill-gray600"
      d="M9.37502 7.79154C9.12537 7.29011 8.7424 7.3345 8.35551 7.3345C7.66407 7.3345 6.58594 8.16271 6.58594 9.70409C6.58594 10.9673 7.14259 12.3501 9.01831 14.4187C10.8285 16.415 13.207 17.4477 15.1817 17.4126C17.1563 17.3774 17.5625 15.6782 17.5625 15.1044C17.5625 14.85 17.4047 14.7231 17.296 14.6886C16.6231 14.3657 15.382 13.764 15.0996 13.6509C14.8172 13.5379 14.6698 13.6908 14.5781 13.774C14.3221 14.018 13.8144 14.7372 13.6406 14.899C13.4668 15.0607 13.2077 14.9789 13.0999 14.9177C12.7031 14.7585 11.6272 14.2799 10.7696 13.4486C9.70899 12.4205 9.64675 12.0667 9.44693 11.7519C9.28707 11.5 9.40437 11.3454 9.46291 11.2779C9.69142 11.0142 10.007 10.6071 10.1485 10.4048C10.29 10.2025 10.1776 9.89539 10.1102 9.70409C9.82033 8.88138 9.57474 8.19269 9.37502 7.79154Z"
      fill="#525252"
    />
  </svg>
);

export default Whatsapp;
