// algoliaClient.js
import algoliasearch from "algoliasearch/lite";
import places from "places.js";

const searchClient = algoliasearch(
  "A7CLQA6PW3",
  "feeae2f97d3967f2f5eea6c323ecc58d"
);

export { searchClient, places };
