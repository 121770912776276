import { useCallback, useEffect, useState } from "react";
import { Place } from "../redux/types";
import { useDispatch } from "react-redux";
import { googlePlaceInfoApi } from "../redux/actions/property";

function useGooglePlaceInfo(placeId: string) {
  const dispatch = useDispatch();
  const [place, setPlace] = useState<Place | null | undefined>(null);
  const getPlaces = useCallback(async () => {
    if (placeId.length > 0) {
      // @ts-ignore
      const residents = (await dispatch(googlePlaceInfoApi(placeId))) as Place; //getGooglePlaceInfo(placeId);
      setPlace(residents);
    }
    // eslint-disable-next-line
  }, [placeId]);

  useEffect(() => {
    getPlaces();
    // eslint-disable-next-line
  }, [placeId]);

  return { place };
}

export default useGooglePlaceInfo;
