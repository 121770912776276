import { useEffect } from "react";
import { paths } from "../../../../routes/paths";
import Loader from "../../../loader-page";
import { useNavigate, useParams } from "react-router-dom";

const Referral = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  useEffect(() => {
    navigate(paths.SIGNUP, { state: { referCode: code } });

    // eslint-disable-next-line
  }, []);
  return <Loader />;
};

export default Referral;
