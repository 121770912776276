import React from "react";
import uploadImg from "../assets/images/uploadimg.png";
interface UploadProps {
  onClick: () => void;
  loader?: boolean;
}

const Upload: React.FC<UploadProps> = ({ onClick, loader }) => {
  return (
    <div
      className="w-full h-48 rounded-xl border-dashed border border-gray600 flex flex-col items-center justify-center cursor-pointer"
      onClick={loader ? () => {} : onClick}
    >
      {loader ? null : <img src={uploadImg} alt="" />}
      <span className="text-primary500 text-sm font-bold">
        {loader ? (
          <div className="border-eadWhite h-10 w-10 animate-spin rounded-full border-4 border-t-primary500 border-r-primary500" />
        ) : (
          "Click to upload"
        )}
      </span>
    </div>
  );
};

export default Upload;
