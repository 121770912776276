import React from "react";

const Wifi = ({ ...rest }) => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7.99996 11.6665H8.00663M15.2042 4.46701C13.3063 2.72783 10.777 1.6665 7.99988 1.6665C5.22272 1.6665 2.69345 2.72783 0.795532 4.46701M3.15458 6.82848C4.44666 5.69028 6.14267 4.99984 7.99994 4.99984C9.85721 4.99984 11.5532 5.69028 12.8453 6.82848M10.4655 9.18324C9.78611 8.65068 8.93007 8.33317 7.99988 8.33317C7.05562 8.33317 6.18778 8.66036 5.50354 9.20756"
      stroke="#525252"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Wifi;
