import Hostimg from "../../../assets/images/hostimg.png";
import { Link, useNavigate } from "react-router-dom";
import { InputField, PhoneInputField } from "../../../components/custom/text-input";
import OnboardingLayout from "../../../components/custom/onboarding-layout";
import { paths } from "../../../routes/paths";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoading } from "../../../redux/reducers/loader-slice";
import { signUpUserEmail } from "../../../redux/actions/authuser";
import { FormProvider, useForm } from "react-hook-form";
import {
  email_validation,
  name_validation,
  password_validation_signup,
} from "../../../utils/input-validation";
import { useState } from "react";
import GoogleSignin from "../../../components/custom/google-signin-custom";

const Host = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm();
  const isLoading = useSelector(getIsLoading);
  const [phone, setPhone] = useState("");
  const [errMsg, setErrorMsg] = useState<string | null>(null);

  const onSubmit = methods.handleSubmit(async (data) => {
    setErrorMsg(null);
    if (phone.length < 9) {
      setErrorMsg("Phone number is required");
      return;
    }
    // @ts-ignore
    const res = await dispatch(signUpUserEmail({ ...data, phone: phone }));
    methods.reset();
    if (res) {
      navigate(paths.EMAIL_VERIFY);
    }
  });

  return (
    <OnboardingLayout image={Hostimg}>
      <div className="flex flex-col items-center justify-center pt-8 gap-8">
        <p className="text-center text-2xl text-gray1000 dark:text-gray200 font-display font-bold lg:w-1/2 xl:w-1/2 2xl:w-1/2">
          Let’s turn your space into profit
        </p>
        <p className="text-base text-gray1000 font-display dark:text-gray200 mb-6">
          Create a new account
        </p>
      </div>
      <div className="flex flex-col items-center justify-center mx-3">
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => e.preventDefault()}
            noValidate
            autoComplete="off"
            className="w-full flex flex-col items-center justify-center"
          >
            <InputField
              label="First name*"
              placeholder="Enter your first name"
              type="text"
              name="first_name"
              validation={name_validation}
            />
            <InputField
              label="Last name*"
              placeholder="Enter your last name"
              type="text"
              name="last_name"
              validation={name_validation}
            />
            <InputField
              label="Email*"
              placeholder="Enter your email"
              type="email"
              name="email"
              validation={email_validation}
            />
             <PhoneInputField
                label="Phone*"
                value={phone}
                onChange={(phone: any) => setPhone(phone)}
                inputProps={{
                  name: "phone",
                  required: true,
                  // autoFocus: true,
                }}
                // isValid={phone.length > 9}
                errMsg={errMsg}
              />
            <InputField
              label="Password*"
              placeholder="Enter your password"
              type="password"
              name="password"
              validation={password_validation_signup}
            />

            <div className="flex justify-between my-6 w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
              <div>
                <input type="checkbox" />
                <span className="mx-1 text-xs font-display dark:text-gray200 font-semibold text-gray1000">
                  Remember me
                </span>
              </div>
              <Link
                to={paths.FORGOT_PASSWORD}
                className="text-xs font-display font-semibold text-primary500"
              >
                Forgot password
              </Link>
            </div>
            <button
              disabled={isLoading}
              onClick={onSubmit}
              className={`${
                isLoading ? "bg-primary300" : "bg-primary500"
              } opacity flex justify-center cursor-pointer p-2 text-gray100 rounded-full text-center font-display font-bold text-xs shadow-md mb-4 w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              {isLoading ? (
                <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
              ) : (
                "Get Started"
              )}
            </button>

            <GoogleSignin />
          </form>
        </FormProvider>
        <Link
          to={paths.SIGNIN}
          className="text-xs text-gray1000 dark:text-gray200 text-center my-2"
        >
          Already have an account{" "}
          <span className="text-xs text-primary500 mx-2">Login</span>
        </Link>
      </div>
    </OnboardingLayout>
  );
};

export default Host;
