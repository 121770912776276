import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Auth from "../middleware/storage";
import { paths } from "./paths";

export default function ProtectedRoutes() {
  if (Auth.isAuthenticated()) {
    if (Auth.isEmailVerified()) {
      return <Outlet />;
    }
    return <Navigate to={paths.EMAIL_VERIFY} />;
  }
  return <Navigate to={paths.SIGNIN} />;
}
