import React, { FC } from "react";

interface Props extends React.SVGProps<SVGSVGElement> {}

const ArrowTopRight: FC<Props> = ({ color, ...rest }) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      className={` stroke-[${color}] ${
        color === "#fff" ? "dark:stroke-gray100" : "dark:stroke-gray600"
      }`}
      d="M1.40002 11.6665L11.4 1.6665M11.4 1.6665H1.40002M11.4 1.6665V11.6665"
      stroke={color || "#F10001"}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ArrowTopRight;
