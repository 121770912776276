import "./styles.scss";
import NavbarHost from "../../../components/custom/navbar-host";
import Agent from "../../../assets/images/agent.png";
import Owner from "../../../assets/images/owner.png";
import { paths } from "../../../routes/paths";
import { useNavigate } from "react-router-dom";
import { usePropertyForm } from "../host-onboard/hooks/use-property-form";

const HostType = () => {
  const navigate = useNavigate();
  const { setPropertyFieldValue, propertyFormValue } = usePropertyForm();

  return (
    <div className="w-screen h-screen bg-gray200 relative dark:bg-gray1000">
      <NavbarHost />
      <div className="h-3/4 flex flex-col items-center justify-center mx-2">
        <p className="lg:text-4xl text-2xl text-center font-display font-bold text-gray1000 dark:text-gray200 py-3">
          What best describes you?
        </p>
        <p className="text-sm font-display text-gray1000 dark:text-gray200">
          Let us know if you are an Agent or Owner.
        </p>
        <div className="flex items-center justify-center flex-wrap lg:gap-5 py-16 gap-3">
          <div
            onClick={() => {
              setPropertyFieldValue({
                ...propertyFormValue,
                host_type: "owner",
              });
              navigate(`${paths.HOST_LANDING}/enter-address`);
            }}
            className="cursor-pointer"
          >
            <img src={Owner} className="object-contain lg:w-80 w-60" alt="" />
            <p className="lg:text-xl text-sm font-display font-bold text-gray1000 text-center dark:text-gray200">
              Property owner
            </p>
          </div>
          <div
            onClick={() => {
              setPropertyFieldValue({
                ...propertyFormValue,
                host_type: "agent",
              });
              navigate(`${paths.HOST_LANDING}/enter-address`);
            }}
            className="cursor-pointer"
          >
            <img src={Agent} className="object-contain lg:w-72 w-60" alt="" />
            <p className="lg:text-xl text-sm font-display font-bold text-gray1000 pt-3 lg:pt-8 text-center dark:text-gray200">
              Agent
            </p>
          </div>
        </div>
        {/* <p onClick={() => navigate(paths.HOME)} className="cursor-pointer text-sm font-display text-primary500">Skip for later</p> */}
      </div>
    </div>
  );
};

export default HostType;
