import React, { useState, useRef } from "react";
import NancyAds from "../../assets/images/nancyvid.png";
import Modal from "./modal";

const YoutubeEmbed: React.FC = () => {
  const [play, setPlay] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleClose = () => {
    // Pause the video
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        "*"
      );
    }
    setPlay(false);
  };

  const handleOpen = () => {
    if (iframeRef.current) {
      iframeRef.current.src =
        "https://www.youtube.com/embed/M0Ly5ItX_JM?autoplay=0&controls=0&enablejsapi=1";
    }
    setPlay(true);
  };

  return (
    <div>
      {/* Video Placeholder */}
      <div
        onClick={handleOpen}
        className="md:h-[32rem] relative cursor-pointer"
      >
        <img
          src={NancyAds}
          alt="Nancy Ads"
          className="object-cover w-full h-full"
        />
      </div>

      {/* Modal Overlay */}
      <Modal onClose={handleClose} open={play} className="w-3/4 h-3/4">
        {/* YouTube Iframe */}
        <iframe
          ref={iframeRef}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/M0Ly5ItX_JM?autoplay=1&controls=0&enablejsapi=1"
          title="YouTube video player"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="w-full h-full rounded-lg"
        ></iframe>
      </Modal>
    </div>
  );
};

export default YoutubeEmbed;
