import React from "react";

const ChevronLeft = ({ ...rest }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...rest}>
    <path
      d="M19 12.8711H5M5 12.8711L12 19.8711M5 12.8711L12 5.87109"
      stroke="#525252"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ChevronLeft;
