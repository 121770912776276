import HostDashboardLayout from "./components/host-dashboard-layout";
import MakeBookingSection from "../../../components/custom/make-bookings-section";
import { useParams } from "react-router-dom";
import EditIcon from "../../../components/icons/edit";
import Location from "../../../components/icons/location";
import { useGetGuestProfile } from "../../../hooks/use-stats";
import ReviewsCardInfo from "../../../components/custom/reviews-card-info";
import ReviewStarsRating from "../../../components/custom/reviewStarsRating";

const Badge = ({ className }: { className?: string }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.3942 1.26644C11.8688 0.984267 12.4597 0.984267 12.9342 1.26644L14.5851 2.24805C14.8237 2.38992 15.0969 2.46312 15.3745 2.45955L17.2949 2.43488C17.847 2.42779 18.3587 2.72324 18.6286 3.20489L19.5675 4.88041C19.7032 5.12258 19.9032 5.32255 20.1453 5.45826L21.8209 6.39713C22.3025 6.66703 22.598 7.17875 22.5909 7.73082L22.5662 9.6513C22.5626 9.92888 22.6358 10.202 22.7777 10.4407L23.7593 12.0915C24.0415 12.5661 24.0415 13.157 23.7593 13.6315L22.7777 15.2824C22.6358 15.521 22.5626 15.7941 22.5662 16.0717L22.5909 17.9922C22.598 18.5443 22.3025 19.056 21.8209 19.3259L20.1453 20.2648C19.9032 20.4005 19.7032 20.6004 19.5675 20.8426L18.6286 22.5181C18.3587 22.9998 17.847 23.2952 17.2949 23.2881L15.3745 23.2635C15.0969 23.2599 14.8237 23.3331 14.5851 23.475L12.9342 24.4566C12.4597 24.7388 11.8688 24.7388 11.3942 24.4566L9.74339 23.475C9.50478 23.3331 9.23162 23.2599 8.95404 23.2635L7.03355 23.2881C6.48149 23.2952 5.96976 22.9998 5.69987 22.5181L4.76099 20.8426C4.62529 20.6004 4.42532 20.4005 4.18314 20.2648L2.50762 19.3259C2.02597 19.056 1.73053 18.5443 1.73762 17.9922L1.76229 16.0717C1.76585 15.7941 1.69266 15.521 1.55078 15.2824L0.569176 13.6315C0.287001 13.157 0.287001 12.5661 0.569176 12.0915L1.55078 10.4407C1.69266 10.202 1.76585 9.92888 1.76229 9.6513L1.73762 7.73082C1.73053 7.17875 2.02597 6.66703 2.50762 6.39713L4.18314 5.45826C4.42532 5.32255 4.62529 5.12258 4.76099 4.88041L5.69987 3.20489C5.96976 2.72324 6.48149 2.42779 7.03355 2.43488L8.95404 2.45955C9.23161 2.46312 9.50478 2.38992 9.74339 2.24805L11.3942 1.26644Z"
      fill="#2F80ED"
    />
    <path
      d="M16.6315 17.8923C16.6315 17.1038 16.6315 16.7096 16.5342 16.3888C16.3151 15.6665 15.7499 15.1013 15.0276 14.8822C14.7068 14.7849 14.3126 14.7849 13.5241 14.7849H10.6992C9.91072 14.7849 9.51649 14.7849 9.1957 14.8822C8.47342 15.1013 7.90821 15.6665 7.68911 16.3888C7.5918 16.7096 7.5918 17.1038 7.5918 17.8923M14.6541 10.2651C14.6541 11.6692 13.5158 12.8075 12.1116 12.8075C10.7075 12.8075 9.56923 11.6692 9.56923 10.2651C9.56923 8.86093 10.7075 7.72266 12.1116 7.72266C13.5158 7.72266 14.6541 8.86093 14.6541 10.2651Z"
      stroke="#FCFCFC"
      stroke-width="1.50661"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const GuestInfo = () => {
  const { id } = useParams();
  const { guestProfile } = useGetGuestProfile(id);
  const rating = Math.floor(Number(guestProfile?.rating));

  return (
    <HostDashboardLayout
      crumbs={[{ name: "Guest Profile", path: `/guest-info/${id}` }]}
    >
      {/* <hr className="border-gray500 dark:border-gray800 py-2" /> */}
      <div className="md:p-4 p-2 max-w-screen-lg mx-auto border border-gray500 dark:border-gray800 rounded-lg dark:text-gray300">
        <div className="flex flex-wrap items-center justify-center w-full lg:gap-28 gap-4">
          {guestProfile?.user?.profile_pic_url ? (
            <img
              className="lg:w-64 lg:h-64 w-20 h-20 rounded-full bg-gray500"
              src={guestProfile.user?.profile_pic_url}
              alt="guest"
            />
          ) : (
            <div className="lg:w-64 lg:h-64 w-32 h-32 rounded-full bg-gray500 flex items-center justify-center relative">
              <EditIcon className="lg:w-10 h-10" />
              <Badge className="absolute bottom-1 right-1 lg:bottom-3 lg:right-8" />
            </div>
          )}
          <div className="flex flex-col items-center justify-center gap-2 lg:p-20 lg:px-30 p-4  border border-gray500 dark:border-gray800 rounded-lg">
            <p className="text-lg font-medium  text-center mb-1">{`${guestProfile?.user?.first_name} ${guestProfile?.user?.last_name}`}</p>
            <p className="text-sm text-center flex items-center gap-2">
              <Location /> Lagos Nigeria
            </p>
            <p className="text-sm text-center">
              Total bookings {guestProfile?.bookingCount}
            </p>
            <p className="text-sm text-center font-medium ">
              Date joined March, 2022
            </p>
            <div className="flex items-center gap-1 text-sm">
              <ReviewStarsRating rating={(Number(rating))} />
              <span className="text-gray800 font-semibold">
                {rating} / 5.0 reviews
              </span>
            </div>
          </div>
        </div>
        <hr className="border-gray500 dark:border-gray800 my-8" />
        <p className="text-base font-medium my-2">Verification</p>
        <div className="flex flex-col gap-2">
          <p className="text-base font-medium  bg-gray300 p-2 rounded-tl-xl rounded-tr-xl dark:bg-gray700">
            Status
          </p>
          <div className="flex items-center justify-between">
            <p className="text-sm">Phone verification</p>
            <p
              className={`text-xs ${
                guestProfile?.user?.is_verified
                  ? "text-green-500"
                  : "text-primary500"
              } p-1 rounded-full bg-gray300`}
            >
              {guestProfile?.user?.is_verified ? "Verified" : "Unverified"}
            </p>
          </div>
          <div className="flex items-center gap-2 justify-between">
            <p className="text-sm">Email verification</p>
            <p
              className={`text-xs ${
                guestProfile?.user?.is_email_verified
                  ? "text-green-500"
                  : "text-primary500"
              } p-1 rounded-full bg-gray300`}
            >
              {guestProfile?.user?.is_email_verified
                ? "Verified"
                : "Unverified"}
            </p>
          </div>
          <div className="flex items-center gap-2 justify-between">
            <p className="text-sm">Document verification</p>
            {/* <p
              className={`text-xs ${
                guestProfile?.user?.document_number
                  ? "text-green-500"
                  : "text-primary500"
              } p-1 rounded-full bg-gray300`}
            >
              {guestProfile?.user?.document_number
                ? "Verified"
                : "Unverified"}
            </p> */}
          </div>
        </div>
        <hr className="border-gray500 dark:border-gray800 my-8" />
        {(guestProfile?.user?.user_reviews || []).length > 0 && <p className="text-base font-medium  my-2">Reviews</p>}
        <div className="gap-2 grid grid-cols-3 ">
          {(guestProfile?.user?.user_reviews || []).map((item) => (
            <ReviewsCardInfo
              key={item.user.id}
              name={item.user.first_name}
              review={item.comment}
              location={""}
              date={item.created_at}
              pic={item.user.profile_pic_url || ""}
            />
          ))}
        </div>
      </div>
      <MakeBookingSection />
    </HostDashboardLayout>
  );
};

export default GuestInfo;
