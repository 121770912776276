import React from "react";
import ChevronLeft from "../../../../components/icons/chevron-left";
import ChevronRightWhite from "../../../../components/icons/chevron-right-white";
import { useSelector } from "react-redux";
import { getIsLoading } from "../../../../redux/reducers/loader-slice";

interface Props {
  onNext: () => void;
  onBack: () => void;
  disable?: boolean;
  nextBtn?: string;
}
const NavigateButton = ({
  onNext,
  onBack,
  disable,
  nextBtn = "Next",
}: Props) => {
  const isLoading = useSelector(getIsLoading);

  return (
    <div className="w-full flex items-center justify-center gap-2 py-4">
      <button
        onClick={onBack}
        className="flex gap-1 text-xs font-display font-semibold py-1 px-2 rounded-full shadow bg-gray500 items-center text-gray1000 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100"
      >
        <ChevronLeft width="15" /> Back
      </button>
      <button
        onClick={onNext}
        disabled={disable || isLoading}
        className={`flex gap-1 text-xs font-display font-semibold py-1 px-2 rounded-full shadow ${
          disable || isLoading ? "bg-primary300 cursor-not-allowed" : "bg-primary500"
        } items-center text-eadWhite transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
      >
        {isLoading ? (
          <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
        ) : (
          nextBtn
        )}
        <ChevronRightWhite width="15" />
      </button>
    </div>
  );
};

export default NavigateButton;
