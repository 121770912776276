import ArrowTopRight from "../icons/arrow-top-right";
import Location from "../icons/location";
import "./custom.css";
import { PropertyFormType } from "../../redux/types";
import { currencyFormat, truncate } from "../../utils/helper-functions";
import PillsTwo from "./pills-two";
import { getIconComponent } from "../../utils/get-icons";
import Avatar from "../icons/avatar";

interface Prop {
  price: number;
  duration: string;
  title: string;
  img: string;
  desc: string;
  location: string;
  property?: PropertyFormType;
}

const ShortletsViewCard = ({
  price,
  duration,
  img,
  title,
  desc,
  location,
  property,
}: Prop) => {
  return (
    <div
      className={`flex flex-col justify-between lg:w-80  w-80 my-4 cursor-pointer`}
    >
      <img src={img} alt="" className="rounded-xl shadow-lg h-72" />
      <div className="flex flex-col">
        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center gap-2">
            <p className="text-lg font-bold text-gray1000 font-display dark:text-gray200">
              {currencyFormat(price || 0)}
            </p>
            <span className="text-xs font-display text-gray1000 dark:text-gray200">
              {duration}
            </span>
          </div>
          <ArrowTopRight />
        </div>
        <p className="text-sm font-bold font-display text-gray900 my-2 dark:text-gray200">
          {title}
        </p>
        <p className="text-xs font-display text-gray800 dark:text-gray200">
          {truncate(desc, 100)}
        </p>
        <div className="flex flex-wrap items-center gap-2 overflow-x-scroll no-scrollbar my-3">
          {/* <Pills
            title={`${property?.bedrooms}Bedroom`}
            icon={<Bedroom className="w-3" />}
          />
          <Pills
            title={`${property?.bathrooms}Bathroom`}
            icon={<Bathroom className="w-3" />}
          />
          <Pills
            title={`${property?.toilets}Toilet`}
            icon={<ToiletIcon className="w-3" />}
          /> */}
          <PillsTwo
            title={`${property?.bedrooms ?? 0} Bedroom${
              (property?.bedrooms ?? 0) > 1 ? "s" : ""
            }`}
            icon={getIconComponent("room-icon")}
          />
          <PillsTwo
            title={`${property?.bathrooms ?? 0} Bathroom${
              (property?.bathrooms ?? 0) > 1 ? "s" : ""
            }`}
            icon={getIconComponent("bathroom-icon")}
          />
          <PillsTwo
            title={`${property?.toilets ?? 0} Toilet${
              (property?.toilets ?? 0) > 1 ? "s" : ""
            }`}
            icon={getIconComponent("toilet-icon")}
          />
          <PillsTwo
            title={`${property?.guests ?? 0} Guest${
              (property?.guests ?? 0) > 1 ? "s" : ""
            }`}
            icon={<Avatar className="h-5" />}
          />
        </div>
        <div className="flex gap-2 items-center">
          <Location />
          <p className="text-xs font-display text-gray800 dark:text-gray200">
            {location}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShortletsViewCard;
