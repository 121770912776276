import { useEffect } from "react";
import { paths } from "../../../../routes/paths";
import BreadcrumbsTwo from "../../host-dashboard/components/breadcrumb-two";
import { useDispatch, useSelector } from "react-redux";
import { fetchFavoriteProperties } from "../../../../redux/actions/property";
import { getFavourites } from "../../../../redux/reducers/property-slice";
import { useNavigate } from "react-router-dom";
import { BiHeart } from "react-icons/bi";
import FavouriteItem from "../../../../components/custom/favorite-item";

const Favourites = () => {
  const dispatch = useDispatch();
  const favouritesProperties = useSelector(getFavourites);
  const navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchFavoriteProperties());
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="mb-10">
        <BreadcrumbsTwo name="Favourites" path={`${paths.GUEST}/favourites`} />
      </div>

      <hr className="border-gray400 dark:border-gray800  my-3" />

      {favouritesProperties.length === 0 ? (
        <div className="w-full bg-primary100 border border-gray400 rounded-2xl h-96 flex flex-col gap-2 p-5 text-center items-center justify-center text-gray800 dark:text-gray200">
          <BiHeart className="text-5xl" />
          <span className="font-semibold text-2xl tracking-tight">
            No Favourite
          </span>
          <span className="w-3/4 text-sm">
            you will find your favourite listings here
          </span>
          <button
            onClick={() => navigate(paths.SEARCH_LIST)}
            className="bg-primary500 font-display text-sm font-bold text-eadWhite text-center h-9 flex items-center justify-center px-3 rounded-full mt-3"
          >
            Find a property
          </button>
        </div>
      ) : (
        <div>
          {favouritesProperties.map((hit) => (
            <FavouriteItem
              pic={hit?.property_media?.[0]?.url}
              price={hit?.price}
              title={hit?.name}
              description={hit?.description}
              location={hit?.address}
              hostname={`${hit?.host?.first_name} ${hit?.host?.last_name}`}
              to={`${paths.VIEW_PROPERTY}/${hit?.slug}`}
              item={hit}
              hit={hit}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Favourites;
