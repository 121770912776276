import React from "react";

const MinusCircle = ({ ...rest }) => (
  <svg
    {...rest}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="dark:stroke-white"
      d="M8 12.666H16M22 12.666C22 18.1889 17.5228 22.666 12 22.666C6.47715 22.666 2 18.1889 2 12.666C2 7.14317 6.47715 2.66602 12 2.66602C17.5228 2.66602 22 7.14317 22 12.666Z"
      stroke="#525252"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default MinusCircle;
