import React from "react";

const BedroomWhite = ({ ...rest }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M3 11.2316C3 10.6572 3 10.37 3.07403 10.1056C3.1396 9.87129 3.24737 9.65095 3.39203 9.45536C3.55534 9.23456 3.78202 9.05825 4.23539 8.70563L11.0177 3.4305C11.369 3.15725 11.5447 3.02062 11.7387 2.9681C11.9098 2.92176 12.0902 2.92176 12.2613 2.9681C12.4553 3.02062 12.631 3.15725 12.9823 3.4305L19.7646 8.70563C20.218 9.05825 20.4447 9.23456 20.608 9.45536C20.7526 9.65095 20.8604 9.87129 20.926 10.1056C21 10.37 21 10.6572 21 11.2316V18.4665C21 19.5866 21 20.1466 20.782 20.5745C20.5903 20.9508 20.2843 21.2568 19.908 21.4485C19.4802 21.6665 18.9201 21.6665 17.8 21.6665H6.2C5.07989 21.6665 4.51984 21.6665 4.09202 21.4485C3.71569 21.2568 3.40973 20.9508 3.21799 20.5745C3 20.1466 3 19.5866 3 18.4665V11.2316Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default BedroomWhite;
