import React from "react";
import Twitter from "../icons/twitter";
import Facebook from "../icons/facebook";
import Instagram from "../icons/instagram";

const FooterBase = () => {
  return (
    <footer className="bg-white relative max-w-screen-2xl mx-auto px-4 dark:bg-gray1000">
      <div className="py-4">
        <div className="flex items-center justify-between">
          <p className="text-xs font-display dark:text-gray200">
            © 2024 Eve After Dark. All rights reserved.
          </p>
          <div className="flex gap-4">
            <Twitter className="h-4" />
            <Facebook className="h-4" />
            <Instagram className="h-4" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterBase;
