import { createSlice } from "@reduxjs/toolkit";
import { PropertyDetailsType } from "../types";
import { RootState } from "../store";

const initialState: PropertyDetailsType = {
  properties: [],
  pagination: {},
  propertyForm: {
    name: "",
    description: "",
    price: 0,
    address: "",
    longitude: 0,
    latitude: 0,
    country: "",
    google_location: {} || null,
    currency_id: 0,
    category_id: 1,
    sub_category_id: 0,
    media_urls: [],
    is_negotiable: false,
    status: "available",
    details: [],
    bedrooms: 0,
    bathrooms: 0,
    toilets: 0,
    hide_location: true,
    host_type: "",
    amenities: [],
    check_in_time: "14:00:00",
    check_out_time: "12:00:00",
    rules: [],
    contact_phone_number: "",
    contact_email: "",
    contact_whatsapp_number: "",
    inspection_date: null,
    guests: 0,
    is_verified: false,
    is_suspended: false,
    blocked_out_dates: [],
    monday_availability: false,
    tuesday_availability: false,
    wednesday_availability: false,
    thursday_availability: false,
    friday_availability: false,
    saturday_availability: false,
    sunday_availability: false,
    property_media: [],
    host: {
      id: 0,
      first_name: "",
      last_name: "",
      profile_pic_url: "",
    },
    share_link: "",
  },
  property: {
    id: 0,
    name: "",
    address: "",
    longitude: 0,
    latitude: 0,
    google_location: {},
    price: "",
    description: "",
    slug: "",
    visits: 0,
    is_promoted: false,
    details: [],
    category_id: 0,
    sub_category_id: 0,
    is_negotiable: false,
    status: "",
    is_advert: false,
    host_type: "",
    is_inspected: false,
    inspection_date: "",
    hide_location: false,
    bedrooms: 0,
    bathrooms: 0,
    toilets: 0,
    is_verified: false,
    is_suspended: false,
    is_live: false,
    created_at: "",
    updated_at: "",
    is_deleted: false,
    deleted_at: null,
    property_media: [],
    category: {
      id: 0,
      name: "",
      icon_url: null,
      product_count: 0,
      rank: 0,
    },
    sub_category: {
      id: 0,
      name: "",
      icon_url: null,
      category_id: 0,
      product_count: 0,
    },
    favourite_properties: [],
    reviews: [],
    share_link: "",
    property_amenities: [],
    rules: [],
    contact_phone_number: "",
    contact_email: "",
    contact_whatsapp_number: "",
    guests: 0,
    blocked_out_dates: [],
    check_in_time: "",
    check_out_time: "",
    monday_availability: false,
    tuesday_availability: false,
    wednesday_availability: false,
    thursday_availability: false,
    friday_availability: false,
    saturday_availability: false,
    sunday_availability: false,
    host: {
      currency: null,
      email: "",
      first_name: "",
      id: 0,
      last_name: "",
      phone: "",
      profile_pic_url: "",
    },
    service_fee: 0,
    service_fee_type: "",
  },
  verificationChecklist: {
    user: {
      is_email_verified: false,
      is_phone_verified: false,
      is_verified: false,
      is_bank_verified: false,
      kyc_document_number: null,
      kyc_document_type: null,
      kyc_document_url: null,
      verification_status: "",
    },
    user_properties: [],
  },
  similarProperty: [],
  dashboardStats: {
    published: 0,
    pending_review: 0,
    draft: 0,
    active_bookings: 0,
    pending_bookings: 0,
    upcoming_bookings: 0,
    history_bookings: 0,
    cancelled_bookings: 0
  },
  guestBookings: [],
  favourites: [],
  hostBookings: [],
};

export const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setProperties: (state, action) => {
      state.properties = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setProperty: (state, action) => {
      state.property = action.payload;
    },
    setPropertyForm: (state, action) => {
      state.propertyForm = action.payload;
    },
    setVerificationChecklist: (state, action) => {
      state.verificationChecklist = action.payload;
    },
    setSimilarProperties: (state, action) => {
      state.similarProperty = action.payload;
    },
    setDashboardStats: (state, action) => {
      state.dashboardStats = action.payload;
    },
    setGuestBookings: (state, action) => {
      state.guestBookings = action.payload;
    },
    setFavourites: (state, action) => {
      state.favourites = action.payload;
    },
    setHostBooking: (state, action) => {
      state.hostBookings = action.payload;
    },
    clearState: (state) => {
      state.properties = [];
    },
  },
});

// actions
export const {
  setProperties,
  setPagination,
  setProperty,
  clearState,
  setPropertyForm,
  setVerificationChecklist,
  setSimilarProperties,
  setDashboardStats,
  setGuestBookings,
  setFavourites,
  setHostBooking,
} = propertySlice.actions;

// reducer
export const propertyReducer = propertySlice.reducer;

// selectors
export const getProperties = (state: RootState) => state?.property?.properties;
export const getProperty = (state: RootState) => state?.property?.property;
export const getPagination = (state: RootState) => state?.property?.pagination;
export const getPropertyForm = (state: RootState) =>
  state?.property?.propertyForm;
export const getVerificationChecklist = (state: RootState) =>
  state?.property?.verificationChecklist;
export const getSimilarProperties = (state: RootState) =>
  state?.property?.similarProperty;
export const getDashboardStats = (state: RootState) =>
  state?.property?.dashboardStats;
export const getGuestBookings = (state: RootState) =>
  state?.property?.guestBookings;
export const getFavourites = (state: RootState) => state?.property?.favourites;
export const getHostBookings = (state: RootState) =>
  state?.property?.hostBookings;
