import React from "react";

const Avatar = ({ ...rest }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect y="0.333496" width="40" height="40" rx="20" fill="#E5E5E5" />
    <path
      d="M28 29.3335C28 27.9379 28 27.2401 27.8278 26.6723C27.44 25.3939 26.4395 24.3935 25.1611 24.0057C24.5933 23.8335 23.8956 23.8335 22.5 23.8335H17.5C16.1044 23.8335 15.4067 23.8335 14.8389 24.0057C13.5605 24.3935 12.56 25.3939 12.1722 26.6723C12 27.2401 12 27.9379 12 29.3335M24.5 15.8335C24.5 18.3188 22.4853 20.3335 20 20.3335C17.5147 20.3335 15.5 18.3188 15.5 15.8335C15.5 13.3482 17.5147 11.3335 20 11.3335C22.4853 11.3335 24.5 13.3482 24.5 15.8335Z"
      stroke="#525252"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Avatar;
