import Navbar from "../../../../components/custom/navbar";
import Footer from "../../../../components/custom/footer";
import MakeBookingSection from "../../../../components/custom/make-bookings-section";

const PrivacyPolicy = () => {
  return (
    <div className="bg-eadWhite dark:bg-gray1000 ">
      <div className="relative max-w-screen-2xl mx-auto pb-24 px-4">
        <Navbar />

        {/* main text PrivacyPolicy */}
        <div className="w-full flex flex-col items-start xl:items-center lg:items-center justify-center gap-4 md:gap-5 md:pt-16">
          <p className="text-base font-display font-semibold text-primary800 lg:text-center">
            Privacy Policy
          </p>
          <p className="font-bold font-display text-3xl md:text-4xl text-gray1000 dark:text-gray200">
            Terms of Service
          </p>
        </div>
        <div className="md:px-32 md:mt-20 mt-5 flex flex-col gap-3 text-sm font-display">
          <span className="font-bold text-black dark:text-white">Terms of Use</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            These Terms of Service (“Terms”) are a binding legal agreement
            between you and Eve AfterDark Shortlet that govern the right to use
            the websites, applications, and other offerings from Eve AfterDark
            Shortlet (collectively, the “Eve AfterDark Shortlet Platform”). When
            used in these Terms, “Eve AfterDark Shortlet,” “we,” “us,” or “our”
            refers to the Eve AfterDark Shortlet entity set out on Schedule 1
            with whom you are contracting.
          </p>
          <p className="text-justify text-gray900 dark:text-gray-300">
            The Eve AfterDark Shortlet Platform enables users (“Members”) to
            publish, offer, search for, and book services. Members who publish
            and offer services are “Hosts” and Members who search for, book, or
            use services are “Guests.” Hosts offer accommodations
            (“Accommodations”), activities, excursions, and events
            (“Experiences”), and a variety of travel and other services
            (collectively, “Host Services,” and each Host Service offering, a
            “Listing”). As the provider of the Eve AfterDark Shortlet Platform,
            Eve AfterDark Shortlet does not own, control, offer or manage any
            Listings or Host Services. Eve AfterDark Shortlet is not a party to
            the contracts entered into directly between Hosts and Guests, nor is
            Eve AfterDark Shortlet a real estate broker, travel agency, or
            insurer. Eve AfterDark Shortlet is not acting as an agent in any
            capacity for any Member, except as specified in the Payments Terms
            of Service (“Payment Terms”).{" "}
          </p>
          <p className="text-justify text-gray900 dark:text-gray-300">
            We maintain other terms and policies that supplement these Terms
            like our Privacy Policy, which describes our collection and use of
            personal data, and our Payments Terms, which govern any payment
            services provided to Members by the Eve AfterDark Shortlet payment
            entities (collectively "Eve AfterDark Shortlet Payments").{" "}
          </p>
          <span className="font-bold text-black dark:text-white mt-5">Guest Terms</span>
          <span className="font-bold text-black dark:text-white">
            1. Searching and Booking on Eve AfterDark Shortlet.
          </span>
          <span className="font-bold text-black dark:text-white">1.1 Searching</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            You can search for Host Services by using criteria like the type of
            Host Service, type of listing, travel destination, travel dates, and
            number of guests. You can also use filters to refine your search
            results. Search results are based on their relevance to your search
            and other criteria. Relevance considers factors like price,
            availability, Reviews, customer service and cancellation history,
            popularity, previous trips and saved Listings, Host requirements
            (e.g. minimum or maximum nights), and more.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">1.2 Booking</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            When you book a Listing, you are agreeing to pay all charges for
            your booking including the Listing price, applicable fees like Eve
            AfterDark Shortlet’s service fee, offline fees, taxes, and any other
            items identified during checkout (collectively, “Total Price”). If
            you choose to pay using a currency that differs from the currency
            set by the Host for their Listing, the price displayed to you is
            based on a currency conversion rate determined by us. You are also
            agreeing that Eve AfterDark Shortlet via Eve AfterDark Shortlet
            Payments may charge the Payment Method (as defined in the Payment
            Terms) used to book the Listing in order to collect Damage Claim (as
            defined in Section 14) amounts.
          </p>
          <p className="text-justify text-gray900 dark:text-gray-300">
            When you receive the booking confirmation, a contract for Host
            Services (a "Reservation") is formed directly between you and the
            Host. By making a Reservation you are agreeing to the terms of the
            contract. The terms of the contract include these Terms, all terms
            of the Reservation, including without limitation, the cancellation
            policy and any other rules, standards, policies, or requirements
            identified in the Listing or during checkout that apply to the
            Reservation. It is your responsibility to read and understand these
            terms of the contract including these Terms and all terms of the
            Reservation including all rules, standards, policies, and
            requirements prior to booking a Listing. Be aware that some Hosts
            work with a co-host or as part of a team to provide their Host
            Services.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            1.3 Accommodation Reservations.{" "}
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            An Accommodation Reservation is a limited license to enter, occupy,
            and use the Accommodation. The Host retains the right to re-enter
            the Accommodation during your stay, to the extent: (i) it is
            reasonably necessary, (ii) permitted by your contract with the Host,
            and (iii) permitted by applicable law. If you stay past checkout,
            the Host has the right to make you leave in a manner permitted with
            applicable law, including by imposing reasonable overstay penalties.
            You may not exceed the maximum number of allowed Guests.
          </p>
          <span className="font-bold text-black dark:text-white">
            1.4 Reservations for Experiences and Other Host Services.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            An Experience or other Host Service Reservation entitles you to
            participate in, attend, or use that Host Service. You are
            responsible for confirming that you, and anyone you invite, meet
            minimum age, proficiency, fitness, or other requirements. You are
            responsible for informing the Host of any medical or physical
            conditions, or other circumstances that may impact your ability to
            participate, attend, or use the Experience or Host Service. Except
            where expressly authorized, you may not allow any person to join an
            Experience or Host Service unless they are included as an additional
            guest during the booking process.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">
            2. Cancellations, Reservation Issues, Refunds and Booking
            Modifications.
          </span>
          <span className="font-bold text-black dark:text-white">
            2.1 Cancellations, Reservation Issues, and Refunds.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            In general, if you cancel a Reservation, the amount refunded to you
            is determined by the Host's cancellation policy that applies to that
            Reservation. But, in certain situations, other policies take
            precedence and determine what amount is refunded to you. If
            something outside your control forces you to cancel a Reservation,
            you may be eligible for a partial or full refund under our
            Extenuating Circumstances Policy. If the Host cancels, or you
            experience a Reservation Issue (as defined in our Rebooking and
            Refund Policy), you may be eligible for rebooking assistance or a
            partial or full refund under the Rebooking and Refund Policy.
            Different policies apply to certain categories of Listings; for
            example Experiences Reservations are governed by the Experiences
            Guest Refund Policy. See each Additional Legal Term or Policy for
            details about what is covered, and what refund applies in each
            situation. You may appeal a decision by Eve AfterDark Shortlet by
            contacting our customer service.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            2.2 Booking Modifications.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Guests and Hosts are responsible for any booking modifications they
            agree to make via the Eve AfterDark Shortlet Platform or direct Eve
            AfterDark Shortlet customer service to make on their behalf
            ("Booking Modifications"), and agree to pay any additional amounts,
            fees, or taxes associated with any Booking Modification.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">
            3. Your Responsibilities and Assumption of Risk.
          </span>
          <span className="font-bold text-black dark:text-white">
            3.1 Your Responsibilities.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            You are responsible and liable for your own acts and omissions and
            are also responsible for the acts and omissions of anyone you invite
            to join or provide access to any Accommodation, all areas and
            facilities where the Accommodation is located that the Host and
            Guest are legally entitled to use in connection with the
            Accommodation (“Common Areas”), or any Experience or other Host
            Service. For example, this means: (i) you are responsible for
            leaving an Accommodation (and related personal property) or Common
            Areas in the condition it was in when you arrived, (ii) you are
            responsible for paying all reasonable Damage Claim amounts, and
            (iii) you must act with integrity, treat others with respect, and
            comply with applicable laws at all times. If you are booking for an
            additional guest who is a minor or if you bring a minor to a Host
            Service, you must be legally authorized to act on behalf of the
            minor and you are solely responsible for the supervision of that
            minor.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            3.2 Your Assumption of Risk.{" "}
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            You acknowledge that many activities carry inherent risks and agree
            that, to the maximum extent permitted by applicable law, you assume
            the entire risk arising out of your access to and use of the Eve
            AfterDark Shortlet Platform and any Content (as defined in Section
            9), including your stay at any Accommodation, participation in any
            Experience, use of any other Host Service, or any other interaction
            you have with other Members whether in person or online. This means
            it is your responsibility to investigate a Host Service to determine
            whether it is suitable for you. For example, Host Services may carry
            risk of illness, bodily injury, disability, or death, and you freely
            and willfully assume those risks by choosing to participate in those
            Host Services.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">Host Terms</span>
          <span className="font-bold text-black dark:text-white">
            4. Hosting on Eve AfterDark Shortlet.{" "}
          </span>
          <span className="font-bold text-black dark:text-white">Host</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            As a Host, Eve AfterDark Shortlet offers you the right to use the
            Eve AfterDark Shortlet Platform in accordance with these Terms to
            share your Accommodation, Experience, or other Host Service with our
            vibrant community of Guests - and earn money doing it. It’s easy to
            create a Listing and you are in control of how you host - set your
            price, availability, and rules for each Listing.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            4.3 Independence of Hosts.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Your relationship with Eve AfterDark Shortlet is that of an
            independent individual or entity and not an employee, agent, joint
            ventures, or partner of Eve AfterDark Shortlet, except that Eve
            AfterDark Shortlet Payments acts as a payment collection agent as
            described in the Payments Terms. Eve AfterDark Shortlet does not
            direct or control your Host Service, and you understand that you
            have complete discretion whether and when to provide Host Services,
            and at what price and on what terms to offer them.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            4.2 Contracting with Guests.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            When you accept a booking request, or receive a booking confirmation
            through the Eve AfterDark Shortlet Platform, you are entering into a
            contract directly with the Guest, and are responsible for delivering
            the Host Service under the terms and at the price specified in your
            Listing. You are also agreeing to pay applicable fees like Eve
            AfterDark Shortlet’s service fee (and applicable taxes) for each
            booking. Eve AfterDark Shortlet Payments will deduct amounts you owe
            from your payout unless we and you agree to a different method. Any
            terms or conditions that you include in any supplemental contract
            with Guests must: (i) be consistent with these Terms, our Additional
            Legal Terms, Policies, and the information provided in your Listing,
            and (ii) be prominently disclosed in your Listing description.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">
            5. Managing Your Listing.
          </span>
          <span className="font-bold text-black dark:text-white">
            5.1 Creating and Managing Your Listing.{" "}
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            The Eve AfterDark Shortlet Platform provides tools that make it easy
            for you to set up and manage a Listing. Your Listing must include
            complete and accurate information about your Host Service, your
            price, other charges like cleaning fees, resort fees, offline fees,
            and any rules or requirements that apply to your Guests or Listing.
            You are responsible for your acts or omissions as well as keeping
            your Listing information (including calendar availability) and
            content (like photos) up-to-date and accurate at all times. You are
            responsible for obtaining appropriate insurance for your Host
            Services and we suggest you carefully review policy terms and
            conditions including coverage details and exclusions. You may only
            maintain one Listing per Accommodation, but may have multiple
            Listings for a single property if it has multiple places to stay.
            Any offer of an Experience is subject to our Additional Terms for
            Experience Hosts.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            5.2 Know Your Legal Obligations.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            You are responsible for understanding and complying with any laws,
            rules, regulations, and contracts with third parties that apply to
            your Listing or Host Services. For example: Some landlords and
            leases, or homeowner and condominium association rules, restrict or
            prohibit subletting, short-term rentals and/or longer-term stays.
            Some cities have zoning or other laws that restrict the short-term
            rental of residential properties. Some jurisdictions require Hosts
            to register, get a permit, or obtain a license before providing
            certain Host Services (such as short-term rentals, longer-term
            stays, preparing food, serving alcohol for sale, guiding tours, or
            operating a vehicle). In some places, the Host Services you want to
            offer may be prohibited altogether. Some jurisdictions require that
            you register Guests who stay at your Accommodation. Some
            jurisdictions have laws that create tenancy rights for Guests and
            additional obligations for Hosts. For example, some places have
            landlord-tenant, rent control, and eviction laws that may apply to
            longer stays. Check your local rules to learn what rules apply to
            the Host Services you plan to offer. Information we provide
            regarding legal requirements is for informational purposes only and
            you should independently confirm your obligations. You are
            responsible for handling and using personal data of Guests and
            others in compliance with applicable privacy laws and these Terms,
            including our Host Privacy Standards. If you have questions about
            how local laws apply you should always seek legal advice.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">5.3 Search Results.</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            The ranking of Listings in search results on the Eve AfterDark
            Shortlet Platform depends on a variety of factors, including these
            main parameters:
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            Guest search parameters (e.g. number of Guests, destination, time
            and duration of the trip, price range), Listing characteristics
            (e.g. price, calendar availability, number and quality of images,
            Reviews, type of Host Service, Host status, length of time the
            Listing has been live on the Eve AfterDark Shortlet Platform, Guest
            engagement and popularity),
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            Guest experience (e.g. customer service and cancellation history of
            the Host, ease of booking), Host and Listing requirements (e.g.
            minimum or maximum nights, booking cut-off time), and Guest
            preferences and history (e.g. previous trips, viewed and saved
            Listings, location from where the Guest is searching). Search
            results may be different on our mobile application than on our
            website, and may also differ in the map view. Eve AfterDark Shortlet
            may allow Hosts to promote their Listings in search or elsewhere on
            the Eve AfterDark Shortlet Platform by paying an additional fee.
            More information about the factors that determine how your Listing
            appears in search results, our current promotional programs (if
            any), and how we identify promoted Content can be found in our Help
            Center.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            5.4 Your Responsibilites.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            You are responsible and liable for your own acts and omissions and
            are also responsible for the acts and omissions of anyone you allow
            to participate in providing your Host Services. You are responsible
            for setting your price and establishing rules and requirements for
            your Listing. You must describe any and all fees and charges in your
            Listing description and may not collect any additional fees or
            charges outside the Eve AfterDark Shortlet Platform except those
            expressly authorized by our Offline Fee Policy. Do not encourage
            Guests to create third-party accounts, submit reviews, provide their
            contact information, or take other actions outside the Eve AfterDark
            Shortlet Platform in violation of our Off-Platform Policy.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            5.5 Hosting as a Team or Organization.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            If you work with a co-host or host as part of a team, business, or
            other organization, you are responsible and liable as a Host under
            these Terms for the acts and omissions of each entity and individual
            who participates in providing your Host Services and you are
            responsible for informing personnel engaged by you to deliver any
            Host Services of your obligations under these Terms. If you accept
            terms or enter into contracts with third parties, you represent and
            warrant that you are authorized to enter into contracts for and bind
            your team, business or other organization, and that each entity you
            use is in good standing under the laws of the place where it is
            established. If you perform other functions, you represent and
            warrant that you are authorized to perform those functions. If you
            instruct Eve AfterDark Shortlet to transfer a portion of your payout
            to a co host or other Hosts, or send payments to someone else, you
            must be authorized to do so, and are responsible and liable for the
            payment amounts and accuracy of any payout information you provide.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            5.6 Your Assumption of Risk.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            You acknowledge that hosting carries inherent risks and agree that
            you assume the entire risk arising out of your access to and use of
            the Eve AfterDark Shortlet Platform, offering Host Services, or any
            interaction you have with other Members whether in person or online.
            You agree that you have had the opportunity to investigate the Eve
            AfterDark Shortlet Platform and any laws, rules, regulations, or
            obligations that may be applicable to your Listings or Host Services
            and that you are not relying upon any statement of law made by Eve
            AfterDark Shortlet.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">
            6. Cancellations, Reservation Issues, and Booking Modifications.
          </span>
          <span className="font-bold text-black dark:text-white">
            6.1 Cancellations and Reservation Issues.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            In general, if a Guest cancels a Reservation, the amount paid to you
            is determined by the cancellation policy that applies to that
            Reservation. As a host, you should not cancel on a Guest without a
            valid reason under our Extenuating Circumstances Policy or
            applicable law. If you cancel on a Guest without such a valid
            reason, we may impose a cancellation fee and other consequences. If:
            (i) a Guest experiences a Reservation Issue (as defined by the
            Rebooking and Refund Policy), (ii) an Extenuating Circumstance
            arises, or (iii) a Reservation is canceled under Section 12 of these
            Terms, the amount you are paid will be reduced by the amount we
            refund or otherwise provide to the Guest, and by any other
            reasonable costs we incur as a result of the cancellation. If a
            Guest receives a refund after you have already been paid, or the
            amount of the refund and other costs incurred by Eve AfterDark
            Shortlet exceeds your payout, Eve AfterDark Shortlet (via Eve
            AfterDark Shortlet Payments) may recover that amount from you,
            including by deducting the refund against your future payouts. You
            agree that Eve AfterDark Shortlet’s Rebooking and Refund Policy,
            Extenuating Circumstances Policy, and these Terms preempt the
            cancellation policy you set in situations where they allow for the
            cancellation of a Reservation and/or the issuance of refunds to
            Guests. If we reasonably expect to provide a refund to a Guest under
            one of these policies, we may delay release of any payout for that
            Reservation until a refund decision is made. If you Host an
            Experience, please note that the Experience Cancellation Policy,
            Experiences Guest Refund Policy and different cancellation fees and
            consequences apply to your Reservations. See each Policy for details
            about what is covered, and what your payout will be in each
            situation.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            6.2 Booking Modifications.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Hosts and Guests are responsible for any Booking Modifications they
            agree to make via the Eve AfterDark Shortlet Platform or direct Eve
            AfterDark Shortlet customer service to make on their behalf, and
            agree to pay any additional amounts, fees or taxes associated with a
            Booking Modification.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">7. Taxes.</span>
          <span className="font-bold text-black dark:text-white">7.1 Host Taxes.</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            As a Host, you are responsible for determining and fulfilling your
            obligations under applicable laws to report, collect, remit, or
            include in your price any applicable VAT or other indirect taxes,
            occupancy taxes, tourist, income, or other taxes ("Taxes").
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            7.2 Collection and Remittance by Eve AfterDark Shortlet.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            In jurisdictions where Eve AfterDark Shortlet facilitates the
            collection and/or remittance of Taxes on behalf of Hosts, you
            instruct and authorize Eve AfterDark Shortlet to collect Taxes on
            your behalf, and/or to remit such Taxes to the relevant Tax
            authority. Any Taxes that are collected and/or remitted by Eve
            AfterDark Shortlet are identified to Members on their transaction
            records, as applicable. Eve AfterDark Shortlet may seek additional
            amounts from Members (including by deducting such amounts from
            future payouts) when the Taxes collected and/or remitted are
            insufficient to fully discharge that Members’ tax obligations, and
            you agree that your sole remedy for Taxes collected by Eve AfterDark
            Shortlet is a refund from the applicable Tax authority. You
            acknowledge and agree that we retain the right, with prior notice to
            affected Members, to cease the collection and remittance of Taxes in
            any jurisdiction for any reason.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">7.3 Tax Information.</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            As a Host, you are responsible for determining and fulfilling your
            obligations under applicable laws to report, collect, remit, or
            include in your price any applicable VAT or other indirect taxes,
            occupancy taxes, tourist, income, or other taxes ("Taxes").
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">General Terms</span>
          <span className="font-bold text-black dark:text-white">8. Reviews.</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            After each Host Service, Guests and Hosts will have an opportunity
            to review each other. Your Review must be accurate and may not
            contain any discriminatory, offensive, defamatory, or other language
            that violates these Terms, applicable law, or our Content Policy or
            Review Policy. Reviews are not verified by Eve AfterDark Shortlet
            for accuracy and may be incorrect or misleading.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">9. Content</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Parts of the Eve AfterDark Shortlet Platform enable you to provide
            feedback, text, photos, audio, video, information, and other content
            (“Content”). By providing Content, in whatever form and through
            whatever means, you grant Eve AfterDark Shortlet a non-exclusive,
            worldwide, royalty-free, perpetual, sub-licensable and transferable
            license to access, use, store, copy, modify, prepare derivative
            works of, distribute, publish, transmit, stream, broadcast, and
            otherwise exploit, in any manner such that Content to provide and/or
            promote the Eve AfterDark Shortlet Platform, in any media or
            platform, known or unknown to date and in particular on Internet and
            social networks. If Content includes personal information, such
            Content will only be used for these purposes if such use complies
            with applicable data protection laws in accordance with our Privacy
            Policy. Where Eve AfterDark Shortlet pays for the creation of
            Content or facilitates its creation, Eve AfterDark Shortlet may own
            that Content, in which case supplemental terms or disclosures will
            say that. You are solely responsible for all Content that you
            provide and warrant that you either own it or are authorized to
            grant Eve AfterDark Shortlet the rights described in these Terms.
            You are responsible and liable if any of your Content violates or
            infringes the intellectual property or privacy rights of any third
            party. Content must comply with our Content Policy and
            Non-discrimination Policy, which prohibit, among other things,
            discriminatory, obscene, harassing, deceptive, violent, and illegal
            content. You agree that Eve AfterDark Shortlet may make available
            services or automated tools to translate Content and that your
            Content may be translated using such services or tools. Eve
            AfterDark Shortlet does not guarantee the accuracy or quality of
            translations and Members are responsible for confirming the accuracy
            of such translations.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">10. Fees</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Eve AfterDark Shortlet may charge fees (and applicable Taxes) to
            Hosts and Guests for the right to use the Eve AfterDark Shortlet
            Platform. Any applicable fees are disclosed to Guests before making
            a Booking. Except as otherwise provided on the Eve AfterDark
            Shortlet Platform, service fees are non-refundable. Eve AfterDark
            Shortlet reserves the right to change the service fees at any time,
            and will provide Members notice of any fee changes before they
            become effective. Fee changes will not affect bookings made prior to
            the effective date of the fee change.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">
            11. Eve AfterDark Shortlet Platform Rules.
          </span>
          <span className="font-bold text-black dark:text-white">
            11.1 Rules. You must follow these rules and must not help or induce
            others to break or circumvent these rules.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Act with integrity and treat others with respect Do not lie,
            misrepresent something or someone, or pretend to be someone else. Be
            polite and respectful when you communicate or interact with others.
            Do not attempt to evade enforcement of these Terms, our Additional
            Legal Terms, Policies, or our Standards, such as by creating a
            duplicate account or listings. Follow our Non-discrimination Policy
            and do not discriminate against or harass others. Do not scrape,
            hack, reverse engineer, compromise or impair the Eve AfterDark
            Shortlet Platform Do not use bots, crawlers, scrapers, or other
            automated means to access or collect data or other content from or
            otherwise interact with the Eve AfterDark Shortlet Platform.Do not
            hack, avoid, remove, impair, or otherwise attempt to circumvent any
            security or technological measure used to protect the Eve AfterDark
            Shortlet Platform or Content. Do not decipher, decompile,
            disassemble, or reverse engineer any of the software or hardware
            used to provide the Eve AfterDark Shortlet Platform. Do not take any
            action that could damage or adversely affect the performance or
            proper functioning of the Eve AfterDark Shortlet Platform.
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            Only use the Eve AfterDark Shortlet Platform as authorized by these
            Terms or another agreement with us You may only use another Member’s
            personal information as necessary to facilitate a transaction using
            the Eve AfterDark Shortlet Platform as authorized by these Terms. Do
            not use the Eve AfterDark Shortlet Platform, our messaging tools, or
            Members’ personal information to send commercial messages without
            their express consent. You may use Content made available through
            the Eve AfterDark Shortlet Platform solely as necessary to enable
            your use of the Eve AfterDark Shortlet Platform as a Guest or Host.
            Do not use Content unless you have permission from the Content owner
            or the use is authorized by us in these Terms or another agreement
            you have with us. Do not request, make, or accept a booking or any
            payment outside of the Eve AfterDark Shortlet Platform to avoid
            paying fees, taxes or for any other reason. See our Offline Fee
            Policy for exceptions. Do not require or encourage Guests to open an
            account, leave a review, complete a survey, or otherwise interact,
            with a third-party website, application or service before, during or
            after a Reservation, unless authorized by Eve AfterDark Shortlet. Do
            not engage in any practices that are intended to manipulate our
            search algorithm. Do not book Host Services unless you are actually
            using the Host Services. Do not use, copy, display, mirror or frame
            the Eve AfterDark Shortlet Platform, any Content, any Eve AfterDark
            Shortlet branding, or any page layout or design without our consent.
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            Honor your legal obligations
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            Understand and follow the laws that apply to you, including privacy,
            data protection, and export laws.
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            If you provide us with someone else’s personal information, you: (i)
            must do so in compliance with applicable law, (ii) must be
            authorized to do so, and (iii) authorize us to process that
            information under our Privacy Policy.{" "}
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            Read and follow our Terms, Additional Legal Terms, Policies, and
            Standards.
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            Do not organize or facilitate unauthorized parties or events. You
            are responsible and liable for any party or event during your
            Reservation that violates our rules for parties and events, as
            incorporated by reference herein.
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            Do not use the name, logo, branding, or trademarks of Eve AfterDark
            Shortlet or others without permission, and only as set forth in our
            Trademark Guidelines.
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            Do not use or register any domain name, social media handle, trade
            name, trademark, branding, logo, or other source identifier that is
            confusingly similar to any Eve AfterDark Shortlet trademarks, logos
            or branding. See our Trademark Guidelines for additional details.
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            Do not offer Host Services that violate the laws or agreements that
            apply to you.
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            Do not offer or solicit prostitution or participate in or facilitate
            human trafficking.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            11.2 Reporting Violations.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            If you believe that a Member, Listing or Content poses an imminent
            risk of harm to a person or property, you should immediately contact
            local authorities before contacting Eve AfterDark Shortlet. In
            addition, if you believe that a Member, Listing or Content has
            violated our Standards, you should report your concerns to Eve
            AfterDark Shortlet. If you reported an issue to local authorities,
            Eve AfterDark Shortlet may request a copy of that report. Except as
            required by law, we are not obligated to take action in response to
            any report.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            11.3 Copyright Notifications.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            If you believe that Content on the Eve AfterDark Shortlet Platform
            infringes copyrights, please notify us in accordance with our
            Copyright Policy.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">
            12. Termination, Suspension and other Measures.
          </span>
          <span className="font-bold text-black dark:text-white">12.1 Term.</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            The agreement between you and Eve AfterDark Shortlet reflected by
            these Terms is effective when you access the Eve AfterDark Shortlet
            Platform (for example to create an account) and remains in effect
            until either you or we terminate the agreement in accordance with
            these Terms.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">12.2 Termination.</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            You may terminate this agreement at any time by sending us an email
            or by deleting your account. Eve AfterDark Shortlet may terminate
            this agreement and your account for any reason by providing you 30
            days’ notice via email or using any other contact information you
            have provided for your account. Eve AfterDark Shortlet may also
            terminate this agreement immediately and without notice and stop
            providing access to the Eve AfterDark Shortlet Platform if you
            breach these Terms, you violate our Additional Legal Terms, or
            Policies, you violate applicable laws, or we reasonably believe
            termination is necessary to protect Eve AfterDark Shortlet, its
            members, or third parties. If your account has been inactive for
            more than two years, we may terminate your account without prior
            notice.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">12.3 Member Violations.</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            If (i) you breach these Terms, our Additional Legal Terms, Policies,
            or our Standards, (ii) you violate applicable laws, regulations, or
            third-party rights, or (iii) Eve AfterDark Shortlet believes it is
            reasonably necessary to protect Eve AfterDark Shortlet, its members,
            or third parties; Eve AfterDark Shortlet may, with or without prior
            notice: suspend or limit your access to or use of the Eve AfterDark
            Shortlet Platform and/or your account; suspend, remove, disable
            access to, or restrict visibility of Listings, Reviews, or other
            Content; cancel pending or confirmed bookings; or suspend or revoke
            any special status associated with your account.
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            For minor violations or where otherwise appropriate as Eve AfterDark
            Shortlet determines in its sole discretion, you will be given notice
            of any intended measure by Eve AfterDark Shortlet and an opportunity
            to resolve the issue. You may appeal actions taken by us under this
            Section by contacting customer service. If a Reservation is canceled
            under this Section, the amount paid to the Host will be reduced by
            the amount we refund or otherwise provide to the Guest, and by any
            other costs we incur as a result of the cancellation.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">12.4 Legal Mandates.</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Eve AfterDark Shortlet may take any action it determines is
            reasonably necessary to comply with applicable law, or the order or
            request of a court, law enforcement, or other administrative agency
            or governmental body, including the measures described above in
            Section 12.3.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">
            12.5 Effect of Termination.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            If you are a Host and terminate your Eve AfterDark Shortlet account,
            any confirmed booking(s) will be automatically cancelled and your
            Guests will receive a full refund. If you terminate your account as
            a Guest, any confirmed booking(s) will be automatically cancelled
            and any refund will depend upon the terms of the Reservation’s
            cancellation policy. When this agreement has been terminated, you
            are not entitled to a restoration of your account or any of your
            Content. If your access to or use of the Eve AfterDark Shortlet
            Platform has been limited, or your Eve AfterDark Shortlet account
            has been suspended, or this agreement has been terminated by us, you
            may not register a new account or access or use the Eve AfterDark
            Shortlet Platform through an account of another Member.
          </p>{" "}
          <span className="font-bold text-black dark:text-white">12.5 Survival.</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Parts of these Terms that by their nature survive termination, will
            survive termination of this agreement, including Sections 1 through
            25.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">13. Modification.</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Eve AfterDark Shortlet may modify these Terms at any time. When we
            make material changes to these Terms, we will post the revised Terms
            on the Eve AfterDark Shortlet Platform and update the “Last Updated”
            date at the top of these Terms. We will also provide you with notice
            of any material changes by email, notifications through the Eve
            AfterDark Shortlet Platform, messaging service, or any other contact
            method made available by us and selected by you at least 30 days
            before the date they become effective. If you disagree with the
            revised Terms, you may terminate this agreement immediately as
            provided in these Terms. If you do not terminate your agreement
            before the date the revised Terms become effective, your continued
            access to or use of the Eve AfterDark Shortlet Platform will
            constitute acceptance of the revised Terms.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">
            14. Resolving Complaints and Damage Claims.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            If a member provides valid evidence that you, your guest(s), or your
            pet(s) have:
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            (i) damaged the complaining Member’s, or the Accommodation owner’s
            (where the Accommodation owner is not also the Host), real or
            personal property, or real or personal property the complaining
            Member is responsible for, or has an economic interest in; or
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            (ii) caused loss of booking income for bookings via the Eve
            AfterDark Shortlet Platform or other consequential damages which
            result directly from the damage caused under (i) above; or
          </p>{" "}
          <p className="text-justify text-gray900 dark:text-gray-300">
            (iii) otherwise caused the complaining Member to incur cleaning
            costs in excess of the Member’s cleaning fee (each of (i), (ii), and
            (iii) being a ("Damage Claim"), the complaining Member can notify
            Eve AfterDark Shortlet and/or seek compensation through the
            Resolution Center. You will be notified of the Damage Claim and
            given an opportunity to respond. If you agree to pay, or if the
            Damage Claim is escalated to Eve AfterDark Shortlet and Eve
            AfterDark Shortlet determines in its sole discretion that the Damage
            Claim is valid and you are responsible for the Damage Claim, Eve
            AfterDark Shortlet via Eve AfterDark Shortlet Payments can collect
            the amount of the Damage Claim from you. You agree that Eve
            AfterDark Shortlet may seek to recover from you under any insurance
            policies you maintain and that Eve AfterDark Shortlet may also
            pursue against you any remedies it may have available under
            applicable law, including referral of the matter to a collections
            agency, and/or pursuit of available causes of action and/or claims
            against you. You agree to cooperate in good faith, provide any
            information Eve AfterDark Shortlet requests, execute documents, and
            take further reasonable action, in connection with Damage Claims,
            Member complaints, claims under insurance policies, or other claims
            related to your provision or use of Host Services.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">
            15. Eve AfterDark Shortlet’s Role.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            We offer you the right to use a platform that enables Members to
            publish, offer, search for, and book Host Services. While we work
            hard to ensure our members have great experiences using Eve
            AfterDark Shortlet, we do not and cannot control the conduct of
            Guests and Hosts. You acknowledge that Eve AfterDark Shortlet has
            the right, but does not have any obligation, to monitor the use of
            the Eve AfterDark Shortlet Platform and verify information provided
            by our members. For example, we may review, disable access to,
            remove, or edit Content to: (i) operate, secure and improve the Eve
            AfterDark Shortlet Platform (including for fraud prevention, risk
            assessment, investigation and customer support purposes); (ii)
            ensure Members’ compliance with these Terms; (iii) comply with
            applicable law or the order or requirement of a court, law
            enforcement or other administrative agency or governmental body;
            (iv) address Content that we determine is harmful or objectionable;
            (v) take actions set out in these Terms; and (vi) maintain and
            enforce any quality or eligibility criteria, including by removing
            Listings that don’t meet quality and eligibility criteria. Members
            acknowledge and agree that Eve AfterDark Shortlet administers its
            Additional Legal Terms, Policies (such as our Extenuating
            Circumstances Policy) and Standards (such as basic requirements for
            hosts), including decisions about whether and how to apply them to a
            particular situation, at its sole discretion. Members agree to
            cooperate with and assist Eve AfterDark Shortlet in good faith, and
            to provide Eve AfterDark Shortlet with such information and take
            such actions as may be reasonably requested by Eve AfterDark
            Shortlet with respect to any investigation undertaken by Eve
            AfterDark Shortlet regarding the use or abuse of the Eve AfterDark
            Shortlet Platform. Eve AfterDark Shortlet is not acting as an agent
            for any Member except for where Eve AfterDark Shortlet Payments acts
            as a collection agent as provided in the Payments Terms.
          </p>{" "}
          <span className="font-bold text-black dark:text-white mt-5">
            16. Member Accounts.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            You must register an account to access and use many features of the
            Eve AfterDark Shortlet Platform. Registration is only permitted for
            legal entities, partnerships and natural persons who are 18 years or
            older. You represent and warrant that you are not a person or entity
            barred from using the Eve AfterDark Shortlet Platform under the laws
            of the Federal Republic of Nigeria, your place of residence, or any
            other applicable jurisdiction. You must provide accurate, current,
            and complete information during registration and keep your account
            information up-to-date. You may not transfer your account to someone
            else. You are responsible for maintaining the confidentiality and
            security of your account credentials and may not disclose your
            credentials to any third party. You are responsible and liable for
            activities conducted through your account and must immediately
            notify Eve AfterDark Shortlet if you suspect that your credentials
            have been lost, stolen, or your account is otherwise compromised. If
            and as permitted by applicable law, we may, but have no obligation
            to (i) ask you to provide identification or other information, (ii)
            undertake checks designed to help verify your identity or
            background, (iii) screen you against third-party databases or other
            sources and request reports from service providers, and (iv) obtain
            reports from public records of criminal convictions or sex offender
            registrations or their local equivalents.
          </p>
          <span className="font-bold text-black dark:text-white mt-5">
            17. Disclaimer of Warranties.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            We provide the Eve AfterDark Shortlet Platform and all Content “as
            is” without warranty of any kind and we disclaim all warranties,
            whether express or implied. For example: (i) we do not endorse or
            warrant the existence, conduct, performance, safety, quality,
            legality or suitability of any Guest, Host, Host Service, Listing or
            third party; (ii) we do not warrant the performance or
            non-interruption of the Eve AfterDark Shortlet Platform; and (iii)
            we do not warrant that verification, identity or background checks
            conducted on Listings or Members (if any) will identify past
            misconduct or prevent future misconduct. Any references to a member
            or Listing being "verified" (or similar language) indicate only that
            the Member or Listing or Eve AfterDark Shortlet has completed a
            relevant verification or identification process and nothing else.
            The disclaimers in these Terms apply to the maximum extent permitted
            by law. If you have statutory rights or warranties Eve AfterDark
            Shortlet cannot disclaim, the duration of any such statutorily
            required rights or warranties, will be limited to the maximum extent
            permitted by law.
          </p>
          <span className="font-bold text-black dark:text-white mt-5">
            18. Limitations on Liability.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Neither Eve AfterDark Shortlet (including its affiliates and
            personnel) nor any other party involved in creating, producing, or
            delivering the Eve AfterDark Shortlet Platform or any Content will
            be liable for any incidental, special, exemplary or consequential
            damages, including lost profits, loss of data or loss of goodwill,
            service interruption, computer damage or system failure or the cost
            of substitute products or services, or for any damages for personal
            or bodily injury or emotional distress arising out of or in
            connection with (i) these Terms, (ii) the use of or inability to use
            the Eve AfterDark Shortlet Platform or any Content, (iii) any
            communications, interactions or meetings you may have with someone
            you interact or meet with through, or as a result of, your use of
            the Eve AfterDark Shortlet Platform, or (iv) publishing or booking
            of a Listing, including the provision or use of Host Services,
            whether based on warranty, contract, tort (including negligence),
            product liability or any other legal theory, and whether or not Eve
            AfterDark Shortlet has been informed of the possibility of such
            damage, even if a limited remedy set out in these Terms is found to
            have failed of its essential purpose.
          </p>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Except for our obligation to transmit payments to Hosts under these
            Terms, or make payments under the Eve AfterDark Shortlet Host Damage
            Protection, in no event will Eve AfterDark Shortlet’s aggregate
            liability for any claim or dispute arising out of or in connection
            with these Terms, your interaction with any Members, or your use of
            or inability to use the Eve AfterDark Shortlet Platform, any
            Content, or any Host Service, exceed: (A) to Guests, the amount you
            paid as a Guest during the 12-month period prior to the event giving
            rise to the liability, (B) to Hosts, the amount paid to you as a
            Host in the 12-month period prior to the event giving rise to the
            liability, or (C) to anyone else, One Hundred Thousand Naira. (NGN
            100,000.00)
          </p>
          <p className="text-justify text-gray900 dark:text-gray-300">
            These limitations of liability and damages are fundamental elements
            of the agreement between you and Eve AfterDark Shortlet. If
            applicable law does not allow the limitations of liability set out
            in these Terms, the above limitations may not apply to you.
          </p>
          <span className="font-bold text-black dark:text-white mt-5">
            19. Indemnification.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            To the maximum extent permitted by applicable law, you agree to
            release, defend (at Eve AfterDark Shortlet’s option), indemnify, and
            hold Eve AfterDark Shortlet (including Eve AfterDark Shortlet
            Payments, other affiliates, and their personnel) harmless from and
            against any claims, liabilities, damages, losses, and expenses,
            including, without limitation, reasonable legal and accounting fees,
            arising out of or in any way connected with: (i) your breach of
            these Terms (including any supplemental or additional terms that
            apply to a product or feature) or our Additional Legal Terms,
            Policies or Standards, (ii) your improper use of the Eve AfterDark
            Shortlet Platform, (iii) your interaction with any Member, stay at
            an Accommodation, participation in an Experience or other Host
            Service, including without limitation any injuries, losses or
            damages (whether compensatory, direct, incidental, consequential or
            otherwise) of any kind arising in connection with or as a result of
            such interaction, stay, participation or use, (iv) your failure, or
            our failure at your direction, to accurately report, collect or
            remit Taxes, or (v) your breach of any laws, regulations or third
            party rights such as intellectual property or privacy rights.
          </p>
          <span className="font-bold text-black dark:text-white mt-5">
            20. Contracting Entities.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Based on your country of residence or establishment and what you are
            doing on the Eve AfterDark Shortlet Platform, If we identify through
            the Eve AfterDark Shortlet Platform, an Eve AfterDark Shortlet
            entity other than the one set out on Schedule 1 as being responsible
            for a product, feature or transaction, the Eve AfterDark Shortlet
            entity so identified is your contracting entity with respect to that
            product, feature or transaction. If you change your country of
            residence or establishment, the Eve AfterDark Shortlet company you
            contract with (as set out on Schedule 1) and the applicable version
            of the Terms of Service will be determined by your new country of
            residence or establishment, from the date on which your country of
            residence or establishment changes.
          </p>
          <span className="font-bold text-black dark:text-white mt-5">21. Miscellaneous.</span>
          <span className="font-bold text-black dark:text-white">
            21.1 Other Terms Incorporated by Reference.{" "}
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Our Host Damage Protection, , Rebooking and Refund Policy,
            Experiences Guest Refund Policy, Content Policy, Non-discrimination
            Policy, Extenuating Circumstances Policy, Additional Legal Terms,
            Policies, Standards and other supplemental policies and terms linked
            to in these Terms apply to your use of the Eve AfterDark Shortlet
            Platform, are incorporated by reference into this policy, and form
            part of your agreement with Eve AfterDark Shortlet.
          </p>
          <span className="font-bold text-black dark:text-white">
            21.2 Interpreting these Terms.
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Except as they may be supplemented by additional terms, conditions,
            policies, guidelines, standards, and in product disclosures, these
            Terms (including those items incorporated by reference) constitute
            the entire agreement between Eve AfterDark Shortlet and you
            pertaining to your access to or use of the Eve AfterDark Shortlet
            Platform and supersede any and all prior oral or written
            understandings or agreements between Eve AfterDark Shortlet and you.
            These Terms do not and are not intended to confer any rights or
            remedies upon anyone other than you and Eve AfterDark Shortlet. If
            any provision of these Terms is held to be invalid or unenforceable,
            except as otherwise indicated in Section 22.11 above, such provision
            will be struck and will not affect the validity and enforceability
            of the remaining provisions. Where the word “will” is used in these
            Terms it connotes an obligation with the same meaning as “shall.”
          </p>
          <span className="font-bold text-black dark:text-white">21.3 No Waiver.</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Eve AfterDark Shortlet’s failure to enforce any right or provision
            in these Terms will not constitute a waiver of such right or
            provision unless acknowledged and agreed to by us in writing. Except
            as expressly set forth in these Terms, the exercise by either party
            of any of its remedies under these Terms will be without prejudice
            to its other remedies under these Terms or otherwise permitted under
            law.
          </p>
          <span className="font-bold text-black dark:text-white">21.4 Assignment.</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            You may not assign, transfer or delegate this agreement or your
            rights and obligations hereunder without Eve AfterDark Shortlet's
            prior written consent. Eve AfterDark Shortlet may without
            restriction assign, transfer or delegate this agreement and any
            rights and obligations hereunder, at its sole discretion, with 30
            days’ prior notice.
          </p>
          <span className="font-bold text-black dark:text-white">21.5 Notice. </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Unless specified otherwise, any notices or other communications to
            Members permitted or required under this agreement, will be provided
            electronically and given by Eve AfterDark Shortlet via email, Eve
            AfterDark Shortlet Platform notification, messaging service
            (including SMS and WeChat), or any other contact method we enable
            and you provide.
          </p>
          <span className="font-bold text-black dark:text-white">
            21.6 Third-Party Services.{" "}
          </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            The Eve AfterDark Shortlet Platform may contain links to third-party
            websites, applications, services or resources (“Third-Party
            Services”) that are subject to different terms and privacy
            practices. Eve AfterDark Shortlet is not responsible or liable for
            any aspect of such Third-Party Services and links to such
            Third-Party Services are not an endorsement.
          </p>
          <span className="font-bold text-black dark:text-white">21.7 Google Terms.</span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Some translations on the Eve AfterDark Shortlet Platform are powered
            by Google. Google disclaims all warranties related to the
            translations, express or implied, including any warranties of
            accuracy, reliability, and any implied warranties for
            merchantability, fitness for a particular purpose and
            non-infringement. Some areas of the Eve AfterDark Shortlet Platform
            implement Google Maps/Earth mapping services, including Google Maps
            API(s). Your use of Google Maps/Earth is subject to the Google
            Maps/Google Earth Additional Terms of Service.
          </p>
          <span className="font-bold text-black dark:text-white">21.8 Apple Terms. </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            If you access or download our application from the Apple App Store,
            you agree to Apple’s Licensed Application End User License
            Agreement.
          </p>
          <span className="font-bold text-black dark:text-white">21.9 Platform Content. </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Content made available through the Eve AfterDark Shortlet Platform,
            including without limitation trademarks, trade dress, inventions,
            algorithms, computer programs (in source code and object code),
            customer and marketing information and other content (“Platform
            Content”), whether registered or unregistered, which may be
            protected by copyright, trademark, patent, trade secrets, know how,
            and/or other laws of the Federal Republic of Nigeria and other
            countries. You acknowledge that all intellectual property rights for
            that Platform Content are the exclusive property of Eve AfterDark
            Shortlet and/or its licensors and agree that you will not remove,
            alter or obscure any copyright, trademark, service mark or other
            proprietary rights notices. You may not use, copy, adapt, modify,
            prepare derivative works of, distribute, license, sell, transfer,
            publicly display, publicly perform, transmit, broadcast or otherwise
            exploit any Platform Content accessed through the Eve AfterDark
            Shortlet Platform except to the extent you are the legal owner of
            that Platform Content or as expressly permitted in these Terms.
            Subject to your compliance with these Terms, Eve AfterDark Shortlet
            grants you a limited, non-exclusive, non-sublicensable, revocable,
            non-transferable license to (i) download and use the Application on
            your personal device(s); and (ii) access and view the Platform
            Content made available on or through the Eve AfterDark Shortlet
            Platform and accessible to you, solely for your personal and
            non-commercial use.
          </p>
          <span className="font-bold text-black dark:text-white">21.10 Force Majeure. </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            Eve AfterDark Shortlet shall not be liable for any delay or failure
            to perform resulting from abnormal or unforeseeable circumstances
            outside its reasonable control, the consequences of which would have
            been unavoidable despite all efforts to the contrary, including, but
            not limited to, acts of God, natural disasters, war, terrorism,
            riots, embargoes, acts of civil or military authorities, fire,
            floods, accidents, pandemics, epidemics or disease, strikes or
            shortages of transportation facilities, fuel, energy, labor or
            materials.
          </p>
          <span className="font-bold text-black dark:text-white">21.11 Emails and SMS. </span>
          <p className="text-justify text-gray900 dark:text-gray-300">
            You will receive administrative communications from us using the
            email address or other contact information you provide for your Eve
            AfterDark Shortlet account. Enrollment in additional email
            subscription programs will not affect the frequency of these
            administrative emails, though you should expect to receive
            additional emails specific to the program(s) to which you have
            subscribed. You may also receive promotional emails from us. No fee
            is charged for these promotional emails, but third-party data rates
            could apply. You can control whether you receive promotional emails
            using the notification preferences in your account settings. Please
            note that you will not be able to take advantage of certain
            promotions if you disable certain communication settings or do not
            have an Eve AfterDark Shortlet account. In Nigeria if you consent to
            receive SMS (text messages) from us, you will be subject to our SMS
            Terms
          </p>
        </div>

        {/* make bookings */}
        <MakeBookingSection />
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
