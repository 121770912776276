import React, { useEffect } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  children: any;
  className?: string;
};

export default function Modal({ open, onClose, children, className }: Props) {
  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }

    // Cleanup function to reset overflow style when the component unmounts or open changes
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [open]);

  return (
    // backdrop
    <div
      onClick={onClose}
      className={` z-50
        fixed inset-0 flex justify-center items-center transition-colors
        ${open ? "visible bg-black/70" : "invisible"}
      `}
    >
      {/* modal */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`
         rounded-xl shadow transition-all mx-2 bg-white dark:bg-[#141414] dark:text-gray100
          ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"} ${className}
        `}
      >
        {children}
      </div>
    </div>
  );
}
