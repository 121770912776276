import React from "react";
import ReviewStarsRating from "./reviewStarsRating";
import moment from "moment";

type Props = {
  name?: string;
  date?: string;
  location?: string;
  review?: string;
  pic?: string;
  rating?: number
};

const ReviewsCardInfo = ({ name, review, location, date, pic, rating }: Props) => {
  return (
    <div className="flex rounded-lg dark:text-gray200">
      <div className="p-4 w-full flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          {pic ? (
            <img src={pic} alt="" className="rounded-full w-12 h-12" />
          ) : (
            <svg
              width="41"
              height="41"
              viewBox="0 0 41 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.332031"
                y="0.667969"
                width="40"
                height="40"
                rx="20"
                fill="#E5E5E5"
              />
              <path
                d="M28.332 29.668C28.332 28.2724 28.332 27.5746 28.1598 27.0068C27.772 25.7284 26.7716 24.728 25.4932 24.3402C24.9254 24.168 24.2276 24.168 22.832 24.168H17.832C16.4365 24.168 15.7387 24.168 15.1709 24.3402C13.8925 24.728 12.8921 25.7284 12.5043 27.0068C12.332 27.5746 12.332 28.2724 12.332 29.668M24.832 16.168C24.832 18.6533 22.8173 20.668 20.332 20.668C17.8467 20.668 15.832 18.6533 15.832 16.168C15.832 13.6827 17.8467 11.668 20.332 11.668C22.8173 11.668 24.832 13.6827 24.832 16.168Z"
                stroke="#525252"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}

          <div>
            <p className="text-sm font-display font-semibold text-gray800 dark:text-gray200">
              {name}
            </p>
            <p className="text-sm font-display text-gray800 dark:text-gray200">{location}</p>
          </div>
        </div>
        <div className="flex items-center gap-2 mt-1">
          <ReviewStarsRating rating={rating || 0} />
          <p className="text-sm font-display text-gray800 dark:text-gray200">{moment(date).format("MMMM YYYY")}</p>
        </div>
        <p className="text-sm font-display text-gray800 my-1 dark:text-gray200">{review}</p>
      </div>
    </div>
  );
};

export default ReviewsCardInfo;
