import React, { forwardRef } from "react";
import CheckRedIcon from "../icons/check-red";
import { getIconComponent } from "../../utils/get-icons";
import { TbBuilding, TbHotelService } from "react-icons/tb";

interface Props {
  id?: string;
  placeholder?: string;
  onChange: any;
  data?: { id?: string; value?: string; name?: string; label?: string }[];
  label?: string;
  name?: string;
  value?: string;
  readOnly?: boolean;
}

interface RadioProps {
  icon: any;
  title: string;
  onSelect: () => void;
  isSelected: boolean;
}

interface CheckProps {
  icon: any;
  title: string;
  onSelect: () => void;
  isSelected: boolean;
}

export const SelectInput = forwardRef(
  (
    { id, placeholder, data, onChange, label, name, value, ...props }: Props,
    ref
  ) => (
    <div className="flex flex-col">
      <label
        htmlFor="country"
        className="text-xs font-display my-1 dark:text-gray200"
      >
        {label}
      </label>
      <div className="p-1 rounded-full border border-gray400 bg-gray200 dark:bg-gray1000 dark:text-gray300">
        <select
          id={id}
          onChange={onChange}
          name={value || id}
          className="w-full bg-transparent placeholder:text-xs text-xs font-display "
          {...props}
          value={value}
        >
          <option value="" className="font-display">
            {placeholder}
          </option>
          {data?.map((item, i) => {
            return (
              <option
                key={i}
                id={item.label}
                value={item.value || value}
                className="font-display"
              >
                {item?.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  )
);

export const CardRadioItem = ({
  icon,
  title,
  onSelect,
  isSelected,
}: RadioProps) => {
  const iconTag = getIconComponent(icon);
  const displayedIcon =
    iconTag.type === TbHotelService ? <TbBuilding /> : iconTag;

  return (
    <button
      onClick={onSelect}
      className={`flex p-2 justify-between rounded-xl w-36 border m-1 transition-all duration-200 ease-in-out ${
        isSelected ? "border-primary500" : "border-gray500 dark:border-gray800"
      }`}
    >
      <div className="">
        <div className="text-3xl">{displayedIcon}</div>
        <p className="font-display text-sm text-gray1000 font-semibold dark:text-gray200">
          {title}
        </p>
      </div>
      <div
        className={`p-1 rounded-full border transition-all duration-200 ease-in-out ${
          isSelected
            ? "border-primary500"
            : "border-gray500 dark:border-gray800"
        }`}
      >
        <div
          className={`p-1 bg-primary500 rounded-full transition-all duration-200 ease-in-out ${
            isSelected ? "block" : "hidden"
          }`}
        />
      </div>
    </button>
  );
};

export const CardCheckItem = ({
  icon,
  title,
  onSelect,
  isSelected,
}: CheckProps) => {
  const iconTag = getIconComponent(icon);
  const displayedIcon =
    iconTag.type === TbHotelService ? <TbBuilding /> : iconTag;

  return (
    <button
      onClick={onSelect}
      className={`flex p-2 justify-between rounded-xl lg:w-40 w-36 h-16 border my-1 lg:my-0 transition-all duration-200 ease-in-out ${
        isSelected ? "border-primary500" : "border-gray500 dark:border-gray800"
      }`}
    >
      <div className="flex flex-col justify-between h-full">
        <div className="text-3xl">{displayedIcon}</div>
        <p className="font-display text-xs text-gray1000 font-semibold mt-1 dark:text-gray200">
          {title}
        </p>
      </div>
      <div
        className={`w-4 h-4 flex items-center justify-center border rounded-md p-0.5 transition-all duration-200 ease-in-out ${
          isSelected
            ? "border-primary400"
            : "border-gray500 dark:border-gray800"
        }`}
      >
        {isSelected && <CheckRedIcon />}
      </div>
    </button>
  );
};
