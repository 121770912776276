import { useEffect, useState } from "react";
import apiClient from "../network/axios-client";
import { urls } from "../network";
import { HostProfileProps } from "../redux/types";

interface BalanceProp {
  wallets: {
    id: number;
    previous_balance: number;
    amount: number;
    current_balance: number;
    currency_code: string;
  }[];
  awailable_balance: {
    currency: string;
    sum: number;
    id: number;
  }[];
}

interface WalletProps {
  id: number;
  previous_balance: number;
  amount: number;
  current_balance: number;
  currency_code: string;
}

interface AvailableBalanceProp {
  currency: string;
  sum: number;
  id: number;
}

interface MetricsProps {
  bookingsCount: number | undefined;
  overallRating: number | undefined;
  totalEarnings: {
    currency: string;
    total_earnings: number;
    id?: number;
  }[];
}

interface MetricsDataProps {
  bookingsCount: number | undefined;
  overallRating: number | undefined;
  totalEarnings: {
    currency: string;
    total_earnings: number;
    id?: number;
  } | null;
}

export const useGetBalances = (currency: string = "NGN") => {
  const [balances, setBalances] = useState<BalanceProp>({
    wallets: [],
    awailable_balance: [],
  });
  const [isLoadingBalance, setIsLoadingBalance] = useState(false);
  const [currencyBalance, setCurrencyBalance] =
    useState<AvailableBalanceProp | null>(null);
  const [currencyWallet, setCurrencyWallet] = useState<WalletProps | null>(
    null
  );

  const fetchBalances = async () => {
    setIsLoadingBalance(true);
    const res = await apiClient.get(urls.HostBalance);
    setIsLoadingBalance(false);
    if (res.data.hasError) {
    } else {
      setBalances(res.data.data);
    }
    // eslint-disable-next-line
  };

  useEffect(() => {
    fetchBalances();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const walletObj = balances?.wallets.filter(
      (item) => item.currency_code === currency
    );
    const available = balances?.awailable_balance.filter(
      (item) => item.currency === currency
    );
    if (walletObj?.length > 0 || available.length > 0) {
      setCurrencyWallet(walletObj[0]);
      setCurrencyBalance(available[0]);
    } else {
      setCurrencyWallet(null);
      setCurrencyBalance(null);
    }

    // eslint-disable-next-line
  }, [currency, balances]);

  return {
    balances,
    isLoadingBalance,
    currencyBalance,
    currencyWallet,
    fetchBalances,
  };
};

export const useDashboardMetric = (currency: string = "NGN") => {
  const [metrics, setMetrics] = useState<MetricsProps | null>(null);
  const [isLoadingMetric, setIsLoadingMetric] = useState(false);
  const [metricData, setMetricData] = useState<MetricsDataProps | null>(null);

  const fetchBalances = async () => {
    setIsLoadingMetric(true);
    const res = await apiClient.get(urls.DashboardMetric);
    setIsLoadingMetric(false);
    if (res.data.hasError) {
    } else {
      setMetrics(res.data.data.dashboard_stats);
    }
    // eslint-disable-next-line
  };

  useEffect(() => {
    const metricEarns = metrics?.totalEarnings.filter(
      (item) => item.currency === currency
    );
    if (metricEarns && metricEarns?.length > 0) {
      setMetricData({
        bookingsCount: metrics?.bookingsCount,
        overallRating: metrics?.overallRating,
        totalEarnings: {
          currency: metricEarns[0].currency,
          total_earnings: metricEarns[0].total_earnings,
          id: metricEarns[0].id,
        },
      });
    } else {
      setMetricData({
        bookingsCount: metrics?.bookingsCount,
        overallRating: metrics?.overallRating,
        totalEarnings: null,
      });
    }
    // eslint-disable-next-line
  }, [currency, metrics]);

  useEffect(() => {
    fetchBalances();
    // eslint-disable-next-line
  }, []);

  return {
    metrics,
    metricData,
    isLoadingMetric,
  };
};

export const useGetHostProfile = (id: any) => {
  const [hostProfile, setHostProfile] = useState<HostProfileProps | null>(null);
  const [isLoadingHostProfile, setLoadingHostProfile] = useState(false);

  const fetchHostProfile = async () => {
    setLoadingHostProfile(true);
    const res = await apiClient.get(urls.HostProfile(id));
    setLoadingHostProfile(false);
    if (res.data.hasError) {
    } else {
      setHostProfile(res.data.data);
    }
    // eslint-disable-next-line
  };

  useEffect(() => {
    fetchHostProfile();
    // eslint-disable-next-line
  }, [id]);

  return {
    hostProfile,
    isLoadingHostProfile,
  };
};

export const useGetGuestProfile = (id: any) => {
  const [guestProfile, setGuestProfile] = useState<HostProfileProps | null>(
    null
  );
  const [isLoadingGuestProfile, setLoadingGuestProfile] = useState(false);

  const fetchHostProfile = async () => {
    setLoadingGuestProfile(true);
    const res = await apiClient.get(urls.GuestProfile(id));
    setLoadingGuestProfile(false);
    if (res.data.hasError) {
    } else {
      setGuestProfile(res.data.data);
    }
    // eslint-disable-next-line
  };

  useEffect(() => {
    fetchHostProfile();
    // eslint-disable-next-line
  }, [id]);

  return {
    guestProfile,
    isLoadingGuestProfile,
  };
};
