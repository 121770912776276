import React from "react";

const ChevronRightWhite = ({ ...rest }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...rest}>
    <path
      d="M5 12.332H19M19 12.332L12 5.33203M19 12.332L12 19.332"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ChevronRightWhite;
