import React from "react";

const AddCircle = ({ ...rest }) => (
  <svg
    {...rest}
    width="24"
    height="27"
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="dark:stroke-white"
      d="M12 10.666V18.666M8 14.666H16M22 14.666C22 20.1889 17.5228 24.666 12 24.666C6.47715 24.666 2 20.1889 2 14.666C2 9.14317 6.47715 4.66602 12 4.66602C17.5228 4.66602 22 9.14317 22 14.666Z"
      stroke="#525252"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default AddCircle;
