import React, { FC } from "react";

interface Props extends React.SVGProps<SVGSVGElement> {}

const ArrowTopRightBlack: FC<Props> = ({ ...rest }) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1 11.667L11 1.66699M11 1.66699H1M11 1.66699V11.667"
      stroke="#525252"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ArrowTopRightBlack;
