import React from "react";
import { Link } from "react-router-dom";
import ChevronRightRed from "../icons/chevron-right-red";
// import StarIcon from "../icons/star";
// import ChevronRight from "../icons/chevron-right";

type Props = {
  name?: string;
  review?: string;
  pic?: string;
  hostId?: number
};

const HostUserCard = ({ name, pic, hostId }: Props) => {
  return (
    <div className="flex items-center justify-between my-4">
      <div className="flex items-center gap-4">
        <div className="bg-gray500 rounded-full w-12 h-12 dark:bg-gray200">
          <img src={pic} className="rounded-full object-fit w-12 h-12" alt="" />
        </div>
        <div>
          <div className="flex items-center gap-1">
            <p className="lg:text-base text-xs font-display font-bold dark:text-gray-200">Hosted by {name}</p>
            <p className="lg:text-sm text-xs font-display text-gray-800 dark:text-gray-200">Since 2024</p>
          </div>
          {/* <div className="flex gap-1 items-center mb-1">
            <StarIcon />
            <p className="text-xs font-display text-gray900 dark:text-gray-200">
              {review} reviews
            </p>
          </div> */}
        </div>
      </div>
      <div className="lg:flex hidden gap-2 items-center">
        <Link to={`/host-info/${hostId}`} className="text-xs font-display text-primary500 font-semibold">
          More about the host
        </Link>
        <ChevronRightRed />
      </div>
    </div>
  );
};

export default HostUserCard;
