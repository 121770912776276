import React, { useState, useEffect } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import Upload from "../upload";
import Modal from "../../components/custom/modal";
import { useDispatch, useSelector } from "react-redux";
import { PropertyType } from "../../redux/types";
import {
  fetchSingleProperty,
  updateProperty,
  uploadImagesMultiple,
} from "../../redux/actions/property";
import { getIsLoading } from "../../redux/reducers/loader-slice";
import { showToast } from "../../utils/helper-functions";

interface ImageGalleryProps {
  imageData: string[];
  title: string;
  editable?: boolean;
  activeListing: PropertyType;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({
  imageData, //: initialImages,
  editable = false,
  activeListing,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const [editMode, setEditMode] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState<string[]>(imageData);
  const [hoveredImageIndex, setHoveredImageIndex] = useState<number | null>(
    null
  );
  const [images, setImages] = useState<string[]>(imageData);
  const [deletedImageIndexes, setDeletedImageIndexes] = useState<string[]>([]);

  useEffect(() => {
    setImages(imageData);
    setUploadedFiles(imageData);
  }, [imageData]);

  const handleEditPhotosClick = () => {
    setEditMode(true);
  };

  const handleExitEditMode = () => {
    setUploadedFiles([]);
    setEditMode(false);
  };

  const openModal = (image: string) => {
    setModalImage(image);
    setModalOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflowY = "auto";
  };

  const handleDeleteImage = (index: string) => {
    if (deletedImageIndexes.includes(index)) {
      setDeletedImageIndexes(deletedImageIndexes.filter((i) => i !== index));
      setUploadedFiles([...uploadedFiles, index]);
    } else {
      setUploadedFiles(uploadedFiles.filter((item) => item !== index));
      setDeletedImageIndexes([...deletedImageIndexes, index]);
    }
  };

  const handleSetCoverImage = (index: number) => {
    const allImages = [...images, ...uploadedFiles];
    const newCoverImage = allImages[index];
    const updatedImages = [
      newCoverImage,
      ...allImages.filter((_, i) => i !== index),
    ];
    setImages(updatedImages.filter((_, i) => i < images.length));
    setUploadedFiles(updatedImages.filter((_, i) => i >= images.length));
  };

  const handleUploadClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.multiple = true;
    fileInput.click();
    const fd = new FormData();

    fileInput.onchange = async (event: any) => {
      const files = Array.from(event.target.files) as any[];
      if (files.length > 5) {
        showToast(
          "Please you can only select a maximum of 5 images",
          "error"
        );
        return;
      }
      files.forEach((image: Blob) => {
        fd.append(`media`, image, "media");
      });
      // fd.append("media", event.target.files[0], "media");
      // @ts-ignore
      const res = (await dispatch(uploadImagesMultiple(fd))) as string[];
      if (res) {
        setImages((prevUploadedFiles) => [...prevUploadedFiles, ...res]);
        setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, ...res]);
      }
      return;
    };
  };

  const handleSaveClick = async () => {
    console.log("delete", uploadedFiles);
    // return;
    const res = await dispatch(
      // @ts-ignore
      updateProperty({
        id: activeListing.id,
        // @ts-ignore
        media_urls: uploadedFiles,
      })
    );

    if (res) {
      // @ts-ignore
      dispatch(fetchSingleProperty(activeListing.id));
      setEditMode(false);
    }
  };

  return (
    <div>
      <Modal className="bg-transparent" open={modalOpen} onClose={closeModal}>
        <div className="relative rounded-lg overflow-hidden max-w-[20rem] h-fit md:max-w-[65rem] w-fit md:h-[35rem]">
          <button
            onClick={closeModal}
            className="absolute top-0 right-0 m-2 text-primary500 text-2xl font-bold"
          >
            <IoIosCloseCircle className="text-4xl" />
          </button>
          <img
            src={modalImage}
            alt="Expanded view"
            className="w-full h-full object-contain object-top"
          />
        </div>
      </Modal>

      {editMode ? (
        <div className="h-fit text-gray1000 bg-gray100 dark:bg-[#141414] dark:border-gray800 dark:text-gray100 border border-gray400 rounded-xl p-4 flex flex-col gap-1">
          <div className="flex justify-between items-center mb-4">
            <span className="text-lg font-semibold">
              {editable ? "Add Photos" : "Photos"} ({images?.length})
            </span>
            <div>
              {editable ? (
                <div className="flex gap-2 items-center font-bold">
                  <button
                    onClick={handleExitEditMode}
                    className="bg-gray400 dark:bg-gray800 h-11 px-4 rounded-full"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSaveClick}
                    className="bg-primary500 text-white h-11 px-4 rounded-full"
                  >
                    Save
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleExitEditMode}
                  className="bg-gray400 dark:bg-gray800 h-10 px-4 rounded-full"
                >
                  Back
                </button>
              )}
            </div>
          </div>
          <div className="grid md:grid-cols-3 gap-2">
            {[...images]?.map((image, index) => (
              <div
                key={index}
                className={`w-full h-48 rounded-xl overflow-hidden transition duration-200 ease-in-out relative ${
                  deletedImageIndexes.includes(image) ? "grayscale" : ""
                }`}
                onMouseEnter={() => setHoveredImageIndex(index)}
                onMouseLeave={() => setHoveredImageIndex(null)}
              >
                <img
                  src={image}
                  alt=""
                  className="object-cover w-full h-full cursor-pointer"
                  onClick={() => openModal(image)}
                />
                {index === 0 && editable && (
                  <div className="absolute top-2 left-2 bg-gray400 dark:bg-gray800 font-semibold py-1 px-4 rounded-full">
                    Cover
                  </div>
                )}
                <div className="md:block hidden">
                  {hoveredImageIndex === index && editable && (
                    <div className="absolute top-2 right-2 flex text-sm font-semibold gap-1">
                      <button
                        className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out rounded-full h-9 px-4"
                        onClick={() => handleSetCoverImage(index)}
                      >
                        Set as Cover
                      </button>
                      <button
                        className="bg-primary500 hover:bg-primary400 transition-all duration-200 ease-in-out text-white rounded-full h-9 px-4"
                        onClick={() => handleDeleteImage(image)}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
                {/* for mobile */}
                <div className="block md:hidden">
                  <div className="absolute top-2 right-2 flex text-sm font-semibold gap-1">
                    <button
                      className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out rounded-full h-9 px-4"
                      onClick={() => handleSetCoverImage(index)}
                    >
                      Set as Cover
                    </button>
                    <button
                      className="bg-primary500 hover:bg-primary400 transition-all duration-200 ease-in-out text-white rounded-full h-9 px-4"
                      onClick={() => handleDeleteImage(image)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {editable && (
              <Upload onClick={handleUploadClick} loader={isLoading} />
            )}
          </div>
        </div>
      ) : (
        <div className="flex md:flex-row flex-col gap-3 md:h-[26rem]">
          {/* Main view */}
          <div className="md:w-[65%] h-full rounded-lg overflow-hidden">
            {images?.[0] ? (
              <img
                src={images?.[0]}
                alt="Main view"
                className="object-cover w-full h-full cursor-pointer"
                onClick={() => openModal(images?.[0])}
              />
            ) : (
              <div className="flex items-center justify-center w-full h-full bg-gray-200 text-gray-500">
                No image available
              </div>
            )}
          </div>
          {/* Secondary and Tertiary views */}
          <div className="flex flex-row  md:flex-col gap-3 md:w-[35%] h-full">
            {/* Secondary view */}
            <div className="md:h-1/2 h-28 md:w-auto w-1/2 rounded-lg overflow-hidden">
              {images?.[1] ? (
                <img
                  src={images?.[1]}
                  alt="Secondary view"
                  className="object-cover w-full h-full rounded-lg cursor-pointer"
                  onClick={() => openModal(images?.[1])}
                />
              ) : (
                <div className="flex items-center justify-center w-full h-full bg-gray-200 text-gray-500">
                  No image available
                </div>
              )}
            </div>
            {/* Tertiary view */}
            <div className="md:h-1/2 h-28 md:w-auto w-1/2 flex items-center relative overflow-hidden rounded-lg justify-center">
              <button
                onClick={handleEditPhotosClick}
                className="w-full h-full absolute flex items-center justify-center bg-black/60"
              >
                <span className="underline font-semibold text-lg text-white">
                  {editable ? "Edit Photos" : "View Photos"}
                </span>
              </button>
              {images?.[2] ? (
                <img
                  src={images?.[2]}
                  alt="Tertiary view"
                  className="object-cover w-full h-full rounded-lg cursor-pointer"
                  onClick={() => openModal(images?.[2])}
                />
              ) : (
                <div className="flex items-center justify-center w-full h-full bg-gray-200 text-gray-500">
                  No image available
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
