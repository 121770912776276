import React from "react";

const CheckRedIcon = ({...rest}) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    {...rest}
  >
    <path
      d="M14.4758 1.5L5.30912 10.6667L1.14246 6.5"
      stroke="#FF5C5C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default CheckRedIcon;
