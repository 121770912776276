import React, { useState } from "react";
import { TextAreaInput } from "../../../../components/custom/text-input";
import { useNavigate } from "react-router-dom";
import NavigateButton from "./NavigateButton";
import { paths } from "../../../../routes/paths";
import { usePropertyForm } from "../hooks/use-property-form";

const AddShortTitle = () => {
  const navigate = useNavigate();
  const { setPropertyFieldValue, propertyFormValue, propertyCategories } =
    usePropertyForm();
  const addressArr = propertyFormValue.address.split(",");
  const propertytypeselected = propertyCategories.subCategories.filter(
    (item) => item.id === propertyFormValue.sub_category_id
  );

  const [title, setTitle] = useState(
      `${propertyFormValue.bedrooms} Bedroom ${propertytypeselected[0]?.name} in ${
        addressArr[2]
      }, ${
        addressArr[3]
      }`
  );

  return (
    <div className="flex flex-col items-center mx-4 overflow-scroll no-scrollbar">
      <p className="font-display text-3xl lg:text-2xl font-bold text-gray1000 lg:mx-8 lg:text-center xl:text-center mb-8 dark:text-gray200">
        Add a short title
      </p>
      <div className="w-full">
        <TextAreaInput
          placeholder="Enter title"
          label="Short titles work best. Have fun with it – you can always change it later."
          onChange={(val: any) => setTitle(val.target.value)}
          value={title}
          className="dark:bg-gray800 dark:text-gray200"
        />
      </div>
      {/* navigation */}
      <NavigateButton
        onBack={() => navigate(-1)}
        disable={title.length === 0}
        onNext={() => {
          setPropertyFieldValue({
            ...propertyFormValue,
            name: title,
          });
          navigate(`${paths.HOST_LANDING}/property-description`);
        }}
      />
    </div>
  );
};

export default AddShortTitle;
