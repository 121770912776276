import React, { FC } from "react";

interface VerifiedUserProps extends React.SVGProps<SVGSVGElement> {}

const VerifiedUser: FC<VerifiedUserProps> = ({ className, ...rest }) => (
  <svg
    className={`md:w-7 md:h-7 w-4 h-4 ${className || ""}`}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M14.9778 1.27575C15.6078 0.901171 16.3922 0.901171 17.0222 1.27575L19.2136 2.57881C19.5304 2.76715 19.893 2.86432 20.2615 2.85958L22.8109 2.82684C23.5437 2.81742 24.2231 3.20962 24.5813 3.849L25.8277 6.07322C26.0078 6.3947 26.2733 6.66016 26.5947 6.8403L28.819 8.08664C29.4583 8.44492 29.8505 9.12422 29.8411 9.85708L29.8084 12.4065C29.8037 12.775 29.9008 13.1376 30.0892 13.4543L31.3922 15.6458C31.7668 16.2758 31.7668 17.0602 31.3922 17.6901L30.0892 19.8816C29.9008 20.1984 29.8037 20.561 29.8084 20.9295L29.8411 23.4789C29.8505 24.2117 29.4583 24.891 28.819 25.2493L26.5947 26.4956C26.2733 26.6758 26.0078 26.9412 25.8277 27.2627L24.5813 29.4869C24.2231 30.1263 23.5437 30.5185 22.8109 30.5091L20.2615 30.4764C19.893 30.4716 19.5304 30.5688 19.2136 30.7571L17.0222 32.0602C16.3922 32.4348 15.6078 32.4348 14.9778 32.0602L12.7864 30.7571C12.4696 30.5688 12.107 30.4716 11.7385 30.4764L9.18911 30.5091C8.45625 30.5185 7.77695 30.1263 7.41867 29.4869L6.17233 27.2627C5.99219 26.9412 5.72673 26.6758 5.40525 26.4956L3.18103 25.2493C2.54165 24.891 2.14945 24.2117 2.15887 23.4789L2.19161 20.9295C2.19635 20.561 2.09918 20.1984 1.91084 19.8816L0.607783 17.6901C0.233202 17.0602 0.233202 16.2758 0.607783 15.6458L1.91084 13.4543C2.09918 13.1376 2.19635 12.775 2.19161 12.4065L2.15887 9.85708C2.14945 9.12422 2.54165 8.44492 3.18103 8.08664L5.40525 6.8403C5.72673 6.66016 5.99219 6.3947 6.17233 6.07322L7.41867 3.849C7.77695 3.20962 8.45625 2.81742 9.18911 2.82684L11.7385 2.85958C12.107 2.86432 12.4696 2.76715 12.7864 2.57881L14.9778 1.27575Z"
      fill="#2F80ED"
    />
    <path
      d="M22 23.418C22 22.3713 22 21.848 21.8708 21.4221C21.58 20.4633 20.8297 19.713 19.8709 19.4221C19.445 19.293 18.9217 19.293 17.875 19.293H14.125C13.0783 19.293 12.555 19.293 12.1291 19.4221C11.1703 19.713 10.42 20.4633 10.1292 21.4221C10 21.848 10 22.3713 10 23.418M19.375 13.293C19.375 15.1569 17.864 16.668 16 16.668C14.136 16.668 12.625 15.1569 12.625 13.293C12.625 11.429 14.136 9.91797 16 9.91797C17.864 9.91797 19.375 11.429 19.375 13.293Z"
      stroke="#FCFCFC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VerifiedUser;
