import React from "react";
import ArrowRight from "../icons/arrow-right";
import ArrowLeft from "../icons/arrow-left";

interface PaginationProps {
  currentPage: number;
  itemsPerPage?: number;
  totalPages: number;
  onPrevPage: (page: number) => void;
  onNextPage: (page: number) => void;
}

const PaginationTwo: React.FC<PaginationProps> = ({
  currentPage,
  itemsPerPage,
  totalPages,
  onNextPage,
  onPrevPage,
}) => {
  console.log(itemsPerPage);
  return (
    <div className="flex justify-between mt-5 pt-5 border-t border-gray400 dark:border-gray800">
      <button
        onClick={() => onPrevPage(currentPage - 1)}
        disabled={currentPage === 1}
        className={`flex md:text-base text-sm items-center gap-2 text-gray800 dark:text-gray100 font-medium ${
          currentPage === 1 ? "cursor-not-allowed" : ""
        }`}
      >
        <ArrowLeft className="dark:stroke-gray100" />
        Previous
      </button>
      <div className="flex gap-2 items-center">
        {currentPage !== 1 && (
          <button
            className={`md:min-w-10 min-w-5 h-5 md:h-10 text-xs md:text-base flex items-center justify-center rounded-full text-gray800`}
          >
            {1}
          </button>
        )}
        <button
          className={`md:min-w-10 min-w-5 h-5 md:h-10 text-xs md:text-base flex items-center justify-center rounded-full bg-primary100 dark:bg-[#FF3333] text-primary400 dark:text-gray100`}
        >
          {currentPage}
        </button>
        {currentPage !== 1 && (
          <button
            className={`md:min-w-10 min-w-5 h-5 md:h-10 text-xs md:text-base flex items-center justify-center rounded-full text-gray800`}
          >
            {totalPages}
          </button>
        )}
      </div>
      <button
        onClick={() => onNextPage(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`flex md:text-base text-sm items-center gap-2 text-gray800 dark:text-gray100 font-medium ${
          currentPage === totalPages ? "cursor-not-allowed" : ""
        }`}
      >
        Next
        <ArrowRight className="dark:stroke-gray100" />
      </button>
    </div>
  );
};

export default PaginationTwo;
