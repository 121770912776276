import { paths } from "../../../../routes/paths";
import BreadcrumbsTwo from "../../host-dashboard/components/breadcrumb-two";
import Carousel from "../../../../components/custom/carousel";
import { BsChevronRight } from "react-icons/bs";
import Maps from "../../../../assets/images/maps.png";
import { useMemo, useState } from "react";
import { TextAreaInput } from "../../../../components/custom/text-input";
import ReviewStars from "../../../../components/custom/reviewStars";
// import MasterCard from "../../../../assets/images/Mastercard.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useBookingInfo } from "../../../../hooks/use-book-summary";
import moment from "moment";
import MapView from "../../../../components/custom/map-view-location";
import { currencyFormat } from "../../../../utils/helper-functions";
import { FaRegClock } from "react-icons/fa";
import { usePropertyReviews } from "../../../../hooks/use-reviews";
import Whatsapp from "../../../../components/icons/whatsapp";
import Emailicon from "../../../../components/icons/email";
import Phoneicon from "../../../../components/icons/phone";
import Modal from "../../../../components/custom/modal";
import ReviewsCardInfo from "../../../../components/custom/reviews-card-info";

const BookingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoadingBookinginfo, singleBookingInfo } = useBookingInfo({
    bookingId: Number(id),
  });
  const { postPropertyReview } = usePropertyReviews(id);
  const [details, setDetails] = useState("");
  const [rating, setRating] = useState(0);
  const [isToggleActive, setIsToggleActive] = useState(false);
  const [openMsg, setOpenMsg] = useState(false);

  const eadFee = useMemo(() => {
    return (
      Number(singleBookingInfo?.total_amount_paid) -
        Number(singleBookingInfo?.property_rate) *
          Number(singleBookingInfo?.number_of_nights) || 0
    );

    // eslint-disable-next-line
  }, [singleBookingInfo]);
  const handleSubmitreviews = () => {
    postPropertyReview({
      property_id: Number(singleBookingInfo?.property_json?.id),
      comment: details,
      experience: "",
      rating: rating,
      booking_id: Number(id),
    });
  };

  const openEmail = () =>
    (window.location.href = `mailto:${singleBookingInfo?.property_json?.contact_email}`);

  const handleToggle = () => {
    setIsToggleActive(!isToggleActive);
  };

  return (
    <div>
      <div className="mb-10">
        <BreadcrumbsTwo
          name="Reservation Details"
          path={`${paths.GUEST}/booking-details/${id}`}
        />
        <hr className="border-gray400 dark:border-gray800  my-3" />
        <div className="grid md:grid-cols-2 gap-2 font-display">
          {/* left */}
          <div className="flex flex-col gap-2 bg-gray100 dark:bg-transparent border border-gray400 dark:border-gray800 text-gray1000 dark:text-gray100 p-3 rounded-2xl">
            <Carousel
              // @ts-ignore
              slides={
                singleBookingInfo?.property_json?.property_media?.map(
                  (item) => ({
                    url: item.url,
                    title: item.url,
                  })
                ) || []
              }
              classNameImg="rounded-xl object-cover w-full h-64"
            />{" "}
            <div className="flex items-center gap-2">
              {" "}
              <span className="text-lg font-bold">
                {singleBookingInfo?.property_json?.name}
              </span>
              {singleBookingInfo?.property_json?.is_verified && (
                <svg
                  className="md:w-7 md:h-7 w-4 h-4 "
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.9778 1.27575C15.6078 0.901171 16.3922 0.901171 17.0222 1.27575L19.2136 2.57881C19.5304 2.76715 19.893 2.86432 20.2615 2.85958L22.8109 2.82684C23.5437 2.81742 24.2231 3.20962 24.5813 3.849L25.8277 6.07322C26.0078 6.3947 26.2733 6.66016 26.5947 6.8403L28.819 8.08664C29.4583 8.44492 29.8505 9.12422 29.8411 9.85708L29.8084 12.4065C29.8037 12.775 29.9008 13.1376 30.0892 13.4543L31.3922 15.6458C31.7668 16.2758 31.7668 17.0602 31.3922 17.6901L30.0892 19.8816C29.9008 20.1984 29.8037 20.561 29.8084 20.9295L29.8411 23.4789C29.8505 24.2117 29.4583 24.891 28.819 25.2493L26.5947 26.4956C26.2733 26.6758 26.0078 26.9412 25.8277 27.2627L24.5813 29.4869C24.2231 30.1263 23.5437 30.5185 22.8109 30.5091L20.2615 30.4764C19.893 30.4716 19.5304 30.5688 19.2136 30.7571L17.0222 32.0602C16.3922 32.4348 15.6078 32.4348 14.9778 32.0602L12.7864 30.7571C12.4696 30.5688 12.107 30.4716 11.7385 30.4764L9.18911 30.5091C8.45625 30.5185 7.77695 30.1263 7.41867 29.4869L6.17233 27.2627C5.99219 26.9412 5.72673 26.6758 5.40525 26.4956L3.18103 25.2493C2.54165 24.891 2.14945 24.2117 2.15887 23.4789L2.19161 20.9295C2.19635 20.561 2.09918 20.1984 1.91084 19.8816L0.607783 17.6901C0.233202 17.0602 0.233202 16.2758 0.607783 15.6458L1.91084 13.4543C2.09918 13.1376 2.19635 12.775 2.19161 12.4065L2.15887 9.85708C2.14945 9.12422 2.54165 8.44492 3.18103 8.08664L5.40525 6.8403C5.72673 6.66016 5.99219 6.3947 6.17233 6.07322L7.41867 3.849C7.77695 3.20962 8.45625 2.81742 9.18911 2.82684L11.7385 2.85958C12.107 2.86432 12.4696 2.76715 12.7864 2.57881L14.9778 1.27575Z"
                    fill="#2F80ED"
                  ></path>
                  <path
                    d="M22 20.7773V15.954C22 15.5711 22 15.3797 21.9506 15.2033C21.9069 15.0472 21.8351 14.9003 21.7386 14.7699C21.6298 14.6227 21.4787 14.5051 21.1764 14.2701L21.1764 14.2701L16.6549 10.7533C16.4207 10.5711 16.3035 10.4801 16.1742 10.445C16.0601 10.4142 15.9399 10.4142 15.8258 10.445C15.6965 10.4801 15.5793 10.5711 15.3451 10.7533L10.8236 14.2701L10.8236 14.2701C10.5213 14.5051 10.3702 14.6227 10.2614 14.7699C10.1649 14.9003 10.0931 15.0472 10.0494 15.2033C10 15.3797 10 15.5711 10 15.954V20.7773C10 21.524 10 21.8974 10.1453 22.1826C10.2732 22.4335 10.4771 22.6375 10.728 22.7653C11.0132 22.9106 11.3866 22.9106 12.1333 22.9106H19.8667C20.6134 22.9106 20.9868 22.9106 21.272 22.7653C21.5229 22.6375 21.7268 22.4335 21.8547 22.1826C22 21.8974 22 21.524 22 20.7773Z"
                    stroke="#FCFCFC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              )}
            </div>
            {/* <div className="flex items-center gap-1 text-sm">
              <FaStar className="text-amber-500 text-base" />
              <span className="text-gray800 font-semibold">
                4.50 - 4 reviews
              </span>
            </div> */}
            <hr className="border-gray400 my-1 dark:border-gray800" />
            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-2 text-sm">
                Checkin From
                {isLoadingBookinginfo ? (
                  <div className="animate-pulse">
                    <div className="h-5 w-20 bg-gray600 rounded"></div>
                  </div>
                ) : (
                  <span className="font-bold text-lg md:text-xl text-gray900 dark:text-gray200">
                    {moment(singleBookingInfo?.check_in_date).format("D/MM/YY")}
                  </span>
                )}
                {moment(singleBookingInfo?.check_in_date).format("dddd")}{" "}
                {singleBookingInfo?.property_json?.check_in_time}
              </div>
              <BsChevronRight className="text-gray700 text-3xl" />
              <div className="flex flex-col gap-2 text-sm">
                Check out
                {isLoadingBookinginfo ? (
                  <div className="animate-pulse">
                    <div className="h-5 w-20 bg-gray600 rounded"></div>
                  </div>
                ) : (
                  <span className="font-bold text-lg md:text-xl text-gray900 dark:text-gray200">
                    {moment(singleBookingInfo?.check_out_date).format(
                      "D/MM/YY"
                    )}
                  </span>
                )}
                {moment(singleBookingInfo?.check_out_date).format("dddd")}{" "}
                {singleBookingInfo?.property_json?.check_out_time}
              </div>
            </div>
            <div className="border border-gray400 rounded-xl p-3 my-4 dark:border-gray800">
              {singleBookingInfo?.approval_status !== "pending" ? (
                <div>
                  <p className="text-base font-bold font-display dark:text-gray200">
                    Location
                  </p>
                  <span className="text-xs text-gray1000 dark:text-gray100">
                    {singleBookingInfo?.property_json?.address}
                  </span>
                  <div className="mt-1">
                    {singleBookingInfo?.property_json?.latitude &&
                    singleBookingInfo?.property_json?.longitude ? (
                      <MapView
                        latitude={singleBookingInfo?.property_json?.latitude}
                        longitude={singleBookingInfo?.property_json?.longitude}
                      />
                    ) : (
                      <img src={Maps} alt="" />
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-2 items-center text-center">
                  <FaRegClock className="text-lg text-gray800 dark:text-gray200" />
                  <span className="font-bold text-sm">
                    Your reservation pending confirmation
                  </span>
                  <span className="text-sm">
                    Your address and host information will appear here
                  </span>
                  <span className="text-xs">
                    Booking ID: {singleBookingInfo?.booking_reference}
                  </span>
                </div>
              )}
            </div>
            <div className="flex flex-col">
              {singleBookingInfo?.property_json &&
                singleBookingInfo?.property_json?.rules.length > 0 && (
                  <p className="text-base font-bold font-display dark:text-gray200">
                    Ground Rules
                  </p>
                )}
              {singleBookingInfo?.property_json?.rules.map((item) => (
                <div className="flex items-center gap-1 text-sm">
                  <span>{item}</span>{" "}
                  <span className="text-gray400 text-3xl">•</span>
                </div>
              ))}
            </div>
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center p-2 bg-gray300 dark:bg-gray900 h-14 w-14 rounded-full">
                <img
                  src={
                    singleBookingInfo?.host?.profile_pic_url ||
                    "https://media.proprofs.com/images/QM/user_images/2503852/-Type-Of-Person-.webp"
                  }
                  className="w-full h-full object-cover rounded-full"
                  alt=""
                />
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-base font-bold font-display dark:text-gray200 flex gap-2 text-gray800">
                  Hosted by {singleBookingInfo?.host?.first_name || ""}
                  <span className="font-normal">
                    Since{" "}
                    {moment(singleBookingInfo?.host.created_at).format("YYYY")}
                  </span>
                </p>
                {/* <div className="flex items-center gap-1 text-sm">
                  <FaStar className="text-amber-500 text-base" />
                  <span className="text-gray800 font-semibold">
                    4.50 - 4 reviews
                  </span>
                </div> */}
              </div>
            </div>
            {singleBookingInfo?.approval_status !== "pending" ? (
              <>
                <div
                  className={`flex flex-col gap-3 transition-all duration-300 ease-in-out ${
                    isToggleActive ? "scale-100" : "scale-0 h-0"
                  }`}
                >
                  <Link
                    className="flex items-center gap-2 text-base"
                    to="#"
                    onClick={openEmail}
                  >
                    <Emailicon />
                    {singleBookingInfo?.property_json?.contact_email}
                  </Link>
                  <Link
                    className="flex items-center gap-2 text-base"
                    to={`tel:${singleBookingInfo?.property_json?.contact_phone_number}`}
                  >
                    <Phoneicon />
                    {singleBookingInfo?.property_json?.contact_phone_number}
                  </Link>
                  <Link
                    className="flex items-center gap-2 text-base"
                    to={`https://api.whatsapp.com/send?phone=${singleBookingInfo?.property_json?.contact_whatsapp_number}`}
                  >
                    <Whatsapp />
                    {singleBookingInfo?.property_json?.contact_whatsapp_number}
                  </Link>
                </div>
                <button
                  onClick={handleToggle}
                  className="bg-primary500 cursor-pointer text-nowrap hover:bg-primary400 transition-all duration-200 ease-in-out my-3 text-white rounded-full flex font-semibold items-center justify-center text-sm p-3 gap-2"
                >
                  Contact Host
                </button>
              </>
            ) : null}
          </div>
          {/* right */}
          <div className="flex flex-col h-fit gap-2">
            <div className="flex flex-col h-fit gap-3 bg-gray100 dark:bg-eadBlack border border-gray400 dark:border-gray800 text-gray1000 dark:text-gray100 p-3 rounded-2xl">
              <p className="text-base font-bold font-display dark:text-gray200">
                Manage
              </p>
              <div className="flex flex-col text-sm">
                <button
                  onClick={() =>
                    navigate(
                      `${paths.VIEW_PROPERTY}/${singleBookingInfo?.property_json?.slug}`
                    )
                  }
                  className="flex items-center justify-between border-t py-3 border-gray400 dark:border-gray800 "
                >
                  Rebook
                  <BsChevronRight />
                </button>{" "}
                {/* <button className="flex items-center justify-between border-t py-3 border-gray400 dark:border-gray800 ">
                  Cancel reservation
                  <BsChevronRight />
                </button>{" "} */}
                <button
                  onClick={() => setOpenMsg(true)}
                  className="flex items-center justify-between border-t py-3 border-gray400 dark:border-gray800 "
                >
                  Contact support
                  <BsChevronRight />
                </button>
              </div>
            </div>
            {singleBookingInfo?.status === "reserved" &&
            singleBookingInfo?.reviews &&
            singleBookingInfo?.reviews?.length === 0 && (
              <div className="flex flex-col h-fit gap-3 bg-gray100 dark:bg-eadBlack border border-gray400 dark:border-gray800 text-gray1000 dark:text-gray100 p-3 rounded-2xl">
                <p className="text-base font-bold font-display dark:text-gray200">
                  Write a review
                </p>
                <hr className="border-gray400 dark:border-gray800 my-0.5" />
                <ReviewStars setRate={(e) => setRating(e)} />
                <TextAreaInput
                  placeholder="Enter review"
                  onChange={(val: any) => setDetails(val.target.value)}
                  value={details}
                  className="bg-transparent"
                />{" "}
                <button
                  onClick={handleSubmitreviews}
                  className="bg-gray400 cursor-pointer text-nowrap hover:bg-gray500 transition-all duration-200 ease-in-out my-3 text-gray1000 rounded-full flex font-bold items-center justify-center text-sm p-3 gap-2"
                >
                  Submit review
                </button>
              </div>
            )}
            {(singleBookingInfo?.reviews || [])?.map((item) => (
              <ReviewsCardInfo
                key={item.id}
                name={`${singleBookingInfo?.guest?.first_name} ${singleBookingInfo?.guest?.last_name}`}
                review={item.comment}
                location=""
                date={item?.created_at}
                pic={item.user?.profile_pic_url || ""}
              />
            ))}
            <div className="flex flex-col h-fit gap-3 bg-gray100 dark:bg-eadBlack border border-gray400 dark:border-gray800 text-gray1000 dark:text-gray100 p-3 rounded-2xl">
              <p className="text-base font-bold font-display dark:text-gray200">
                Payment
              </p>
              <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                {isLoadingBookinginfo ? (
                  <div className="animate-pulse">
                    <div className="h-5 w-20 bg-gray600 rounded"></div>
                  </div>
                ) : (
                  <span className="text-sm">
                    {currencyFormat(singleBookingInfo?.property_rate || 0)} x
                    {singleBookingInfo?.number_of_nights} night
                  </span>
                )}
                {isLoadingBookinginfo ? (
                  <div className="animate-pulse">
                    <div className="h-5 w-20 bg-gray600 rounded"></div>
                  </div>
                ) : (
                  <span className="text-base font-medium">
                    {currencyFormat(singleBookingInfo?.property_rate || 0)}
                  </span>
                )}
              </div>
              <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                <span className="text-sm">EAD Booking Fee</span>
                {isLoadingBookinginfo ? (
                  <div className="animate-pulse">
                    <div className="h-5 w-20 bg-gray600 rounded"></div>
                  </div>
                ) : (
                  <span className="text-base font-medium">
                    {currencyFormat(eadFee || 0)}
                  </span>
                )}
              </div>
              {/* <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                <span className="text-sm">VAT</span>
                <span className="text-base font-medium">₦200</span>
              </div> */}
              <hr className="border-gray400  dark:border-gray800" />
              <div className="flex justify-between items-center text-gray1000 dark:text-gray100 font-bold">
                <span className="text-sm">Total</span>

                {isLoadingBookinginfo ? (
                  <div className="animate-pulse">
                    <div className="h-5 w-20 bg-gray600 rounded"></div>
                  </div>
                ) : (
                  <span className="text-lg">
                    {currencyFormat(singleBookingInfo?.total_amount_paid || 0)}
                  </span>
                )}
              </div>
              <hr className="border-gray400  dark:border-gray800" />
              {/* <div className="flex justify-between items-center text-gray1000 dark:text-gray100 font-bold">
                <span className="flex items-center text-sm gap-1 font-normal">
                  <img alt="masterCard" src={MasterCard} />
                  Debit card (***1234)
                </span>{" "}
                {isLoadingBookinginfo ? (
                  <div className="animate-pulse">
                    <div className="h-5 w-20 bg-gray600 rounded"></div>
                  </div>
                ) : (
                  <span className="text-base font-medium">
                    {currencyFormat(singleBookingInfo?.total_amount_paid || 0)}
                  </span>
                )}
              </div> */}
              <button className="bg-gray400 cursor-pointer text-nowrap hover:bg-gray500 transition-all duration-200 ease-in-out my-3 text-gray1000 rounded-full flex font-bold items-center justify-center text-sm p-3 gap-2">
                Get receipt
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openMsg}
        onClose={() => {
          setOpenMsg(false);
        }}
        className="lg:w-1/3"
      >
        <div className="p-4 rounded-xl w-full">
          <p className="text-base font-semibold text-gray1000 dark:text-gray200 mb-3">
            Contact support
          </p>
          <p className="text-base font-light text-gray1000 dark:text-gray200 mb-3">
            Please contact EAD Shortlets support 08170700011 08170400011 or
            hello@eadshortlets.com to change your bank details.
          </p>
          <div className="w-full flex justify-center">
            <button
              onClick={() => setOpenMsg(false)}
              className={`flex cursor-pointer text-xs font-display font-semibold p-2 px-4 rounded-full shadow bg-primary500 items-center text-eadWhite transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              okay
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BookingDetails;
