import React, { FC } from "react";

interface VerifiedHouseProps extends React.SVGProps<SVGSVGElement> {}

const VerifiedHouse: FC<VerifiedHouseProps> = ({ className, ...rest }) => (
  <svg
    className={`md:w-7 md:h-7 w-4 h-4 ${className || ""}`}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9778 1.27575C15.6078 0.901171 16.3922 0.901171 17.0222 1.27575L19.2136 2.57881C19.5304 2.76715 19.893 2.86432 20.2615 2.85958L22.8109 2.82684C23.5437 2.81742 24.2231 3.20962 24.5813 3.849L25.8277 6.07322C26.0078 6.3947 26.2733 6.66016 26.5947 6.8403L28.819 8.08664C29.4583 8.44492 29.8505 9.12422 29.8411 9.85708L29.8084 12.4065C29.8037 12.775 29.9008 13.1376 30.0892 13.4543L31.3922 15.6458C31.7668 16.2758 31.7668 17.0602 31.3922 17.6901L30.0892 19.8816C29.9008 20.1984 29.8037 20.561 29.8084 20.9295L29.8411 23.4789C29.8505 24.2117 29.4583 24.891 28.819 25.2493L26.5947 26.4956C26.2733 26.6758 26.0078 26.9412 25.8277 27.2627L24.5813 29.4869C24.2231 30.1263 23.5437 30.5185 22.8109 30.5091L20.2615 30.4764C19.893 30.4716 19.5304 30.5688 19.2136 30.7571L17.0222 32.0602C16.3922 32.4348 15.6078 32.4348 14.9778 32.0602L12.7864 30.7571C12.4696 30.5688 12.107 30.4716 11.7385 30.4764L9.18911 30.5091C8.45625 30.5185 7.77695 30.1263 7.41867 29.4869L6.17233 27.2627C5.99219 26.9412 5.72673 26.6758 5.40525 26.4956L3.18103 25.2493C2.54165 24.891 2.14945 24.2117 2.15887 23.4789L2.19161 20.9295C2.19635 20.561 2.09918 20.1984 1.91084 19.8816L0.607783 17.6901C0.233202 17.0602 0.233202 16.2758 0.607783 15.6458L1.91084 13.4543C2.09918 13.1376 2.19635 12.775 2.19161 12.4065L2.15887 9.85708C2.14945 9.12422 2.54165 8.44492 3.18103 8.08664L5.40525 6.8403C5.72673 6.66016 5.99219 6.3947 6.17233 6.07322L7.41867 3.849C7.77695 3.20962 8.45625 2.81742 9.18911 2.82684L11.7385 2.85958C12.107 2.86432 12.4696 2.76715 12.7864 2.57881L14.9778 1.27575Z"
      fill="#2F80ED"
    />
    <path
      d="M22 20.7773V15.954C22 15.5711 22 15.3797 21.9506 15.2033C21.9069 15.0472 21.8351 14.9003 21.7386 14.7699C21.6298 14.6227 21.4787 14.5051 21.1764 14.2701L21.1764 14.2701L16.6549 10.7533C16.4207 10.5711 16.3035 10.4801 16.1742 10.445C16.0601 10.4142 15.9399 10.4142 15.8258 10.445C15.6965 10.4801 15.5793 10.5711 15.3451 10.7533L10.8236 14.2701L10.8236 14.2701C10.5213 14.5051 10.3702 14.6227 10.2614 14.7699C10.1649 14.9003 10.0931 15.0472 10.0494 15.2033C10 15.3797 10 15.5711 10 15.954V20.7773C10 21.524 10 21.8974 10.1453 22.1826C10.2732 22.4335 10.4771 22.6375 10.728 22.7653C11.0132 22.9106 11.3866 22.9106 12.1333 22.9106H19.8667C20.6134 22.9106 20.9868 22.9106 21.272 22.7653C21.5229 22.6375 21.7268 22.4335 21.8547 22.1826C22 21.8974 22 21.524 22 20.7773Z"
      stroke="#FCFCFC"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default VerifiedHouse;
