import { useNavigate } from "react-router-dom";
import { paths } from "../../routes/paths";
import HouseIcon from "../icons/house-guest";
import Whatsapp from "../icons/whatsapp";

const MakeBookingSection = () => {
  const navigate = useNavigate();
  return (
    <div className="relative">
      <div className="w-full self-center text-center font-display py-24 flex flex-col gap-3 md:gap-5 items-center justify-center dark:bg-gray1000">
        <span className="font-bold text-xl lg:text-2xl w-2/3 text-gray1000 dark:text-gray300">
          Live your dreams, book or host a guest today!
        </span>
        <span className="text-gray800 text-sm lg:text-base w-2/3 dark:text-gray300">
          Your dreams are valid and the first step starts with living it with
          comfort.
        </span>
        <div className="flex items-center gap-3 mt-3">
          <button
            onClick={() => navigate(paths.HOST_TYPE)} // Use the combined handler
            className="bg-primary500 text-nowrap hover:bg-primary400 transition-all text-white rounded-full flex font-semibold items-center justify-center text-sm p-3 gap-2"
          >
            <HouseIcon />
            Host guests
          </button>
          <button
            onClick={() => navigate(paths.SEARCH_LIST)}
            className="bg-gray400 text-nowrap hover:bg-gray500 transition-all text-gray1000 rounded-full flex font-semibold items-center justify-center text-sm p-3 gap-2"
          >
            <Whatsapp />
            Make a booking
          </button>
        </div>
      </div>{" "}
    </div>
  );
};

export default MakeBookingSection;
