import { useState } from "react";
import ShortletsViewCard from "../../../../components/custom/shortlet-view-card";
import NavigateButton from "./NavigateButton";
import { useNavigate } from "react-router-dom";
import { usePropertyForm } from "../hooks/use-property-form";
import { paths } from "../../../../routes/paths";
import Modal from "../../../../components/custom/modal";
import BookInspection from "./BookInspection";
import { useDispatch } from "react-redux";
import {
  createProperty,
  updateProperty,
} from "../../../../redux/actions/property";
import { PropertyFormType } from "../../../../redux/types";

type ValuePiece = Date | null;

const ReviewListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyFormValue, resetPropertyDefault } = usePropertyForm();
  const [open, setOpen] = useState(false);
  const [propertyId, setPropertyId] = useState<number | null | undefined>(null);
  const [value, setPickDate] = useState<ValuePiece>(new Date());
  const [toc, setToc] = useState(false);

  const onCreateProperty = async () => {
    // @ts-ignore
    const res = (await dispatch(
      // @ts-ignore
      createProperty({ ...propertyFormValue, inspection_date: null })
    )) as PropertyFormType;
    if (res) {
      setPropertyId(res.id);
      setOpen(true);
      resetPropertyDefault();
    }
  };

  const onBookInspection = async () => {
    // @ts-ignore
    const res = await dispatch(
      // @ts-ignore
      updateProperty({ id: propertyId, inspection_date: value?.toISOString() })
    );
    if (res) {
      setOpen(false);
      setTimeout(
        () => navigate(paths.HOST_DASHBOARD + "/latest-activity"),
        300
      );
    }
  };

  return (
    <div className="flex flex-col items-center mx-4 overflow-scroll no-scrollbar">
      <p className="font-display text-xl lg:text-2xl font-bold text-gray1000 lg:mx-8 lg:text-center xl:text-center mb-4 mt-4">
        All done, review your listing.
      </p>
      <div className="container w-full flex flex-col items-center justify-center gap-4">
        <div className="border rounded-lg px-4 border-gray400 dark:border-gray800">
          <ShortletsViewCard
            img={propertyFormValue.media_urls[0]}
            price={Number(propertyFormValue.price)}
            duration="per night"
            title={propertyFormValue.name}
            desc={propertyFormValue.description}
            location={propertyFormValue.address}
            property={propertyFormValue}
          />
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            className="accent-primary500"
            name="amenities"
            id="amenities"
            onChange={() => setToc((prev) => !prev)}
            checked={toc}
          />
          <p className="font-display text-xs dark:text-gray200">
            I agree with the terms and conditions
          </p>
        </div>
      </div>
      <NavigateButton
        onBack={() => navigate(-1)}
        onNext={onCreateProperty}
        nextBtn="Finish"
        disable={!toc}
      />
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(
            () => navigate(paths.HOST_DASHBOARD + "/latest-activity"),
            300
          );
        }}
      >
        <BookInspection
          onCancel={() => {
            setOpen(false);
            setTimeout(
              () => navigate(paths.HOST_DASHBOARD + "/latest-activity"),
              300
            );
          }}
          onConfirm={onBookInspection}
          onChange={(val) => setPickDate(val)}
          value={value}
        />
      </Modal>
    </div>
  );
};

export default ReviewListing;
