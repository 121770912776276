import React from "react";

const Location = ({ ...rest }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...rest}
  >
    <g clip-path="url(#clip0_11_40049)">
      <path
        d="M8.00008 9C9.10465 9 10.0001 8.10457 10.0001 7C10.0001 5.89543 9.10465 5 8.00008 5C6.89551 5 6.00008 5.89543 6.00008 7C6.00008 8.10457 6.89551 9 8.00008 9Z"
        stroke="#A3A3A3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.00008 15.3333C9.33341 12.6667 13.3334 10.9455 13.3334 7.33333C13.3334 4.38781 10.9456 2 8.00008 2C5.05456 2 2.66675 4.38781 2.66675 7.33333C2.66675 10.9455 6.66675 12.6667 8.00008 15.3333Z"
        stroke="#A3A3A3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11_40049">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(6.10352e-05 0.666504)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Location;
