import React from "react";

type Props = {
  color?: string;
  className?: string;
};

const ShareIcon = ({ color, className }: Props) => (
  <svg
    className="md:w-5 md:h-5 w-4 h-4"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className || "dark:stroke-gray100"}
      d="M21 6.66797H17.8C16.1198 6.66797 15.2798 6.66797 14.638 6.99495C14.0735 7.28257 13.6146 7.74151 13.327 8.306C13 8.94773 13 9.78781 13 11.468V12.668M21 6.66797L18 3.66797M21 6.66797L18 9.66797M10 3.66797H7.8C6.11984 3.66797 5.27976 3.66797 4.63803 3.99495C4.07354 4.28257 3.6146 4.74151 3.32698 5.306C3 5.94773 3 6.78781 3 8.46797V16.868C3 18.5481 3 19.3882 3.32698 20.0299C3.6146 20.5944 4.07354 21.0534 4.63803 21.341C5.27976 21.668 6.11984 21.668 7.8 21.668H16.2C17.8802 21.668 18.7202 21.668 19.362 21.341C19.9265 21.0534 20.3854 20.5944 20.673 20.0299C21 19.3882 21 18.5481 21 16.868V14.668"
      stroke={color || "#525252"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShareIcon;
