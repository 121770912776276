import Axios from "axios";
import apiClient from "../network/axios-client";
import { propertyDefaultForm } from "../pages/app/host-onboard/hooks/use-property-form";

const Auth = {
  setToken: (token) => {
    localStorage.setItem("token", token);
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  getToken: () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return null;
    }
    return token;
  },
  getRefreshToken: () => {
    const token = localStorage.getItem("refresh_token");
    if (token) {
      return token;
    }
    return false;
  },
  isAuthenticated: () => {
    const token = localStorage.getItem("token");
    if (token === "null" || token === "undefined" || !token) {
      return false;
    }
    if (token) return true;
    return false;
  },
  setIsEmailVerified: (verified) => {
    localStorage.setItem("isEmailVerified", verified);
  },
  isEmailVerified: () => {
    const verified = localStorage.getItem("isEmailVerified");
    if (
      verified === "null" ||
      verified === "undefined" ||
      !verified ||
      verified === "false"
    ) {
      return false;
    }
    if (verified === "true") return true;

    return false;
  },
  destroyToken: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    localStorage.removeItem("isEmailVerified");
    localStorage.setItem("property", JSON.stringify(propertyDefaultForm));
    delete Axios.defaults.headers.common.Authorization;
    return true;
  },
  setUser: (user) => {
    localStorage.setItem("user", JSON.stringify(user));
  },
  getUser: () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user;
  },
};

export default Auth;
