import React, { useState } from "react";
import { FaXmark } from "react-icons/fa6";
import Plusicon from "../../../../components/icons/plus-icon";
import Minusicon from "../../../../components/icons/minus-icon";
import { TextInput } from "../../../../components/custom/text-input";
import { usePropertyForm } from "../../host-onboard/hooks/use-property-form";

interface Props {
  closeModal: () => void;
  updateFilter: (pa: any) => void;
}

const FilterSection: React.FC<Props> = ({ closeModal, updateFilter }) => {
  const [propertyTypeFilter, setPropertyTypeFilter] = useState<number | string>(
    ""
  );
  const [bedroomCount, setBedroomCount] = useState(1);
  const [maxPrice, setMaxPrice] = useState(10000);
  const [minPrice, setMinPrice] = useState(1000);
  const { propertyCategories } = usePropertyForm();

  return (
    <div className="top-0 right-0 lg:w-[35vw] w-[90vw] bg-eadWhite dark:bg-gray1000  px-6 py-10 text-gray1000 fixed h-screen overflow-scroll">
      <div className="flex items-center justify-between">
        <p className="text-2xl font-display font-bold text-gray1000 dark:text-gray200">
          Filter your result
        </p>
        <div
          onClick={closeModal}
          className="bg-gray500 p-2 rounded-full flex items-center justify-center"
        >
          <FaXmark width="30" />
        </div>
      </div>
      <div className="py-2 flex flex-col justify-between lg:h-full">
        <div>
          <p className="font-display text-sm text-gray1000 dark:text-gray200">
            Property
          </p>
          <div className="flex items-center gap-2 py-3 flex-wrap lg:flex-nowrap">
            <p
              onClick={() => setPropertyTypeFilter("")}
              className={`${
                propertyTypeFilter === ""
                  ? "text-primary500 bg-primary100"
                  : "text-gray1000 dark:text-gray200"
              } p-2 px-3 rounded-md cursor-pointer text-sm `}
            >
              All
            </p>
            {propertyCategories.subCategories.map((item, index) => {
              return (
                <p
                  key={index}
                  onClick={() => setPropertyTypeFilter(item.id)}
                  className={`${
                    propertyTypeFilter === item.id
                      ? "text-primary500 bg-primary100"
                      : "text-gray1000 dark:text-gray200"
                  } p-2 px-3 rounded-md cursor-pointer text-sm `}
                >
                  {item.name}
                </p>
              );
            })}
          </div>
          <div className="py-2">
            <p className="font-display text-sm font-medium dark:text-gray200">
              Bedrooms
            </p>
            <div className="flex gap-2 items-center mt-3">
              <button
                onClick={() =>
                  bedroomCount > 1 && setBedroomCount((prev) => prev - 1)
                }
                className=" p-1  rounded-full border border-gray500 dark:bg-gray1000 dark:border-gray900"
              >
                <Minusicon />
              </button>
              <div className="p-1 px-3 rounded-full border border-gray500 dark:border-gray900">
                <p className="font-display text-base dark:text-gray200">
                  {bedroomCount}
                </p>
              </div>
              <button
                onClick={() => setBedroomCount((prev) => prev + 1)}
                className="p-1 rounded-full border border-gray500 dark:bg-gray1000 dark:border-gray900"
              >
                <Plusicon />
              </button>
            </div>
          </div>
          <div className="py-4 lg:w-auto w-32">
            <p className="font-display text-sm font-medium dark:text-gray200 mb-4">
              Price range
            </p>
            <div className="flex gap-2 items-center">
              <TextInput
                placeholder="Min"
                onChange={(e: any) => setMinPrice(e.target.value)}
                type="number"
                value={minPrice}
              />
              <p className="font-semibold text-gray1000 dark:text-gray200">-</p>
              <TextInput
                placeholder="Max"
                onChange={(e: any) => setMaxPrice(e.target.value)}
                type="number"
                value={maxPrice}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center gap-4 w-full my-12 lg:my-8">
          <button
            onClick={() => {
              updateFilter({
                bedroomCount: null,
                propertyTypeFilter: null,
                minPrice: null,
                maxPrice: null,
              });
              setPropertyTypeFilter("");
              setTimeout(() => closeModal(), 300);
            }}
            className="text-sm text-gray1000 bg-gray500 rounded-full flex items-center p-2 font-semibold"
          >
            Reset filters
          </button>
          <button
            onClick={() => {
              updateFilter({
                bedroomCount,
                propertyTypeFilter,
                maxPrice,
                minPrice,
              });
              closeModal();
            }}
            className="text-sm text-eadWhite bg-primary500 rounded-full flex items-center p-2 font-semibold"
          >
            Update results
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
