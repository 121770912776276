import React, { createContext, useState, ReactNode } from "react";
import { paths } from "../routes/paths";

interface SignInLinkContextType {
  signInLink: string;
  setSignInLink: React.Dispatch<React.SetStateAction<string>>;
}

const defaultContextValue: SignInLinkContextType = {
  signInLink: paths.HOST_DASHBOARD + "/latest-activity",
  setSignInLink: () => {},
};

export const SignInLinkContext =
  createContext<SignInLinkContextType>(defaultContextValue);

interface SignInLinkContextProviderProps {
  children: ReactNode;
}

export const SignInLinkContextProvider: React.FC<
  SignInLinkContextProviderProps
> = ({ children }) => {
  const [signInLink, setSignInLink] = useState<string>(
    defaultContextValue.signInLink
  );

  return (
    <SignInLinkContext.Provider value={{ signInLink, setSignInLink }}>
      {children}
    </SignInLinkContext.Provider>
  );
};
