import HostDashboardLayout from "./components/host-dashboard-layout";
import MakeBookingSection from "../../../components/custom/make-bookings-section";
import { useNavigate, useParams } from "react-router-dom";
import { useBookingInfo } from "../../../hooks/use-book-summary";
import moment from "moment";
import { BsChevronRight } from "react-icons/bs";
import { currencyFormat } from "../../../utils/helper-functions";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import EditIcon from "../../../components/icons/edit";
import ArrowTopRightBlack from "../../../components/icons/arrow-top-right copy";
import Location from "../../../components/icons/location";
import PillsTwo from "../../../components/custom/pills-two";
import { getIconComponent } from "../../../utils/get-icons";
import { BookingStatus } from "../../../utils/static-data";
import Modal from "../../../components/custom/modal";
import { TextAreaInput } from "../../../components/custom/text-input";
import ReviewsCardInfo from "../../../components/custom/reviews-card-info";
import ReviewStars from "../../../components/custom/reviewStars";
import { useGuestReviews } from "../../../hooks/use-reviews";

const Badge = ({ className }: { className?: string }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.3942 1.26644C11.8688 0.984267 12.4597 0.984267 12.9342 1.26644L14.5851 2.24805C14.8237 2.38992 15.0969 2.46312 15.3745 2.45955L17.2949 2.43488C17.847 2.42779 18.3587 2.72324 18.6286 3.20489L19.5675 4.88041C19.7032 5.12258 19.9032 5.32255 20.1453 5.45826L21.8209 6.39713C22.3025 6.66703 22.598 7.17875 22.5909 7.73082L22.5662 9.6513C22.5626 9.92888 22.6358 10.202 22.7777 10.4407L23.7593 12.0915C24.0415 12.5661 24.0415 13.157 23.7593 13.6315L22.7777 15.2824C22.6358 15.521 22.5626 15.7941 22.5662 16.0717L22.5909 17.9922C22.598 18.5443 22.3025 19.056 21.8209 19.3259L20.1453 20.2648C19.9032 20.4005 19.7032 20.6004 19.5675 20.8426L18.6286 22.5181C18.3587 22.9998 17.847 23.2952 17.2949 23.2881L15.3745 23.2635C15.0969 23.2599 14.8237 23.3331 14.5851 23.475L12.9342 24.4566C12.4597 24.7388 11.8688 24.7388 11.3942 24.4566L9.74339 23.475C9.50478 23.3331 9.23162 23.2599 8.95404 23.2635L7.03355 23.2881C6.48149 23.2952 5.96976 22.9998 5.69987 22.5181L4.76099 20.8426C4.62529 20.6004 4.42532 20.4005 4.18314 20.2648L2.50762 19.3259C2.02597 19.056 1.73053 18.5443 1.73762 17.9922L1.76229 16.0717C1.76585 15.7941 1.69266 15.521 1.55078 15.2824L0.569176 13.6315C0.287001 13.157 0.287001 12.5661 0.569176 12.0915L1.55078 10.4407C1.69266 10.202 1.76585 9.92888 1.76229 9.6513L1.73762 7.73082C1.73053 7.17875 2.02597 6.66703 2.50762 6.39713L4.18314 5.45826C4.42532 5.32255 4.62529 5.12258 4.76099 4.88041L5.69987 3.20489C5.96976 2.72324 6.48149 2.42779 7.03355 2.43488L8.95404 2.45955C9.23161 2.46312 9.50478 2.38992 9.74339 2.24805L11.3942 1.26644Z"
      fill="#2F80ED"
    />
    <path
      d="M16.6315 17.8923C16.6315 17.1038 16.6315 16.7096 16.5342 16.3888C16.3151 15.6665 15.7499 15.1013 15.0276 14.8822C14.7068 14.7849 14.3126 14.7849 13.5241 14.7849H10.6992C9.91072 14.7849 9.51649 14.7849 9.1957 14.8822C8.47342 15.1013 7.90821 15.6665 7.68911 16.3888C7.5918 16.7096 7.5918 17.1038 7.5918 17.8923M14.6541 10.2651C14.6541 11.6692 13.5158 12.8075 12.1116 12.8075C10.7075 12.8075 9.56923 11.6692 9.56923 10.2651C9.56923 8.86093 10.7075 7.72266 12.1116 7.72266C13.5158 7.72266 14.6541 8.86093 14.6541 10.2651Z"
      stroke="#FCFCFC"
      stroke-width="1.50661"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const BookingInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    singleBookingInfo,
    approveBooking,
    declineBooking,
    fetchSingleBookingInfo,
  } = useBookingInfo({
    bookingId: Number(id),
  });
  const [active, setActive] = useState("info");
  const [openDecline, setOpenDecline] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [reason, setReason] = useState("");
  const [details, setDetails] = useState("");
  const [rating, setRating] = useState(0);

  const { postGuestReview } = useGuestReviews();

  const PropertyInfoItem = ({
    label,
    value,
  }: {
    label: string;
    value: string;
  }) => (
    <div className="flex items-center justify-between w-full">
      <p className="text-xs">{label}:</p>
      <p className="text-xs font-semibold text-right">{value}</p>
    </div>
  );

  const handleSubmitreviews = async () => {
    await postGuestReview({
      property_id: Number(singleBookingInfo?.property_json?.id),
      experience: "",
      guest_id: Number(singleBookingInfo?.guest?.id),
      comment: details,
      rating: rating,
      booking_id: Number(id),
    });
    fetchSingleBookingInfo(Number(id));
  };

  return (
    <HostDashboardLayout
      crumbs={[{ name: "Booking ID", path: `/booking-info/${id}` }]}
    >
      <hr className="border-gray500 dark:border-gray800 py-2" />
      <div className="md:p-4 p-2 max-w-screen-2xl mx-auto">
        {singleBookingInfo?.status === BookingStatus.awaiting && (
          <div className="flex items-center justify-between my-3">
            <p className="text-xl font-semibold dark:text-gray300">
              This booking requires your confirmation.
            </p>
            <div className="flex items-center gap-2">
              <button
                onClick={() => setOpenDecline(true)}
                className="bg-gray300 rounded-full text-xs flex w-fit items-center font-semibold gap-2 p-2"
              >
                <MdClose size={20} /> Decline
              </button>
              <button
                onClick={() => setOpenApprove(true)}
                className="bg-primary400 text-gray100 rounded-full text-xs flex w-fit items-center font-semibold gap-2 p-2"
              >
                Approve
              </button>
            </div>
          </div>
        )}
        <div className="flex md:flex-row flex-col gap-5 pt-2 font-display text-gray1000">
          <div className="md:w-[32%] w-full flex flex-col gap-3 md:border-r border-gray400 dark:border-gray800 md:pr-5">
            <div className="p-4 border border-gray500 rounded-lg bg-gray200 items-center justify-center flex flex-col mb-3 dark:bg-gray1000 dark:text-gray300">
              {singleBookingInfo?.guest.profile_pic_url ? (
                <img
                  className="w-24 h-24 rounded-full bg-gray500"
                  src={singleBookingInfo.guest?.profile_pic_url}
                  alt="guest"
                />
              ) : (
                <div className="w-24 h-24 rounded-full bg-gray500 flex items-center justify-center relative">
                  <EditIcon />
                  <Badge className="absolute bottom-1 right-1" />
                </div>
              )}
              <div className="p-2">
                <p
                  className={`text-base font-semibold text-center mb-1 ${
                    singleBookingInfo?.guest?.is_verified
                      ? "flex gap-2 items-center"
                      : ""
                  }`}
                >
                  {`${singleBookingInfo?.guest?.first_name} ${singleBookingInfo?.guest?.last_name}`}{" "}
                  {singleBookingInfo?.guest?.is_verified && (
                    <span className="text-xs bg-blue-200 text-blue-700 rounded-full p-1 px-2">
                      Verified
                    </span>
                  )}
                </p>
                <p className="text-sm text-center">Total bookings {0}</p>
                <p
                  onClick={() =>
                    navigate(`/guest-info/${singleBookingInfo?.guest.id}`)
                  }
                  className="text-xs font-semibold text-center p-2 bg-gray400 rounded-full my-2 cursor-pointer flex gap-2 items-center dark:text-gray1000"
                >
                  View guest profile <ArrowTopRightBlack />
                </p>
              </div>
            </div>
            <div className="p-4 border border-gray500 rounded-lg bg-gray200 flex flex-col mb-3 dark:bg-gray1000 dark:text-gray300">
              <p className="text-base font-semibold pb-2">Booking Summary</p>
              <div className="p-2 border border-gray500 rounded-lg bg-gray200 dark:bg-gray1000 flex flex-col gap-3 mb-3">
                <div className="flex gap-2">
                  <img
                    className="w-28 h-16 rounded-lg"
                    src={
                      singleBookingInfo?.property_json?.property_media[0].url ||
                      ""
                    }
                    alt=""
                  />
                  <p className="text-sm font-semibold">
                    {singleBookingInfo?.property_json?.name}
                  </p>
                </div>
                <p className="text-xs flex gap-2">
                  <Location /> {singleBookingInfo?.property_json?.address}
                </p>
                <div className="flex items-center gap-2">
                  <PillsTwo
                    title={`${singleBookingInfo?.property_json?.bathrooms} Bathroom`}
                    icon={getIconComponent("bathroom-icon")}
                  />
                  <PillsTwo
                    title={`${singleBookingInfo?.property_json?.bedrooms} Bedroom`}
                    icon={getIconComponent("room-icon")}
                  />
                </div>
              </div>
              <div className="p-2 border border-gray500 rounded-lg bg-gray200 flex flex-col gap-3 dark:bg-gray1000">
                <div className="flex items-center justify-between w-full">
                  <p className="text-sm">Booking ID:</p>
                  <p className="text-xs font-semibold text-right">
                    {singleBookingInfo?.booking_reference}
                  </p>
                </div>
                <hr className="border-gray500 my-1" />
                <div className="flex items-center justify-between cursor-pointer">
                  <div className="flex flex-col gap-2 text-sm">
                    Checkin From
                    <span className="font-bold text-lg md:text-xl  dark:text-gray200">
                      {moment(singleBookingInfo?.check_in_date).format(
                        "D/MM/YY"
                      )}
                    </span>
                    {moment(singleBookingInfo?.check_in_date).format("dddd")}{" "}
                    {singleBookingInfo?.property_json?.check_in_time}
                  </div>
                  <BsChevronRight className="text-gray700 text-3xl" />
                  <div className="flex flex-col gap-2 text-sm">
                    Check out
                    <span className="font-bold text-lg md:text-xl  dark:text-gray200">
                      {moment(singleBookingInfo?.check_out_date).format(
                        "D/MM/YY"
                      )}
                    </span>
                    {moment(singleBookingInfo?.check_out_date).format("dddd")}{" "}
                    {singleBookingInfo?.property_json?.check_out_time}
                  </div>
                </div>
                <hr className="border-gray500 my-1" />
                <PropertyInfoItem
                  label="Booking type"
                  value={singleBookingInfo?.type || ""}
                />
                <PropertyInfoItem
                  label="Total guest"
                  value={`${singleBookingInfo?.total_guests}`}
                />
                <PropertyInfoItem
                  label="No of nights"
                  value={`${singleBookingInfo?.number_of_nights}`}
                />
              </div>

              <div className="p-2 border border-gray500 rounded-lg bg-gray200 flex flex-col gap-2 my-4 dark:bg-gray1000">
                <div className="flex items-center justify-between w-full">
                  <p className="text-base font-semibold">Total Payout</p>
                  <p className="text-base font-semibold">
                    {currencyFormat(singleBookingInfo?.host_payout || 0)}
                  </p>
                </div>
                <hr className="border-gray500" />
                <p className="text-sm font-semibold">Break down</p>
                <PropertyInfoItem
                  label="Night rate"
                  value={`${currencyFormat(
                    singleBookingInfo?.property_rate || 0
                  )} x ${singleBookingInfo?.number_of_nights}`}
                />
                <PropertyInfoItem
                  label="Total fee"
                  value={`${currencyFormat(
                    singleBookingInfo?.total_amount_paid || 0
                  )}`}
                />
                <div className="flex items-center justify-between w-full">
                  <p className="text-xs">{`EAD service fee`}</p>
                  <p className="text-xs text-gray1000 dark:text-gray200 font-semibold text-right">
                    {`-${currencyFormat(
                      singleBookingInfo?.ead_booking_payout || 0
                    )}`}
                  </p>
                </div>
                <hr className="border-gray500 my-2" />
                <div className="flex items-center justify-between w-full">
                  <p className="text-sm">Status</p>
                  <p className="text-xs text-green-500 bg-gray300 p-1 rounded-full text-right">
                    success
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-[68%] w-full h-fit flex flex-col">
            <div className="flex items-center gap-3">
              <p
                onClick={() => setActive("info")}
                className={`font-display text-sm lg:text-base font-bold cursor-pointer text-gray1000 dark:text-gray200 transition-all duration-200 ease-in-out ${
                  active === "info" ? "" : "text-gray600 dark:text-gray700"
                }`}
              >
                Booking Information
              </p>
              {/* <p
                onClick={() => setActive("details")}
                className={`font-display text-sm lg:text-base font-bold cursor-pointer text-gray1000 dark:text-gray200 transition-all duration-200 ease-in-out ${
                  active === "details" ? "" : "text-gray600 dark:text-gray700"
                }`}
              >
                Property details
              </p> */}
            </div>
            <hr className="border-gray400 dark:border-gray800  my-3" />
            <div className="border border-gray500 rounded-lg p-4 mb-3 dark:text-gray300">
              <p className="font-semibold mb-2 text-lg">Guest information</p>
              <div className="border border-gray500 rounded-lg p-2 flex flex-col gap-3">
                <div className="flex items-center justify-between w-full">
                  <p className="text-base font-semibold">Total Bookings</p>
                  <p className="text-base">{0}</p>
                </div>
                <div className="flex items-center justify-between w-full">
                  <p className="text-base font-semibold">Date joined</p>
                  <p className="text-base">
                    {moment(singleBookingInfo?.guest.created_at).format(
                      "MMMM YYYY"
                    )}
                  </p>
                </div>
                <hr className="border-gray400 dark:border-gray800  my-1" />
                <p className="text-base font-semibold">Verification status:</p>
                <div className="flex items-center gap-2">
                  <p className="text-sm">Phone verification</p>
                  <p
                    className={`text-xs ${
                      singleBookingInfo?.guest.is_verified
                        ? "text-green-500"
                        : "text-primary500"
                    } p-1 rounded-full bg-gray300`}
                  >
                    {singleBookingInfo?.guest.is_verified
                      ? "Verified"
                      : "Unverified"}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <p className="text-sm">Email verification</p>
                  <p
                    className={`text-xs ${
                      singleBookingInfo?.guest.is_email_verified
                        ? "text-green-500"
                        : "text-primary500"
                    } p-1 rounded-full bg-gray300`}
                  >
                    {singleBookingInfo?.guest.is_email_verified
                      ? "Verified"
                      : "Unverified"}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <p className="text-sm">Document verification</p>
                  <p
                    className={`text-xs ${
                      singleBookingInfo?.guest?.document_number
                        ? "text-green-500"
                        : "text-primary500"
                    } p-1 rounded-full bg-gray300`}
                  >
                    {singleBookingInfo?.guest?.document_number
                      ? "Verified"
                      : "Unverified"}
                  </p>
                </div>
                {singleBookingInfo?.type === "friend" && (
                  <hr className="border-gray400 dark:border-gray800  my-1" />
                )}
                {singleBookingInfo?.type === "friend" && (
                  <div className="flex flex-col gap-3">
                    <p className="text-base font-medium my-2">
                      Booking for someone else:
                    </p>
                    <div className="flex items-center justify-between gap-2">
                      <p className="text-sm">Name</p>
                      <p className="text-sm font-medium">
                        {`${singleBookingInfo?.guest_info.first_name} ${singleBookingInfo?.guest_info.last_name}`}
                      </p>
                    </div>
                    <div className="flex items-center  justify-between gap-2">
                      <p className="text-sm">Email address</p>
                      <p className="text-sm font-medium">
                        {`${singleBookingInfo?.guest_info.email}`}
                      </p>
                    </div>
                    <div className="flex items-center  justify-between gap-2">
                      <p className="text-sm">Phone number</p>
                      <p className="text-sm font-medium">
                        {`${singleBookingInfo?.guest_info.phone}`}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="border border-gray500 rounded-lg p-4 mb-3 dark:text-gray-300">
              <p className="font-semibold mb-2">Additional comment</p>
              <div className="border border-gray500 rounded-lg flex flex-col gap-3 bg-gray300 dark:bg-gray800 p-4">
                <p className="text-xs">{singleBookingInfo?.comment || ""}</p>
              </div>
            </div>
            <div className="border border-gray500 rounded-lg p-4 mb-3 dark:text-gray-300">
              <p className="font-semibold mb-2">Reviews</p>
              <div className="flex flex-col gap-3 p-1">
                {(singleBookingInfo?.reviews || [])?.map((item) => {
                  if (item.user_id === singleBookingInfo?.guest.id && item.reviewer_role === "guest") {
                    return (
                      <ReviewsCardInfo
                        key={item.id}
                        name={`${singleBookingInfo?.guest?.first_name} ${singleBookingInfo?.guest?.last_name}`}
                        review={item.comment}
                        location=""
                        date={item?.created_at}
                        pic={singleBookingInfo.guest?.profile_pic_url || ""}
                      />
                    );
                  }
                  return []
                })}
                {(singleBookingInfo?.reviews || [])?.map((item) => {
                  if (item.host_id === singleBookingInfo?.host.id && item.reviewer_role === "host") {
                    return (
                      <ReviewsCardInfo
                        key={item.id}
                        name={`${singleBookingInfo?.host?.first_name} ${singleBookingInfo?.host?.last_name}`}
                        review={item.comment}
                        location=""
                        date={item?.created_at}
                        pic={singleBookingInfo.host?.profile_pic_url || ""}
                      />
                    );
                  }
                  return []
                })}

                {(singleBookingInfo?.reviews || [])?.length !== 0 &&
                singleBookingInfo?.reviews?.filter(
                  (item) => item.reviewer_role === "host"
                ) &&
                singleBookingInfo?.reviews?.filter(
                  (item) => item.reviewer_role === "host"
                )?.length === 0 ? (
                  <div className="flex flex-col h-fit gap-3 p-3 rounded-2xl">
                    <p className="text-base font-bold font-display dark:text-gray200">
                      Write a review
                    </p>
                    <hr className="border-gray400 dark:border-gray800 my-0.5" />
                    <ReviewStars setRate={(e) => setRating(e)} />
                    <TextAreaInput
                      placeholder="Enter review"
                      onChange={(val: any) => setDetails(val.target.value)}
                      value={details}
                      className="bg-transparent"
                    />{" "}
                    <button
                      onClick={handleSubmitreviews}
                      className="bg-gray400 cursor-pointer text-nowrap hover:bg-gray500 transition-all duration-200 ease-in-out my-3 text-gray1000 rounded-full flex font-bold items-center justify-center text-sm p-3 gap-2"
                    >
                      Submit review
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openApprove}
        onClose={() => {
          setOpenApprove(false);
        }}
        className="lg:w-1/3"
      >
        <div className="p-4 rounded-xl w-full">
          <p className="text-base font-semibold text-gray1000 dark:text-gray200 mb-3">
            Are you sure?
          </p>
          <p className="text-base font-light text-gray1000 dark:text-gray200 mb-3">
            Are you sure you want to approve this booking
          </p>
          <div className="w-full flex justify-center items-center gap-6">
            <button
              onClick={() => {
                setOpenApprove(false);
              }}
              className={`flex cursor-pointer text-xs font-display font-semibold p-2 px-4 rounded-full shadow bg-gray500 items-center text-gray1000 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setOpenApprove(false);
                approveBooking();
              }}
              className={`flex cursor-pointer text-xs font-display font-semibold p-2 px-4 rounded-full shadow bg-primary500 items-center text-eadWhite transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              Approve
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={openDecline}
        onClose={() => {
          setOpenDecline(false);
        }}
        className="lg:w-1/3"
      >
        <div className="p-4 rounded-xl w-full">
          <p className="text-base font-semibold text-gray1000 dark:text-gray200 mb-3">
            Are you sure you want to decline this booking?
          </p>
          <div>
            <TextAreaInput
              label="State your reason"
              onChange={(e) => setReason(e.target.value)}
              className="my-3 w-full lg:w-full"
            />
          </div>
          <div className="w-full flex justify-center items-center gap-6">
            <button
              onClick={() => {
                setOpenDecline(false);
              }}
              className={`flex cursor-pointer text-xs font-display font-semibold p-2 px-4 rounded-full shadow bg-gray500 items-center text-gray1000 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setOpenDecline(false);
                declineBooking(reason);
              }}
              className={`flex cursor-pointer text-xs font-display font-semibold p-2 px-4 rounded-full shadow bg-primary500 items-center text-eadWhite transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              Decline
            </button>
          </div>
        </div>
      </Modal>
      <MakeBookingSection />
    </HostDashboardLayout>
  );
};

export default BookingInfo;
