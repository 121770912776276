import React, { useState, useEffect } from "react";
import EditIcon from "../../../../components/icons/edit";
import { PropertyType } from "../../../../redux/types";
import DateInspectionItem from "./date-inspection-item";
import { useDispatch } from "react-redux";
import {
  fetchSingleProperty,
  updateProperty,
} from "../../../../redux/actions/property";
import moment from "moment";

interface PropertyAvalabilityProps {
  activeListing: PropertyType;
}

const PropertyAvalability: React.FC<PropertyAvalabilityProps> = ({
  activeListing,
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [check_in_time, setCheckin] = useState<string>("");
  const [check_out_time, setCheckout] = useState<string>("");

  const [availableDays, setAvailableDays] = useState({
    monday_availability: false,
    tuesday_availability: false,
    wednesday_availability: false,
    thursday_availability: false,
    friday_availability: false,
    saturday_availability: false,
    sunday_availability: false,
  });

  // useEffect(() => {
  //   if (dates.length > 0 && dates[selectedDateIndex]) {
  //     const [checkIn, checkOut] = dates[selectedDateIndex].time.split(" - ");
  //     setSelectedCheckIn(checkIn);
  //     setSelectedCheckOut(checkOut);
  //   }
  // }, [selectedDateIndex, dates]);

  useEffect(() => {
    setAvailableDays({
      ...availableDays,
      monday_availability: activeListing.monday_availability,
      tuesday_availability: activeListing.tuesday_availability,
      wednesday_availability: activeListing.wednesday_availability,
      thursday_availability: activeListing.thursday_availability,
      friday_availability: activeListing.friday_availability,
      saturday_availability: activeListing.saturday_availability,
      sunday_availability: activeListing.sunday_availability,
    });
    setCheckin(activeListing.check_in_time);
    setCheckout(activeListing.check_out_time);

    // eslint-disable-next-line
  }, [activeListing]);

  const handleSaveClick = async () => {
    const res = await dispatch(
      // @ts-ignore
      updateProperty({
        id: activeListing.id,
        ...availableDays,
        check_in_time: check_in_time,
        check_out_time: check_out_time,
      })
    );

    if (res) {
      // @ts-ignore
      dispatch(fetchSingleProperty(activeListing.id));
      setEditMode(false);
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  return (
    <div className="h-fit w-full text-gray900 dark:text-gray100 border border-gray400 dark:border-gray800 rounded-3xl p-4 flex flex-col gap-2">
      <div className="flex flex-wrap md:gap-0 gap-3 items-center justify-between mb-5">
        <span className="font-bold">When will this property be available?</span>
        {editMode ? (
          <div className="flex gap-2">
            <button
              onClick={handleCancelClick}
              className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out h-10 flex items-center gap-2 rounded-full font-bold text-sm px-4"
            >
              Cancel
            </button>
            <button
              onClick={handleSaveClick}
              className="bg-primary500 hover:bg-primary600 transition-all duration-200 ease-in-out h-10 flex items-center gap-2 rounded-full font-bold text-sm px-4 text-white"
            >
              Save
            </button>
          </div>
        ) : (
          <button
            onClick={() => setEditMode(true)}
            className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out flex items-center gap-2 rounded-full font-bold text-sm h-9 px-3"
          >
            Edit
            <EditIcon />
          </button>
        )}
      </div>
      <p className="text-sm font-bold">Available on</p>
      <div
        className={`h-fit text-gray900 dark:text-gray100 flex flex-col gap-2 ${
          editMode
            ? "border border-gray400 dark:border-gray800 rounded-3xl p-4 md:w-[30rem]"
            : ""
        }`}
      >
        {editMode ? (
          <div className="flex flex-col gap-1">
            <div className="mb-4 grid grid-cols-2 gap-2">
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="text-sm text-gray1000 font-medium">
                  Check in
                </label>
                <div className="border rounded-full p-1 border-gray400 dark:border-gray800 ">
                  <input
                    aria-label="Time"
                    type="time"
                    onChange={(e) => setCheckin(e.target.value)}
                    value={check_in_time}
                    className="text-gray1000 dark:gray200 w-full outline-none focus:outline-none bg-transparent"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="text-sm text-gray1000 font-medium">
                  Check out
                </label>
                <div className="border rounded-full p-1 border-gray400 dark:border-gray800 ">
                  <input
                    aria-label="Time"
                    type="time"
                    onChange={(e) => setCheckout(e.target.value)}
                    value={check_out_time}
                    className="text-gray1000 dark:gray200 w-full outline-none focus:outline-none bg-transparent"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <DateInspectionItem
                dayofweek={"Monday"}
                showToggle
                activeToggle={availableDays.monday_availability}
                onToggleAction={() =>
                  setAvailableDays({
                    ...availableDays,
                    monday_availability: !availableDays.monday_availability,
                  })
                }
                className="items-center justify-between pb-2 border-b-[1px] border-b-gray400"
              />
              <DateInspectionItem
                dayofweek={"Tuesday"}
                showToggle
                activeToggle={availableDays.tuesday_availability}
                onToggleAction={() =>
                  setAvailableDays({
                    ...availableDays,
                    tuesday_availability: !availableDays.tuesday_availability,
                  })
                }
                className="items-center justify-between pb-2 border-b-[1px] border-b-gray400"
              />
              <DateInspectionItem
                dayofweek={"Wednesday"}
                showToggle
                activeToggle={availableDays.wednesday_availability}
                onToggleAction={() =>
                  setAvailableDays({
                    ...availableDays,
                    wednesday_availability:
                      !availableDays.wednesday_availability,
                  })
                }
                className="items-center justify-between pb-2 border-b-[1px] border-b-gray400"
              />
              <DateInspectionItem
                dayofweek={"Thursday"}
                showToggle
                activeToggle={availableDays.thursday_availability}
                onToggleAction={() =>
                  setAvailableDays({
                    ...availableDays,
                    thursday_availability: !availableDays.thursday_availability,
                  })
                }
                className="items-center justify-between pb-2 border-b-[1px] border-b-gray400"
              />
              <DateInspectionItem
                dayofweek={"Friday"}
                showToggle
                activeToggle={availableDays.friday_availability}
                onToggleAction={() =>
                  setAvailableDays({
                    ...availableDays,
                    friday_availability: !availableDays.friday_availability,
                  })
                }
                className="items-center justify-between pb-2 border-b-[1px] border-b-gray400"
              />
              <DateInspectionItem
                dayofweek={"Saturday"}
                showToggle
                activeToggle={availableDays.saturday_availability}
                onToggleAction={() =>
                  setAvailableDays({
                    ...availableDays,
                    saturday_availability: !availableDays.saturday_availability,
                  })
                }
                className="items-center justify-between pb-2 border-b-[1px] border-b-gray400"
              />
              <DateInspectionItem
                dayofweek={"Sunday"}
                showToggle
                activeToggle={availableDays.sunday_availability}
                onToggleAction={() =>
                  setAvailableDays({
                    ...availableDays,
                    sunday_availability: !availableDays.sunday_availability,
                  })
                }
                className="items-center justify-between"
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-1">
            {activeListing.monday_availability && (
              <DateInspectionItem
                dayofweek={"Monday"}
                timeInspect={`${moment(
                  activeListing.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  activeListing.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
            {activeListing.tuesday_availability && (
              <DateInspectionItem
                dayofweek={"Tuesday"}
                timeInspect={`${moment(
                  activeListing.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  activeListing.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
            {activeListing.wednesday_availability && (
              <DateInspectionItem
                dayofweek={"Wednesday"}
                timeInspect={`${moment(
                  activeListing.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  activeListing.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
            {activeListing.thursday_availability && (
              <DateInspectionItem
                dayofweek={"Thursday"}
                timeInspect={`${moment(
                  activeListing.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  activeListing.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
            {activeListing.friday_availability && (
              <DateInspectionItem
                dayofweek={"Friday"}
                timeInspect={`${moment(
                  activeListing.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  activeListing.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
            {activeListing.saturday_availability && (
              <DateInspectionItem
                dayofweek={"Saturday"}
                timeInspect={`${moment(
                  activeListing.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  activeListing.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
            {activeListing.sunday_availability && (
              <DateInspectionItem
                dayofweek={"Sunday"}
                timeInspect={`${moment(
                  activeListing.check_in_time,
                  "hh:mm A"
                ).format("hh:mm A")} - ${moment(
                  activeListing.check_out_time,
                  "hh:mm A"
                ).format("hh:mm A")}`}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyAvalability;
