import React from "react";

const CircleCircle = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2814 12.9488C16.1336 12.5669 18.3333 10.124 18.3333 7.16732C18.3333 3.94566 15.7216 1.33398 12.5 1.33398C9.54328 1.33398 7.10037 3.53371 6.71852 6.38588M13.3333 12.1673C13.3333 15.389 10.7216 18.0007 7.49996 18.0007C4.2783 18.0007 1.66663 15.389 1.66663 12.1673C1.66663 8.94566 4.2783 6.33398 7.49996 6.33398C10.7216 6.33398 13.3333 8.94566 13.3333 12.1673Z"
      stroke="#525252"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default CircleCircle;
