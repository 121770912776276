export const urls = {
  Signin: "/users/sign-in",
  Signup: "/users/sign-up",
  VerifyEmail: "/users/verify-email",
  ResendEmailVerification: "/users/resend-verification/email",
  ForgotPassword: "/users/forgot-password",
  ResetPassword: "/users/reset-password",
  UserProfile: "/users/profile/view",
  ViewAllProperties: (limit: number) =>
    `/homePageListing/viewAll?paginate=${limit || 10}&page=1`,
  ViewSingleProperties: (id: string) => `/properties/${id}`,
  AdminViewSingleProperties: (id: string) => `/admin/properties/view-one/${id}`,
  MediaUploadOne: `/media/upload-one`,
  MediaUploadMultiple: `/media/upload-multiple`,
  MediaUploadOneClean: `/media/upload-one-clean`,
  DocumentUploadOneClean: `/media/upload-document`,
  AddProperty: `/properties/add`,
  EditProperty: `/properties/edit`,
  AllCategories: `/general/lists`,
  AllBanks: (country?: string) =>
    `/general/fetch-approved-banks/?country=${country || "Nigeria"}`,
  ProcessNameEnquiry: `/users/verify-bank-details`,
  ChangePassword: `/users/change-password`,
  EditProfile: `/users/edit-profile`,
  HostProperties: (id?: string) =>
    `/users/host-properties?filter=${id || "published"}`,
  UpdatePublished: (id: string) => `/properties/edit-published/${id}`,
  UpdateBlockedDates: `/properties/update-blocked-out-date`,
  UpdateBankDetails: `/users/update-bank-details`,
  UpdateRequestKyc: `/users/request-kyc`,
  UpdatePropertyRequestKyc: `/properties/request-verification`,
  ViewVerificationChecklist: `/users/verification-checklist`,
  DeleteProperty: (id: string | number) => `/properties/archive/${id}`,
  ViewSimilarProperties: (id: any) => `/properties/similar-properties/${id}`,
  SpecialReservation: `/properties/special-reservation`,
  DashboardStats: `/users/dashboard-stats`,
  AutocompleteplacesUrl: (input: any) =>
    `/properties/google/auto-complete-places?input=${input}`,
  GooglePlaceInfoUrl: (placeId: any) =>
    `/properties/google/get-google-place-info?placeId=${placeId}`,
  GoogleSigninAuth: (token: string) =>
    `/users/auth/google?access_token=${token}`,
  BookingSummary: `/booking/summary`,
  BookingInitiate: `/booking/initiate`,
  PaymentVerify: (reference: string) =>
    `/payment/verify-transaction/${reference}`,
  BookingSingleInfo: (id: string) => `/booking/booking-info/${id}`,
  BookingPay: `/booking/pay`,
  ViewGuestBookings: (filter?: string) =>
    `/booking/guest-bookings?filter=${filter}`,
  ViewFavourites: `/properties/favourites/view-all`,
  AddFavourite: `/properties/favourites/add`,
  RemoveFavourite: (id: any) => `/properties/favourites/remove/${id}`,
  ViewHostBookings: (filter: any) =>
    `/booking/host-bookings?filter=${filter || "awaiting_approval"}`,
  ValidateBooking: `/booking/validate-booking`,
  HostBalance: `/payment/host-balance`,
  DashboardMetric: `/users/dashboard-metrics`,
  HostTransactions: (currency?: string, filter?: string, page?: number) =>
    `/payment/host-transactions?currency=${currency || "NGN"}&filter=${
      filter || ""
    }&page=${page || 1}&paginate=10`,
  HostProfile: (id: any) => `users/host-profile/${id}`,
  GuestProfile: (id: any) => `/users/guest-profile/${id}`,
  ReviewProperty: `/reviews/property-review`,
  ReviewGuest: `/reviews/guest-review`,
  ViewPropertyReviews: (id: any) => `/reviews/property-reviews/${id}`,
  ViewGuestReviews: `/reviews/guest-reviews`,
  ViewHostReviews: `/reviews/host-reviews`,
  DeleteReview: `/reviews/delete/3`,
  EditReview: `/reviews/edit`,
  EditBooking: "/booking/edit",
  ViewReferrals: `/users/referrals`,
  SendInvite: `/users/invite`,
  VerifyPhone: `/users/resend-verification/phone`,
  ValidatePhone: `/users/verify-phone`
};
