import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import VerifiedHouse from "../../../components/icons/verified-house";
import HostDashboardLayout from "./components/host-dashboard-layout";
import ArrowTopRight from "../../../components/icons/arrow-top-right";
import CopyIcon from "../../../components/icons/copy";
import ShareIcon from "../../../components/icons/share";
import ImageGallery from "../../../components/custom/image-gallery";
import AboutProperty from "./components/about-property";
import DeleteIcon from "../../../components/icons/delete";
import ToggleButton from "../../../components/custom/toggle-button";
import ConfirmDeleteModal from "./components/confirm-delete";
import { useDispatch, useSelector } from "react-redux";
import {
  getProperty,
  getVerificationChecklist,
} from "../../../redux/reducers/property-slice";
import {
  deleteProperty,
  fetchSingleProperty,
  updatePropertyPublished,
  viewChecklist,
} from "../../../redux/actions/property";
import PropertyAvalability from "./components/property-avalability";
import AboutPersonal from "./components/about-personal";
import BlockedDates from "./components/blocked-dates";
import CalendarDisplay from "../../../components/custom/calendar-display";
import PropertyVerificationCardView from "./components/property-verification-card-view";
import { paths } from "../../../routes/paths";
import VerificationCardView from "./components/verification-card-view";
import { RWebShare } from "react-web-share";
import { truncate } from "../../../utils/helper-functions";
import PropertyPreference from "./components/property-preference";

const ManageProperty: React.FC = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const property = useSelector(getProperty);
  const propertyStatus = useSelector(getVerificationChecklist);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const propertyVerifier = propertyStatus?.user_properties.filter(
    (item) => item.id === property?.id
  )[0];

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchSingleProperty(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchSingleProperty(id));
    // @ts-ignore
    dispatch(viewChecklist());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopy = () => {
    if (property?.share_link) {
      navigator.clipboard.writeText(property.share_link).then(() => {
        setTooltipVisible(true);
        setTimeout(() => {
          setTooltipVisible(false);
        }, 2000);
      });
    }
  };

  const onPublishProperty = async () => {
    setLoading(true);
    const res = await dispatch(
      // @ts-ignore
      updatePropertyPublished({ id: property.id, is_live: !property.is_live })
    );
    setLoading(false);
    if (res) {
      // @ts-ignore
      dispatch(fetchSingleProperty(id));
    }
  };

  const handleDeleteActiveListing = () => {
    setIsConfirmDeleteOpen(true);
  };

  const confirmDelete = async () => {
    setIsConfirmDeleteOpen(false);
    // @ts-ignore
    const res = await dispatch(deleteProperty(id));

    if (res) {
      // do something...
      navigate(-1);
    }
  };

  const closeConfirmDelete = () => {
    setIsConfirmDeleteOpen(false);
  };

  return (
    <HostDashboardLayout>
      <div className="md:px-10 font-display flex flex-col gap-3">
        <div className="flex flex-col gap-2 border-b border-gray400 dark:border-gray800 pb-5">
          {/* title and publish toggle */}
          <div className="flex md:flex-row flex-col md:gap-0 gap-2 md:items-center justify-between">
            <div className="flex items-center gap-2 md:w-fit w-full">
              <span className="text-xl font-bold dark:text-gray200">
                {property?.name}
              </span>
              {property.is_verified && <VerifiedHouse />}
            </div>
            <button
              // onClick={() => onPublishProperty()}
              className="bg-gray100 dark:bg-transparent dark:text-gray100 w-fit border border-gray400 dark:border-gray800 text-sm font-semibold flex items-center gap-3 text-gray800 p-1.5 rounded-full"
            >
              <span> Published</span>{" "}
              {loading && (
                <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
              )}
              <ToggleButton
                isActive={property.is_live}
                onToggle={() => onPublishProperty()}
              />
            </button>
          </div>
          <div className="flex flex-wrap items-center gap-2">
            <Link
              to={`${paths.VIEW_PROPERTY}/${property.slug}`}
              className="bg-primary500 text-nowrap hover:bg-primary400 transition-all duration-300 ease-in-out text-eadWhite rounded-full text-sm mt-3 w-fit flex font-semibold gap-2 items-center justify-center px-3 h-8 lg:mt-0"
            >
              View property page
              <ArrowTopRight color="#fff" />
            </Link>
            <div className="relative">
              <button
                className="bg-gray400 dark:bg-gray800 dark:text-gray100 max-w-80 hover:bg-gray500 transition-all duration-300 ease-in-out text-gray1000 rounded-full w-fit text-sm flex font-semibold gap-2 items-center justify-center px-3 min-h-8"
                onClick={handleCopy}
              >
                {truncate(property?.name, 30)}
                <CopyIcon />
              </button>
              {tooltipVisible && (
                <div className="absolute w-full flex items-center justify-center mt-1">
                  <span className="bg-gray800 text-white text-xs rounded-full px-2 py-1">
                    Copied
                  </span>
                </div>
              )}
            </div>
            <RWebShare
              data={{
                text: property?.name,
                url: property?.share_link,
                title: property?.name,
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className="bg-gray400 dark:bg-gray800 dark:text-gray100 text-nowrap hover:bg-gray500 transition-all duration-300 ease-in-out text-gray1000 rounded-full text-sm w-fit flex font-semibold gap-2 items-center justify-center px-3 h-8">
                Share
                <ShareIcon />
              </button>
            </RWebShare>
          </div>
        </div>
        <div className="flex md:flex-row flex-col gap-5 pt-2 pb-5 text-gray1000">
          <div className="md:w-[68%] flex flex-col gap-5">
            <ImageGallery
              editable={true}
              imageData={property?.property_media?.map((item) => item.url)}
              title={"items"}
              activeListing={property}
            />
            {property && <AboutProperty activeListing={property} />}
            {property && <PropertyAvalability activeListing={property} />}
            {property && <BlockedDates activeListing={property} />}
            {property && <AboutPersonal activeListing={property} />}
            {property && <PropertyPreference activeListing={property} />}
            <button
              onClick={handleDeleteActiveListing}
              className="bg-primary100 dark:bg-[#660808] dark:text-gray100 flex items-center gap-1 text-primary500 font-bold rounded-full w-fit text-sm p-1.5"
            >
              <DeleteIcon />
              Delete
            </button>
          </div>
          <div className="md:w-[32%] h-fit w-full text-gray1000 flex flex-col gap-5">
            <VerificationCardView />
            <PropertyVerificationCardView
              property={property}
              propertyChecklist={propertyVerifier}
            />

            <div className=" bg-gray100 border border-gray400 dark:bg-[#141414] dark:text-gray100 dark:border-gray800 rounded-xl p-4 flex flex-col gap-1">
              <span className="md:text-base text-sm font-bold">
                Preview Availability
              </span>
              <span className="text-gray900 md:text-sm text-xs font-medium mb-5 dark:text-gray100">
                In your local timezone (Europe/London) 
                <button className="underline">Update</button>
              </span>
              <CalendarDisplay activeListing={property} />
              <div className="w-full mt-3 py-2 md:py-4 bg-gray400 dark:bg-gray800 dark:text-gray100 text-xs md:text-sm text-gray1000 rounded-xl flex items-center justify-center">
                Greyed dates are set to unavailable
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        isOpen={isConfirmDeleteOpen}
        onClose={closeConfirmDelete}
        onConfirm={confirmDelete}
      />
    </HostDashboardLayout>
  );
};

export default ManageProperty;
