import { useSelector } from "react-redux";
import { getUserProperties } from "../../../../redux/reducers/user-slice";
import { getVerificationChecklist } from "../../../../redux/reducers/property-slice";
import CheckMarkIcon from "../../../../components/icons/check-mark";
import ChevronRight from "../../../../components/icons/chevron-right";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../routes/paths";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

interface VerificationCardViewProps {}

const VerifyListCheck = ({
  title,
  active,
}: {
  title: string;
  active: boolean;
}) => (
  <div className="flex items-center gap-3 py-1">
    <span
      className={`${
        active ? "text-black dark:text-gray-100" : "text-primary500"
      }`}
    >
      ●
    </span>
    <span className="text-sm font-normal text-gray1000 dark:text-gray200">
      {title}
    </span>
    {active ? <CheckMarkIcon className="flex-shrink-0 w-4 h-4" /> : null}
  </div>
);

const VerificationCardView: React.FC<VerificationCardViewProps> = () => {
  const navigate = useNavigate();
  const userProperties = useSelector(getUserProperties);
  const propertyStatus = useSelector(getVerificationChecklist);
  const currentProfile = localStorage.getItem("USERTYPE");
  const [toggled, setToggled] = useState(true);

  const propertyVerifyInfo = propertyStatus?.user_properties.filter(
    (item) => item.id === userProperties?.[0]?.id
  )[0];

  return (
    <div className="bg-gray100 p-4 rounded-xl border border-gray400 dark:border-gray800 dark:bg-gray900 w-full">
      <div
        onClick={() => setToggled((prev) => !prev)}
        className="flex items-center justify-between w-full"
      >
        <p className="text-base font-bold text-gray1000 dark:text-gray200 pb-2">
          Pending verification
        </p>
        <FaChevronDown
          className={`text-gray-800 transform transition-transform duration-300 ${
            toggled ? "rotate-180" : ""
          }`}
        />
      </div>
      {toggled && (
        <>
          <p className="text-sm font-normal text-gray1000 dark:text-gray200 pb-2">
            What you need to do
          </p>
          {currentProfile === "HOST" ? (
            <div className="bg-gray100 p-3 rounded-xl border border-gray400 dark:border-gray800 mb-3 dark:bg-gray900">
              <div className="flex gap-2 items-center pb-2">
                <p className="text-sm font-bold text-gray1000 dark:text-gray200">
                  Account verification checklist
                </p>

                <p className="text-xs bg-gray400 rounded-full px-2 py-0.5 dark:bg-gray1000 dark:text-gray200">
                  {propertyStatus.user.is_verified
                    ? "verified"
                    : propertyStatus.user.verification_status}
                </p>
              </div>
              <p className="text-xs font-normal text-gray1000 dark:text-gray200 pb-2">
                Complete your verification to publish your property
              </p>
              <div>
                <VerifyListCheck
                  title="Verify your email"
                  active={propertyStatus.user.is_email_verified}
                />
                <VerifyListCheck
                  title="Verify your phone number"
                  active={propertyStatus.user.is_phone_verified}
                />
                <VerifyListCheck
                  title="Verify your bank details"
                  active={propertyStatus.user.is_bank_verified}
                />
                <VerifyListCheck
                  title="Upload your ID (any government approved identity card)"
                  active={!!propertyStatus.user.kyc_document_url}
                />
              </div>
            </div>
          ) : (
            <div className="bg-gray100 p-3 rounded-xl border border-gray400 dark:border-gray800 mb-3 dark:bg-gray900">
              <div className="flex gap-2 items-center pb-2">
                <p className="text-sm font-bold text-gray1000 dark:text-gray200">
                  Account verification checklist
                </p>

                <p className="text-xs bg-gray400 rounded-full px-2 py-0.5 dark:bg-gray1000 dark:text-gray200">
                  {propertyStatus.user.is_verified
                    ? "verified"
                    : propertyStatus.user.verification_status}
                </p>
              </div>
              <p className="text-xs font-normal text-gray1000 dark:text-gray200 pb-2">
                Complete your verification
              </p>
              <div>
                <VerifyListCheck
                  title="Verify your email"
                  active={propertyStatus.user.is_email_verified}
                />
                <VerifyListCheck
                  title="Verify your phone number"
                  active={propertyStatus.user.is_phone_verified}
                />
                <VerifyListCheck
                  title="Upload your ID (any government approved identity card)"
                  active={!!propertyStatus.user.kyc_document_url}
                />
              </div>
            </div>
          )}
          {userProperties.length > 0 && currentProfile === "HOST" && (
            <div className="bg-gray100 p-3 rounded-xl border border-gray400 dark:border-gray800 mb-3 dark:bg-gray900">
              <div className="flex gap-2 items-center pb-2">
                <p className="text-sm font-bold text-gray1000 dark:text-gray200">
                  {userProperties.length > 0 ? userProperties[0].name : ""}
                </p>
                <p className="text-xs bg-gray400 rounded-full px-2 py-0.5 dark:bg-gray1000">
                  {propertyVerifyInfo?.verification_status}
                </p>
                <p className="text-xs bg-gray400 rounded-full px-2 py-0.5 dark:bg-gray1000">
                  {userProperties[0]?.is_verified ? "verified" : "unverified"}
                </p>
              </div>
              <div>
                <VerifyListCheck
                  title="Verify your email"
                  active={propertyStatus.user.is_email_verified}
                />
                <VerifyListCheck
                  title="Verify your phone number"
                  active={propertyStatus.user.is_phone_verified}
                />
                <VerifyListCheck
                  title="Upload your ID (any government approved identity card)"
                  active={!!propertyStatus.user.kyc_document_url}
                />
              </div>
            </div>
          )}

          <button
            onClick={() => navigate(`${paths.GUEST}/verification`)}
            className="flex w-full justify-center items-center gap-2 rounded-full p-1.5 bg-gray400 my-2 dark:bg-gray1000"
          >
            <span className="text-sm text-gray1000 dark:text-gray200 font-semibold ">
              See verification cheklist
            </span>
            <ChevronRight />
          </button>
        </>
      )}
    </div>
  );
};

export default VerificationCardView;
