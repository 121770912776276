import React from "react";
import FooterBase from "./footer-base";
import EADLogo from "../../assets/images/EADlogo.svg";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes/paths";

interface Props {
  children: any;
  image: any;
}
const OnboardingLayout = ({ children, image }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center w-screen">
      <div className="w-full h-screen relative flex flex-col justify-between dark:bg-gray1000 pt-2">
        <div className="flex flex-col items-center justify-center w-full py-2 dark:bg-gray1000 sticky inset-x-0 top-0 z-50">
          <img
            src={EADLogo}
            alt=""
            className="h-10 lg:h-16 w-auto cursor-pointer flex flex-col items-center"
            onClick={() => navigate(paths.HOME)}
          />
          <p className="text-xs lg:text-sm text-gray1000 dark:text-gray200 font-semibold text-center">
            EAD Shortlets
          </p>
        </div>
        <div className="overflow-y-auto scrollbar-thin">{children}</div>
        <div className="w-full dark:bg-gray1000">
          <div className="hidden lg:block xl:block 2xl:block ">
            <FooterBase />
          </div>
        </div>
      </div>
      <div className="bg-primary300 w-full h-screen hidden lg:block xl:block 2xl:block">
        <img src={image} alt="" className="object-cover h-full" />
      </div>
    </div>
  );
};

export default OnboardingLayout;
