import { useCallback, useRef, useState } from "react";

export const useFormFields = <T extends object>(defaultValues: T) => {
  type FormFields = typeof defaultValues;
  const [formFields, setFormFields] = useState(defaultValues);

  const setFieldValue = useCallback(
    <F extends keyof FormFields>(field: F, value: FormFields[F]) => {
      setFormFields((state) => ({
        ...state,
        [field]: value,
      }));
    },
    []
  );

  const resetFields = useRef(() => setFormFields(defaultValues));

  return [formFields, setFieldValue, resetFields.current] as const;
};
