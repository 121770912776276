import React, { useState, useRef, useEffect } from "react";
import ChevronDown from "../icons/chevron-down";

interface DropdownProps {
  options: [{ value: string; label: string }];
  onSelect: (selectedOption: string) => void;
  value?: string | number; // Optional prop for default value
  label?: string;
  subtext?: string;
  className?: string;
}

const DropdownTwo: React.FC<DropdownProps> = ({
  options,
  onSelect,
  value = "Select",
  label,
  subtext,
  className,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | number>(value);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (option: { value: string; label: string }) => {
    setSelectedOption(option.label);
    onSelect(option.value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <p className="text-xs pb-1 text-gray1000 dark:text-gray200">
        {label || ""}
      </p>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full flex px-3 text-sm items-center justify-between border border-gray400 dark:border-gray800 rounded-full p-2 focus:outline-none focus:border-gray700 transition duration-300 ease-in-out ${
          isOpen ? "border-gray700" : ""
        } ${
          selectedOption === "Select"
            ? "text-gray-400 dark:text-gray400"
            : "text-gray1000 dark:text-gray100"
        }`}
      >
        {selectedOption}
        <ChevronDown
          className={`transform transition-transform duration-300 ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        />
      </button>
      {isOpen && (
        <div className="absolute text-sm flex flex-col scrollbar-thin dark:text-gray100 w-full bg-white dark:bg-[#141414] border border-gray400 dark:border-gray800 rounded-2xl mt-2 max-h-60 overflow-y-auto shadow-xl shadow-neutral-200/30 dark:shadow-gray1000/30 z-10">
          {options.map((option, index) => (
            <span
              key={index}
              className="p-3 hover:bg-gray100 dark:hover:bg-gray800 cursor-pointer transition-all duration-300 ease-in-out"
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </span>
          ))}
        </div>
      )}
      <p className="text-xs pb-1 text-gray1000 dark:text-gray200">
        {subtext || ""}
      </p>
    </div>
  );
};

export default DropdownTwo;
