import React from "react";
import Location from "../icons/location";
import SearchIcon from "../icons/search";
import CloseMobileIcon from "../icons/close-mobile";
import useGooglePlaces from "../../hooks/use-google-places";
import CheckRedIcon from "../icons/check-red";

interface Props {
  ref?: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
  onClose?: () => void;
  placeHolder?: string;
  onSelectAddress?: (pa?: any) => void;
  onSelectPlaceId?: (pa?: any) => void;
  address?: string;
}

const AutocompletePlace = ({
  ref,
  value,
  onClose,
  onChange,
  placeHolder,
  onSelectPlaceId,
  onSelectAddress,
  address,
}: Props) => {
  const { places } = useGooglePlaces(value || "");

  return (
    <div>
      <div className="flex items-center justify-between px-3 py-2 md:px-5 md:py-4">
        <div className="flex items-center gap-2 flex-grow">
          <SearchIcon className="" />
          <input
            ref={ref}
            type="text"
            placeholder={placeHolder || "Enter location"}
            className="border-transparent bg-transparent focus:border-transparent text-sm focus:outline-none focus:ring-0 w-full"
            onChange={onChange}
            value={value}
            autoFocus
          />
        </div>
        <div className="flex items-center gap-1">
          {value && value?.length > 0 && (
            <button onClick={onClose} className="ease-in-out">
              <CloseMobileIcon />
            </button>
          )}
        </div>
      </div>
      <div className="w-full items-center max-h-44 overflow-y-auto scrollbar-thin">
        {value && value?.length > 0 && places.length === 0 && (
          <div className="h-18 flex items-center justify-center py-5">
            <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500 border-r-primary500" />
          </div>
        )}
        {address &&
          address?.length > 0 &&
          places?.map((place) => (
            <div
              key={place.place_id}
              onClick={() => {
                if (onSelectAddress) {
                  onSelectAddress(place?.description);
                }
                if (onSelectPlaceId) {
                  onSelectPlaceId(place?.place_id);
                }
              }}
              className={`w-full flex items-center justify-between rounded-lg hover:bg-gray300 dark:hover:bg-gray700 p-2 flex-shrink-0 cursor-pointer`}
            >
              <div className="flex items-center gap-2 px-2 w-3/4">
                <Location className="w-4 flex-shrink-0" />
                <p className="text-sm">{place?.description}</p>
              </div>
              <CheckRedIcon className="w-3" />
            </div>
          ))}
      </div>
    </div>
  );
};

export default AutocompletePlace;
