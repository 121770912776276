import Navbar from "../../../components/custom/navbar";
import "./styles.scss";
import Footer from "../../../components/custom/footer";
import MakeBookingSection from "../../../components/custom/make-bookings-section";
import Location from "../../../components/icons/location";
// import StarIcon from "../../../components/icons/star";
import ChevronRight from "../../../components/icons/chevron-right";
import Minusicon from "../../../components/icons/minus-icon";
import Plusicon from "../../../components/icons/plus-icon";
import Maps from "../../../assets/images/maps.png";
import Pills from "../../../components/custom/pills";
// import ReviewsCard from "../../../components/custom/reviews-card";
import HostUserCard from "../../../components/custom/host-user-card";
import SimilarListing from "../../../components/custom/similar-listing";
import ArrowTopRight from "../../../components/icons/arrow-top-right";
import ArrowTopRightBlack from "../../../components/icons/arrow-top-right copy";
import { ImageGridStatic } from "../../../components/custom/image-slider";
import Carousel from "../../../components/custom/carousel";
import Modal from "../../../components/custom/modal";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFavourites,
  getProperty,
  getSimilarProperties,
  setProperty,
} from "../../../redux/reducers/property-slice";
import {
  addFavouriteProperty,
  fetchSimilarProperty,
  fetchSingleProperty,
  removeFavouriteProperty,
} from "../../../redux/actions/property";
import { Link, useParams } from "react-router-dom";
import { currencyFormat, truncate } from "../../../utils/helper-functions";
import { RWebShare } from "react-web-share";
import BreadcrumbsTwo from "../host-dashboard/components/breadcrumb-two";
import { paths } from "../../../routes/paths";
import DatePicker from "react-datepicker";
import moment from "moment";
import useQuery from "../../../hooks/use-query";
import { addDays, eachDayOfInterval, getDay } from "date-fns";
import { usePropertyForm } from "../host-onboard/hooks/use-property-form";
import SpecialReservations from "../../../components/custom/special-reservations";
import PillsTwo from "../../../components/custom/pills-two";
import { getIconComponent } from "../../../utils/get-icons";
import ScrollTop from "../../../components/custom/scroll-to-top";
import MapView from "../../../components/custom/map-view-location";
import { useBookingSummary } from "../../../hooks/use-book-summary";
import { Helmet } from "react-helmet";
import HeartFull from "../../../components/icons/heart-full";
import HeartEmpty from "../../../components/icons/heart-empty";
import ReviewsCardInfo from "../../../components/custom/reviews-card-info";
import { ReviewsProps } from "../../../redux/types";

type ValuePiece = Date;

const SkeletonBar = () => (
  <div className="animate-pulse w-full my-2">
    <div className="h-2 bg-gray600 rounded w-1/2"></div>
  </div>
);

const ViewProperty = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const query = useQuery();
  const property = useSelector(getProperty);
  const similarProperties = useSelector(getSimilarProperties);
  const favouritesProperties = useSelector(getFavourites);
  const [isOpen, setOpen] = useState(false);
  const [countChildren, setCountChildren] = useState(0);
  const [countAdult, setCountAdult] = useState(1);
  const { propertyCategories } = usePropertyForm();
  const [checkinDate, setCheckinDate] = useState<ValuePiece>(
    addDays(new Date()?.toISOString(), 1) || new Date()
  );
  const [checkoutDate, setCheckoutDate] = useState<ValuePiece>(
    addDays(new Date()?.toISOString(), 2) || new Date()
  );
  const [isDateOpen, setIsDateopen] = useState("hidden");
  const [warning, setWarning] = useState("");
  const [noOfDays, setNoDays] = useState(0);
  const {
    bookingSummary,
    bookingSummaryErrMsg,
    isLoadingBookSummary,
    isInitiateLoading,
    initiateBooking,
  } = useBookingSummary({
    checkinDate,
    checkoutDate,
    countAdult,
    countChildren,
    propertyId: property?.id,
  });

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchSingleProperty(id));
    return () => {
      dispatch(setProperty({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (property?.id) {
      // @ts-ignore
      dispatch(fetchSimilarProperty(property?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property?.id]);

  useEffect(() => {
    if (query.get("checkinDate")) {
      // @ts-ignore
      setCheckinDate(new Date(query.get("checkinDate")));
    }
    if (query.get("checkoutDate")) {
      // @ts-ignore
      setCheckoutDate(new Date(query.get("checkoutDate")));
    }
    if (query.get("guests")) {
      setCountAdult(Number(query.get("guests")));
    }
  }, [query]);

  useEffect(() => {
    const dayin = moment(checkinDate?.toISOString());
    const dayOut = moment(checkoutDate?.toISOString());
    const diffDays = dayOut.diff(dayin, "days");
    setNoDays(diffDays <= 0 ? 1 : diffDays);

    // eslint-disable-next-line
  }, [checkinDate, checkoutDate, property]);

  const getAvaliableDays = useMemo(
    () => [
      property.monday_availability ? null : 1,
      property.tuesday_availability ? null : 2,
      property.wednesday_availability ? null : 3,
      property.thursday_availability ? null : 4,
      property.friday_availability ? null : 5,
      property.saturday_availability ? null : 6,
      property.sunday_availability ? null : 0,
    ],

    // eslint-disable-next-line
    [property]
  );

  const customDisabledDates = property?.blocked_out_dates?.map(
    (item) => new Date(item)
  );

  const isWeekdayDisabled = useMemo(
    () => (date: any) => {
      const day = getDay(date);
      const disableAvailableDays = getAvaliableDays
        .filter((item) => item !== null)
        .some((disableDay) => day === disableDay);
      return disableAvailableDays; // Disable days and custom dates
    },
    // eslint-disable-next-line
    [property, checkinDate, checkoutDate, getAvaliableDays]
  );

  const onChange = (dates: [any, any]) => {
    const [start, end] = dates;
    setWarning("");
    setCheckinDate(start);
    setCheckoutDate(end);
    console.log("endend", end);
    if (start && end) {
      const allDates = eachDayOfInterval({ start, end });
      const hasDisabledDate = allDates.some(isWeekdayDisabled);
      if (hasDisabledDate) {
        setWarning(
          "Warning: The selected date range includes a disabled date."
        );
      } else {
        setWarning("");
      }
    }
    if (end) {
      setIsDateopen("hidden");
    }
  };

  const isSaved = useMemo(
    () => favouritesProperties.map((item) => item?.id).includes(property?.id),
    [property, favouritesProperties]
  );

  return (
    <div>
      <Helmet>
        <title>{property?.name || "EADS Property"}</title>
        <meta
          name="description"
          content={property?.name || "EADS Property description"}
        />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content={property?.name} />
        <meta property="og:description" content={property?.description} />
        <meta
          property="og:image"
          content={
            property?.property_media ? property?.property_media[0]?.url : ""
          }
        />
        <meta property="og:url" content={`${property?.share_link}`} />
        <meta property="og:type" content="property" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={property.name} />
        <meta name="twitter:description" content={property.description} />
        <meta
          name="twitter:image"
          content={
            property?.property_media ? property?.property_media[0]?.url : ""
          }
        />

        {/* Canonical Link */}
        <link rel="canonical" href={`${property?.share_link}`} />
      </Helmet>

      <ScrollTop />
      <div className="bg-eadWhite dark:bg-gray1000 relative pb-18 px-4">
        <div className="max-w-screen-2xl mx-auto">
          <Navbar />
        </div>
        <div className="max-w-screen-lg mx-auto lg:pt-16">
          <div className="flex items-center justify-between">
            <BreadcrumbsTwo
              name={property?.name}
              path={`${paths.VIEW_PROPERTY}/${property?.slug}`}
            />

            <div className="flex gap-2 items-center">
              <button
                className="hidden lg:flex 2xl:flex"
                onClick={() => {
                  isSaved
                    ? // @ts-ignore
                      dispatch(removeFavouriteProperty(property?.id))
                    : dispatch(
                        // @ts-ignore
                        addFavouriteProperty({ property_id: property?.id })
                      );
                }}
              >
                {isSaved ? (
                  <HeartFull className="h-8" />
                ) : (
                  <HeartEmpty className="h-8" />
                )}
              </button>
              <RWebShare
                data={{
                  text: property?.name,
                  url: property?.share_link,
                  title: property?.name,
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <button className="text-gray1000 dark:text-gray300 lg:bg-gray400 dark:bg-gray900 flex items-center gap-1 rounded-full px-2 h-8">
                  <span className="hidden lg:block text-xs font-semibold">
                    Share
                  </span>
                  <ArrowTopRightBlack className="w-4 lg:2" />
                </button>
              </RWebShare>
            </div>
          </div>

          <hr className="border-gray400 dark:border-gray800  my-6" />

          {/* images */}
          <ImageGridStatic
            // @ts-ignore
            images={property?.property_media}
            seeAllactions={() => setOpen(true)}
          />

          <div className="relative lg:hidden xl:hidden rounded-xl overflow-hidden">
            <div className="flex items-center justify-between absolute top-4 z-50 inset-x-1">
              {property?.is_verified ? (
                <div className="flex gap-1 rounded-full bg-green-50 items-center p-1">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2334 1.12283C11.7059 0.841894 12.2941 0.841894 12.7666 1.12283L14.4102 2.10012C14.6478 2.24138 14.9198 2.31425 15.1961 2.3107L17.1082 2.28614C17.6578 2.27908 18.1673 2.57323 18.436 3.05277L19.3708 4.72093C19.5059 4.96204 19.705 5.16113 19.9461 5.29624L21.6142 6.231C22.0938 6.4997 22.3879 7.00918 22.3808 7.55883L22.3563 9.47088C22.3527 9.74724 22.4256 10.0192 22.5669 10.2568L23.5442 11.9004C23.8251 12.3728 23.8251 12.9611 23.5442 13.4336L22.5669 15.0772C22.4256 15.3148 22.3527 15.5867 22.3563 15.8631L22.3808 17.7752C22.3879 18.3248 22.0938 18.8343 21.6142 19.103L19.9461 20.0377C19.705 20.1729 19.5059 20.3719 19.3708 20.6131L18.436 22.2812C18.1673 22.7608 17.6578 23.0549 17.1082 23.0478L15.1961 23.0233C14.9198 23.0197 14.6478 23.0926 14.4102 23.2339L12.7666 24.2112C12.2941 24.4921 11.7059 24.4921 11.2334 24.2112L9.58977 23.2339C9.35221 23.0926 9.08024 23.0197 8.80388 23.0233L6.89183 23.0478C6.34219 23.0549 5.83271 22.7608 5.564 22.2812L4.62925 20.6131C4.49414 20.3719 4.29505 20.1729 4.05394 20.0377L2.38577 19.103C1.90624 18.8343 1.61209 18.3248 1.61915 17.7752L1.64371 15.8631C1.64726 15.5867 1.57439 15.3148 1.43313 15.0772L0.455837 13.4336C0.174901 12.9611 0.174901 12.3728 0.455837 11.9004L1.43313 10.2568C1.57439 10.0192 1.64726 9.74724 1.64371 9.47088L1.61915 7.55883C1.61209 7.00918 1.90624 6.4997 2.38577 6.231L4.05394 5.29624C4.29505 5.16113 4.49414 4.96204 4.62925 4.72093L5.564 3.05277C5.83271 2.57323 6.34219 2.27908 6.89183 2.28614L8.80388 2.3107C9.08024 2.31425 9.35221 2.24138 9.58977 2.10012L11.2334 1.12283Z"
                      fill="#2F80ED"
                    />
                    <path
                      d="M16.5 15.7509V12.1335C16.5 11.8463 16.5 11.7027 16.463 11.5705C16.4302 11.4533 16.3763 11.3432 16.304 11.2454C16.2223 11.135 16.109 11.0468 15.8823 10.8705L15.8823 10.8705L12.4912 8.23295C12.3155 8.09633 12.2277 8.02801 12.1307 8.00175C12.0451 7.97858 11.9549 7.97858 11.8693 8.00175C11.7723 8.02801 11.6845 8.09633 11.5088 8.23295L8.1177 10.8705L8.11769 10.8705C7.89101 11.0468 7.77767 11.135 7.69601 11.2454C7.62368 11.3432 7.5698 11.4533 7.53701 11.5705C7.5 11.7027 7.5 11.8463 7.5 12.1335V15.7509C7.5 16.311 7.5 16.591 7.60899 16.8049C7.70487 16.9931 7.85785 17.1461 8.04601 17.242C8.25992 17.3509 8.53995 17.3509 9.1 17.3509H14.9C15.4601 17.3509 15.7401 17.3509 15.954 17.242C16.1422 17.1461 16.2951 16.9931 16.391 16.8049C16.5 16.591 16.5 16.311 16.5 15.7509Z"
                      stroke="#FCFCFC"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="text-xs font-display text-green-700 font-semibold">
                    Verified
                  </span>
                </div>
              ) : (
                <></>
              )}

              <button
                className=""
                onClick={() => {
                  isSaved
                    ? // @ts-ignore
                      dispatch(removeFavouriteProperty(property?.id))
                    : dispatch(
                        // @ts-ignore
                        addFavouriteProperty({ property_id: property?.id })
                      );
                }}
              >
                {isSaved ? (
                  <HeartFull className="h-8" />
                ) : (
                  <HeartEmpty className="h-8" />
                )}
              </button>
            </div>
            <Carousel
              // @ts-ignore
              slides={property?.property_media || []}
              classNameImg="rounded-xl object-cover w-full h-48"
            />
          </div>

          <div className="lg:flex items-start justify-center my-4">
            <div className="lg:w-2/3 flex flex-col mr-3">
              <div className="lg:hidden flex items-center justify-between">
                <div className="flex gap-1 items-center my-2">
                  <p className="text-2xl font-display font-bold text-gray1000 dark:text-gray200 ">
                    {currencyFormat(property?.price || 0)}
                  </p>
                  <p className="text-xs font-display dark:text-gray200">
                    per night
                  </p>
                </div>
                <ArrowTopRight />
              </div>

              {property?.name ? (
                <p className="lg:text-xl flex gap-2 text-base font-display text-gray1000 dark:text-gray200 font-bold mb-2">
                  {property?.name}{" "}
                  {property?.is_verified && (
                    <div className="lg:flex items-center gap-2 hidden">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.2334 1.12283C11.7059 0.841894 12.2941 0.841894 12.7666 1.12283L14.4102 2.10012C14.6478 2.24138 14.9198 2.31425 15.1961 2.3107L17.1082 2.28614C17.6578 2.27908 18.1673 2.57323 18.436 3.05277L19.3708 4.72093C19.5059 4.96204 19.705 5.16113 19.9461 5.29624L21.6142 6.231C22.0938 6.4997 22.3879 7.00918 22.3808 7.55883L22.3563 9.47088C22.3527 9.74724 22.4256 10.0192 22.5669 10.2568L23.5442 11.9004C23.8251 12.3728 23.8251 12.9611 23.5442 13.4336L22.5669 15.0772C22.4256 15.3148 22.3527 15.5867 22.3563 15.8631L22.3808 17.7752C22.3879 18.3248 22.0938 18.8343 21.6142 19.103L19.9461 20.0377C19.705 20.1729 19.5059 20.3719 19.3708 20.6131L18.436 22.2812C18.1673 22.7608 17.6578 23.0549 17.1082 23.0478L15.1961 23.0233C14.9198 23.0197 14.6478 23.0926 14.4102 23.2339L12.7666 24.2112C12.2941 24.4921 11.7059 24.4921 11.2334 24.2112L9.58977 23.2339C9.35221 23.0926 9.08024 23.0197 8.80388 23.0233L6.89183 23.0478C6.34219 23.0549 5.83271 22.7608 5.564 22.2812L4.62925 20.6131C4.49414 20.3719 4.29505 20.1729 4.05394 20.0377L2.38577 19.103C1.90624 18.8343 1.61209 18.3248 1.61915 17.7752L1.64371 15.8631C1.64726 15.5867 1.57439 15.3148 1.43313 15.0772L0.455837 13.4336C0.174901 12.9611 0.174901 12.3728 0.455837 11.9004L1.43313 10.2568C1.57439 10.0192 1.64726 9.74724 1.64371 9.47088L1.61915 7.55883C1.61209 7.00918 1.90624 6.4997 2.38577 6.231L4.05394 5.29624C4.29505 5.16113 4.49414 4.96204 4.62925 4.72093L5.564 3.05277C5.83271 2.57323 6.34219 2.27908 6.89183 2.28614L8.80388 2.3107C9.08024 2.31425 9.35221 2.24138 9.58977 2.10012L11.2334 1.12283Z"
                          fill="#2F80ED"
                        />
                        <path
                          d="M16.5 15.7509V12.1335C16.5 11.8463 16.5 11.7027 16.463 11.5705C16.4302 11.4533 16.3763 11.3432 16.304 11.2454C16.2223 11.135 16.109 11.0468 15.8823 10.8705L15.8823 10.8705L12.4912 8.23295C12.3155 8.09633 12.2277 8.02801 12.1307 8.00175C12.0451 7.97858 11.9549 7.97858 11.8693 8.00175C11.7723 8.02801 11.6845 8.09633 11.5088 8.23295L8.1177 10.8705L8.11769 10.8705C7.89101 11.0468 7.77767 11.135 7.69601 11.2454C7.62368 11.3432 7.5698 11.4533 7.53701 11.5705C7.5 11.7027 7.5 11.8463 7.5 12.1335V15.7509C7.5 16.311 7.5 16.591 7.60899 16.8049C7.70487 16.9931 7.85785 17.1461 8.04601 17.242C8.25992 17.3509 8.53995 17.3509 9.1 17.3509H14.9C15.4601 17.3509 15.7401 17.3509 15.954 17.242C16.1422 17.1461 16.2951 16.9931 16.391 16.8049C16.5 16.591 16.5 16.311 16.5 15.7509Z"
                          stroke="#FCFCFC"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span className="text-xs text-green-500">Verified</span>
                    </div>
                  )}
                </p>
              ) : (
                <SkeletonBar />
              )}

              <div className="flex gap-1 items-center">
                <Location />
                {property?.address ? (
                  <p className="text-xs font-display text-gray900 dark:text-gray200 ">
                    {property?.address}
                  </p>
                ) : (
                  <SkeletonBar />
                )}
              </div>
              <hr className="border-gray400 my-3 dark:border-gray800 " />
              <div className="flex gap-2 items-end md:items-center justify-between md:px-0 px-2 py-4 fixed bottom-0 inset-x-2 lg:hidden bg-gray200 dark:bg-gray1000 z-50">
                <div className="flex md:hidden flex-col">
                  <p className="font-display text-base text-gray1000 font-medium dark:text-gray200">
                    Total
                  </p>{" "}
                  {isLoadingBookSummary ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <p className="font-display text-base text-gray900 font-bold dark:text-gray200">
                      {bookingSummary?.totalAmount
                        ? currencyFormat(bookingSummary?.totalAmount || 0)
                        : "----"}
                    </p>
                  )}{" "}
                </div>
                <div className="flex items-center">
                  <button
                    disabled={bookingSummaryErrMsg?.length > 1}
                    onClick={() => initiateBooking()}
                    className={`w-[12em] flex font-display gap-1 items-center justify-center ${
                      bookingSummaryErrMsg?.length > 1 || isInitiateLoading
                        ? "bg-primary200"
                        : "bg-primary500 cursor-pointer"
                    } text-eadWhite font-bold p-2 rounded-full shadow-sm text-xs`}
                  >
                    {isInitiateLoading && (
                      <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500 mx-1" />
                    )}
                    <span> Reserve</span>
                  </button>
                </div>
              </div>

              <div>
                {/* types */}
                {property?.bedrooms ? (
                  <div className="flex items-center gap-2 overflow-x-scroll no-scrollbar my-4">
                    <PillsTwo
                      title={`${property?.bedrooms ?? 0} Bedroom${
                        (property?.bedrooms ?? 0) > 1 ? "s" : ""
                      }`}
                      icon={getIconComponent("room-icon")}
                    />
                    <PillsTwo
                      title={`${property?.bathrooms ?? 0} Bathroom${
                        (property?.bathrooms ?? 0) > 1 ? "s" : ""
                      }`}
                      icon={getIconComponent("bathroom-icon")}
                    />
                    <PillsTwo
                      title={`${property?.toilets ?? 0} Toilet${
                        (property?.toilets ?? 0) > 1 ? "s" : ""
                      }`}
                      icon={getIconComponent("toilet-icon")}
                    />
                  </div>
                ) : (
                  <SkeletonBar />
                )}

                {/* details info */}
                {property?.description ? (
                  <p className="text-sm font-display font-light text-gray800 dark:text-gray200  mb-2">
                    {property?.description}
                  </p>
                ) : (
                  <SkeletonBar />
                )}
                {property?.property_amenities?.length > 0 && (
                  <>
                    <hr className="border-gray400 my-3 dark:border-gray800" />
                    <p className="text-lg text-gray-900 font-display font-bold dark:text-gray200">
                      Popular amenities
                    </p>
                  </>
                )}

                {/* amenities */}
                <div className="flex items-center gap-2 flex-wrap my-3">
                  {property?.property_amenities?.map((item) => (
                    <Pills
                      title={item.amenity.name}
                      icon={getIconComponent(item.amenity.icon_tag)}
                    />
                  ))}
                </div>
                <hr className="border-gray400 my-3 dark:border-gray800 " />
                {/* review  */}
                <div className="flex items-center gap-2 flex-wrap my-3 border rounded-xl bg-gray300 dark:bg-gray1000 dark:border-gray800 lg:grid lg:grid-cols-2">
                  {(property?.reviews || []).map((item: ReviewsProps) => (
                    <ReviewsCardInfo
                      key={item.id}
                      name={`${item.user?.first_name} ${item.user?.last_name}`}
                      review={item.comment}
                      location=""
                      date={item?.created_at}
                      pic={item.user?.profile_pic_url || ""}
                    />
                  ))}
                </div>
                {/* about host */}
                <HostUserCard
                  name={
                    property?.host?.first_name + " " + property?.host?.last_name
                  }
                  review="4.50 - 4"
                  pic={property?.host?.profile_pic_url}
                  hostId={property?.host?.id}
                />
                <hr className="border-gray400 my-5 dark:border-gray800 " />

                {/* rules */}
                <div className="hidden lg:block">
                  <p className="text-lg font-bold font-display dark:text-gray200 ">
                    Ground Rules
                  </p>
                  <div className="flex items-center gap-4 my-2">
                    {property?.rules?.map((item) => (
                      <p className="text-sm font-display text-gray800 dark:text-gray200 flex items-center gap-2">
                        {item}
                        <div className="w-3 h-3 bg-gray-300 rounded-full"></div>
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:w-1/3 ">
              <div className="border border-gray400 rounded-xl p-3 dark:border-gray800 dark:bg-eadBlack">
                <div className="flex gap-1 items-center">
                  <p className="text-2xl font-display font-bold text-gray1000 dark:text-gray200 ">
                    {currencyFormat(property?.price || 0)}
                  </p>
                  <p className="text-xs font-display dark:text-gray200">
                    per night
                  </p>
                </div>
                <div className="flex p-2 border border-gray400 dark:border-gray800 rounded-lg justify-between my-4">
                  <div className=" w-full border-r">
                    <p className="text-sm font-display dark:text-gray200 ">
                      Checkin Time
                    </p>
                    <p className="text-sm text-gray1000 font-display dark:text-gray200 dark:bg-gray1000 font-bold">
                      {property?.check_in_time}
                    </p>
                  </div>
                  <div className=" w-full pl-2">
                    <p className="text-sm font-display dark:text-gray200">
                      Checkout Time
                    </p>
                    <p className="text-sm text-gray1000 dark:bg-gray1000 dark:text-gray200 font-display font-bold">
                      {property?.check_out_time}
                    </p>
                  </div>
                </div>

                <div className="relative">
                  <p className="text-sm font-semibold text-primary500">
                    {warning}
                  </p>
                  <div
                    onClick={() =>
                      setIsDateopen(isDateOpen === "flex" ? "hidden" : "flex")
                    }
                    className="cursor-pointer z-50 flex p-2 border border-gray400 dark:border-gray800 rounded-lg justify-between my-4 flex-shrink-0"
                  >
                    <div className="border-r w-1/2">
                      <p className="text-sm font-display dark:text-gray200 ">
                        Checkin Date
                      </p>
                      <p className="text-sm text-gray1000 dark:text-gray200">
                        {moment(checkinDate).format("DD-MM-yyyy")}
                      </p>
                    </div>
                    <div className="pl-2 w-1/2">
                      <p className="text-sm font-display dark:text-gray200">
                        Checkout Date
                      </p>
                      <p className="text-sm text-gray1000 dark:text-gray200">
                        {moment(checkoutDate).format("DD-MM-yyyy")}
                      </p>
                    </div>
                  </div>
                  <div className={`transition-all ${isDateOpen}`}>
                    <DatePicker
                      minDate={new Date()}
                      filterDate={(date) => !isWeekdayDisabled(date)}
                      excludeDates={customDisabledDates}
                      onChange={onChange}
                      startDate={checkinDate}
                      //@ts-ignore
                      endDate={checkoutDate}
                      selectsRange
                      inline
                      className="font-display"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray1000 font-display dark:text-gray200">
                      Adults
                    </p>
                    <p className="text-xs text-gray700 font-display dark:text-gray200">
                      Age 13+
                    </p>
                  </div>

                  <div className="flex gap-4 items-center mt-3">
                    <button
                      onClick={() =>
                        setCountAdult((prev) => (prev === 0 ? 0 : prev - 1))
                      }
                      className=" p-2  rounded-full border border-gray500 bg-gray400 dark:bg-gray600 dark:border-gray800"
                    >
                      <Minusicon />
                    </button>
                    <div className="py-2 px-4  rounded-full border border-gray500 dark:bg-gray1000 dark:border-gray1000">
                      <p className="font-display text-base dark:text-gray200">
                        {countAdult}
                      </p>
                    </div>
                    <button
                      onClick={() =>
                        setCountAdult((prev) =>
                          prev >= property.guests ? prev : prev + 1
                        )
                      }
                      className="p-2  rounded-full border border-gray500 bg-gray400 dark:bg-gray600 dark:border-gray800"
                    >
                      <Plusicon />
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray1000 font-display dark:text-gray200">
                      Children
                    </p>
                    <p className="text-xs text-gray700 font-display dark:text-gray200">
                      Under 12
                    </p>
                  </div>

                  <div className="flex gap-4 items-center mt-3">
                    <button
                      onClick={() =>
                        setCountChildren((prev) => (prev === 0 ? 0 : prev - 1))
                      }
                      className="p-2 rounded-full border border-gray500 bg-gray400 dark:bg-gray600 dark:border-gray800"
                    >
                      <Minusicon />
                    </button>
                    <div className="py-2 px-4  rounded-full border border-gray500 dark:bg-gray1000 dark:border-gray1000">
                      <p className="font-display text-base dark:text-gray200">
                        {countChildren}
                      </p>
                    </div>
                    <button
                      onClick={() => setCountChildren((prev) => prev + 1)}
                      className="p-2  rounded-full border border-gray500 bg-gray400 dark:bg-gray600 dark:border-gray800"
                    >
                      <Plusicon />
                    </button>
                  </div>
                </div>

                <hr className="border-gray400 my-2 dark:border-gray800" />
                {typeof bookingSummaryErrMsg && (
                  <p className="text-sm text-primary500">
                    {bookingSummaryErrMsg}
                  </p>
                )}

                {isLoadingBookSummary ? (
                  <div className="animate-pulse w-full flex items-center justify-between my-3">
                    <div className="h-5 w-40 bg-gray600 rounded"></div>
                    <div className="h-5 w-20 bg-gray600 rounded"></div>
                  </div>
                ) : (
                  <div className="flex items-center justify-between my-3">
                    <p className="font-display text-base text-gray1000 dark:text-gray200">
                      {currencyFormat(property?.price || 0)} *{" "}
                      {bookingSummary?.nights || noOfDays} night
                      {noOfDays > 1 && "s"}
                    </p>
                    <p className="font-display text-base text-gray900 font-medium dark:text-gray200">
                      {bookingSummary?.basePrice
                        ? currencyFormat(bookingSummary?.basePrice || 0)
                        : "----"}
                    </p>
                  </div>
                )}

                <hr className="border-gray400 my-2 dark:border-gray800" />
                <div className="flex items-center justify-between my-3">
                  <p className="font-display text-base text-gray1000 dark:text-gray200">
                    EAD Booking fee
                  </p>
                  {isLoadingBookSummary ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <p className="font-display text-base text-gray900 font-medium dark:text-gray200">
                      {bookingSummary?.serviceFee
                        ? currencyFormat(bookingSummary?.serviceFee || 0)
                        : "----"}
                    </p>
                  )}
                </div>
                <hr className="border-gray400 my-2 dark:border-gray800" />
                <div className="flex items-center justify-between my-3">
                  <p className="font-display text-base text-gray1000 font-medium dark:text-gray200">
                    Total
                  </p>
                  {isLoadingBookSummary ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <p className="font-display text-base text-gray900 font-bold dark:text-gray200">
                      {bookingSummary?.totalAmount
                        ? currencyFormat(bookingSummary?.totalAmount || 0)
                        : "----"}
                    </p>
                  )}
                </div>
                <hr className="border-gray400 my-2 dark:border-gray800 md:block hidden" />

                <div className="md:flex hidden gap-2 items-center justify-between my-3">
                  <button
                    disabled={bookingSummaryErrMsg?.length > 1}
                    onClick={() => initiateBooking()}
                    className={`w-full flex font-display gap-1 items-center justify-center ${
                      bookingSummaryErrMsg?.length > 1 || isInitiateLoading
                        ? "bg-primary200"
                        : "bg-primary500 cursor-pointer"
                    } text-eadWhite font-bold p-2 rounded-full shadow-sm text-xs`}
                  >
                    {isInitiateLoading && (
                      <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500 mx-1" />
                    )}
                    <span> Reserve</span>
                  </button>
                </div>
              </div>

              <div className="border border-gray400 rounded-xl p-3 my-4 dark:border-gray800">
                <div className="flex items-center justify-between mb-2">
                  <p className="text-xl font-bold font-display dark:text-gray200">
                    Location
                  </p>
                  {property?.latitude && property?.longitude ? (
                    <Link
                      to={`https://www.google.com/maps?q=${property?.latitude},${property?.longitude}`}
                      target="_blank"
                      className="text-xs font-display text-primary500"
                    >
                      View in map
                    </Link>
                  ) : (
                    <button
                      disabled
                      className="text-xs font-display text-primary500/50 cursor-not-allowed"
                    >
                      View in map
                    </button>
                  )}
                </div>
                <div>
                  {property?.latitude && property?.longitude ? (
                    <MapView
                      latitude={property?.latitude}
                      longitude={property?.longitude}
                    />
                  ) : (
                    <img src={Maps} alt="" />
                  )}
                </div>
              </div>
            </div>
          </div>

          <hr className="border-gray400 my-5 dark:border-gray800" />

          {/* similar listing */}
          <div className="hidden lg:block">
            {similarProperties?.length > 0 && (
              <div className="flex items-center justify-between">
                <p className="text-xl font-bold font-display dark:text-gray200">
                  Similar Listings
                </p>
                <div className="flex items-center gap-1">
                  <p className="text-xs font-display dark:text-gray200">
                    See all
                  </p>
                  <ChevronRight />
                </div>
              </div>
            )}

            <div className="flex flex-col items-center gap-2 w-full">
              {similarProperties?.slice(0, 3).map((item) => (
                <SimilarListing
                  to={`${paths.VIEW_PROPERTY}/${property?.slug}`}
                  pic={item?.property_media[0]?.url}
                  price={currencyFormat(item?.price)}
                  title={item?.name}
                  description={truncate(item?.description, 35)}
                  location={truncate(item?.address, 30)}
                  hostname={
                    item?.host?.first_name + " " + item?.host?.last_name
                  }
                />
              ))}
            </div>
          </div>

          {/* reservations */}
          <SpecialReservations
            message={`I will like to book ${
              propertyCategories?.subCategories &&
              propertyCategories?.subCategories?.filter(
                (item) => item.id === property.sub_category_id
              )?.[0]?.name
            } with ${property?.bedrooms} Bedroom in ${
              property?.city
            } to stay between ${moment(checkinDate).format(
              "DD-MMM-YYYY"
            )} to ${moment(checkoutDate).format("DD-MMM-YYYY")}`}
          />
        </div>
      </div>

      {/* make bookings */}
      <MakeBookingSection />
      {/* footer */}
      <Footer />
      <Modal
        className="w-[70rem] rounded-xl h-[40rem]"
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <Carousel
          // @ts-ignore
          slides={property?.property_media || []}
          classNameImg="rounded-xl object-contain w-[70rem] h-[40rem]"
        />
      </Modal>
    </div>
  );
};

export default ViewProperty;
