import React from "react";

const Phoneicon = ({...rest}) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M18.9002 8.33724C20.2025 8.59133 21.3994 9.22825 22.3376 10.1665C23.2759 11.1047 23.9128 12.3016 24.1669 13.6039M18.9002 3.00391C21.6059 3.30449 24.129 4.51614 26.0552 6.43992C27.9814 8.36369 29.1962 10.8853 29.5002 13.5906M13.8028 18.8213C12.2007 17.2192 10.9357 15.4077 10.0077 13.4749C9.92786 13.3086 9.88795 13.2255 9.85729 13.1203C9.74833 12.7465 9.82659 12.2875 10.0533 11.9709C10.1171 11.8818 10.1933 11.8056 10.3457 11.6532C10.8118 11.1871 11.0449 10.954 11.1973 10.7196C11.7719 9.83578 11.7719 8.69634 11.1973 7.81248C11.0449 7.57811 10.8118 7.34504 10.3457 6.8789L10.0859 6.61907C9.37727 5.91048 9.02298 5.55619 8.64247 5.36373C7.88572 4.98097 6.99204 4.98097 6.23529 5.36373C5.85478 5.55619 5.50048 5.91048 4.79189 6.61907L4.58171 6.82925C3.87555 7.53542 3.52247 7.8885 3.2528 8.36854C2.95358 8.90122 2.73843 9.72854 2.74025 10.3395C2.74188 10.8901 2.84869 11.2664 3.0623 12.019C4.21026 16.0635 6.37622 19.88 9.5602 23.064C12.7442 26.2479 16.5607 28.4139 20.6052 29.5619C21.3578 29.7755 21.7341 29.8823 22.2847 29.8839C22.8956 29.8857 23.723 29.6706 24.2556 29.3714C24.7357 29.1017 25.0888 28.7486 25.7949 28.0425L26.0051 27.8323C26.7137 27.1237 27.068 26.7694 27.2604 26.3889C27.6432 25.6321 27.6432 24.7385 27.2604 23.9817C27.068 23.6012 26.7137 23.2469 26.0051 22.5383L25.7453 22.2785C25.2791 21.8123 25.0461 21.5793 24.8117 21.4269C23.9278 20.8522 22.7884 20.8522 21.9045 21.4269C21.6702 21.5793 21.4371 21.8123 20.971 22.2785C20.8185 22.4309 20.7423 22.5071 20.6532 22.5709C20.3367 22.7976 19.8777 22.8758 19.5039 22.7669C19.3987 22.7362 19.3155 22.6963 19.1493 22.6165C17.2165 21.6885 15.4049 20.4234 13.8028 18.8213Z"
      stroke="#525252"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Phoneicon;
