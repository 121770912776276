import { useState } from "react";
import { CardCheckItem } from "../../../../components/custom/select-input";
import NavigateButton from "./NavigateButton";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../routes/paths";
import { usePropertyForm } from "../hooks/use-property-form";

const PropertyAmenity = () => {
  const navigate = useNavigate();
  const { setPropertyFieldValue, propertyFormValue, propertyCategories } =
    usePropertyForm();
  const [selectAmenities, setSelectedAmenities] = useState<number[]>([
    ...propertyFormValue.amenities,
  ]);

  const handleAmenities = (id: number) => {
    if (selectAmenities.includes(id)) {
      setSelectedAmenities(selectAmenities.filter((x) => x !== id));
    } else {
      setSelectedAmenities([...selectAmenities, id]);
    }
  };

  return (
    <div className="flex flex-col items-center mx-4 overflow-scroll no-scrollbar">
      <p className="font-display text-xl lg:text-2xl font-bold text-gray1000 lg:mx-8 lg:text-center xl:text-center mb-8 dark:text-gray200">
        Tell guests what your place has to offer
      </p>
      <div className="w-full">
        <div className="flex flex-wrap items-center justify-center lg:gap-2 gap-1">
          {propertyCategories.amenities.map((item, index) => (
            <CardCheckItem
              key={index}
              icon={item.icon_tag}
              onSelect={() => handleAmenities(item.id)}
              title={item.name}
              isSelected={selectAmenities.includes(item.id)}
            />
          ))}
        </div>
      </div>
      {/* navigation */}
      <NavigateButton
        onBack={() => navigate(-1)}
        onNext={() => {
          setPropertyFieldValue({
            ...propertyFormValue,
            amenities: selectAmenities,
          });
          navigate(`${paths.HOST_LANDING}/property-image-upload`);
        }}
      />
    </div>
  );
};

export default PropertyAmenity;
