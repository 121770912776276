import React, { useCallback, useEffect, useState } from "react";
import { SelectInput } from "../../../../components/custom/select-input";
import { InputField } from "../../../../components/custom/text-input";
import { countries } from "../../../../utils/static-data";
import ToggleButton from "../../../../components/custom/toggle-button";
import { usePropertyForm } from "../hooks/use-property-form";
import { useLocation, useNavigate } from "react-router-dom";
import { paths } from "../../../../routes/paths";
import NavigateButton from "./NavigateButton";
import { FormProvider, useForm } from "react-hook-form";
import useGooglePlaceInfo from "../../../../hooks/use-google-place-info";

const ConfirmAddress = () => {
  const navigate = useNavigate();
  const methods = useForm();
  const { state } = useLocation();
  const { place } = useGooglePlaceInfo(state?.placeId);
  const { propertyFormValue, setPropertyFieldValue } = usePropertyForm();
  const [toggle, setToggle] = useState(false);
  const [country, setCountry] = useState("");
  const addressArray = propertyFormValue.address.split(",");

  useEffect(() => {
    populateAddress();
    // eslint-disable-next-line
  }, [place]);

  const onSubmit = methods.handleSubmit(async (data) => {
    await setPropertyFieldValue({
      ...propertyFormValue,
      address: `${data.house},${data.street},${data.town},${data.city},${data.country}`,
      country: data.country,
      city: data.city,
      // @ts-ignore
      latitude: place?.geometry.location.lat,
      // @ts-ignore
      longitude: place?.geometry.location.lng,
      google_location: JSON.stringify(place),
      hide_location: toggle
    });
    navigate(`${paths.HOST_LANDING}/property-type`);
    methods.reset();
  });

  const populateAddress = useCallback(() => {
    if (place?.address_components) {
      for (let index = 0; index < place?.address_components.length; index++) {
        if (place?.address_components[index]?.types.includes("street_number")) {
          methods.setValue(
            "house",
            place?.address_components[index]?.long_name || ""
          );
        }
        if (place?.address_components[index]?.types.includes("route")) {
          methods.setValue(
            "street",
            place?.address_components[index]?.long_name || ""
          );
        }
        if (place?.address_components[index]?.types.includes("neighborhood")) {
          methods.setValue(
            "town",
            place?.address_components[index]?.long_name || ""
          );
        }
        if (place?.address_components[index]?.types.includes("locality")) {
          methods.setValue(
            "city",
            place?.address_components[index]?.long_name || ""
          );
        }
        if (place?.address_components[index]?.types.includes("country")) {
          methods.setValue(
            "country",
            place?.address_components[index]?.long_name || ""
          );
          setCountry(place?.address_components[index]?.long_name);
        }
      }
    } else {
      setCountry("Nigeria");
      methods.setValue("country", "Nigeria");
      methods.setValue(
        "house",
        addressArray.length > 0 ? addressArray[0]?.trim() : ""
      );
      methods.setValue(
        "town",
        addressArray.length > 1 ? addressArray[2]?.trim() : ""
      );
      methods.setValue(
        "city",
        addressArray.length > 2 ? addressArray[3]?.trim() : ""
      );
      methods.setValue(
        "street",
        addressArray.length > 1 ? addressArray[1]?.trim() : ""
      );
    }

    // eslint-disable-next-line
  }, [place]);

  return (
    <div className="flex flex-col items-center mx-2">
      <p className="font-display text-xl lg:text-2xl font-bold text-gray1000 lg:mx-8 lg:text-center xl:text-center mb-8 dark:text-gray200">
        Confirm your address
      </p>
      <div className="w-full lg:w-4/5">
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => e.preventDefault()}
            noValidate
            autoComplete="off"
          >
            <SelectInput
              label="Country"
              id="country"
              placeholder="Select your country"
              onChange={(text: any) => setCountry(text.target.value)}
              data={countries}
              value={country}
            />
            <hr className="my-2 border-gray400" />
            <div className="lg:flex items-center justify-between mb-2 gap-2">
              <InputField
                label="Street address"
                placeholder="Street address"
                type="text"
                name="street"
                validation={{
                  required: {
                    value: true,
                    message: "Street cannot be empty",
                  },
                }}
              />
              <InputField
                label="Exact Floor, flat number, house number"
                placeholder="House number"
                type="text"
                name="house"
                validation={{
                  required: {
                    value: false,
                    message: "House number cannot be empty",
                  },
                }}
              />
            </div>
            <div className="lg:flex items-center justify-between mb-2 gap-2">
              <InputField
                label="City"
                placeholder="City"
                type="text"
                name="city"
                validation={{
                  required: {
                    value: true,
                    message: "City cannot be empty",
                  },
                }}
              />
              <InputField
                label="Town"
                placeholder="Town"
                type="text"
                name="town"
                validation={{
                  required: {
                    value: true,
                    message: "Town cannot be empty",
                  },
                }}
              />
            </div>
          </form>
        </FormProvider>
        <hr className="my-2 border-gray400" />
        <div className="flex justify-between items-start gap-2">
          <div>
            <p className="font-display text-xs font-bold text-gray1000">
              Specify location in description
            </p>
            <p className="font-display text-xs font-medium text-gray700">
              We will only share specific location after you have been book (eg.
              Lekki, Lagos). You can turn on specific location if you like to
              make it clear to guest before booking eg. (Randle st, Lekki Lagos)
            </p>
          </div>
          <button>
            <ToggleButton
              isActive={toggle}
              onToggle={() => setToggle(!toggle)}
            />
          </button>
        </div>
      </div>
      {/* navigation */}
      <NavigateButton onBack={() => navigate(-1)} onNext={onSubmit} />
    </div>
  );
};

export default ConfirmAddress;
