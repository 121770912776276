import React from "react";

const Emailicon = ({...rest}) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M3.16699 9.66797L14.0536 17.2886C14.9351 17.9057 15.3759 18.2142 15.8553 18.3337C16.2789 18.4393 16.7218 18.4393 17.1453 18.3337C17.6248 18.2142 18.0655 17.9057 18.9471 17.2886L29.8337 9.66797M9.56699 27.0013H23.4337C25.6739 27.0013 26.794 27.0013 27.6496 26.5653C28.4023 26.1818 29.0142 25.5699 29.3977 24.8173C29.8337 23.9616 29.8337 22.8415 29.8337 20.6013V12.068C29.8337 9.82776 29.8337 8.70765 29.3977 7.85201C29.0142 7.09936 28.4023 6.48744 27.6496 6.10394C26.794 5.66797 25.6739 5.66797 23.4337 5.66797H9.56699C7.32678 5.66797 6.20668 5.66797 5.35103 6.10394C4.59838 6.48744 3.98646 7.09936 3.60297 7.85201C3.16699 8.70765 3.16699 9.82776 3.16699 12.068V20.6013C3.16699 22.8415 3.16699 23.9616 3.60297 24.8173C3.98646 25.5699 4.59838 26.1818 5.35103 26.5653C6.20668 27.0013 7.32678 27.0013 9.56699 27.0013Z"
      stroke="#525252"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Emailicon;
