import React from "react";

const CloseMobileIcon = ({ ...rest }) => (
  <svg
    width="45"
    height="35"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect
      className="dark:fill-gray800"
      x="0.791016"
      width="44"
      height="44"
      rx="22"
      fill="#E5E5E5"
    />
    <path
      className="dark:stroke-gray300"
      d="M27.791 17L17.791 27M17.791 17L27.791 27"
      stroke="#525252"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default CloseMobileIcon;
