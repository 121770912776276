import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { InputField, InputTextAreaField, PhoneInputField } from "./text-input";
import {
  email_validation,
  name_validation,
} from "../../utils/input-validation";
import { getCurrentUser } from "../../redux/reducers/user-slice";
import { makeSpecialReservations } from "../../redux/actions/property";

type Props = {
  message?: string;
};

const SpecialReservations = ({ message }: Props) => {
  const dispatch = useDispatch();
  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const user = useSelector(getCurrentUser);
  const [phone, setPhone] = useState("");
  const [errMsg, setErrorMsg] = useState<string | null>(null);
  const to_do_type = "pending_special_reservation";

  useEffect(() => {
    methods.setValue("email", user?.email);
    methods.setValue("first_name", user?.first_name);
    methods.setValue("last_name", user?.last_name);
    methods.setValue("message", message);
    setPhone(user?.phone);
    // eslint-disable-next-line
  }, [window.location.pathname, user, message]);

  const onSubmit = methods.handleSubmit(async (data) => {
    setErrorMsg(null);
    if (phone.length < 9) {
      setErrorMsg("Phone number is required");
      return;
    }

    setLoading(true);
    await dispatch(
      // @ts-ignore
      makeSpecialReservations({
        ...data,
        to_do_type: to_do_type,
        phone_number: phone,
      })
    );
    setLoading(false);
    methods.reset();
  });

  return (
    <div className="py-12">
      <p className="text-xl font-display font-bold text-gray1000 dark:text-gray200">
        Want to make special reservations?
      </p>
      <p className="text-sm font-display text-gray800 dark:text-gray200">
        Send us a message and we would reach back to you as soon as possible
      </p>

      <FormProvider {...methods}>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
        >
          <div className="lg:flex gap-4 my-4">
            <InputField
              label="First name*"
              placeholder="Enter your first name"
              type="text"
              name="first_name"
              validation={name_validation}
            />
            <InputField
              label="Last name*"
              placeholder="Enter your last name"
              type="text"
              name="last_name"
              validation={name_validation}
            />
          </div>
          <div className="lg:flex gap-4 my-4">
            <InputField
              label="Email*"
              placeholder="Enter your email"
              type="email"
              name="email"
              validation={email_validation}
            />
            <PhoneInputField
              label="Phone*"
              value={phone}
              onChange={(phone: any) => setPhone(phone)}
              inputProps={{
                name: "phone",
                required: true,
                // autoFocus: true,
              }}
              // isValid={phone.length > 9}
              errMsg={errMsg}
            />
          </div>
          <InputTextAreaField
            label="Your message (optional)"
            placeholder="I am looking for shortlet to rent in Lekki. Please let me know about any suitable properties you have. Thanks."
            name="message"
            className="lg:w-full text-sm"
          />
          <button
            onClick={onSubmit}
            disabled={loading}
            className="text-sm text-eadWhite bg-primary500 rounded-full p-2 px-3 font-semibold my-4 flex gap-2 items-center"
          >
            Send message
            {loading && (
              <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
            )}
          </button>
        </form>
      </FormProvider>
    </div>
  );
};

export default SpecialReservations;
