import React from "react";

type Props = {
  color?: string;
  className?: string;
};

const DeleteIcon = ({ color, className, ...rest }: Props) => (
  <svg
    className={`flex-shrink-0 md:w-5 md:h-5 h-4 w-4 ${className}`}
    width="20"
    height="23"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="dark:stroke-gray100"
      d="M14 5.66797V4.86797C14 3.74786 14 3.18781 13.782 2.75999C13.5903 2.38366 13.2843 2.0777 12.908 1.88596C12.4802 1.66797 11.9201 1.66797 10.8 1.66797H9.2C8.07989 1.66797 7.51984 1.66797 7.09202 1.88596C6.71569 2.0777 6.40973 2.38366 6.21799 2.75999C6 3.18781 6 3.74786 6 4.86797V5.66797M8 11.168V16.168M12 11.168V16.168M1 5.66797H19M17 5.66797V16.868C17 18.5481 17 19.3882 16.673 20.0299C16.3854 20.5944 15.9265 21.0534 15.362 21.341C14.7202 21.668 13.8802 21.668 12.2 21.668H7.8C6.11984 21.668 5.27976 21.668 4.63803 21.341C4.07354 21.0534 3.6146 20.5944 3.32698 20.0299C3 19.3882 3 18.5481 3 16.868V5.66797"
      stroke="#FF3333"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default DeleteIcon;
