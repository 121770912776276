import React from "react";

const Bedroom = ({ ...rest }) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1.00006 6.70987C1.00006 6.32697 1.00006 6.13552 1.04941 5.9592C1.09313 5.80303 1.16497 5.65613 1.26141 5.52574C1.37029 5.37854 1.52141 5.261 1.82365 5.02592L6.34519 1.50917C6.57941 1.327 6.69652 1.23592 6.82583 1.2009C6.93993 1.17001 7.06019 1.17001 7.17429 1.2009C7.30361 1.23592 7.42071 1.327 7.65493 1.50917L12.1765 5.02592C12.4787 5.261 12.6298 5.37854 12.7387 5.52574C12.8352 5.65613 12.907 5.80303 12.9507 5.9592C13.0001 6.13552 13.0001 6.32697 13.0001 6.70987V11.5332C13.0001 12.2799 13.0001 12.6533 12.8547 12.9385C12.7269 13.1894 12.5229 13.3933 12.272 13.5212C11.9868 13.6665 11.6135 13.6665 10.8667 13.6665H3.13339C2.38666 13.6665 2.01329 13.6665 1.72807 13.5212C1.47719 13.3933 1.27322 13.1894 1.14539 12.9385C1.00006 12.6533 1.00006 12.2799 1.00006 11.5332V6.70987Z"
      stroke="#A3A3A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Bedroom;
