import React from "react";

type Props = {
  color?: string;
};

const ArrowTopRightWhite = ({ color, ...rest }: Props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...rest}>
    <path
      d="M7 17.666L17 7.66602M17 7.66602H7M17 7.66602V17.666"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ArrowTopRightWhite;
