import React, { useEffect, useState } from "react";
import EditIcon from "../../../../components/icons/edit";
import { PropertyType } from "../../../../redux/types";
import {
  fetchSingleProperty,
  updateProperty,
} from "../../../../redux/actions/property";
import { useDispatch } from "react-redux";
import DropdownTwo from "../../../../components/custom/drop-down-two";
import { TextInput } from "../../../../components/custom/text-input";

interface PropertyPreferenceProps {
  activeListing: PropertyType;
}

const durationFrame = [
  { label: "Week(s)", value: "w" },
  { label: "Month(s)", value: "m" },
  { label: "Hour(s)", value: "h" },
];

const PropertyPreference: React.FC<PropertyPreferenceProps> = ({
  activeListing,
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [bookMethod, setBookMethod] = useState("");
  const [minframe, setMinframe] = useState("");
  const [minDuration, setMinduration] = useState("");
  const [maxframe, setMaxframe] = useState("");
  const [maxDuration, setMaxduration] = useState("");

  useEffect(() => {
    setMaxduration(
      activeListing?.maximum_booking_notice?.charAt(
        activeListing?.maximum_booking_notice?.length - 1
      ) || "m"
    );
    setMinduration(
      activeListing?.minimum_booking_notice?.charAt(
        activeListing?.minimum_booking_notice?.length - 1
      ) || "h"
    );
    setMinframe(
      activeListing?.minimum_booking_notice?.substring(
        0,
        activeListing?.minimum_booking_notice?.length - 1
      ) || "1"
    );
    setMaxframe(
      activeListing?.maximum_booking_notice?.substring(
        0,
        activeListing?.maximum_booking_notice?.length - 1
      ) || "1"
    );
    setBookMethod(activeListing?.booking_type || "");
  }, [activeListing]);

  const handleSaveClick = async () => {
    const res = await dispatch(
      // @ts-ignore
      updateProperty({
        id: activeListing?.id,
        maximum_booking_notice: maxframe + maxDuration,
        minimum_booking_notice: minframe + minDuration,
        booking_type: bookMethod,
      })
    );

    if (res) {
      // @ts-ignore
      dispatch(fetchSingleProperty(activeListing?.id));
      setEditMode(false);
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  const readbalePeriod = (txt: string) => {
    if (txt.includes("w")) return txt.substring(0, txt.length - 1) + " Week(s)";
    if (txt.includes("h")) return txt.substring(0, txt.length - 1) + " Hour(s)";
    if (txt.includes("m"))
      return txt.substring(0, txt.length - 1) + " Month(s)";
    return "no period set";
  };

  const stripPeriods = (txt: string) => {
    if (txt.includes("w")) return "Week(s)";
    if (txt.includes("h")) return "Hour(s)";
    if (txt.includes("m")) return "Month(s)";
    return "no period set";
  };

  const readableBookingtype = (txt: string) => {
    switch (txt) {
      case "instant":
        return `Instant Booking`;
      case "requires_approval":
        return `Non Instant Booking`;
      default:
        break;
    }
  };

  return (
    <div className="h-fit w-full text-gray900 dark:text-gray100 border border-gray400 dark:border-gray800 rounded-3xl p-4 flex flex-col gap-2">
      <div className="flex flex-wrap md:gap-0 gap-3 items-center justify-between mb-5">
        <span className="font-bold">Property preference</span>
        {editMode ? (
          <div className="flex gap-2">
            <button
              onClick={handleCancelClick}
              className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out h-10 flex items-center gap-2 rounded-full font-bold text-sm px-4"
            >
              Cancel
            </button>
            <button
              onClick={handleSaveClick}
              className={` bg-primary500 hover:bg-primary600 transition-all duration-200 ease-in-out h-10 flex items-center gap-2 rounded-full font-bold text-sm px-4 text-white`}
            >
              Save
            </button>
          </div>
        ) : (
          <button
            onClick={() => setEditMode(true)}
            className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out flex items-center gap-2 rounded-full font-bold text-sm h-9 px-3"
          >
            Edit
            <EditIcon />
          </button>
        )}
      </div>
      {editMode ? (
        <>
          <p className="text-xs font-semibold ">Minimum Notice Period</p>
          <div className="flex items-center gap-3 w-full">
            <TextInput
              onChange={(e) => setMinframe(e.target.value)}
              type="number"
              placeholder="1"
              className="w-full lg:w-1/3"
              labelClass="text-xs"
              label="Duration"
              value={minframe}
            />
            <DropdownTwo
              label="Durarion frame"
              // @ts-ignore
              options={durationFrame}
              // @ts-ignore
              onSelect={(e) => setMinduration(e)}
              className="lg:w-1/3"
              value={stripPeriods(minDuration || "")}
            />
          </div>
          <p className="text-[10px]">
            What is the minimum amount of time you require between guest booking
            and guest check-in
          </p>
          <p className="text-xs font-semibold">Maximum Notice Period</p>
          <div className="flex items-center gap-3 w-full">
            <TextInput
              onChange={(e) => setMaxframe(e.target.value)}
              type="number"
              placeholder="1"
              className="w-full lg:w-1/3"
              labelClass="text-xs"
              label="Duration"
              value={maxframe}
            />
            <DropdownTwo
              label="Durarion frame"
              // @ts-ignore
              options={durationFrame}
              // @ts-ignore
              onSelect={(e) => setMaxduration(e)}
              className="lg:w-1/3"
              value={stripPeriods(maxDuration || "")}
            />
          </div>
          <p className="text-[10px]">
            What is the longest time in advance guests can book your property
            e.g, if you set to 1 month, guest can book your property 1 month in
            advance from the current date
          </p>
          <DropdownTwo
            label="Booking Method"
            // @ts-ignore
            options={[
              { label: "Instant booking", value: "instant" },
              { label: "Non Instant booking", value: "requires_approval" },
            ]}
            onSelect={(e) => setBookMethod(e)}
            value={readableBookingtype(bookMethod)}
          />
          <p className="text-[10px]">
            With instant booking, guests will automatically book your properties
            as long as long as the properties are available while require
            approval allows you to manually approve every new booking request.
          </p>
        </>
      ) : (
        <>
          <p className="font-semibold text-gray800 dark:text-gray100">
            Minimum Notice Period
          </p>
          <p className="text-xs">
            What is the minimum amount of time you require between guest booking
            and guest check-in
          </p>
          <p>{readbalePeriod(activeListing?.minimum_booking_notice || "")}</p>
          <p className="font-semibold text-gray800 dark:text-gray100">
            Maximum Notice Period
          </p>
          <p className="text-xs">
            What is the longest time in advance guests can book your property
            e.g, if you set to 1 month, guest can book your property 1 month in
            advance from the current date
          </p>
          <p>{readbalePeriod(activeListing?.maximum_booking_notice || "")}</p>
          <p className="font-semibold text-gray800 dark:text-gray100">
            Booking method
          </p>
          <p className="text-xs">
            With instant booking, guests will automatically book your properties
            as long as long as the properties are available while require
            approval allows you to manually approve every new booking request.
          </p>
          <p>{readableBookingtype(activeListing?.booking_type || "")}</p>
        </>
      )}
    </div>
  );
};

export default PropertyPreference;
