import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../components/custom/navbar";
import Footer from "../../../components/custom/footer";
import { useEffect, useState } from "react";
import { paths } from "../../../routes/paths";
import { useDispatch, useSelector } from "react-redux";
import { viewChecklist } from "../../../redux/actions/property";
import {
  fetchAllBanks,
  fetchUser,
  fetchUserProperties,
} from "../../../redux/actions/authuser";
import BreadcrumbsTwo from "../host-dashboard/components/breadcrumb-two";
import { getCurrentUser } from "../../../redux/reducers/user-slice";
import VerificationCardView from "../host-dashboard/components/verification-card-view";
import { FaChevronDown } from "react-icons/fa";

const Guest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector(getCurrentUser);
  const pathnames = location.pathname.split("/").filter((x) => x);
  const [active, setActive] = useState(pathnames[1]);
  const currentProfile = localStorage.getItem("USERTYPE");
  const [toggled, setToggled] = useState(true);

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchUser());
    // @ts-ignore
    dispatch(fetchUserProperties());
    // @ts-ignore
    dispatch(viewChecklist());

    // @ts-ignore
    dispatch(fetchAllBanks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActive(pathnames[1]);
  }, [pathnames]);

  const sideRoutes = [
    { name: "Bookings", route: "bookings" },
    { name: "Favourites", route: "favourites" },
    { name: "Settings", route: "settings" },
    { name: "Promotions", route: "promotions" },
    { name: "Verification", route: "verification" },
    { name: "Legal", route: "legal" },
  ];

  const SideTab = ({
    action,
    path,
    title,
  }: {
    action: () => void;
    path: string;
    title: string;
  }) => (
    <p
      onClick={action}
      className={`font-display text-sm font-bold p-2 dark:text-gray200 transition-all duration-200 ease-in-out ${
        active === path && "bg-gray400 dark:bg-gray800"
      } rounded-lg my-3 cursor-pointer`}
    >
      {title}
    </p>
  );

  return (
    <div className="bg-eadWhite max-w-screen-2xl mx-auto relative dark:bg-gray1000">
      <Navbar />
      <div className="max-w-screen-xl mx-auto lg:py-6">
        <div className="mb-8 px-3">
          <BreadcrumbsTwo name="Guest" path={`${paths.GUEST}/bookings`} />
        </div>

        <div className="flex items-center gap-3 p-2">
          {profile?.profile_pic_url ? (
            <img
              src={profile.profile_pic_url || ""}
              className="lg:w-16 lg:h-16 w-10 h-10 rounded-full"
              alt=""
            />
          ) : (
            <div className="lg:w-14 lg:h-14 w-10 h-10 rounded-full bg-gray500 flex item-center justify-center" />
          )}
          <p className="text-lg font-semibold dark:text-gray200 text-gray1000 lg:text-2xl">
            Welcome back, {profile?.first_name}
          </p>
        </div>
        <hr className="border-gray400 dark:border-gray800  my-5" />
      </div>
      <div className="lg:flex items-start justify-center lg:gap-6 gap-4 mx-2 max-w-screen-xl lg:mx-auto">
        {pathnames[1] === "bookings" && (
          <div className="block lg:hidden xl:hidden 2xl:hidden mb-4">
            <VerificationCardView />
          </div>
        )}
        <div className="lg:w-1/3 w-full border rounded-2xl border-gray-300 dark:bg-gray1000 dark:border-gray800 bg-gray300 px-3  h-fit">
          <div
            onClick={() => setToggled((prev) => !prev)}
            className="flex items-center justify-between"
          >
            <p
              className={`font-display text-sm font-bold p-2 dark:text-gray200 transition-all duration-200 ease-in-out rounded-lg my-3 cursor-pointer`}
            >
              Menu
            </p>
            <FaChevronDown
              className={`text-gray-800 transform transition-transform duration-300 ${
                toggled ? "rotate-180" : ""
              }`}
            />
          </div>
          {toggled && (
            <>
              {sideRoutes.map((item) => (
                <SideTab
                  action={() => {
                    setActive(item.route);
                    navigate(`${paths.GUEST}/${item.route}`);
                  }}
                  path={item.route}
                  title={item.name}
                />
              ))}
              <p
                onClick={() => {
                  if (currentProfile === "HOST") {
                    localStorage.setItem("USERTYPE", "GUEST");
                    navigate(paths.GUEST + "/bookings");
                  } else {
                    localStorage.setItem("USERTYPE", "HOST");
                    navigate(paths.HOST_DASHBOARD + "/latest-activity");
                  }
                }}
                className={`font-display text-sm font-bold p-2 dark:text-gray200 transition-all duration-200 ease-in-out rounded-lg my-3 cursor-pointer`}
              >
                {currentProfile === "HOST"
                  ? "Switch to guest"
                  : "Switch to host"}
              </p>
            </>
          )}
        </div>
        <div className="w-full my-3 mt-5 overflow-y-auto">
          <Outlet />
        </div>
        {pathnames[1] === "bookings" && (
          <div className="hidden lg:block xl:block 2xl:block w-full lg:w-1/3">
            <VerificationCardView />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Guest;
