import React from "react";

const NGNicon = ({ ...rest }) => (
  <svg width="24" height="23" viewBox="0 0 24 23" fill="none" {...rest}>
    <path
      d="M-0.000488281 11.3336C-0.000488281 16.4933 3.25601 20.8917 7.82562 22.5873V0.0800781C3.25601 1.77555 -0.000488281 6.17411 -0.000488281 11.3336Z"
      fill="#6DA544"
    />
    <path
      d="M23.9999 11.3336C23.9999 6.17411 20.7434 1.77555 16.1738 0.0800781V22.5873C20.7434 20.8917 23.9999 16.4933 23.9999 11.3336Z"
      fill="#6DA544"
    />
  </svg>
);

export default NGNicon;
