import React, { useState } from "react";
import Minusicon from "../../../../components/icons/minus-icon";
import Plusicon from "../../../../components/icons/plus-icon";
import NavigateButton from "./NavigateButton";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../routes/paths";
import { usePropertyForm } from "../hooks/use-property-form";

const SetPriceProperty = () => {
  const navigate = useNavigate();
  const { setPropertyFieldValue, propertyFormValue } = usePropertyForm();
  const [pricing, setPricing] = useState(
    Number(propertyFormValue.price) || 1000
  );

  return (
    <div className="flex flex-col items-center mx-4 overflow-scroll no-scrollbar">
      <p className="font-display text-xl lg:text-2xl font-bold text-gray1000 dark:text-gray200 lg:mx-8 lg:text-center xl:text-center mb-8">
        Set a price for your property
      </p>
      <div className="w-full">
        <div className="w-full flex flex-col items-center border rounded-lg border-gray500 py-8 px-2 my-4 bg-gray100 dark:bg-gray1000 dark:border-gray800">
          <div className="flex items-center justify-center gap-2 mb-6">
            <button
              onClick={() => pricing > 1 && setPricing((prev) => prev - 1000)}
              className=" p-1 rounded-lg border border-gray500 shadow-md dark:border-gray800"
            >
              <Minusicon />
            </button>

            <select
              name="currency"
              id=""
              className="text-sm font-display dark:bg-gray1000 dark:text-gray200"
            >
              <option value="1">NGN</option>
            </select>

            <p className="font-display text-4xl font-extrabold dark:bg-gray1000">
              {/* {pricing.toLocaleString()} */}
              <input
                className="font-display text-4xl font-extrabold dark:bg-gray1000 dark:text-gray200 w-40 outline-none focus:border-primary500 border border-transparent transition-all duration-200 ease-in-out rounded-lg px-2"
                type="text"
                value={pricing !== null ? pricing.toString() : ""}
                onChange={(e) => {
                  const value = e.target.value;

                  const numericValue = value
                    .replace(/[^0-9]/g, "")
                    .replace(/^0+/, "");

                  const formattedValue =
                    numericValue === "" ? 0 : Number(numericValue);

                  setPricing(formattedValue);
                }}
              />
            </p>

            <button
              onClick={() => setPricing((prev) => prev + 1000)}
              className="p-1  rounded-lg border border-gray500 shadow-md dark:border-gray800"
            >
              <Plusicon />
            </button>
          </div>
          <p className="font-display text-sm text-gray700 dark:text-gray200">
            Guests will be charged{" "}
            <span className="font-display font-extrabold text-gray1000 underline dark:text-gray200">
              ₦{pricing.toLocaleString()}
            </span>{" "}
            per night
          </p>
          {/* <p className="text-sm text-primary500 font-medium">
            See how we calculate commission
          </p> */}
        </div>
      </div>
      {/* navigation */}
      <NavigateButton
        onBack={() => navigate(-1)}
        disable={pricing <= 0}
        onNext={() => {
          setPropertyFieldValue({
            ...propertyFormValue,
            price: pricing,
            currency_id: 1,
          });
          navigate(`${paths.HOST_LANDING}/property-rules`);
        }}
      />
    </div>
  );
};

export default SetPriceProperty;
