import React from "react";

const StarPrimary = ({ ...rest }) => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.0435 2.27402C13.3508 1.65143 13.5045 1.34014 13.7131 1.24068C13.8946 1.15415 14.1054 1.15415 14.2869 1.24068C14.4955 1.34014 14.6492 1.65143 14.9565 2.27402L17.872 8.18064C17.9628 8.36444 18.0081 8.45634 18.0744 8.5277C18.1331 8.59087 18.2035 8.64206 18.2817 8.67842C18.37 8.71949 18.4714 8.73432 18.6743 8.76396L25.1959 9.7172C25.8827 9.81758 26.2261 9.86777 26.385 10.0355C26.5233 10.1815 26.5883 10.382 26.562 10.5813C26.5317 10.8104 26.2831 11.0525 25.7859 11.5368L21.0686 16.1315C20.9215 16.2747 20.848 16.3463 20.8005 16.4315C20.7585 16.507 20.7316 16.5899 20.7212 16.6756C20.7094 16.7724 20.7268 16.8736 20.7615 17.0759L21.8746 23.5658C21.992 24.2502 22.0507 24.5925 21.9404 24.7956C21.8444 24.9723 21.6738 25.0962 21.476 25.1329C21.2488 25.175 20.9415 25.0134 20.3268 24.6901L14.4965 21.624C14.3148 21.5285 14.224 21.4807 14.1283 21.462C14.0436 21.4454 13.9564 21.4454 13.8717 21.462C13.776 21.4807 13.6852 21.5285 13.5035 21.624L7.67319 24.6901C7.05851 25.0134 6.75117 25.175 6.52392 25.1329C6.3262 25.0962 6.15559 24.9723 6.05961 24.7956C5.94929 24.5925 6.00799 24.2502 6.12539 23.5658L7.23847 17.0759C7.27318 16.8736 7.29053 16.7724 7.27879 16.6756C7.26839 16.5899 7.24144 16.507 7.19943 16.4315C7.15199 16.3463 7.07846 16.2747 6.93141 16.1315L2.21404 11.5368C1.71684 11.0525 1.46825 10.8104 1.43799 10.5813C1.41167 10.382 1.4767 10.1815 1.61496 10.0355C1.77387 9.86777 2.11725 9.81758 2.80402 9.7172L9.32571 8.76396C9.52852 8.73432 9.62993 8.71949 9.71825 8.67842C9.79644 8.64206 9.86684 8.59087 9.92554 8.5277C9.99183 8.45634 10.0372 8.36444 10.1279 8.18064L13.0435 2.27402Z"
        fill="#FF5C5C"
        stroke="#FF5C5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarPrimary;
