import React from "react";
import { Link } from "react-router-dom";
import EAD from "../../assets/images/EADlogo.png";
import Twitter from "../icons/twitter";
import Facebook from "../icons/facebook";
import Instagram from "../icons/instagram";
// import Android from "../../assets/images/android.png";
// import Apple from "../../assets/images/apple.png";

const Footer = () => {
  return (
    <footer className="bg-white text-gray800 pb-4 px-4 lg:px-10 dark:bg-gray1000">
      <div className=" max-w-screen-2xl relative mx-auto">
        <div className="lg:flex  justify-between items-center border-t-2 border-b-2 border-gray300 dark:border-gray800 py-20">
          <div className="flex flex-col gap-10">
            <img
              src={EAD}
              alt=""
              className="object-contain w-auto h-14 self-start"
            />
            <p className="text-sm font-display w-2/3 dark:text-gray300">
              There’s a home you’ll love at Eve After Dark, the largest market
              for shortlet accommodation.
            </p>
            <div className="flex gap-4 text- flex-wrap w-full">
              <Link
                to="/"
                className="text-xs font-semibold font-display dark:text-gray300"
              >
                Home
              </Link>
              <Link
                to="/host"
                className="text-xs font-semibold font-display dark:text-gray300"
              >
                Become a host
              </Link>
              <Link
                to="/"
                className="text-xs font-semibold font-display dark:text-gray300"
              >
                Make a booking
              </Link>
              <Link
                to="/faq"
                className="text-xs font-semibold font-display dark:text-gray300"
              >
                FAQs
              </Link>
              <Link
                to="/privacy-policy"
                className="text-xs font-semibold font-display dark:text-gray300"
              >
                Privacy
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-6">
            {/* <p className="text-sm text-primary600 my-4">Get the app</p>
            <img
              src={Apple}
              alt=""
              className="object-contain w-auto h-12 self-start"
            />
            <img
              src={Android}
              alt=""
              className="object-contain w-auto h-12 self-start"
            /> */}
          </div>
        </div>
        <div className="py-4">
          <div className="flex items-center justify-between">
            <p className="text-xs font-display dark:text-gray300">
              © 2024 Eve After Dark. All rights reserved.
            </p>
            <div className="flex gap-4">
              <Twitter className="h-4" />
              <Facebook className="h-4" />
              <Instagram className="h-4" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
