import React, { useState } from "react";
import { TextAreaInput } from "../../../../components/custom/text-input";
import NavigateButton from "./NavigateButton";
import { useNavigate } from "react-router-dom";
import { usePropertyForm } from "../hooks/use-property-form";
import { paths } from "../../../../routes/paths";

const AddMoreDetails = () => {
  const navigate = useNavigate();
  const { setPropertyFieldValue, propertyFormValue } = usePropertyForm();
  const [details, setDetails] = useState(propertyFormValue.description || "");

  return (
    <div className="flex flex-col items-center mx-4 overflow-scroll no-scrollbar">
      <p className="font-display text-xl lg:text-2xl font-bold text-gray1000 lg:mx-8 lg:text-center xl:text-center mb-8 dark:text-gray200">
        Is there something unique that you’ll like to add?
      </p>
      <div className="w-full">
        <TextAreaInput
          placeholder="Enter description"
          label="A few simple and unique sentences could go a long way."
          onChange={(val: any) => setDetails(val.target.value)}
          value={details}
           className="dark:bg-gray800 dark:text-gray200"
        />
      </div>
      {/* navigation */}
      <NavigateButton
        onBack={() => navigate(-1)}
        disable={details.length === 0}
        onNext={() => {
          setPropertyFieldValue({
            ...propertyFormValue,
            description: details,
          });
          navigate(`${paths.HOST_LANDING}/property-contact`);
        }}
      />
    </div>
  );
};

export default AddMoreDetails;
