import ActivityCard from "./components/activity-card";
import DataFilter from "./components/latest-activity-data-filter";

interface LatestActivityProps {}

const LatestActivity: React.FC<LatestActivityProps> = () => {
  return (
    <div className="flex flex-col gap-10 md:px-7">
      {/* main body */}
      <div className="flex md:flex-row flex-col gap-5 pt-2 font-display text-gray1000 dark:text-gray100">
        <div className="w-full h-fit dark:border-gray800 flex flex-col">
          <ActivityCard />
          <DataFilter />
        </div>
      </div>
    </div>
  );
};

export default LatestActivity;
