import React from "react";

const Facebook = ({ ...rest }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clip-path="url(#clip0_53_38917)">
      <path
        d="M24 12.6665C24 6.03908 18.6274 0.666504 12 0.666504C5.37258 0.666504 0 6.03908 0 12.6665C0 18.656 4.3882 23.6205 10.125 24.5207V16.1353H7.07812V12.6665H10.125V10.0228C10.125 7.01525 11.9166 5.354 14.6576 5.354C15.9701 5.354 17.3438 5.58838 17.3438 5.58838V8.5415H15.8306C14.34 8.5415 13.875 9.46658 13.875 10.4165V12.6665H17.2031L16.6711 16.1353H13.875V24.5207C19.6118 23.6205 24 18.656 24 12.6665Z"
        fill="#A3A3A3"
      />
    </g>
    <defs>
      <clipPath id="clip0_53_38917">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.666504)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Facebook;
