import { useEffect, useRef, useState } from "react";
import { paths } from "../../../../routes/paths";
import { InstantSearch, useSearchBox } from "react-instantsearch";
import { searchClient } from "../../../../plugins/algolia-client";
import DatePicker from "react-datepicker";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import AutocompletePlace from "../../../../components/custom/autocomplete-places";
import { addDays } from "date-fns";
import useGooglePlaceInfo from "../../../../hooks/use-google-place-info";

type Props = {
  setIsOpen: () => void;
  open: boolean;
};

type ValuePiece = Date | null;

const locations = [
  { name: "Lagos", className: "lagosBg" },
  { name: "Abuja", className: "abujaBg" },
  { name: "Ibadan", className: "lagosBg" },
];

function CustomSearchBox({ city }: { city: string }) {
  const { refine } = useSearchBox();
  useEffect(() => {
    refine(city);
  }, [city, refine]);
  return <div></div>;
}

const SearchModal = ({ open }: Props) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [selectedPlace, setSelectedPlace] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [bedroomCount, setBedroomCount] = useState(1);
  const [city, setCity] = useState("");
  const [checkinDate, setCheckinDate] = useState<ValuePiece>(new Date());
  const [checkoutDate, setCheckoutDate] = useState<ValuePiece>( addDays(new Date()?.toISOString(), 1) || new Date());
  const [placeId, setPlaceId] = useState<string>("");

  const { place } = useGooglePlaceInfo(placeId);

  useEffect(() => {
    setTimeout(() => inputRef?.current?.focus(), 300);
  }, [open]);

  console.log('searchTextsearchText', searchText)

  return (
    <div className="w-full bg-eadWhite rounded-2xl dark:dark:bg-eadBlack">
      <InstantSearch searchClient={searchClient} indexName="properties">
        <CustomSearchBox city={searchText} />
        <AutocompletePlace
          ref={inputRef}
          onChange={(e) => {
            setSearchText(e.target.value);
            setCity(e.target.value);
          }}
          value={searchText}
          onClose={() => {
            setSearchText("");
            setCity("");
          }}
          onSelectAddress={(address) => {
            setSearchText(address);
            setCity("");
          }}
          onSelectPlaceId={(id) => setPlaceId(id)}
          address={city}
        />
        <hr className="border-gray400 dark:border-gray700" />

        <div className="w-full">
          <div className="flex gap-2 p-3 md:p-5">
            {searchText.length === 0 &&
              locations.map((location) => (
                <div
                  key={location.name}
                  className={`w-20 h-20 ${
                    location.className
                  } rounded-lg border font-semibold ${
                    selectedPlace.includes(location.name)
                      ? "border-red-500 bg-primary100/70"
                      : "border-gray400 bg-white/50"
                  } flex items-center justify-center cursor-pointer transition-all duration-200 ease-in-out text-sm dark:text-gray1000 text-gray1000`}
                  onClick={() => {
                    setSelectedPlace(location.name);
                    setSearchText(`${location.name}, Nigeria`);
                    setCity(`${location.name}, Nigeria`);
                  }}
                >
                  {location.name}
                </div>
              ))}
          </div>
          <div className="grid md:grid-cols-4 gap-3 border-t dar:border-gray700 p-2 md:p-4">
            <div className="w-full overflow-x-hidden border-r p-2 dark:border-gray700">
              <p className="text-sm text-gray1000 font-display dark:text-gray200">
                Check in
              </p>
              <DatePicker
                minDate={new Date()}
                selected={checkinDate}
                onChange={(date) => {
                  setCheckinDate(date);
                  setCheckoutDate(
                    addDays(date?.toISOString() || new Date(), 1)
                  );
                }}
                className="text-sm dark:bg-eadBlack dark:text-gray200 font-semibold w-full outline-none focus:border focus:border-primary500 transition-all duration-200 ease-in-out rounded"
              />
            </div>
            <div className="w-full overflow-x-hidden border-r p-2 dark:border-gray700">
              <p className="text-sm text-gray1000 font-display dark:text-gray200">
                Check out
              </p>
              <DatePicker
                // @ts-ignore
                minDate={addDays(checkinDate, 1)}
                selected={checkoutDate}
                onChange={(date) => {
                  setCheckoutDate(date);
                }}
                className="text-sm dark:bg-eadBlack dark:text-gray200 font-semibold w-full outline-none focus:border focus:border-primary500 transition-all duration-200 ease-in-out rounded"
              />
            </div>
            <div className="w-full overflow-x-hidden border-r px-2 py-1 dark:border-gray700">
              <p className="text-sm text-gray1000 font-display dark:text-gray200">
                Bedroom
              </p>
              <div className="flex items-center gap-5">
                <button
                  onClick={() =>
                    setBedroomCount((prev) => (prev === 0 ? 0 : prev - 1))
                  }
                  className="p-1 text-sm font-bold rounded-full border border-gray500"
                >
                  <FaMinus />
                </button>
                <div className="py-1 px-3 rounded-full border border-gray500">
                  <p className="font-display text-sm">{bedroomCount}</p>
                </div>
                <button
                  onClick={() => setBedroomCount((prev) => prev + 1)}
                  className="p-1 text-sm  font-bold rounded-full border border-gray500"
                >
                  <FaPlus />
                </button>
              </div>
            </div>

            <div className="flex items-center justify-center w-full">
              {" "}
              <button
                onClick={() => {
                  if (
                    place?.geometry.location.lat &&
                    place?.geometry.location.lng
                  ) {
                    navigate(
                      `${paths.SEARCH_LIST}?citySearch=${searchText}&bedroom=${bedroomCount}&checkinDate=${checkinDate}&checkoutDate=${checkoutDate}&latitude=${place?.geometry.location.lat}&longitude=${place?.geometry.location.lng}`
                    );
                  } else {
                    navigate(
                      `${paths.SEARCH_LIST}?citySearch=${searchText}&bedroom=${bedroomCount}&checkinDate=${checkinDate}&checkoutDate=${checkoutDate}`
                    );
                  }
                }}
                className="text-sm h-9 px-2 shadow-lg rounded-full text-eadWhite bg-primary500 font-display font-semibold w-full"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </InstantSearch>
    </div>
  );
};

export default SearchModal;
