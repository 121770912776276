import React from "react";

const TelegramFilled = ({ ...rest }) => (
  <svg
    {...rest}
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.666016"
      width="39"
      height="39"
      rx="19.5"
      fill="#FAFAFA"
    />
    <rect
      x="0.5"
      y="0.666016"
      width="39"
      height="39"
      rx="19.5"
      stroke="#E5E5E5"
    />
    <g clip-path="url(#clip0_1701_15882)">
      <path
        d="M20 32.166C26.6274 32.166 32 26.7934 32 20.166C32 13.5386 26.6274 8.16602 20 8.16602C13.3726 8.16602 8 13.5386 8 20.166C8 26.7934 13.3726 32.166 20 32.166Z"
        fill="url(#paint0_linear_1701_15882)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.432 20.0399C16.9303 18.5158 19.263 17.511 20.4301 17.0256C23.7627 15.6394 24.4551 15.3987 24.9065 15.3907C25.0058 15.389 25.2277 15.4136 25.3715 15.5302C25.4929 15.6287 25.5263 15.7618 25.5423 15.8552C25.5583 15.9486 25.5782 16.1614 25.5623 16.3276C25.3817 18.2251 24.6003 22.8298 24.2028 24.955C24.0346 25.8542 23.7034 26.1558 23.3827 26.1853C22.6858 26.2494 22.1567 25.7247 21.4817 25.2823C20.4256 24.59 19.8289 24.159 18.8037 23.4834C17.619 22.7027 18.387 22.2736 19.0622 21.5723C19.2389 21.3887 22.3093 18.596 22.3687 18.3426C22.3762 18.3109 22.3831 18.1928 22.3129 18.1305C22.2427 18.0681 22.1392 18.0894 22.0644 18.1064C21.9585 18.1304 20.2713 19.2456 17.0028 21.452C16.5238 21.7808 16.0901 21.9411 15.7014 21.9327C15.2729 21.9234 14.4488 21.6904 13.8361 21.4912C13.0846 21.247 12.4873 21.1178 12.5393 20.7029C12.5664 20.4868 12.864 20.2658 13.432 20.0399Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1701_15882"
        x1="20"
        y1="8.16602"
        x2="20"
        y2="31.988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2AABEE" />
        <stop offset="1" stop-color="#229ED9" />
      </linearGradient>
      <clipPath id="clip0_1701_15882">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(8 8.16602)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default TelegramFilled;
