import React, { FC } from "react";

interface Props extends React.SVGProps<SVGSVGElement> {}

const HeartFull: FC<Props> = ({ ...rest }) => (
  <svg
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g opacity="0.8">
      <rect y="0.335938" width="36" height="36" rx="18" fill="#F5F5F5" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9936 12.6158C16.3275 10.6679 13.5491 10.144 11.4616 11.9276C9.37405 13.7113 9.08016 16.6934 10.7195 18.8029C12.0825 20.5569 16.2075 24.256 17.5594 25.4533C17.7107 25.5872 17.7863 25.6542 17.8745 25.6805C17.9515 25.7035 18.0358 25.7035 18.1128 25.6805C18.201 25.6542 18.2766 25.5872 18.4279 25.4533C19.7798 24.256 23.9048 20.5569 25.2678 18.8029C26.9071 16.6934 26.6491 13.6925 24.5257 11.9276C22.4023 10.1627 19.6598 10.6679 17.9936 12.6158Z"
        fill="#FF3333"
        stroke="#FF3333"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export default HeartFull;
