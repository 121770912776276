import { useState } from "react";
import { CardRadioItem } from "../../../../components/custom/select-input";
import NavigateButton from "./NavigateButton";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../routes/paths";
import { usePropertyForm } from "../hooks/use-property-form";

const PropertyType = () => {
  const navigate = useNavigate();
  const { propertyFormValue, setPropertyFieldValue, propertyCategories } =
    usePropertyForm();
  const [propertyType, setPropertyType] = useState<number>(
    propertyFormValue.sub_category_id || 0
  );
  console.log(propertyCategories);
  return (
    <div className="flex flex-col items-center mx-2">
      <p className="font-display text-xl lg:text-2xl font-bold text-gray1000 lg:mx-8 lg:text-center xl:text-center mb-8 dark:text-gray200">
        What type of property do you own?
      </p>
      <div className="w-full">
        <div className="flex items-center justify-center gap-1 flex-wrap">
          {propertyCategories.subCategories.map((item, index) => (
            <CardRadioItem
              icon={item.icon_url}
              onSelect={() => setPropertyType(item.id)}
              title={item.name}
              isSelected={propertyType === item.id}
            />
          ))}
        </div>
      </div>
      {/* navigation */}
      <NavigateButton
        onBack={() => navigate(-1)}
        disable={propertyType === 0}
        onNext={() => {
          setPropertyFieldValue({
            ...propertyFormValue,
            sub_category_id: propertyType,
          });
          navigate(`${paths.HOST_LANDING}/property-details`);
        }}
      />
    </div>
  );
};

export default PropertyType;
