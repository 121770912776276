import React, { FC } from 'react';

interface HouseIconProps {
  className?: string;
}

const HouseIcon: FC<HouseIconProps> = ({ className }) => (
  <svg
    className={`md:w-5 md:h-5 w-4 h-4 ${className || ''}`}
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 12.1863H14M10.0598 8.14306V16.1245M9.0177 1.95028L2.23539 7.2254C1.78202 7.57802 1.55534 7.75433 1.39203 7.97514C1.24737 8.17072 1.1396 8.39106 1.07403 8.62533C1 8.8898 1 9.17698 1 9.75133V16.9863C1 18.1064 1 18.6664 1.21799 19.0942C1.40973 19.4706 1.71569 19.7765 2.09202 19.9683C2.51984 20.1863 3.07989 20.1863 4.2 20.1863H15.8C16.9201 20.1863 17.4802 20.1863 17.908 19.9683C18.2843 19.7765 18.5903 19.4706 18.782 19.0942C19 18.6664 19 18.1064 19 16.9863V9.75133C19 9.17698 19 8.8898 18.926 8.62533C18.8604 8.39106 18.7526 8.17072 18.608 7.97514C18.4447 7.75433 18.218 7.57802 17.7646 7.22541L10.9823 1.95028C10.631 1.67703 10.4553 1.5404 10.2613 1.48788C10.0902 1.44154 9.9098 1.44154 9.73865 1.48788C9.54468 1.5404 9.36902 1.67703 9.0177 1.95028Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HouseIcon;
