import React from "react";

type Props = {
  images: { id: string; url: string }[];
  seeAllactions?: () => void;
};

export const ImageGridStatic = ({
  images = [],
  seeAllactions,
  ...rest
}: Props) => {
  return (
    <div className="w-full lg:flex gap-4 my-3 h-2/6 hidden">
      <div className="w-2/3 relative rounded-xl h-1/2 bg-gray300">
        {images.length > 0 ? (
          <img
            onClick={seeAllactions}
            src={images[0].url}
            alt=""
            className="rounded-xl w-full object-fit cursor-pointer"
            style={{ height: "470px" }}
          />
        ) : (
          <div className="animate-pulse w-full">
            <div className="rounded-xl h-80 w-full bg-gray600"></div>
          </div>
        )}
      </div>

      <div className="w-1/3  rounded-xl flex flex-col justify-between gap-3">
        {images.length > 1 ? (
          <img
            src={images[1].url}
            alt=""
            className="rounded-xl w-full object-fit"
            style={{ height: "230px" }}
          />
        ) : (
          <div className="animate-pulse w-full">
            <div className="rounded-xl h-40 w-full bg-gray600"></div>
          </div>
        )}
        <div
          onClick={seeAllactions}
          className="relative items-center flex flex-col justify-center rounded-xl"
        >
          <div className="bg-gray1000 opacity-70 w-full h-full absolute rounded-xl" />
          {images.length > 2 ? (
            <img
              src={images[2].url}
              alt=""
              className="rounded-xl w-full object-fit cursor-pointer"
              style={{ height: "230px" }}
            />
          ) : (
            <div className="animate-pulse w-full">
              <div className="rounded-xl h-40 w-full bg-gray600"></div>
            </div>
          )}
          {images.length > 2 && (
            <p className="absolute text-lg font-display text-gray300 underline font-semibold text-center">
              See all photos
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
