import React from "react";

const FacebookFilled = ({ ...rest }) => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" {...rest}>
    <rect
      x="0.5"
      y="0.666016"
      width="39"
      height="39"
      rx="19.5"
      fill="#FAFAFA"
    />
    <rect
      x="0.5"
      y="0.666016"
      width="39"
      height="39"
      rx="19.5"
      stroke="#E5E5E5"
    />
    <g clip-path="url(#clip0_1701_15874)">
      <path
        d="M32 20.166C32 13.5386 26.6274 8.16602 20 8.16602C13.3726 8.16602 8 13.5386 8 20.166C8 26.1555 12.3882 31.12 18.125 32.0202V23.6348H15.0781V20.166H18.125V17.5223C18.125 14.5148 19.9166 12.8535 22.6576 12.8535C23.9701 12.8535 25.3438 13.0879 25.3438 13.0879V16.041H23.8306C22.34 16.041 21.875 16.9661 21.875 17.916V20.166H25.2031L24.6711 23.6348H21.875V32.0202C27.6118 31.12 32 26.1555 32 20.166Z"
        fill="#1877F2"
      />
      <path
        d="M24.6711 23.6348L25.2031 20.166H21.875V17.916C21.875 16.967 22.34 16.041 23.8306 16.041H25.3438V13.0879C25.3438 13.0879 23.9705 12.8535 22.6576 12.8535C19.9166 12.8535 18.125 14.5148 18.125 17.5223V20.166H15.0781V23.6348H18.125V32.0202C19.3674 32.2146 20.6326 32.2146 21.875 32.0202V23.6348H24.6711Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1701_15874">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(8 8.16602)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default FacebookFilled;
