import React from "react";

const Twitter = ({ ...rest }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7.55016 22.4165C16.6045 22.4165 21.5583 14.9132 21.5583 8.40836C21.5583 8.19743 21.5536 7.9818 21.5442 7.77086C22.5079 7.07397 23.3395 6.21076 24 5.2218C23.1025 5.6211 22.1496 5.88189 21.1739 5.99524C22.2013 5.37941 22.9705 4.41198 23.3391 3.27227C22.3726 3.84506 21.3156 4.24912 20.2134 4.46711C19.4708 3.67806 18.489 3.15562 17.4197 2.98055C16.3504 2.80549 15.2532 2.98755 14.2977 3.4986C13.3423 4.00965 12.5818 4.82121 12.1338 5.80782C11.6859 6.79442 11.5754 7.90112 11.8195 8.9568C9.86249 8.85859 7.94794 8.35021 6.19998 7.4646C4.45203 6.579 2.90969 5.33594 1.67297 3.81602C1.0444 4.89975 0.852057 6.18215 1.13503 7.4026C1.418 8.62304 2.15506 9.68995 3.19641 10.3865C2.41463 10.3617 1.64998 10.1512 0.965625 9.77243V9.83336C0.964925 10.9707 1.3581 12.0731 2.07831 12.9533C2.79852 13.8335 3.80132 14.4371 4.91625 14.6615C4.19206 14.8596 3.43198 14.8885 2.69484 14.7459C3.00945 15.7239 3.62157 16.5794 4.44577 17.1928C5.26997 17.8063 6.26512 18.1471 7.29234 18.1677C5.54842 19.5376 3.39417 20.2806 1.17656 20.2771C0.783287 20.2765 0.390399 20.2524 0 20.2049C2.25286 21.6503 4.87353 22.4179 7.55016 22.4165Z"
      fill="#A3A3A3"
    />
  </svg>
);

export default Twitter;
