import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import Googleicon from "../../assets/images/googleicon.png";
import { useDispatch, useSelector } from "react-redux";
import { signInGoogleAuth } from "../../redux/actions/authuser";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes/paths";
import { showToast } from "../../utils/helper-functions";
import { getIsLoading } from "../../redux/reducers/loader-slice";

const GoogleSignin: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(getIsLoading);
  const [loading, setLoading] = useState(false);
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setLoading(true);
      // @ts-ignore
      const res = await dispatch(signInGoogleAuth(codeResponse.access_token));
      setLoading(false);
      if (res) {
        navigate(paths.HOST_DASHBOARD + "/latest-activity");
      }
    },
    onError(errorResponse) {
      console.log(errorResponse);
      showToast(
        errorResponse.error_description || "Something went wrong",
        "danger"
      );
    },
    flow: "implicit",
  });

  return (
    <button
      // @ts-ignore
      onClick={login}
      disabled={loading || isLoading}
      className="flex items-center justify-center p-2 dark:bg-gray800 dark:border-gray900  border border-gray400 bg-gray200 rounded-full shadow-md mb-4 w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100"
    >
      {loading && (
        <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500 mr-3" />
      )}
      <img src={Googleicon} alt="" className="h-4" />
      <span className="mx-2 text-gray1000 dark:text-gray200 text-center font-display font-bold text-xs">
        Sign in with Google
      </span>
    </button>
  );
};

export default GoogleSignin;
