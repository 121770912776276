import React from "react";

const LinkedinFilled = ({ ...rest }) => (
  <svg
    {...rest}
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.666016"
      width="39"
      height="39"
      rx="19.5"
      fill="#FAFAFA"
    />
    <rect
      x="0.5"
      y="0.666016"
      width="39"
      height="39"
      rx="19.5"
      stroke="#E5E5E5"
    />
    <g clip-path="url(#clip0_1701_15880)">
      <path
        d="M30.2283 8.16602H9.77167C9.30179 8.16602 8.85116 8.35267 8.51891 8.68493C8.18666 9.01718 8 9.46781 8 9.93768V30.3943C8 30.8642 8.18666 31.3149 8.51891 31.6471C8.85116 31.9794 9.30179 32.166 9.77167 32.166H30.2283C30.6982 32.166 31.1488 31.9794 31.4811 31.6471C31.8133 31.3149 32 30.8642 32 30.3943V9.93768C32 9.46781 31.8133 9.01718 31.4811 8.68493C31.1488 8.35267 30.6982 8.16602 30.2283 8.16602ZM15.1533 28.611H11.545V17.1493H15.1533V28.611ZM13.3467 15.561C12.9374 15.5587 12.5379 15.4352 12.1987 15.2061C11.8596 14.977 11.5958 14.6525 11.4409 14.2737C11.2859 13.8949 11.2466 13.4786 11.328 13.0775C11.4094 12.6763 11.6078 12.3083 11.8982 12.0198C12.1885 11.7313 12.5578 11.5353 12.9595 11.4565C13.3611 11.3777 13.7771 11.4196 14.155 11.577C14.5328 11.7344 14.8555 12.0002 15.0825 12.3408C15.3094 12.6815 15.4303 13.0817 15.43 13.491C15.4339 13.765 15.3825 14.0371 15.279 14.2908C15.1755 14.5446 15.022 14.7749 14.8276 14.9681C14.6332 15.1612 14.4018 15.3133 14.1474 15.4152C13.893 15.517 13.6207 15.5666 13.3467 15.561ZM28.4533 28.621H24.8467V22.3594C24.8467 20.5127 24.0617 19.9427 23.0483 19.9427C21.9783 19.9427 20.9283 20.7493 20.9283 22.406V28.621H17.32V17.1577H20.79V18.746H20.8367C21.185 18.041 22.405 16.836 24.2667 16.836C26.28 16.836 28.455 18.031 28.455 21.531L28.4533 28.621Z"
        fill="#0A66C2"
      />
    </g>
    <defs>
      <clipPath id="clip0_1701_15880">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(8 8.16602)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LinkedinFilled;
