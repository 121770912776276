import React, { FC } from "react";

interface CheckMarkIconProps extends React.SVGProps<SVGSVGElement> {}

const CheckMarkIcon: FC<CheckMarkIconProps> = ({ ...rest }) => (
  <svg
    className="md:w-4 md:h-4 w-3 h-3"
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      className="dark:stroke-gray100"
      d="M14.6667 1.66797L5.50004 10.8346L1.33337 6.66797"
      stroke="#737373"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckMarkIcon;
