import React from "react";
import { Link } from "react-router-dom";
import ChevronDown from "../icons/chevron-down";
import Avatar from "../icons/avatar";
import EADLogo from "../../assets/images/EADlogo.png";
import { paths } from "../../routes/paths";

const NavbarHost = () => {
  return (
    <header className="sticky inset-x-0 top-0 bg-gray200 dark:bg-gray1000">
      <nav className="flex items-center justify-between p- lg:px-8">
        <div className="flex items-center gap-3">
          <Link
            to={paths.SEARCH_LIST}
            className="rounded-full 2xl:flex xl:flex lg:flex py-2 px-4 gap-3 items-center border border-gray400 bg-gray300 hidden dark:bg-gray800 dark:border-gray800"
          >
            <span className="text-sm text-gray1000 font-display font-semibold dark:text-gray200">
              Make booking
            </span>
          </Link>
        </div>
      
          <Link to={paths.HOME} className="my-2 flex flex-col items-center gap-1">
            <img src={EADLogo} alt="" className="h-12" />
            <p className="text-xs lg:text-sm text-gray1000 dark:text-gray200 font-semibold text-center">
              EAD Shortlets
            </p>
          </Link>
       
        <div className="flex items-center gap-4">
          <Link
            to={paths.SIGNUP}
            className="hidden 2xl:flex xl:flex lg:flex items-center rounded-full p-2 gap-2 border border-gray400 bg-gray300 dark:bg-gray1000 dark:border-gray800"
          >
            <ChevronDown />
            <Avatar className="h-6" />
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default NavbarHost;
