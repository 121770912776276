import React, { useState } from "react";
// import PropertyDetailCard from "../../../../components/custom/property-detail-card";
import Minusicon from "../../../../components/icons/minus-icon";
import Plusicon from "../../../../components/icons/plus-icon";
import NavigateButton from "./NavigateButton";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../routes/paths";
import { usePropertyForm } from "../hooks/use-property-form";

const MoreDetailsProperty = () => {
  const navigate = useNavigate();
  const { setPropertyFieldValue, propertyFormValue } = usePropertyForm();
  const [bathroomCount, setBathroomCount] = useState(
    propertyFormValue.bathrooms || 1
  );
  const [bedroomCount, setBedroomCount] = useState(
    propertyFormValue.bedrooms || 1
  );
  const [toiletCount, setToiletCount] = useState(
    propertyFormValue.toilets || 1
  );
  const [guestCount, setGuestCount] = useState(propertyFormValue.guests || 1);

  return (
    <div className="flex flex-col items-center mx-2">
      <p className="font-display text-xl lg:text-2xl font-bold text-gray1000 lg:mx-4 lg:text-center xl:text-center mb-8">
        More details about this property
      </p>
      <div className="flex flex-wrap items-center justify-center gap-1 w-full">
        <div className="rounded-xl w-36 p-2 border border-gray500 m-1 ">
          <p className="font-display text-sm font-medium dark:text-gray200">
            Bedrooms
          </p>
          <div className="flex gap-2 items-center mt-3">
            <button
              onClick={() =>
                bedroomCount > 1 && setBedroomCount((prev) => prev - 1)
              }
              className=" p-1  rounded-full border border-gray500 dark:dark:bg-gray1000 "
            >
              <Minusicon />
            </button>
            <div className="p-1 px-3 rounded-full border border-gray500 ">
              <p className="font-display text-base dark:text-gray200">
                {bedroomCount}
              </p>
            </div>
            <button
              onClick={() => setBedroomCount((prev) => prev + 1)}
              className="p-1 rounded-full border border-gray500 dark:dark:bg-gray1000 "
            >
              <Plusicon />
            </button>
          </div>
        </div>
        <div className="rounded-xl w-36 p-2 border border-gray500 m-2">
          <p className="font-display text-base font-medium dark:text-gray200">
            Bathrooms
          </p>
          <div className="flex gap-2 items-center mt-3">
            <button
              onClick={() =>
                bathroomCount > 1 && setBathroomCount((prev) => prev - 1)
              }
              className=" p-1  rounded-full border border-gray500  dark:dark:bg-gray1000"
            >
              <Minusicon />
            </button>
            <div className="p-1 px-3 rounded-full border border-gray500 ">
              <p className="font-display text-base dark:text-gray200">
                {bathroomCount}
              </p>
            </div>
            <button
              onClick={() => setBathroomCount((prev) => prev + 1)}
              className="p-1 rounded-full border border-gray500 dark:dark:bg-gray1000"
            >
              <Plusicon />
            </button>
          </div>
        </div>
        <div className="rounded-xl w-36 p-2 border border-gray500 m-2">
          <p className="font-display text-base font-medium dark:text-gray200">
            Toilets
          </p>
          <div className="flex gap-2 items-center mt-3">
            <button
              onClick={() =>
                toiletCount > 1 && setToiletCount((prev) => prev - 1)
              }
              className=" p-1 rounded-full border border-gray500 dark:dark:bg-gray1000"
            >
              <Minusicon />
            </button>
            <div className="p-1 px-3 rounded-full border border-gray500 ">
              <p className="font-display text-base dark:text-gray200 ">
                {toiletCount}
              </p>
            </div>
            <button
              onClick={() => setToiletCount((prev) => prev + 1)}
              className="p-1 rounded-full border border-gray500 dark:dark:bg-gray1000"
            >
              <Plusicon />
            </button>
          </div>
        </div>
        <div className="rounded-xl w-36 p-2 border border-gray500 m-2 ">
          <p className="font-display text-base font-medium dark:text-gray200">
            Guests
          </p>
          <div className="flex gap-2 items-center mt-3">
            <button
              onClick={() =>
                guestCount > 1 && setGuestCount((prev) => prev - 1)
              }
              className=" p-1 rounded-full border border-gray500 dark:dark:bg-gray1000"
            >
              <Minusicon />
            </button>
            <div className="p-1 px-3 rounded-full border border-gray500 ">
              <p className="font-display text-base dark:text-gray200 ">
                {guestCount}
              </p>
            </div>
            <button
              onClick={() => setGuestCount((prev) => prev + 1)}
              className="p-1 rounded-full border border-gray500 dark:bg-gray1000"
            >
              <Plusicon />
            </button>
          </div>
        </div>
      </div>
      {/* navigation */}
      <NavigateButton
        onBack={() => navigate(-1)}
        onNext={() => {
          setPropertyFieldValue({
            ...propertyFormValue,
            bedrooms: bedroomCount,
            bathrooms: bathroomCount,
            toilets: toiletCount,
            guests: guestCount,
          });
          navigate(`${paths.HOST_LANDING}/property-amenity`);
        }}
      />
    </div>
  );
};

export default MoreDetailsProperty;
