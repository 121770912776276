import React from "react";

const Plusicon = ({ ...rest }) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      className="dark:stroke-gray100"
      d="M10.5001 4.49805V16.1647M4.66675 10.3314H16.3334"
      stroke="#525252"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Plusicon;
