import { FaStar } from "react-icons/fa";

const ratingStar = Array(5);

const ReviewStarsRating = ({ rating }: { rating: number }) => {
  return (
    <div className="flex items-center gap-1 text-sm">
      {[...ratingStar].map((_, index) => (
        <FaStar
          className={`text-base ${
            Number(rating) < index + 1 ? "text-gray-500" : "text-amber-500"
          }`}
        />
      ))}
    </div>
  );
};

export default ReviewStarsRating;
