import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputField } from "../../../components/custom/text-input";
import Emojihmm from "../../../assets/images/emojihmm.png";
import Sentemail from "../../../assets/images/sentemail.png";
import { paths } from "../../../routes/paths";
import ChevronLeft from "../../../components/icons/chevron-left";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoading } from "../../../redux/reducers/loader-slice";
import { forgotPassword } from "../../../redux/actions/authuser";
import Navbar from "../../../components/custom/navbar";
import { FormProvider, useForm } from "react-hook-form";
import { email_validation } from "../../../utils/input-validation";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm();
  const [success, setSuccess] = useState(false);
  const isLoading = useSelector(getIsLoading);
  const [email, setEmail] = useState("");

  const onSubmit = methods.handleSubmit(async (data) => {
    setEmail(data.email);
    // @ts-ignore
    const res = await dispatch(forgotPassword(data));
    methods.reset();
    if (res) {
      setSuccess(true);
      return;
    }
  });

  return (
    <div className="dark:bg-gray1000 h-screen">
      <div className="max-w-screen-xl sticky top-0 mx-auto z-50">
        <Navbar />
      </div>
      <div className="flex flex-col items-center mt-8 lg:mt-24 gap-8 relative px-4 ">
        <div>
          <img
            src={success ? Sentemail : Emojihmm}
            alt=""
            className="object-contain"
          />
        </div>
        <p className="text-2xl font-display font-bold text-gray1000 dark:text-gray200 text-center">
          {success
            ? "We’ve sent it, now check your mail!"
            : "Mmm, forgotten your password?"}
        </p>
        <p className="text-bases font-display text-gray1000 text-center dark:text-gray200">
          {success
            ? `A magic link has been sent to ${email}`
            : "Enter your registered email, we’ll send you reset instructions."}
        </p>
        <div className="flex flex-col items-center justify-center mx-3 w-full lg:w-1/2 gap-6">
          {!success && (
            <FormProvider {...methods}>
              <form
                onSubmit={(e) => e.preventDefault()}
                noValidate
                autoComplete="off"
                className="w-full flex flex-col items-center justify-center gap-3"
              >
                <InputField
                  label="Your Email*"
                  placeholder="Enter your email"
                  type="email"
                  name="email"
                  validation={email_validation}
                />
                <button
                  onClick={onSubmit}
                  disabled={isLoading}
                  className={`${
                    isLoading ? "bg-primary300" : "bg-primary500"
                  } opacity flex justify-center cursor-pointer p-2 text-gray100 rounded-full text-center font-display font-bold text-xs shadow-md mb-4 w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
                >
                  {isLoading ? (
                    <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
                  ) : (
                    "Forgot password"
                  )}
                </button>
              </form>
            </FormProvider>
          )}

          {success && (
            <button
              onClick={() =>
                navigate(paths.RESET_PASSWORD, {
                  state: { email: email },
                })
              }
              className="p-2 text-gray100 bg-primary500 rounded-full text-center font-display font-bold text-xs shadow-md mb-4 w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100"
            >
              Reset password
            </button>
          )}

          {success && (
            <button
              onClick={() => console.log("resend it")}
              className="text-xs text-gray1000 dark:text-gray200 text-center my-2"
            >
              Didn’t receive the email?{" "}
              <span className="text-xs text-primary500 mx-2">
                Click to resend
              </span>
            </button>
          )}

          <Link to={paths.SIGNIN} className="items-center my-2 flex">
            <ChevronLeft />
            <span className="text-xs text-gray1000 dark:text-gray200 mx-2 font-display font-semibold">
              Back to log in
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
