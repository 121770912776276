import React, { FC } from "react";

interface Props extends React.SVGProps<SVGSVGElement> {}

const Award: FC<Props> = ({ ...rest }) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    { ...rest }
  >
    <g clip-path="url(#clip0_1450_19001)">
      <path
        d="M3.5 8.21213V11.667L5.85144 10.7264C5.90632 10.7045 5.93376 10.6935 5.9621 10.6891C5.98722 10.6853 6.01278 10.6853 6.0379 10.6891C6.06624 10.6935 6.09368 10.7045 6.14856 10.7264L8.5 11.667V8.21213M9.75 5.41699C9.75 7.48806 8.07107 9.16699 6 9.16699C3.92893 9.16699 2.25 7.48806 2.25 5.41699C2.25 3.34592 3.92893 1.66699 6 1.66699C8.07107 1.66699 9.75 3.34592 9.75 5.41699Z"
        stroke="#FF5C5C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1450_19001">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 0.666992)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Award;
