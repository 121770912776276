import { useEffect, useState } from "react";
import { paths } from "../../../../routes/paths";
import BreadcrumbsTwo from "../../host-dashboard/components/breadcrumb-two";
import UpcomingBookings from "./UpcomingBookings";
import PastBookings from "./PastBookings";
import { useDispatch } from "react-redux";
import { fetchGuestBookings } from "../../../../redux/actions/property";

const tabs = [
  { title: "Upcoming bookings", filter: "upcoming" },
  { title: "Past bookings", filter: "completed" },
];

const MyBookings = () => {
  const dispatch = useDispatch();

  const [booking, setBooking] = useState("upcoming");

  useEffect(() => {
    //@ts-ignore
    dispatch(fetchGuestBookings(booking));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //@ts-ignore
    dispatch(fetchGuestBookings(booking));

    // eslint-disable-next-line
  }, [booking]);

  return (
    <div>
      <div className="mb-10">
        <BreadcrumbsTwo name="My Booking" path={`${paths.GUEST}/bookings`} />
      </div>
      <div className="flex items-center gap-3">
        {tabs.map((tab) => (
          <p
            onClick={() => setBooking(tab.filter)}
            className={`font-display text-sm lg:text-base font-bold cursor-pointer text-gray1000 dark:text-gray200 transition-all duration-200 ease-in-out ${
              booking === tab.filter ? "" : "text-gray600 dark:text-gray700"
            }`}
          >
            {tab.title}
          </p>
        ))}
      </div>
      <hr className="border-gray400 dark:border-gray800  my-3" />

      <div className=" w-full">
        {booking === "upcoming" && <UpcomingBookings />}
        {booking === "completed" && <PastBookings />}
      </div>
    </div>
  );
};

export default MyBookings;
