import React, { useEffect, useState } from "react";
import PendingReviewCard from "./components/listing-card";
import Pagination from "../../../components/custom/pagination";
// import Calendar from "../../../components/custom/calendar";
import {
  getUserProperties,
  setUserProperties,
} from "../../../redux/reducers/user-slice";
import { useDispatch, useSelector } from "react-redux";
import VerificationCardView from "./components/verification-card-view";
import ListingSkeleton from "../../../components/custom/listing-skeleton";
import { getIsLoading } from "../../../redux/reducers/loader-slice";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../routes/paths";
import { getDashboardStats } from "../../../redux/reducers/property-slice";

const itemsPerPage = 5;

interface PendingReviewProps {}

const PendingReview: React.FC<PendingReviewProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userProperties = useSelector(getUserProperties);
  const [currentPage, setCurrentPage] = useState(1);
  const pendingListings = userProperties;
  const isLoading = useSelector(getIsLoading);
  const stats = useSelector(getDashboardStats);


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    return () => {
      dispatch(setUserProperties([]));
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col gap-10 md:px-7">
      {/* main body */}
      <div className="flex md:flex-row flex-col gap-5 pt-2 font-display text-gray1000 dark:text-gray-100">
        <div className="md:w-[68%] w-full h-fit md:border-r border-gray400 dark:border-gray800 md:pr-5 flex flex-col gap-5">
          <span className="text-base font-bold">
            Pending Review ({stats?.pending_review})
          </span>

          {isLoading && <ListingSkeleton />}

          {pendingListings.length === 0 && !isLoading ? (
            <div className="text-center font-bold flex flex-col rounded-3xl items-center justify-center bg-gray300 dark:bg-gray900 h-[27.5rem]">
              <p className="dark:text-gray200">No pending listings.</p>
              <button
                onClick={() => navigate(paths.HOST_TYPE)}
                className="bg-primary500 text-nowrap hover:bg-primary400 transition-all my-3 text-white rounded-full flex font-semibold items-center justify-center text-sm p-3 gap-2"
              >
                Register a property
              </button>
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-5">
                {pendingListings.map((item, index) => (
                  <PendingReviewCard key={index} property={item} />
                ))}
              </div>
              <Pagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={pendingListings.length}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </div>
        <div className="md:w-[32%] w-full flex flex-col gap-3">
          <VerificationCardView />
          {/* <div className=" w-full bg-gray100 dark:bg-[#141414]  border border-gray400 dark:border-gray800 rounded-xl p-4 flex flex-col gap-1">
            <Calendar />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PendingReview;
