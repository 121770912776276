import { connectRouter } from "connected-react-router";
import { loaderReducer } from "./reducers/loader-slice";

import { BrowserHistory } from "history";
import { userReducer } from "./reducers/user-slice";
import { propertyReducer } from "./reducers/property-slice";

export const createRootReducer = (history: BrowserHistory) => ({
  router: connectRouter(history),
  loader: loaderReducer,
  user: userReducer,
  property: propertyReducer
});
