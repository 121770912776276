import { useCallback, useEffect, useState } from "react";
import useDebounce from "./use-debounce";
import { useDispatch } from "react-redux";
import { autocompleteApi } from "../redux/actions/property";
import { Places } from "../redux/types";

function useGooglePlaces(searchText: string) {
  const dispatch = useDispatch();
  const [places, setPlaces] = useState<Places[]>([]);

  const debouncedValue = useDebounce(searchText, 1000);

  const getPlaces = useCallback(async () => {
    // @ts-ignore
    const residents = (await dispatch(
      // @ts-ignore
      autocompleteApi(debouncedValue)
    )) as Places[]; // autocompletePlaces(debouncedValue);
    setPlaces(residents);

    // eslint-disable-next-line
  }, [debouncedValue]);

  useEffect(() => {
    getPlaces();
    // eslint-disable-next-line
  }, [debouncedValue]);

  return { places };
}

export default useGooglePlaces;
