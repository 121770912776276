import { useRef, useEffect } from "react";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isToday,
  parse,
  startOfToday,
  startOfWeek,
} from "date-fns";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import { useState } from "react";

interface CalendarProps {
  onClose?: () => void;
  updateActiveListing?: (dateStrings: any) => void;
  blockedOutDates: string[];
}

const CalendarDateSelector: React.FC<CalendarProps> = ({
  updateActiveListing,
  blockedOutDates,
  onClose,
}) => {
  const today = startOfToday();
  const days = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  const colStartClasses = [
    "col-start-7", // Sunday
    "col-start-1", // Monday
    "col-start-2", // Tuesday
    "col-start-3", // Wednesday
    "col-start-4", // Thursday
    "col-start-5", // Friday
    "col-start-6", // Saturday
  ];

  const modalRef = useRef<HTMLDivElement>(null);

  const [currMonth, setCurrMonth] = useState(() => format(today, "MMM-yyyy"));
  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  useEffect(() => {
    if (blockedOutDates) {
      if (blockedOutDates?.length > 0) {
        setSelectedDates(blockedOutDates);
      }
    }
  }, [blockedOutDates]);

  let firstDayOfMonth = parse(currMonth, "MMM-yyyy", new Date());

  const daysInMonth = eachDayOfInterval({
    start: startOfWeek(firstDayOfMonth, { weekStartsOn: 1 }),
    end: endOfWeek(endOfMonth(firstDayOfMonth), { weekStartsOn: 1 }),
  });

  const getPrevMonth = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const firstDayOfPrevMonth = add(firstDayOfMonth, { months: -1 });
    setCurrMonth(format(firstDayOfPrevMonth, "MMM-yyyy"));
  };

  const getNextMonth = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const firstDayOfNextMonth = add(firstDayOfMonth, { months: 1 });
    setCurrMonth(format(firstDayOfNextMonth, "MMM-yyyy"));
  };

  const handleModalDayClick = (day: Date) => {
    setSelectedDates((prevDates) => {
      if (prevDates.includes(day.toISOString())) {
        return prevDates.filter((d) => d !== day.toISOString());
      } else {
        return [...prevDates, day.toISOString()];
      }
    });
  };

  const handleConfirmDates = () => {
    if (updateActiveListing) {
      updateActiveListing(selectedDates);
    }
  };

  return (
    <div className="flex items-center justify-center">
      <div className="rounded-lg p-6 w-full max-w-md" ref={modalRef}>
        <h2 className="text-xl font-semibold">Selected blockout date(s)</h2>
        <p className="mt-1">Add days when you do not want to get bookings.</p>
        <div className="flex items-center justify-between w-full mt-5 ">
          <button
            className="cursor-pointer text-sm text-gray600  hover:text-gray900 transition-all duration-300 ease-in-out"
            onClick={getPrevMonth}
          >
            <FaChevronLeft />
          </button>
          <p className="font-semibold text-sm md:text-lg text-gray900 dark:text-gray100">
            {format(firstDayOfMonth, "MMMM yyyy")}
          </p>

          <button
            className="cursor-pointer text-sm text-gray600  hover:text-gray900 transition-all duration-300 ease-in-out"
            onClick={getNextMonth}
          >
            <FaChevronRight />
          </button>
        </div>
        <div className="grid grid-cols-7 gap-7 place-items-center">
          {days.map((day, idx) => (
            <div key={idx} className="font-semibold md:text-base text-xs">
              {day}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-3 mt-4">
          {daysInMonth.map((day, idx) => (
            <div
              key={idx}
              className={`${colStartClasses[getDay(day)]} relative`}
            >
              <p
                className={`cursor-pointer md:text-base text-xs transition-all duration-200 ease-in-out flex items-center justify-center rounded-full w-7 h-7 md:h-10 md:w-10 ${
                  selectedDates.some((d) => d === day.toISOString())
                    ? "bg-gray400 dark:bg-gray800 dark:text-gray100 text-gray1000"
                    : // : isSameMonth(day, today)
                      // ? "text-gray900 dark:text-gray-100"
                      "text-gray600 "
                }
                 ${
                   today.getTime() < day.getTime()
                     ? "text-gray900"
                     : "text-gray600 "
                 } ${
                  !isToday(day) &&
                  "hover:bg-primary200 dark:hover:bg-primary500/30"
                }`}
                onClick={() => {
                  if (today.getTime() < day.getTime()) {
                    handleModalDayClick(day);
                  }
                }}
              >
                {format(day, "d")}
              </p>
            </div>
          ))}
        </div>
        <div className="mt-4 w-full flex items-center gap-2 font-bold text-sm">
          <button
            className="px-4 h-10 w-fit rounded-full bg-gray400 dark:bg-gray800 dark:text-gray100 hover:bg-gray500 transition-all duration-200 ease-in-out"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 h-10 w-full rounded-full text-white bg-primary500 hover:bg-primary400 transition-all duration-200 ease-in-out"
            onClick={handleConfirmDates}
          >
            Confirm Dates
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarDateSelector;
