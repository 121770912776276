import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoading } from "../../../../redux/reducers/loader-slice";
import { useFormFields } from "../../../../hooks/use-form-fields";
import { TextInput } from "../../../../components/custom/text-input";
import { changeUserPassword } from "../../../../redux/actions/authuser";

const SecurityInfo = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const [errorMsg, setErroMsg] = useState("");
  const [formfields, setFieldValue, resetFields] = useFormFields({
    old_password: "",
    new_password: "",
    confirm_newpassword: "",
  });

  const onChangePassword = async () => {
    setErroMsg("");

    if (formfields.confirm_newpassword !== formfields.new_password) {
      setErroMsg("password do not match");
      return;
    }

    await dispatch(
      // @ts-ignore
      changeUserPassword({
        new_password: formfields.new_password,
        old_password: formfields.old_password,
      })
    );

    resetFields();
  };

  return (
    <div className="">
      <div className="pt-2">
        <p className="text-sm font-bold dark:text-gray200">Your password</p>
        <div className="flex flex-col rounded-2xl p-4 bg-gray300 dark:bg-gray1000 dark:border-gray800 border border-gray400 my-2 w-full gap-3">
          <TextInput
            label="Current password*"
            placeholder=""
            onChange={(e) => setFieldValue("old_password", e.target.value)}
            type="password"
            required
            className="lg:w-full"
            value={formfields.old_password}
          />
          <TextInput
            label="New password*"
            placeholder=""
            onChange={(e) => setFieldValue("new_password", e.target.value)}
            type="password"
            required
            className="lg:w-full"
            value={formfields.new_password}
          />
          <TextInput
            label="Confirm new password*"
            placeholder=""
            onChange={(e) =>
              setFieldValue("confirm_newpassword", e.target.value)
            }
            type="password"
            required
            className="lg:w-full"
            value={formfields.confirm_newpassword}
            errorMsg={errorMsg}
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-center gap-2 py-4">
        <button
          onClick={() => console.log("cancle")}
          className="flex text-xs font-display font-semibold  p-2 rounded-full shadow bg-gray500 items-center text-gray1000 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100"
        >
          Cancel
        </button>
        <button
          onClick={onChangePassword}
          disabled={isLoading}
          className={`flex text-xs font-display font-semibold p-2 rounded-full shadow bg-primary500 items-center text-eadWhite transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
        >
          {isLoading ? (
            <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
};

export default SecurityInfo;
