import React from "react";

const TwitterFilled = ({ ...rest }) => (
  <svg
    {...rest}
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.666016"
      width="39"
      height="39"
      rx="19.5"
      fill="#FAFAFA"
    />
    <rect
      x="0.5"
      y="0.666016"
      width="39"
      height="39"
      rx="19.5"
      stroke="#E5E5E5"
    />
    <path
      d="M15.5475 29.9173C24.6042 29.9173 29.5578 22.4139 29.5578 15.907C29.5578 15.6939 29.5578 15.4817 29.5434 15.2705C30.507 14.5735 31.3389 13.7104 32 12.7217C31.1014 13.1201 30.148 13.3813 29.1718 13.4965C30.1998 12.8812 30.9692 11.9132 31.3366 10.7729C30.3701 11.3466 29.3126 11.7508 28.2099 11.9681C27.4675 11.1787 26.4856 10.656 25.4162 10.4808C24.3468 10.3057 23.2494 10.4879 22.294 10.9992C21.3385 11.5105 20.5782 12.3225 20.1307 13.3095C19.6833 14.2965 19.5735 15.4034 19.8186 16.459C17.8609 16.3608 15.9458 15.8521 14.1975 14.9657C12.4492 14.0794 10.9068 12.8354 9.6704 11.3144C9.04073 12.3984 8.84787 13.6816 9.1311 14.9028C9.41433 16.124 10.1523 17.1913 11.1949 17.8875C10.4112 17.8645 9.64465 17.6531 8.96 17.2712V17.3336C8.96031 18.4704 9.35385 19.5722 10.0739 20.452C10.7939 21.3317 11.7961 21.9354 12.9104 22.1605C12.1855 22.3582 11.4249 22.3871 10.687 22.2449C11.0018 23.2233 11.6144 24.0788 12.4392 24.6919C13.264 25.305 14.2598 25.645 15.2874 25.6645C14.2664 26.4669 13.0973 27.0603 11.8469 27.4105C10.5964 27.7607 9.28921 27.8609 8 27.7054C10.2518 29.1504 12.8719 29.9169 15.5475 29.9134"
      fill="#1DA1F2"
    />
  </svg>
);

export default TwitterFilled;
