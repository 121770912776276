import React, { useState } from "react";
import Navbar from "../../../../components/custom/navbar";
import Footer from "../../../../components/custom/footer";
import ArrowTopRightWhite from "../../../../components/icons/arrow-top-right-white";
import AddCircle from "../../../../components/icons/add-circle";
import MinusCircle from "../../../../components/icons/minus-circle";
import { guestfaqs, hostfaqs } from "../../../../utils/static-data";
import MakeBookingSection from "../../../../components/custom/make-bookings-section";

const FAQ = () => {
  const [active, setActive] = useState<number | null>(null);
  const [activeHostfaq, setActiveHostfaq] = useState<number | null>(null);

  const handleToggle = (index: React.SetStateAction<number | null>) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  const handleToggleHost = (index: React.SetStateAction<number | null>) => {
    if (activeHostfaq === index) {
      setActiveHostfaq(null);
    } else {
      setActiveHostfaq(index);
    }
  };

  return (
    <div className="bg-eadWhite dark:bg-gray1000 ">
      <div className="relative max-w-screen-2xl mx-auto pb-24 px-4">
        <Navbar />

        {/* main text faq */}
        <div className="w-full flex flex-col items-start xl:items-center lg:items-center justify-center gap-4 md:gap-5 md:pt-16">
          <p className="text-base font-display font-semibold text-primary800 lg:text-center">
            Frequently Asked Questions
          </p>
          <p className="font-bold font-display text-3xl md:text-4xl text-gray1000 dark:text-gray200">
            Have questions?
          </p>
          <button className="text-xs rounded-full flex py-2 px-4 items-center text-eadWhite font-display font-bold bg-primary500">
            Contact us <ArrowTopRightWhite />
          </button>
        </div>

        {/* faq accordion */}
        <div className="grid md:grid-cols-2 md:mt-20 mt-10 md:px-12 gap-6">
          <div className="w-full">
            <p className="font-display text-xl font-bold text-gray1000 dark:text-gray200">
              Guest FAQs
            </p>
            <p className="font-display text-sm text-gray900 dark:text-gray200">
              Frequently asked questions by guests
            </p>

            {guestfaqs.map((item) => (
              <div
                key={item.id}
                onClick={() => handleToggle(item.id)}
                className={`rounded-2xl my-4 cursor-pointer transition-all duration-300 ease-in-out flex flex-col justify-start md:mt-8 ${
                  active === item.id
                    ? "bg-gray300 dark:bg-gray800 md:p-6 p-4"
                    : "md:px-6 px-4 md:py-4 py-2"
                }`}
              >
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-3">
                    {active === item.id ? (
                      <MinusCircle className="flex-shrink-0" />
                    ) : (
                      <AddCircle className="flex-shrink-0" />
                    )}
                    <p className="font-display md:text-base text-sm text-gray1000 dark:text-gray200 font-semibold ">
                      {item.header}
                    </p>
                  </div>

                  <p
                    className={`${
                      active === item.id
                        ? "scale-100 h-auto translate-x-0"
                        : "scale-0 h-0 invisible opacity-0 translate-y-80"
                    }font-display md:text-sm text-xs text-gray900 dark:text-gray200 transition-all duration-300 ease-in-out ml-9`}
                  >
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="w-full">
            <p className="font-display text-xl font-bold text-gray1000 dark:text-gray200">
              Host FAQs
            </p>
            <p className="font-display text-sm text-gray900 dark:text-gray200">
              Everything you need to know about being an host
            </p>

            {hostfaqs.map((item) => (
              <div
                key={item.id}
                onClick={() => handleToggleHost(item.id)}
                className={`rounded-2xl my-4 cursor-pointer transition-all duration-300 ease-in-out flex flex-col justify-start md:mt-8 ${
                  activeHostfaq === item.id
                    ? "bg-gray300 dark:bg-gray800 md:p-6 p-4"
                    : "md:px-6 px-4 md:py-4 py-2"
                }`}
              >
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-3">
                    {activeHostfaq === item.id ? (
                      <MinusCircle className="flex-shrink-0" />
                    ) : (
                      <AddCircle className="flex-shrink-0" />
                    )}
                    <p className="font-display md:text-base text-sm text-gray1000 dark:text-gray200 font-semibold ">
                      {item.header}
                    </p>
                  </div>

                  <p
                    className={`${
                      activeHostfaq === item.id
                        ? "scale-100 h-auto translate-x-0"
                        : "scale-0 h-0 invisible opacity-0 translate-y-80"
                    }font-display md:text-sm text-xs text-gray900 dark:text-gray200 transition-all duration-300 ease-in-out ml-9`}
                  >
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* make bookings */}
        <MakeBookingSection />
        <Footer />
      </div>
    </div>
  );
};

export default FAQ;
