import Bedroom from "../icons/bedroom";
import Pills from "./pills";
import Bathroom from "../icons/bathroom";
import Location from "../icons/location";
import Avatar from "../icons/avatar";
// import HeartEmpty from "../icons/heart-empty";
import { Link } from "react-router-dom";
import { currencyFormat, truncate } from "../../utils/helper-functions";
import { PropertyType } from "../../redux/types";
import ToiletIcon from "../icons/toilet";
import { Highlight } from "react-instantsearch";
import { useDispatch, useSelector } from "react-redux";
import { getFavourites } from "../../redux/reducers/property-slice";
import HeartFull from "../icons/heart-full";
import HeartEmpty from "../icons/heart-empty";
import {
  addFavouriteProperty,
  removeFavouriteProperty,
} from "../../redux/actions/property";
// import Carousel from "./carousel";

type Props = {
  price: string;
  icon?: any;
  pic?: string;
  title?: string;
  description?: string;
  location?: string;
  hostname?: string;
  to?: string;
  item?: PropertyType;
  hit?: any;
};

const SearchListing = ({
  title,
  icon,
  pic,
  price,
  description,
  location,
  hostname,
  to,
  item,
  hit,
}: Props) => {
  const dispatch = useDispatch();
  const favouritesProperties = useSelector(getFavourites);
  const isSaved = favouritesProperties
    .map((item) => item?.id)
    .includes(hit?.id);
  return (
    <div className="lg:flex my-4 gap-2 items-center relative w-full flex-shrink-0">
      <div className="w-full lg:w-1/2 relative">
        <Link to={to || "#"} target="_blank">
          <img src={pic} className="h-52 w-full rounded-lg" alt="" />
        </Link>
        <div className="flex items-center justify-between lg:hidden 2xl:hidden">
          {item?.is_verified && (
            <div className="flex gap-1 rounded-full items-center p-1 absolute top-2 left-2">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2334 1.12283C11.7059 0.841894 12.2941 0.841894 12.7666 1.12283L14.4102 2.10012C14.6478 2.24138 14.9198 2.31425 15.1961 2.3107L17.1082 2.28614C17.6578 2.27908 18.1673 2.57323 18.436 3.05277L19.3708 4.72093C19.5059 4.96204 19.705 5.16113 19.9461 5.29624L21.6142 6.231C22.0938 6.4997 22.3879 7.00918 22.3808 7.55883L22.3563 9.47088C22.3527 9.74724 22.4256 10.0192 22.5669 10.2568L23.5442 11.9004C23.8251 12.3728 23.8251 12.9611 23.5442 13.4336L22.5669 15.0772C22.4256 15.3148 22.3527 15.5867 22.3563 15.8631L22.3808 17.7752C22.3879 18.3248 22.0938 18.8343 21.6142 19.103L19.9461 20.0377C19.705 20.1729 19.5059 20.3719 19.3708 20.6131L18.436 22.2812C18.1673 22.7608 17.6578 23.0549 17.1082 23.0478L15.1961 23.0233C14.9198 23.0197 14.6478 23.0926 14.4102 23.2339L12.7666 24.2112C12.2941 24.4921 11.7059 24.4921 11.2334 24.2112L9.58977 23.2339C9.35221 23.0926 9.08024 23.0197 8.80388 23.0233L6.89183 23.0478C6.34219 23.0549 5.83271 22.7608 5.564 22.2812L4.62925 20.6131C4.49414 20.3719 4.29505 20.1729 4.05394 20.0377L2.38577 19.103C1.90624 18.8343 1.61209 18.3248 1.61915 17.7752L1.64371 15.8631C1.64726 15.5867 1.57439 15.3148 1.43313 15.0772L0.455837 13.4336C0.174901 12.9611 0.174901 12.3728 0.455837 11.9004L1.43313 10.2568C1.57439 10.0192 1.64726 9.74724 1.64371 9.47088L1.61915 7.55883C1.61209 7.00918 1.90624 6.4997 2.38577 6.231L4.05394 5.29624C4.29505 5.16113 4.49414 4.96204 4.62925 4.72093L5.564 3.05277C5.83271 2.57323 6.34219 2.27908 6.89183 2.28614L8.80388 2.3107C9.08024 2.31425 9.35221 2.24138 9.58977 2.10012L11.2334 1.12283Z"
                  fill="#2F80ED"
                />
                <path
                  d="M16.5 15.7509V12.1335C16.5 11.8463 16.5 11.7027 16.463 11.5705C16.4302 11.4533 16.3763 11.3432 16.304 11.2454C16.2223 11.135 16.109 11.0468 15.8823 10.8705L15.8823 10.8705L12.4912 8.23295C12.3155 8.09633 12.2277 8.02801 12.1307 8.00175C12.0451 7.97858 11.9549 7.97858 11.8693 8.00175C11.7723 8.02801 11.6845 8.09633 11.5088 8.23295L8.1177 10.8705L8.11769 10.8705C7.89101 11.0468 7.77767 11.135 7.69601 11.2454C7.62368 11.3432 7.5698 11.4533 7.53701 11.5705C7.5 11.7027 7.5 11.8463 7.5 12.1335V15.7509C7.5 16.311 7.5 16.591 7.60899 16.8049C7.70487 16.9931 7.85785 17.1461 8.04601 17.242C8.25992 17.3509 8.53995 17.3509 9.1 17.3509H14.9C15.4601 17.3509 15.7401 17.3509 15.954 17.242C16.1422 17.1461 16.2951 16.9931 16.391 16.8049C16.5 16.591 16.5 16.311 16.5 15.7509Z"
                  stroke="#FCFCFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className="text-xs font-display text-green-500 font-medium">
                Verified
              </span>
            </div>
          )}
          <button
            className="absolute top-2 right-2"
            onClick={() => {
              isSaved
                ? // @ts-ignore
                  dispatch(removeFavouriteProperty(hit?.id))
                : // @ts-ignore
                  dispatch(addFavouriteProperty({ property_id: hit?.id }));
            }}
          >
            {isSaved ? (
              <HeartFull className="h-8" />
            ) : (
              <HeartEmpty className="h-8" />
            )}
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-1 w-full">
        <div className="lg:flex items-center justify-between hidden">
          {item?.is_verified && (
            <div className="flex gap-1 rounded-full items-center p-1">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2334 1.12283C11.7059 0.841894 12.2941 0.841894 12.7666 1.12283L14.4102 2.10012C14.6478 2.24138 14.9198 2.31425 15.1961 2.3107L17.1082 2.28614C17.6578 2.27908 18.1673 2.57323 18.436 3.05277L19.3708 4.72093C19.5059 4.96204 19.705 5.16113 19.9461 5.29624L21.6142 6.231C22.0938 6.4997 22.3879 7.00918 22.3808 7.55883L22.3563 9.47088C22.3527 9.74724 22.4256 10.0192 22.5669 10.2568L23.5442 11.9004C23.8251 12.3728 23.8251 12.9611 23.5442 13.4336L22.5669 15.0772C22.4256 15.3148 22.3527 15.5867 22.3563 15.8631L22.3808 17.7752C22.3879 18.3248 22.0938 18.8343 21.6142 19.103L19.9461 20.0377C19.705 20.1729 19.5059 20.3719 19.3708 20.6131L18.436 22.2812C18.1673 22.7608 17.6578 23.0549 17.1082 23.0478L15.1961 23.0233C14.9198 23.0197 14.6478 23.0926 14.4102 23.2339L12.7666 24.2112C12.2941 24.4921 11.7059 24.4921 11.2334 24.2112L9.58977 23.2339C9.35221 23.0926 9.08024 23.0197 8.80388 23.0233L6.89183 23.0478C6.34219 23.0549 5.83271 22.7608 5.564 22.2812L4.62925 20.6131C4.49414 20.3719 4.29505 20.1729 4.05394 20.0377L2.38577 19.103C1.90624 18.8343 1.61209 18.3248 1.61915 17.7752L1.64371 15.8631C1.64726 15.5867 1.57439 15.3148 1.43313 15.0772L0.455837 13.4336C0.174901 12.9611 0.174901 12.3728 0.455837 11.9004L1.43313 10.2568C1.57439 10.0192 1.64726 9.74724 1.64371 9.47088L1.61915 7.55883C1.61209 7.00918 1.90624 6.4997 2.38577 6.231L4.05394 5.29624C4.29505 5.16113 4.49414 4.96204 4.62925 4.72093L5.564 3.05277C5.83271 2.57323 6.34219 2.27908 6.89183 2.28614L8.80388 2.3107C9.08024 2.31425 9.35221 2.24138 9.58977 2.10012L11.2334 1.12283Z"
                  fill="#2F80ED"
                />
                <path
                  d="M16.5 15.7509V12.1335C16.5 11.8463 16.5 11.7027 16.463 11.5705C16.4302 11.4533 16.3763 11.3432 16.304 11.2454C16.2223 11.135 16.109 11.0468 15.8823 10.8705L15.8823 10.8705L12.4912 8.23295C12.3155 8.09633 12.2277 8.02801 12.1307 8.00175C12.0451 7.97858 11.9549 7.97858 11.8693 8.00175C11.7723 8.02801 11.6845 8.09633 11.5088 8.23295L8.1177 10.8705L8.11769 10.8705C7.89101 11.0468 7.77767 11.135 7.69601 11.2454C7.62368 11.3432 7.5698 11.4533 7.53701 11.5705C7.5 11.7027 7.5 11.8463 7.5 12.1335V15.7509C7.5 16.311 7.5 16.591 7.60899 16.8049C7.70487 16.9931 7.85785 17.1461 8.04601 17.242C8.25992 17.3509 8.53995 17.3509 9.1 17.3509H14.9C15.4601 17.3509 15.7401 17.3509 15.954 17.242C16.1422 17.1461 16.2951 16.9931 16.391 16.8049C16.5 16.591 16.5 16.311 16.5 15.7509Z"
                  stroke="#FCFCFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span className="text-xs font-display text-green-700 font-medium">
                Verified
              </span>
            </div>
          )}
          <button
            className="absolute top-2 right-2"
            onClick={() => {
              isSaved
                ? // @ts-ignore
                  dispatch(removeFavouriteProperty(hit?.id))
                : // @ts-ignore
                  dispatch(addFavouriteProperty({ property_id: hit?.id }));
            }}
          >
            {isSaved ? (
              <HeartFull className="h-8" />
            ) : (
              <HeartEmpty className="h-8" />
            )}
          </button>
        </div>
        <Link
          to={to || "#"}
          target="_blank"
          className="flex items-center gap-2 mt-2"
        >
          <p className="text-xl font-display font-bold dark:text-gray200">
            {currencyFormat(price || 0)}
          </p>
          <p className="text-sm font-display text-gray900 dark:text-gray200">
            per night
          </p>
        </Link>
        <Link
          to={to || "#"}
          target="_blank"
          className="font-display text-base font-bold text-gray900 dark:text-gray200"
        >
          <Highlight attribute="name" hit={hit} />
        </Link>
        <div className="flex flex-col gap-1">
          <Link to={to || "#"} target="_blank">
            <p className="text-sm text-gray800 font-display dark:text-gray200">
              {truncate(description || "", 100)}
            </p>
          </Link>
          <Link
            to={to || "#"}
            target="_blank"
            className="flex items-center gap-2 overflow-x-scroll no-scrollbar my-1"
          >
            <Pills
              title={`${item?.bedrooms}Bedroom`}
              icon={<Bedroom className="w-3" />}
            />
            <Pills
              title={`${item?.bathrooms}Bathoom`}
              icon={<Bathroom className="w-3" />}
            />
            <Pills
              title={`${item?.toilets}Toilet`}
              icon={<ToiletIcon className="w-3" />}
            />
            <Pills
              title={`${item?.guests}Guest`}
              icon={<Bedroom className="w-3" />}
            />
          </Link>
          <Link
            to={to || "#"}
            target="_blank"
            className="flex gap-2 items-center"
          >
            <Location />
            <p className="text-xs font-display text-gray800 dark:text-gray200">
              <Highlight attribute="address" hit={hit} />
            </p>
          </Link>
          <div className="lg:flex items-center justify-between hidden">
            <Link
              to={to || "#"}
              target="_blank"
              className="flex items-center gap-2 mt-1"
            >
              <Avatar className="h-6" />
              <p className="text-xs font-display text-gray-800 dark:text-gray200">
                Hosted by {hostname}
              </p>
            </Link>
            {/* <div className="flex gap-2">
              <Link
                className="flex"
                to={`mailto:${item?.contact_email}?subject=Bookings&body=Hello%20i%20want%20to%20book%20this%20property%20${item?.share_link}`}
              >
                <Emailicon className="h-4" />
                <span className="text-xs font-display dark:text-gray200">
                  Email
                </span>
              </Link>
              <Link
                className="flex"
                to={`https://wa.me/08054062639?text=Hello i want to book this property ${item?.share_link}`}
                target="_blank"
              >
                <Whatsapp className="h-4" />
                <span className="text-xs font-display dark:text-gray200">
                  Whatsapp
                </span>
              </Link>
              <Link className="flex" to={`tel:${item?.contact_phone_number}`}>
                <Phoneicon className="h-4" />
                <span className="text-xs font-display dark:text-gray200">
                  Call
                </span>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchListing;
