import { useState } from "react";
import { paths } from "../../../../routes/paths";
import BreadcrumbsTwo from "../../host-dashboard/components/breadcrumb-two";
import ActiveVerification from "./ActiveVerification";
import IncompleteVerification from "./IncompleteVerification";

const Verification = () => {
  const [settings, setSettings] = useState("Uncompleted");

  return (
    <div>
      <div className="mb-10">
        <BreadcrumbsTwo
          name="Verification"
          path={`${paths.GUEST}/verification`}
        />
      </div>
      <div className="flex items-center gap-3">
        <p
          onClick={() => setSettings("Active")}
          className={`font-display text-sm lg:text-base font-bold cursor-pointer text-gray1000 dark:text-gray200 ${
            settings === "Active" ? "" : "text-gray600 dark:text-gray700"
          }`}
        >
          Active
        </p>
        <p
          onClick={() => setSettings("Uncompleted")}
          className={`font-display text-sm lg:text-base font-bold cursor-pointer text-gray1000 dark:text-gray200 ${
            settings === "Uncompleted" ? "" : "text-gray600 dark:text-gray700"
          }`}
        >
          Uncompleted
        </p>
      </div>
      <hr className="border-gray400 dark:border-gray800  my-3" />

      <div className=" w-full">
        {settings === "Active" && <ActiveVerification />}
        {settings === "Uncompleted" && <IncompleteVerification />}
      </div>
    </div>
  );
};

export default Verification;
